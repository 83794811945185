import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace MapActions
 * @category Actions
 */

/**
 * Creates an action with information on whether or not a map is loading.
 *
 * @param {boolean} isMapLoading - whether or not a map is loading
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf MapActions
 * @alias mapIsLoading
 * @function
 */
export const mapIsLoading = (isMapLoading) => ({
  type: ActionTypes.MAP_IS_LOADING,
  payload: isMapLoading
});

/**
 * Creates an action with latitude, longitude, and zoom level of the stop to zoom into.
 *
 * @param {float} lat - latitude coordinate
 * @param {float} lng - longitude coordinate
 * @param {int} zoom - specific zoom level to determine how much of the world should be made visible on a map
 * @returns {{payload: {lng: *, zoom: *, lat: *}, type: string}} Action with a payload
 * @memberOf MapActions
 * @alias centerToStop
 * @function
 */
export const centerToStop = (lat, lng, zoom) => ({
  type: ActionTypes.CENTER_MAP_TO_STOP,
  payload: { lat, lng, zoom }
});

export const setMap = (map) => ({
  type: ActionTypes.SET_MAP,
  payload: map
});

export const resizeMap = () => ({ type: ActionTypes.RESIZE_MAP });
