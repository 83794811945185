import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './CopyToClipboardWrapper.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

export default function CopyToClipboardWrapper(props) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const copyTimeoutHandle = useRef(null);

  const onCopy = useCallback(() => {
    setCopied(true);
    if (copyTimeoutHandle.current) {
      clearTimeout(copyTimeoutHandle.current);
    }
    copyTimeoutHandle.current = setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [setCopied]);

  useEffect(
    () => () => {
      clearTimeout(copyTimeoutHandle.current);
    },
    []
  );

  return (
    <CopyToClipboard text={props.text} onCopy={onCopy}>
      <div className="copy-to-clipboard-wrapper">
        <i className="icon icon-content-copy" />
        {copied && <div className="copied">{t('copied')}</div>}
      </div>
    </CopyToClipboard>
  );
}

CopyToClipboardWrapper.propTypes = { text: PropTypes.string };

CopyToClipboardWrapper.defaultProps = { text: null };
