import * as React from 'react';
import mapboxgl from 'mapbox-gl';
import * as _ from 'lodash';

import './MapWrapper.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import moment from 'moment';

import 'react-toggle/style.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import { raygunClient } from '../../../../setup/raygunClient';
import * as MapActions from '../../../../state/actions/mapActions';
import AuthUtil from '../../../../common/utils/authUtil';
import MixPanel from '../../../../setup/mixPanel';
import CourierMovementHeatmapClass from '../utils/courierMovementHeatmapClass';
import StopsUtilClass from '../utils/stopsClass';
import MapHelperUtil from '../utils/mapHelpersUtil';
import MissedPointsClass from '../utils/missedPointsClass';
import RegionClass from '../utils/regionClass';
import stopsUtil from '../../utils/stopsUtil';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import CourierAnalysisApi from '../api/courierAnalysisApi';
import { ENTITY_TYPE } from '../../../../common/constants/entityTypes';
import MapUtil from '../../../../common/utils/mapUtil';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const delivery = ['==', ['get', 'deliveryType'], 'delivery'];
const pickup = ['==', ['get', 'deliveryType'], 'pickup'];
const RELEVANT_DISTANCE = 500;
const MAX_LAT = 90;
const MAX_LNG = 200;

/**
 * A wrapper for CourierAnalysis map functionalities
 *
 * @component
 * @alias MapWrapperClass
 * @category CourierAnalysis
 */
class MapWrapperClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfMissedStops: 0,
      isGeoFilterOn: false,
      isHeatMapViewOn: false
    };

    this.map = null;
    this.deliveries = null;
    this.pickups = null;
    this.allStops = null;

    this.zoom = 7.2;
    this.mapLayers = [];

    this.defaultView = MapUtil.getInitialViewStateForCompany();

    props.dispatchIsMapLoading(true);
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [this.defaultView.lng, this.defaultView.lat],
      zoom: this.defaultView.zoom
    });

    this.map = map;
    this.props.dispatchSetMap(map);
    this.CourierMovementHeatmapUtil = new CourierMovementHeatmapClass(map, this.props.t);
    this.StopsUtil = new StopsUtilClass(map, this.props.t);
    this.MissedPointsUtil = new MissedPointsClass(map, this.props.t, this.state.isGeoFilterOn);
    this.RegionUtil = new RegionClass(map);

    this.addMapControls();
    this.setMapEvents();

    this.props.dispatchIsMapLoading(false);
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.hasStops && !this.props.hasStops) || prevProps.teamId !== this.props.teamId) {
      this.StopsUtil.removeAllMarkers();
      this.RegionUtil.hideRegionLayer();
      this.StopsUtil.hideStopsLayer();
      this.CourierMovementHeatmapUtil.hideHeatmapLayer();
    }

    if (
      prevProps.showDelivery !== this.props.showDelivery
      || prevProps.showPickup !== this.props.showPickup
      || prevProps.selectedDay !== this.props.selectedDay
      || prevProps.stopsData !== this.props.stopsData
    ) {
      this.StopsUtil.removeAllMarkers();

      const didHeatmapOrBarChartChange = prevProps.selectedDay !== this.props.selectedDay;
      const didCourierOrDateChange = prevProps.stopsData !== this.props.stopsData;

      if (didCourierOrDateChange) {
        this.RegionUtil.hideRegionLayer();
        this.StopsUtil.hideStopsLayer();
        if (this.state.isGeoFilterOn) {
          this.toggleGeoFilter();
        }
      }

      this.loadMapData(didHeatmapOrBarChartChange, didCourierOrDateChange);
    }

    if (
      this.props.entityType === ENTITY_TYPE.PACKAGE_LOCKERS
      && this.props.stopsData
      && ((prevProps.stopsData && prevProps.stopsData.courierId !== this.props.stopsData.courierId) || (!prevProps.stopsData && this.props.stopsData.courierId))
    ) {
      if (this.props.stopsData.stops && this.props.stopsData.stops.length > 0) {
        this.map.flyTo({
          center: [this.props.stopsData.stops[0].lng, this.props.stopsData.stops[0].lat],
          zoom: 17
        });
      }
    }
  }

  /**
   * Place where we set all map events
   *
   * @function
   */
  setMapEvents = () => {
    this.map.on('data', (e) => {
      if (e.isSourceLoaded) {
        this.StopsUtil.updateMarkers(this.props.entityType);
      }
    });
    this.map.on('move', () => {
      this.StopsUtil.updateMarkers(this.props.entityType);
    });
    this.map.on('moveend', () => {
      this.StopsUtil.updateMarkers(this.props.entityType);
    });

    this.map.on('mousemove', (e) => {
      const features = this.map.queryRenderedFeatures(e.point);
      let hoveredRegion = null;

      features.forEach((feature) => {
        if (feature.properties.kind === 'POLYGON') {
          hoveredRegion = `${this.props.t('Region')}: ${feature.properties.title.replace(/['"]+/g, '')}`;
        }
      });

      document.getElementById('region-displayer').textContent = hoveredRegion;
    });

    let layerReordered = false;
    this.map.on('styledata', () => {
      const layers = ['geocoded-layer', 'route', 'stop-point', 'courier-movement-geo-locations-layer', 'courier-movement-heatmap-layer', 'region-layer'];
      if (!layerReordered && MapUtil.doesMapHaveLayers(this.map, layers)) {
        const hasAllLayers = this.mapLayers.reduce((result, layer) => result && !!this.map.getLayer(layer), true);
        if (hasAllLayers) {
          this.map.moveLayer('geocoded-layer', 'route');
          this.map.moveLayer('stop-point', 'geocoded-layer');
          this.map.moveLayer('courier-movement-geo-locations-layer', 'stop-point');
          this.map.moveLayer('courier-movement-heatmap-layer', 'courier-movement-geo-locations-layer');
          this.map.moveLayer('region-layer', 'courier-movement-heatmap-layer');
          layerReordered = true;
        }
      }
    });
  };

  /**
   * Place where we add all map controls
   *
   * @function
   */
  addMapControls = () => {
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  /**
   * Add all map sources
   *
   * @param {object.<Array>} deliveries - all deliveries
   * @param {object.<Array>} pickups - all pickups
   * @param {object.<Array>} missedDeliveries - missed deliveries
   * @param {object.<Array>} missedPickups - missed pickups
   * @param {object.<Array>} allStops - all stops
   * @param {boolean} didHeatmapOrBarChartChange - if heatmap or barchart were updated
   * @param {boolean} didCourierOrDateChange - if courier od date were changed
   * @function
   */
  addAllSources = (deliveries, pickups, missedDeliveries, missedPickups, allStops, didHeatmapOrBarChartChange, didCourierOrDateChange) => {
    if (this.map.getSource('stops')) {
      this.map.getSource('stops').setData({
        features: [...deliveries, ...pickups],
        type: 'FeatureCollection'
      });
      this.map.getSource('unclustered_stops').setData({
        features: [...deliveries, ...pickups],
        type: 'FeatureCollection'
      });
      this.map.getSource('geocodedExpectedStops').setData({
        features: [...missedDeliveries, ...missedPickups],
        type: 'FeatureCollection'
      });
    } else {
      this.map.addSource('stops', {
        type: 'geojson',
        data: {
          features: [...deliveries, ...pickups],
          type: 'FeatureCollection'
        },
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 50,
        clusterProperties: {
          delivery: ['+', ['case', delivery, 1, 0]],
          pickup: ['+', ['case', pickup, 1, 0]]
        }
      });

      this.map.addSource('unclustered_stops', {
        type: 'geojson',
        data: {
          features: allStops || [],
          type: 'FeatureCollection'
        }
      });

      this.map.addSource('geocodedExpectedStops', {
        type: 'geojson',
        data: {
          features: [...missedDeliveries, ...missedPickups],
          type: 'FeatureCollection'
        }
      });
    }

    if (!this.map.getSource('route')) {
      this.map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: []
          }
        }
      });
    }

    if (this.map.getSource('courier-movement-heatmap')) {
      if (didHeatmapOrBarChartChange || didCourierOrDateChange) {
        this.map.getSource('courier-movement-heatmap').setData({
          features: this.getCourierMovementFeatureCollection(),
          type: 'FeatureCollection'
        });
      }
    } else {
      this.map.addSource('courier-movement-heatmap', {
        type: 'geojson',
        data: {
          features: this.getCourierMovementFeatureCollection(),
          type: 'FeatureCollection'
        }
      });
    }
  };

  /**
   * Add all sources and layers needed for courier analysis
   *
   * @param {boolean} didHeatmapOrBarChartChange - did chart change
   * @param {boolean} didCourierOrDateChange - did courier or date change
   * @function
   */
  loadMapData = async (didHeatmapOrBarChartChange = false, didCourierOrDateChange = true) => {
    if (didHeatmapOrBarChartChange || didCourierOrDateChange) {
      const geoFeatures = this.getStopGeoFeatures();
      this.deliveries = geoFeatures.deliveryGeoFeatures;
      this.pickups = geoFeatures.pickupGeoFeatures;
      this.allStops = [...this.deliveries, ...this.pickups];
      if (didCourierOrDateChange && geoFeatures.bounds) {
        if (geoFeatures.bounds[0][0] + geoFeatures.bounds[0][1] + geoFeatures.bounds[1][0] + geoFeatures.bounds[1][1]) this.map.fitBounds(geoFeatures.bounds, { padding: 100 });
        else this.map.flyTo({ center: [this.defaultView.lng, this.defaultView.lat], zoom: this.defaultView.zoom });
      }
    }

    const deliveries = this.props.showDelivery && this.deliveries ? [...this.deliveries] : [];
    const pickups = this.props.showPickup && this.pickups ? [...this.pickups] : [];

    const missedD = deliveries.filter((stops) => stops.properties.missedDistance > RELEVANT_DISTANCE);

    const missedP = pickups.filter((stops) => stops.properties.missedDistance > RELEVANT_DISTANCE);
    this.setState({ numberOfMissedStops: missedP.length + missedD.length });

    // wait for map to finish loading
    const intervalHandler = setInterval(async () => {
      const isDone = Array.from({ length: 10 }, () => this.map.isStyleLoaded()).every((l) => l === true);

      if (isDone) {
        clearInterval(intervalHandler);

        this.addAllSources(deliveries, pickups, missedD, missedP, this.allStops, didHeatmapOrBarChartChange, didCourierOrDateChange);

        if (didCourierOrDateChange) {
          this.props.dispatchIsMapLoading(true);
          const regionPolygon = await this.getRegionPolygon();
          if (!_.isEmpty(regionPolygon)) this.RegionUtil.plotRegion(regionPolygon, this.zoom);
        }

        this.CourierMovementHeatmapUtil.plotCourierHeatmap(this.state.isHeatMapViewOn);
        this.MissedPointsUtil.plotMissedPoints();
        this.StopsUtil.plotStops(this.state.isGeoFilterOn);

        this.props.dispatchIsMapLoading(false);
      }
    }, 250);
  };

  /**
   * Toggle geo filter view
   *
   * @function
   */
  toggleGeoFilter = () => {
    this.setState(
      (prevState) => ({ isGeoFilterOn: !prevState.isGeoFilterOn }),
      () => {
        MixPanel.track(`Courier Analysis - GeoFilter toggle turned ${this.state.isGeoFilterOn ? 'ON' : 'OFF'}`);
        this.MissedPointsUtil.toggleVisibility(this.state.isGeoFilterOn);
        this.StopsUtil.toggleStopLayer(!this.state.isGeoFilterOn);
      }
    );
  };

  /**
   * Toggle heatmap view
   *
   * @function
   */
  toggleHeatMapView = () => {
    this.setState(
      (prevState) => ({ isHeatMapViewOn: !prevState.isHeatMapViewOn }),
      () => {
        MixPanel.track(`Courier Analysis - Heatmap toggle turned ${this.state.isHeatMapViewOn ? 'ON' : 'OFF'}`);
        this.CourierMovementHeatmapUtil.toggleVisibility(this.state.isHeatMapViewOn);
      }
    );
  };

  /**
   * Get data for displaying region
   *
   * @returns {object} - region polygon
   * @function
   */
  getRegionPolygon = async () => {
    const regionId = this.props.stopsData?.statistics?.region;
    const response = await CourierAnalysisApi.getRegion(regionId).catch((error) => {
      raygunClient.send(error, 'Error loading courier region');
    });

    if (!response || _.isEmpty(response.regionPolygon)) {
      return null;
    }
    return JSON.parse(response.regionPolygon);
  };

  /**
   * Get heatmap feature collection
   *
   * @function
   * @returns {object.<Array>} feature collection
   */
  getCourierMovementFeatureCollection = () => {
    const featureCollection = [];

    if (this.props.stopsData && this.props.stopsData.courierGpsLocations) {
      this.props.stopsData.courierGpsLocations.forEach((point) => {
        let shouldPush = true;
        if (this.props.selectedDay?.size > 0) {
          shouldPush = this.props.selectedDay.has(point.dayOfTheWeek);
        }

        if (this.props.selectedHours?.size > 0) {
          shouldPush = shouldPush && this.props.selectedHours.has(`${point.hour}`);
        }

        if (!shouldPush) {
          return;
        }

        const feature = {
          type: 'Feature',
          properties: {
            dateTime: `${moment.parseZone(point.gpsTimestamp).format('DD.MM.YYYY HH:mm')}`,
            dayOfTheWeek: point.dayOfTheWeek
          },
          geometry: {
            coordinates: [point.lng, point.lat],
            type: 'Point'
          }
        };
        featureCollection.push(feature);
      });
    }

    return featureCollection;
  };

  /**
   * Get stops feature collection
   *
   * @returns {object} delivery and pickups geo-features
   * @function
   */
  getStopGeoFeatures = () => {
    const deliveries = [];
    const pickups = [];
    const northEast = [-MAX_LNG, -MAX_LAT];
    const southWest = [MAX_LNG, MAX_LAT];

    if (this.props.stopsData && this.props.stopsData.stops) {
      this.props.stopsData.stops.forEach((stop) => {
        if (stop.lng && stop.lat && parseInt(stop.lng, 10) !== 0 && parseInt(stop.lat, 10) !== 0) {
          northEast[1] = northEast[1] > stop.lat ? northEast[1] : stop.lat;
          northEast[0] = northEast[0] > stop.lng ? northEast[0] : stop.lng;
          southWest[1] = southWest[1] < stop.lat ? southWest[1] : stop.lat;
          southWest[0] = southWest[0] < stop.lng ? southWest[0] : stop.lng;
        }

        let shouldPushStop = true;
        if (this.props.selectedDay?.size > 0) {
          shouldPushStop = this.props.selectedDay.has(stop.dayOfTheWeek);
        }

        if (this.props.selectedHours?.size > 0) {
          shouldPushStop = shouldPushStop && this.props.selectedHours.has(`${stop.hour}`);
        }

        if (!shouldPushStop) {
          return;
        }

        const feature = {
          type: 'Feature',
          properties: {
            id: Math.random().toString(36).substring(7),
            entityType: this.props.entityType,
            missedDistance: stop.geoDistance,
            actualLong: MapHelperUtil.getStringFromFloat(stop.geoLng),
            actualLat: MapHelperUtil.getStringFromFloat(stop.geoLat),
            time: stop.timestamp,
            dayOfTheWeek: stop.dayOfTheWeek,
            shipmentCode: stop.shipmentCodes[0],
            numberOfShipments: stop.shipmentCodes.length,
            numberOfPackages: stop.numPackages
          },
          geometry: {
            coordinates: [stop.lng, stop.lat],
            type: 'Point'
          }
        };

        feature.properties.name = stop.name;
        feature.properties.address = stop.address;
        feature.properties.deliveryType = stopsUtil.getStopType(stop.event);

        if (feature.properties.deliveryType === 'delivery') {
          deliveries.push(feature);
        } else {
          pickups.push(feature);
        }
      });
    }

    return {
      deliveryGeoFeatures: deliveries,
      pickupGeoFeatures: pickups,
      bounds: [southWest, northEast]
    };
  };

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  render() {
    return (
      <div className="courier-analysis-map-wrapper">
        {AuthUtil.isFeatureEnabled('courierMovement') && (
          <div
            className={this.state.isHeatMapViewOn ? 'heat-map-icon-wrapper active' : 'heat-map-icon-wrapper'}
            onClick={this.toggleHeatMapView}
            data-tip={this.props.t('courierHeatMap')}
            data-for="courier-heat-map-tooltip"
          >
            <i className="icon icon-delivery-van" />

            <ReactTooltip id="courier-heat-map-tooltip" className="tooltip" place="right" effect="solid" />
          </div>
        )}

        {AuthUtil.isFeatureEnabled('misdeliveries') && (
          <div
            className={this.state.isGeoFilterOn ? 'geo-filter active' : 'geo-filter'}
            onClick={this.toggleGeoFilter}
            data-for="geo-filter-tooltip"
            data-html
            data-tip={this.props.t('Show mismatch addresses')}
          >
            <i className="icon-wrong-location" />

            <div className="number-of-missed-stops">{this.state.numberOfMissedStops}</div>

            <ReactTooltip id="geo-filter-tooltip" className="tooltip" place="right" effect="solid" />
          </div>
        )}

        <MapBoxWrapper setMapRef={this.setMapRef} />
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.chartState, ...store.authState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ dispatchIsMapLoading: MapActions.mapIsLoading, dispatchSetMap: MapActions.setMap }, dispatch);
}

MapWrapperClass.propTypes = {
  /**
   * Chart data
   */
  stopsData: PropTypes.shape({
    stops: PropTypes.arrayOf(PropTypes.object),
    statistics: PropTypes.shape({ region: PropTypes.string }),
    courierId: PropTypes.string,
    courierGpsLocations: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  /**
   * Flag for showing pickups on chart
   */
  showPickup: PropTypes.bool,
  /**
   * Flag for showing deliveries on chart
   */
  showDelivery: PropTypes.bool,
  /**
   * Set if map is loading
   */
  dispatchIsMapLoading: PropTypes.func.isRequired,
  /**
   * Selected team ID
   */
  teamId: PropTypes.string.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * True if we are done loading stops
   */
  hasStops: PropTypes.bool,
  /**
   * Days selected on charts
   */
  selectedDay: PropTypes.object,
  /**
   * Hours selected on charts
   */
  selectedHours: PropTypes.object,

  /**
   * Entity type
   */
  entityType: PropTypes.string,
  dispatchSetMap: PropTypes.func.isRequired
};

MapWrapperClass.defaultProps = {
  stopsData: null,
  showPickup: true,
  showDelivery: true,
  hasStops: false,
  selectedDay: null,
  selectedHours: null,
  entityType: ENTITY_TYPE.COURIERS
};

const MapWrapper = connect(mapStateToProps, mapDispatchToProps)(MapWrapperClass);

export default withTranslation('translations')(MapWrapper);
