import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import TableActions from '../../../../../common/components/tables/common/TableActions';
import TableComponent from '../../../../../common/components/tables/tableComponent/TableComponent';
import IdUtils from '../../../../../common/utils/idUtil';
import MixPanel from '../../../../../setup/mixPanel';
import { raygunClient } from '../../../../../setup/raygunClient';
import * as PageActions from '../../../../../state/actions/pageActions';
import './PolicyManagement.scss';
import PermissionsApi from '../../api/permissionsApi';
import PolicyForm from './PolicyForm';

class PolicyManagementClass extends React.Component {
  constructor() {
    super();
    this.state = { tablePageSize: 1 };
  }

  getTableColumns = () => [
    {
      Header: this.props.t('Policy name'),
      accessor: 'name'
    },
    {
      Header: this.props.t('Description'),
      accessor: 'description'
    },
    { accessor: 'id' },
    {
      Header: this.props.t('Actions'),
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editPolicy} />
    }
  ];

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize === 1 || this.state.tablePageSize < pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  upsertPolicy = (policyId, newPayload, upsertType) => {
    this.props.dispatchLoadingPage(true);
    PermissionsApi.upsertPolicies(policyId, newPayload)
      .then((res) => {
        if (res?.data?.upsertPolicy > 0) {
          this.props.refreshData();
          MixPanel.track(`User Management - Policy ${upsertType}`);
        }
      })
      .catch((e) => {
        raygunClient.send(e, 'Error updating policy', { policyId, newPayload });
        toast.error(this.props.t('Oops something went wrong'));
        this.props.dispatchLoadingPage(false);
      });
  };

  addPolicy = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: PolicyForm,
      dialogComponentProps: {
        maxWidth: '100%',
        submitCallback: (newPayload) => {
          this.upsertPolicy(IdUtils.generateId(), newPayload, 'create');
        }
      }
    });
  };

  editPolicy = (policyRow) => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: PolicyForm,
      dialogComponentProps: {
        policy: policyRow.original,
        maxWidth: '100%',
        submitCallback: (newPayload) => {
          this.upsertPolicy(policyRow.values.id, newPayload, 'update');
        }
      }
    });
  };

  render() {
    return (
      <div className="policy-management">
        {this.props.policies && (
          <TableComponent
            key={`policies-${this.state.tablePageSize}`}
            columns={this.getTableColumns()}
            data={this.props.policies}
            paginationLabel={this.props.t('policy-pagination')}
            sortBy={[{ id: 'name' }]}
            pageSize={this.state.tablePageSize}
            onActionButtonClick={this.addPolicy}
            actionButtonText={this.props.t('Add new policy')}
            hiddenColumns={['id']}
            showSearch
            calculateTablePageSize={this.calculateTablePageSize}
            pageUsing="User Management - Policies"
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

PolicyManagementClass.propTypes = {
  policies: PropTypes.arrayOf(PropTypes.shape({})),
  refreshData: PropTypes.func.isRequired,
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

PolicyManagementClass.defaultProps = { policies: null };

const PolicyManagement = withTranslation('translations')(connect(null, mapDispatchToProps)(PolicyManagementClass));

export default PolicyManagement;
