import { gql } from '@apollo/client';
import ApiHelpers from '../../../common/utils/api/apiHelpers';
import DynamoStringExtensionsUtil from '../../../common/utils/api/dynamoStringExtensionsUtil';
import { appSyncClient } from '../../../setup/appSyncClient';

const RealTimeApi = {
  getCourierLocation: getCourierLocation,
  onLocationChange: onLocationChange,
  onShipmentAssigmentChange: onShipmentAssigmentChange,
  getCourierShipments: getCourierShipments,
  onShipmentEventChange: onShipmentEventChange
};

const baseShipmentFields = ['name', 'address', 'shipmentCode', 'phone'];

const GetCourierLocation = gql`
  query GetCourierLocation($courierId: String) {
    getCourierLocation(courierId: $courierId) {
      lat
      lng
      mileage
      route {
        gpsTimestamp
        lat
        lng
      }
    }
  }
`;

const OnLocationChange = gql`
  subscription UpdateCourierLocation($courierId: String) {
    updateCourierLocation(courierId: $courierId) {
      lat
      lng
      courierId
      route {
        lat
        lng
        dayOfTheWeek
        hour
        gpsTimestamp
      }
    }
  }
`;

const GetCourierShipments = gql`
  query GetCourierShipments($courierId: String, $date: String) {
    queryShipments(pk: $courierId, sk: $date,  indexName: "gsi2", featureRequest: "realTime") {
      items {
        ${ApiHelpers.createQueryFieldList(baseShipmentFields)}
        region
        deliveryCompletedAt
        pickupCompletedAt
        shipmentSourceTypeId
        jobType
        events {
         DELIVERED
         PICKED_UP
         OTHER
        }
        fuzzyAddress {
          streetName
          houseNumber
          geoLat
          geoLng
          hasFuzzyMatch
          receiver
        }
      }
    }
  }
`;

const OnShipmentAssigmentChange = gql`
  subscription UpdateShipmentsAssignments($courierId: String) {
    updateShipmentsAssignments(courierId: $courierId) {
      courierId
      shipmentCode
    }
  }
`;

const OnShipmentEventChange = gql`
  subscription UpdateShipmentEvent($courierId: String) {
    updateShipmentEvent(courierId: $courierId) {
      courierId
      jobType
      shipmentId
    }
  }
`;

function getCourierLocation(courierId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetCourierLocation, { courierId: courierId });
  }

  return null;
}

function onLocationChange(courierId) {
  return appSyncClient.subscribe({
    query: OnLocationChange,
    variables: { courierId: DynamoStringExtensionsUtil.addCourierLocationPrefix(courierId) }
  });
}

function onShipmentAssigmentChange(courierId) {
  return appSyncClient.subscribe({
    query: OnShipmentAssigmentChange,
    variables: { courierId: DynamoStringExtensionsUtil.addCourierLocationPrefix(courierId) }
  });
}

async function getCourierShipments($courierId, date) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetCourierShipments, {
      courierId: DynamoStringExtensionsUtil.addShipmentPrefix($courierId),
      date: DynamoStringExtensionsUtil.addShipmentPrefix(date)
    });
  }

  return null;
}

function onShipmentEventChange(courierId) {
  return appSyncClient.subscribe({
    query: OnShipmentEventChange,
    variables: { courierId: courierId }
  });
}

export default RealTimeApi;
