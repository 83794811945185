import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StorageSelect from '../../../../common/components/selections/storageSelect/StorageSelect';
import Button from '../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import './StorageForm.scss';
import TimePickerWrapper from '../../../../common/components/datePickers/TimePickerWrapper';

/**
 * Storage form component
 *
 * @returns {JSX.Element} - Storage form
 * @component
 * @alias StorageForm
 * @category planning
 */

// let endStorage = null;

export default function StorageForm(props) {
  const { t } = useTranslation();
  // const [selectEndStorage, setSelectEndStorage] = useState(true);
  const [departureTime, setDepartureTime] = useState(moment().add(30, 'minutes').format('HH:mm'));
  const startStorage = useRef();

  const onStartStorageChange = useCallback((storage) => {
    startStorage.current = storage;
  }, []);

  // function onEndStorageChange(storage) {
  //   endStorage = storage;
  // }
  //
  // function toggleEndStorage(e) {
  //   setSelectEndStorage(e.target.checked);
  // }

  const handleSubmit = useCallback(() => {
    if (departureTime) {
      if (props.submitCallback) {
        props.submitCallback({ startStorage: startStorage.current, endStorage: startStorage.current, departureTime: departureTime });
        // props.submitCallback({ startStorage: startStorage, endStorage: !selectEndStorage ? endStorage : startStorage });
      }

      AppDialogActionsWrapper.closeAppDialog();
    } else {
      toast.error(t('Time input error'));
    }
  }, [props, departureTime, t]);

  const cancelCallback = useCallback(() => {
    AppDialogActionsWrapper.closeAppDialog();
  }, []);

  return (
    <div className="storage-form">
      <div className="title">{t('Set start and end locations')}</div>
      <div className="selections-wrapper">
        <div className="storage-selection">
          <StorageSelect onStorageChange={onStartStorageChange} returnFullObject />
          {/* {!selectEndStorage && <StorageSelect onStorageChange={onEndStorageChange} label={t("Choose ending point")} returnFullObject />} */}
        </div>
        <TimePickerWrapper onChange={setDepartureTime} defaultTime={departureTime} label={t('Time of departure')} />
        {/* <div className="checkbox-wrapper"> */}
        {/*  <input type="checkbox" checked={selectEndStorage} onChange={toggleEndStorage} /> */}
        {/*  <div className="label">{t("Return to same location")}</div> */}
        {/* </div> */}
      </div>
      <div className="buttons">
        <Button onClick={cancelCallback} type="cancel" text={t('Cancel')} />
        <Button onClick={handleSubmit} text={t('Set')} />
      </div>
    </div>
  );
}

StorageForm.propTypes = { submitCallback: PropTypes.func.isRequired };
