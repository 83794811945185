import { Auth } from 'aws-amplify';
import { appSyncClient } from '../../../setup/appSyncClient';

/**
 * @namespace ApiHelpers
 * @category Common
 */

const ApiHelpers = {
  getHeader: getHeader,
  createQuery: createQuery,
  createQueryFieldList: createQueryFieldList,
  removeTypenameFromObject: removeTypenameFromObject
};

/**
 * Generates header for rest api call with a proper auth token
 *
 * @async
 * @returns {Promise<{Authorization: string, "Content-Type": string}>} ready to use header for API requests
 * @function
 * @memberOf ApiHelpers
 */
async function getHeader() {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
  };
}

function createQuery(query, variables) {
  return appSyncClient.query({
    query: query,
    variables: variables,
    fetchPolicy: 'no-cache'
  });
}

function createQueryFieldList(fields) {
  let qryFieldList = '';

  if (fields && fields.length) {
    fields.forEach((fld) => {
      qryFieldList += `${fld}\n`;
    });
  }

  return qryFieldList;
}

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value;
}

function removeTypenameFromObject(data) {
  return JSON.parse(JSON.stringify(data), omitTypename);
}

export default ApiHelpers;
