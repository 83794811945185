import React from 'react';
import './LogoDisplay.scss';

export default function LogoDisplay() {
  return (
    <div className="logo-display">
      <i className="icon icon-mily-logo" />
    </div>
  );
}
