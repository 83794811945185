import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace AuthActions
 * @category Actions
 */

/**
 * Creates action payload with user permissions to be set
 *
 * @see AuthReducer
 * @param {object} permissions - user permissions
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf AuthActions
 * @alias setUserPermissions
 * @function
 */
export const setUserPermissions = (permissions) => ({
  type: ActionTypes.AUTH_SET_USER_PERMISSIONS,
  payload: permissions
});

/**
 * Creates action for resetting user permissions
 *
 * @returns {{type: string}} Action with an action type
 * @memberOf AuthActions
 * @alias resetUserPermissions
 * @function
 */
export const resetUserPermissions = () => ({ type: ActionTypes.AUTH_REST_USER_PERMISSIONS });
