import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import InfoDialog from './component/InfoDialog';
import './InfoDialogWrapper.scss';
import MixPanel from '../../../../setup/mixPanel';

/**
 * Show title and icon that opens info dialog
 *
 * @param {object} props - component props
 * @returns {JSX.Element} - Info dialog wrapper
 * @component
 * @alias InfoDialogWrapper
 * @category infoDialog
 */
export default function InfoDialogWrapper(props) {
  const [infoDialogAnchorEl, setInfoDialogAnchorEl] = useState(null);

  /**
   * Open info dialog
   *
   * @param {object} event - Js event
   * @function
   */
  const openInfoDialog = (event) => {
    setInfoDialogAnchorEl(event.currentTarget);
    if (props.eventName) MixPanel.track(props.eventName);
  };

  /**
   * Close info dialog
   *
   * @function
   */
  const closeInfoDialog = () => {
    setInfoDialogAnchorEl(null);
  };

  return (
    <div className="info-dialog-wrapper">
      <div className="title">
        {props.title}
        <div className="info-icon-wrapper">
          <div className="icon-background" />
          <i className="icon icon-info" onClick={openInfoDialog} />
        </div>
      </div>

      <Popover
        id="simple-popover"
        open={Boolean(infoDialogAnchorEl)}
        anchorEl={infoDialogAnchorEl}
        onClose={closeInfoDialog}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{ square: true }}
      >
        <InfoDialog bodyText={props.popupText} onClose={closeInfoDialog} />
      </Popover>
    </div>
  );
}

InfoDialogWrapper.propTypes = {
  /**
   * Info dialog body text key
   */
  popupText: PropTypes.string.isRequired,
  /**
   * Info title
   */
  title: PropTypes.string,
  /**
   * Associated event name
   */
  eventName: PropTypes.string
};

InfoDialogWrapper.defaultProps = {
  title: '',
  eventName: ''
};
