import * as ActionTypes from '../constants/actionTypes';

const initialState = { userPermissions: null };

/**
 * Reducer - Authentication
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_SET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload
      };
    case ActionTypes.AUTH_REST_USER_PERMISSIONS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
