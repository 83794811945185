import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { raygunClient } from '../../../../../setup/raygunClient';
import * as PageActions from '../../../../../state/actions/pageActions';
import TeamsApi from '../../api/teamsApi';
import TeamForm from './TeamForm';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import TableComponent from '../../../../../common/components/tables/tableComponent/TableComponent';
import IdUtils from '../../../../../common/utils/idUtil';
import TableActions from '../../../../../common/components/tables/common/TableActions';
import AuthUtil from '../../../../../common/utils/authUtil';

class TeamManagement extends React.Component {
  constructor() {
    super();
    this.state = { tablePageSize: 1 };
  }

  addTeam = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: TeamForm,
      dialogComponentProps: {
        title: this.props.t('Add team'),
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);
          const teamId = IdUtils.generateId();
          TeamsApi.upsertTeam(teamId, data)
            .then(() => this.props.refreshData())
            .catch((error) => {
              toast.error(this.props.t('Oops something went wrong'));
              raygunClient.send(error, 'Error adding team');
              this.props.dispatchLoadingPage(false);
            });
        }
      }
    });
  };

  editTeam = (row) => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: TeamForm,
      dialogComponentProps: {
        title: this.props.t('Edit team'),
        data: row.original,
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);
          TeamsApi.upsertTeam(row.original.id, data)
            .then(() => this.props.refreshData())
            .catch((error) => {
              toast.error(this.props.t('Oops something went wrong'));
              raygunClient.send(error, 'Error editing team');
              this.props.dispatchLoadingPage(false);
            });
        }
      }
    });
  };

  deleteTeam = (data) => {
    AppDialogActionsWrapper.openConfirmationDialog({
      title: `${this.props.t('Delete team')}?`,
      body: `${this.props.t('Team data will be removed, but associated couriers will remain intact')}.`,
      confirmButtonText: this.props.t('Delete'),
      continueCallback: () => {
        TeamsApi.deleteTeam(data.original.id)
          .then(() => {
            this.props.refreshData();
          })
          .catch((error) => {
            toast.error(this.props.t('Oops something went wrong'));
            raygunClient.send(error, 'Error deleting team');
            this.props.dispatchLoadingPage(false);
          });
      }
    });
  };

  getTableColumns = () => {
    const tableColumns = [
      { accessor: 'id' },
      {
        Header: this.props.t('Name'),
        accessor: 'name'
      },
      {
        Header: this.props.t('Description'),
        accessor: 'description'
      },
      {
        Header: this.props.t('Integration Id'),
        accessor: 'integrationId'
      },
      {
        Header: this.props.t('Team type'),
        accessor: 'teamType',
        Cell: (row) => <>{this.props.t(`entity-${row.value}`)}</>
      }
    ];
    if (AuthUtil.isFeatureEnabled('manageTeams')) {
      tableColumns.push({
        Header: this.props.t('Actions'),
        id: 'actions',
        disableGlobalFilter: true,
        className: 'centered',
        Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editTeam} onDelete={this.deleteTeam} />
      });
    }
    return tableColumns;
  };

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize !== pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  render() {
    return (
      <div className="team-management">
        {this.props.teams && (
          <div className="table-wrapper">
            <TableComponent
              key={`teams-${this.state.tablePageSize}`}
              columns={this.getTableColumns()}
              data={this.props.teams}
              paginationLabel={this.props.t('teams-pagination')}
              sortBy={[{ id: 'Name' }]}
              pageSize={this.state.tablePageSize}
              onActionButtonClick={AuthUtil.isFeatureEnabled('manageTeams') ? this.addTeam : null}
              actionButtonText={AuthUtil.isFeatureEnabled('manageTeams') ? this.props.t('Add new team') : null}
              hiddenColumns={['id']}
              showSearch
              calculateTablePageSize={this.calculateTablePageSize}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

TeamManagement.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      teamType: PropTypes.string
    })
  )
};

TeamManagement.defaultProps = { teams: null };

export default withTranslation('translations')(connect(null, mapDispatchToProps)(TeamManagement));
