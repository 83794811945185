import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { raygunClient } from '../../../../../setup/raygunClient';
import * as PageActions from '../../../../../state/actions/pageActions';
import TableComponent from '../../../../../common/components/tables/tableComponent/TableComponent';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import TableActions from '../../../../../common/components/tables/common/TableActions';
import MixPanel from '../../../../../setup/mixPanel';
import PermissionsApi from '../../api/permissionsApi';
import RoleForm from './RoleForm';
import IdUtils from '../../../../../common/utils/idUtil';

class RoleManagement extends React.Component {
  constructor() {
    super();
    this.state = { tablePageSize: 1 };
  }

  upsertRole = (roleId, data, upsertType) => {
    this.props.dispatchLoadingPage(true);
    PermissionsApi.upsertRole(roleId, data.name, data.policy.id, data.zone.id)
      .then((res) => {
        if (res?.data?.upsertData > 0) {
          this.props.refreshData();
          MixPanel.track(`User Management - Role ${upsertType}`);
        }
      })
      .catch((e) => {
        raygunClient.send(e, 'Error updating role', { roleId, data });
        toast.error(this.props.t('Oops something went wrong'));
        this.props.dispatchLoadingPage(false);
      });
  };

  addRole = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: RoleForm,
      dialogComponentProps: {
        policies: this.props.policies,
        zones: this.props.zones,
        title: this.props.t('Add Role'),
        submitCallback: (data) => {
          this.upsertRole(IdUtils.generateId(), data, 'create');
        }
      }
    });
  };

  editRole = (row) => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: RoleForm,
      dialogComponentProps: {
        policies: this.props.policies,
        zones: this.props.zones,
        title: this.props.t('Edit role'),
        data: {
          name: row.values.name,
          zone: { label: row.values.zoneName, value: row.values.zoneId },
          policy: { label: row.values.policyName, value: row.values.policyId }
        },
        disableMail: true,
        submitCallback: (data) => {
          this.upsertRole(row.values.id, data, 'update');
        }
      }
    });
  };

  getTableColumns = () => [
    { accessor: 'id' },
    {
      Header: this.props.t('Role name'),
      accessor: 'name'
    },
    {
      Header: this.props.t('Policy'),
      accessor: 'policyName'
    },
    { accessor: 'policyId' },
    {
      Header: this.props.t('Zone'),
      accessor: 'zoneName'
    },
    { accessor: 'zoneId' },
    {
      Header: this.props.t('Actions'),
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editRole} />
    }
  ];

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize === 1 || this.state.tablePageSize < pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  render() {
    return (
      <div className="role-management">
        {this.props.roles && (
          <div className="table-wrapper">
            <TableComponent
              key={`courier-${this.state.tablePageSize}`}
              columns={this.getTableColumns()}
              data={this.props.roles}
              paginationLabel={this.props.t('role-pagination')}
              sortBy={[{ id: 'name' }]}
              pageSize={this.state.tablePageSize}
              onActionButtonClick={this.addRole}
              actionButtonText={this.props.t('Add new role')}
              hiddenColumns={['id', 'policyId', 'zoneId']}
              showSearch
              calculateTablePageSize={this.calculateTablePageSize}
              pageUsing="User Management - Roles"
            />
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

RoleManagement.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.object),
  policies: PropTypes.arrayOf(PropTypes.object),
  zones: PropTypes.arrayOf(PropTypes.object)
};

RoleManagement.defaultProps = {
  roles: null,
  policies: null,
  zones: null
};

export default withTranslation('translations')(connect(null, mapDispatchToProps)(RoleManagement));
