import React from 'react';
import { Route, Switch } from 'react-router-dom';

import StorageManagementPage from './pages/StorageManagementPage';
import AddStoragePage from './pages/AddStoragePage';

/**
 * Storage page wrapper for page navigation
 *
 * @returns {JSX.Element} - Storage page wrapper for page navigation
 * @component
 * @alias StorageRoutes
 * @category storage-management
 */
export default function StorageRoutes() {
  return (
    <Switch>
      <Route exact path="/storage-management" key="/storage-management" component={StorageManagementPage} />
      <Route path="/storage-management/storage-add" key="/storage-management/storage-add" component={AddStoragePage} />
    </Switch>
  );
}
