import React from 'react';
import PropTypes from 'prop-types';
import './AddressToLabelCard.scss';
import { useTranslation } from 'react-i18next';

export default function AddressToLabelCard(props) {
  const { row } = props;
  const { t } = useTranslation();

  return (
    <div className="address-card">
      <div className="address-info">
        <div>
          <span className="title">
            {' '}
            {`${t('Address')}:`}
            {' '}
          </span>
          {`${row.values.streetName} ${row.values.houseNumber}`}
        </div>
        {row.values.receiver && (
          <div>
            <span className="title">
              {' '}
              {`${t('Receiver')}:`}
              {' '}
            </span>
            {row.values.receiver}
          </div>
        )}
      </div>

      {row.isSelected && <div className="actions">{row.cells[3].render('Cell')}</div>}
    </div>
  );
}

AddressToLabelCard.propTypes = { row: PropTypes.object.isRequired };
