import * as _ from 'lodash';
import moment from 'moment';
import StopsUtil from '../../features/analysis/utils/stopsUtil';

const ShipmentsUtil = {
  parseShipmentsEvents: parseShipmentsEvents,
  getShipmentColorData: getShipmentColorData,
  getShipmentLatLngFromFuzzyAddress: getShipmentLatLngFromFuzzyAddress,
  addClassNameBasedOnDeliveryType: addClassNameBasedOnDeliveryType,
  getShipmentLatLngFromEvent: getShipmentLatLngFromEvent
};

const unsuccessfulAttemptEvents = ['UNSUCCESSFUL_DELIVERY_ATTEMPT', 'CREATED_RETURN', 'CANCELED_RETURN'];

function parseShipmentsEvents(shipment) {
  const newShipment = shipment;
  if (shipment.events) {
    Object.keys(shipment.events).forEach((key) => {
      if (key !== '__typename' && typeof newShipment.events[key] === 'string') {
        newShipment.events[key] = JSON.parse(shipment.events[key]);
      }
    });
  }

  return newShipment;
}

function getShipmentLatLngFromFuzzyAddress(shipment) {
  if (shipment?.fuzzyAddress?.geoLat && shipment?.fuzzyAddress?.geoLng) {
    return {
      ...shipment,
      lat: shipment?.fuzzyAddress?.geoLat,
      lng: shipment?.fuzzyAddress?.geoLng
    };
  }

  return shipment;
}

function getShipmentColorData(shipment, courierId) {
  if (!shipment.events) {
    return {
      className: 'delivery',
      deliveryType: 'delivery'
    };
  }

  if (!_.isEmpty(shipment.events.DELIVERED)) {
    if (`${shipment.events.DELIVERED.courierId}` === courierId) {
      return {
        className: 'delivery-dim',
        deliveryType: 'delivery',
        completed: true
      };
    }

    return {
      className: 'delivery',
      deliveryType: 'delivery'
    };
  }

  if (!_.isEmpty(shipment.events.PICKED_UP)) {
    if (`${shipment.events.PICKED_UP.courierId}` === courierId) {
      return {
        className: 'pickup-dim',
        deliveryType: 'pickup',
        completed: true
      };
    }
  }

  if (!_.isEmpty(shipment.events.OTHER)) {
    const latestUnsuccesfullResponse = getLatestShipmentUnsuccessfulAttempt(shipment);
    if (latestUnsuccesfullResponse) {
      return latestUnsuccesfullResponse;
    }
  }

  return {
    className: 'delivery',
    deliveryType: 'delivery'
  };
}

function addClassNameBasedOnDeliveryType(shipment) {
  return {
    ...shipment,
    className: StopsUtil.getStopType(shipment.jobType)
  };
}

function getLatestShipmentUnsuccessfulAttempt(shipment) {
  let latestTimestamp = null;
  Object.keys(shipment.events.OTHER).forEach((timestamp) => {
    if (moment(timestamp).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && (!latestTimestamp || moment(latestTimestamp).isBefore(moment(timestamp)))) {
      if (unsuccessfulAttemptEvents.includes(shipment.events.OTHER[timestamp].eventType)) {
        latestTimestamp = timestamp;
      }
    }
  });
  return latestTimestamp
    ? {
      className: 'unsuccessful-attempt',
      deliveryType: 'unsuccessful-attempt',
      unsuccessfulAttemptTimestamp: latestTimestamp,
      statusDescription: shipment.events.OTHER[latestTimestamp].statusDescription
    }
    : null;
}

function getShipmentLatLngFromEvent(shipment) {
  let event;
  if (shipment.jobType === 'P' || shipment.jobType === 'R') {
    event = 'PICKED_UP';
  } else {
    event = 'DELIVERED';
  }

  if (shipment?.events[event]?.lat && shipment?.events[event]?.lng) {
    return {
      ...shipment,
      lat: shipment?.events[event]?.lat,
      lng: shipment?.events[event]?.lng
    };
  }

  return shipment;
}

export default ShipmentsUtil;
