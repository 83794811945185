import React from 'react';
import './PlanningFooter.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function PlanningFooter(props) {
  const { t } = useTranslation();

  return (
    <div className="planning-footer">
      <div className="data-wrapper">
        <div className="data-distance">
          {t('Distance Footer')}
          :
          {(props.data.distance / 1000).toFixed(2)}
          {' '}
          km
        </div>
        {props.data.currentDistance && (
          <div className="data-distance">
            {t('Current distance')}
            :
            {(props.data.currentDistance / 1000).toFixed(2)}
            {' '}
            km
          </div>
        )}
        <div className="data-duration">
          {t('Duration Footer')}
          :
          {(props.data.duration / 60).toFixed(0)}
          {' '}
          min
        </div>
      </div>
    </div>
  );
}

PlanningFooter.propTypes = {
  data: PropTypes.shape({
    distance: PropTypes.number,
    currentDistance: PropTypes.number,
    duration: PropTypes.number
  })
};

PlanningFooter.defaultProps = { data: null };
