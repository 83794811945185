import React from 'react';
import './RegionAnalysisMap.scss';

import * as _ from 'lodash';
import geojson2h3 from 'geojson2h3';
import mapboxgl from 'mapbox-gl';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import bbox from '@turf/bbox';
import * as h3 from 'h3-js';
// import ReactDOM from 'react-dom';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import AuthUtil from '../../../../common/utils/authUtil';
import TenantUtil from '../../../../common/utils/tenantUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import * as PageActions from '../../../../state/actions/pageActions';
import { MAP_ADDITIONAL_FEATURES_TYPES } from '../constants/mapAdditionalFeaturesData';
import RegionAnalysisMapViewUtilClass from '../utils/regionAnalysisMapViewUtil';
import PackageLockerUtilClass from '../utils/packageLockersUtil';
import PolygonUtil from '../../utils/polygonUtil';
import QueryStringUtil from '../../../../common/utils/queryStringUtil';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import S3Util from '../../../../common/utils/s3Util';
import BackendResourceConfigUtil from '../../../../common/utils/api/backendResourceConfigUtil';
import RegionAnalysisApi from '../api/regionAnalysisApi';
import HexTypes from '../constants/hexTypes';
import MapUtil from '../../../../common/utils/mapUtil';
import * as MapActions from '../../../../state/actions/mapActions';
// import MapMarkerPopup from './MapMarkerPopup';
import MixPanel from '../../../../setup/mixPanel';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// 40 visually distinct colors generated by https://mokole.com/palette.html.
const POLYGON_COLORS = [
  '#b03060',
  '#556B2F',
  '#A0522D',
  '#7F0000',
  '#e3590a',
  '#b32ab7',
  '#191970',
  '#ff0000',
  '#007700',
  '#05a7bc',
  '#88b329',
  '#00008B',
  '#daa520',
  '#03ae5e',
  '#215fe2',
  '#880088',
  '#9d315a',
  '#ba6c09',
  '#26a726',
  '#ff0000',
  '#0178b7',
  '#ffaa00',
  '#0000CD',
  '#5648ab',
  '#bc0e30',
  '#00bfff',
  '#fd8213',
  '#9370db',
  '#ea4ddd',
  '#997344',
  '#1276d6',
  '#ff6f5d',
  '#ff5100',
  '#2348d9',
  '#e368e3',
  '#808000',
  '#45b592',
  '#A020F0',
  '#a76201',
  '#e95fa4'
];

const HEX_RES = 10;

/**
 * A wrapper for RegionAnalysis map functionalities
 *
 * @component
 * @alias RegionAnalysisMap
 * @category RegionAnalysis
 */
class RegionAnalysisMapClass extends React.Component {
  constructor() {
    super();

    this.map = null;
    this.mapSources = [];
    this.regionStopsDistribution = [];
    this.regionAnalysisMapViewUtil = null;
    this.deliveryAreaTotals = {};
    this.regionsTotals = {};
    this.hexagonOpacityLimits = {};
    this.hexagons = null;
    this.layerReordered = false;
    this.entitiesPerHexMapCount = null;
    this.isochronePinPopup = null;
  }

  componentDidMount() {
    this.props.dispatchLoadingPage(true);
    const defaultView = MapUtil.getInitialViewStateForCompany();

    defaultView.lng = this.props?.initialCenterPositionData?.lng || this.props?.centerPositionData?.lng || defaultView.lng;
    defaultView.lat = this.props?.initialCenterPositionData?.lat || this.props?.centerPositionData?.lat || defaultView.lat;
    defaultView.zoom = this.props?.initialCenterPositionData?.zoom || this.props?.centerPositionData?.zoom || defaultView.zoom;

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [defaultView.lng, defaultView.lat],
      zoom: defaultView.zoom
    });
    this.props.dispatchSetMap(this.map);

    this.regionAnalysisMapViewUtil = new RegionAnalysisMapViewUtilClass(this.map);
    this.packageLockerUtil = new PackageLockerUtilClass(this.map, this.props.t);

    this.setMapEvents();
    this.addMapControls();

    if (this.props.mapSplitMode === 'optimized' && this.props.optimizedMode) {
      this.plotData();
    }

    this.updateAdditionalMapFeatures();

    this.addIsochronePinControls();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldResize !== this.props.shouldResize) {
      this.debounceMapResize();
    }

    if (prevProps.showRegionStopsDistributionChart !== this.props.showRegionStopsDistributionChart) {
      // We have to wait for barChart animation to finish before resizing map
      setTimeout(() => {
        this.debounceMapResize();
      }, 1000);
    }

    if (prevProps.optimizedMode !== this.props.optimizedMode && this.props.mapSplitMode === 'standard') {
      this.debounceMapResize();
    }

    if (
      (prevProps.regionsDateFrom !== this.props.regionsDateFrom
        || prevProps.regionsDateTo !== this.props.regionsDateTo
        || prevProps.centerPositionData !== this.props.centerPositionData)
      && (this.props.mapSplitMode === 'standard' || this.props.optimizedMode)
    ) {
      this.plotData();
      this.updateAdditionalMapFeatures();
    }

    if (prevProps.centerPositionData !== this.props.centerPositionData) {
      this.map.flyTo({
        center: [this.props.centerPositionData.lng, this.props.centerPositionData.lat],
        zoom: this.props.centerPositionData.zoom
      });
      this.updateAdditionalMapFeatures();
    }

    if (prevProps.highlightedRegion !== this.props.highlightedRegion) {
      if (this.props.highlightedRegion.flyTo) {
        const bounds = bbox(this.map.getSource(this.props.highlightedRegion.regionId)._data);
        this.map.fitBounds(bounds, { padding: 200 });
      }

      this.map.setPaintProperty(`${this.props.highlightedRegion.regionId}-layer`, 'fill-opacity', this.props.highlightedRegion.opacity);

      this.map.setPaintProperty(
        `${this.props.highlightedRegion.regionId}-layer`,
        'fill-outline-color',
        this.props.highlightedRegion.opacity === 1 ? '#000000' : this.getColor(this.props.highlightedRegion.regionId)
      );

      this.map.setPaintProperty(
        `heatmap-view-${this.props.highlightedRegion.regionId}-layer`,
        'line-width',
        this.props.highlightedRegion.opacity > 0.5 ? 4 : 1
      );
    }

    if (prevProps.hexType !== this.props.hexType && !_.isEmpty(this.deliveryAreaTotals)) {
      const region = this.props.centerPositionData.id;
      if (this.props.hexType in this.deliveryAreaTotals) {
        this.regionAnalysisMapViewUtil.changeHexagonLayersSource(
          region,
          this.props.hexType,
          this.deliveryAreaTotals[this.props.hexType],
          this.hexagonOpacityLimits
        );
      }

      Object.keys(this.regionsTotals).forEach((regionId) => {
        this.regionAnalysisMapViewUtil.changeClusterCountLayerSource(regionId, this.regionsTotals[regionId][this.props.hexType]);
      });

      this.props.dispatchUpdateRegionStopsDistribution(
        this.regionStopsDistribution
          .map((distribution) => {
            const newDistribution = distribution;
            if (this.regionsTotals[distribution.regionId] && this.regionsTotals[distribution.regionId][this.props.hexType]) {
              newDistribution.count = this.regionsTotals[distribution.regionId][this.props.hexType];
            } else {
              newDistribution.count = 0;
            }
            return newDistribution;
          })
          .sort((a, b) => a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          }))
      );
    }

    if (prevProps.hexType !== this.props.hexType) {
      this.updateAdditionalMapFeatures();
      if (this.props.additionalMapFeatures[MAP_ADDITIONAL_FEATURES_TYPES.ISOCHRONE_PIN]) {
        this.isochronePinPopup = this.getIsochronePinPopup().then((pinPopup) => {
          if (pinPopup) {
            this.isochronePinPopup = pinPopup;
            this.isochronePinPopup.setLngLat(this.isochronePinCoordinates);
          }
        });
      }
    }

    if (prevProps.stopsData !== this.props.stopsData && this.props.mapSplitMode === 'optimized') {
      this.createClusteredRegions(this.props.stopsData);
    }

    if (
      prevProps.additionalMapFeatures[MAP_ADDITIONAL_FEATURES_TYPES.ISOCHRONE_PIN]
      !== this.props.additionalMapFeatures[MAP_ADDITIONAL_FEATURES_TYPES.ISOCHRONE_PIN]
    ) {
      if (this.props.additionalMapFeatures[MAP_ADDITIONAL_FEATURES_TYPES.ISOCHRONE_PIN]) this.showIsochronePin();
      else this.removeIsochronePin();
    }

    if (this.props.additionalMapFeatures) {
      Object.keys(this.props.additionalMapFeatures).forEach((key) => {
        if (this.props.additionalMapFeatures[key] !== prevProps.additionalMapFeatures[key]) {
          this.updateMapFeature(key, this.props.additionalMapFeatures[key]);
        }
      });
    }
  }

  updateAdditionalMapFeatures = () => {
    if (this.props.additionalMapFeatures) {
      Object.keys(this.props.additionalMapFeatures).forEach((key) => {
        if (this.props.additionalMapFeatures[key]) {
          this.updateMapFeature(key, true);
        }
      });
    }
  };

  createClusteredRegions = (clusteredStops) => {
    const newRegionsData = [];
    clusteredStops.forEach((stop) => {
      const hex = h3.geoToH3(stop.Latitude, stop.Longitude, HEX_RES);
      if (!newRegionsData[stop.labels]) {
        newRegionsData[stop.labels] = {
          regionId: stop.labels,
          colorId: stop.labels,
          hexIds: new Set()
        };
      }

      newRegionsData[stop.labels].hexIds.add(hex);
    });

    newRegionsData.forEach((region) => {
      const hexIds = Array.from(region.hexIds);
      const hexagonFeatureCollection = geojson2h3.h3SetToFeatureCollection(hexIds);

      this.mapAddSource(region.regionId, 'geojson', hexagonFeatureCollection);
      this.map.addLayer({
        id: `${region.regionId}-layer`,
        type: 'fill',
        source: region.regionId,
        minzoom: 3,
        layout: { visibility: 'visible' },
        paint: {
          'fill-color': POLYGON_COLORS[region.regionId],
          'fill-opacity': 0.4
        }
      });
    });
  };

  plotData = () => {
    this.layerReordered = false;
    this.showHexagonsInArea(this.props.centerPositionData.id).then((res) => {
      if (res) {
        this.showRegions(this.props.centerPositionData.id);
        this.props.dispatchLoadingPage(false);
      } else {
        this.props.dispatchLoadingPage(false);
      }
    });
  };

  addIsochronePinControls = () => {
    this.isochronePin = new mapboxgl.Marker({ color: '#009bfa', draggable: true });
    const instance = this;

    this.isochronePin.on('dragstart', () => {
      instance.packageLockerUtil.removeIsochrone(instance.isochronePinCoordinates.lat, instance.isochronePinCoordinates.lng);
      instance.removeIsochronePinPopup();
    });

    this.isochronePin.on('dragend', async () => {
      const { lng, lat } = instance.isochronePin.getLngLat();
      instance.packageLockerUtil.addIsochronePolygonToMap(lat, lng);
      instance.isochronePinCoordinates = { lat, lng };
      instance.isochronePinPopup = await instance.getIsochronePinPopup();
      if (instance.isochronePinPopup) instance.isochronePinPopup.setLngLat(instance.isochronePinCoordinates);
      MixPanel.track('Isochrone pin dragged');
    });

    const element = this.isochronePin.getElement();

    element.addEventListener('mouseenter', async () => {
      if (instance.isochronePinPopup) instance.isochronePinPopup.addTo(instance.map);
    });

    element.addEventListener('mouseleave', () => {
      if (instance.isochronePinPopup) instance.isochronePinPopup.remove();
    });
  };

  showIsochronePin = async () => {
    const { lng, lat } = this.map.getCenter();
    this.isochronePinCoordinates = { lat, lng };
    this.isochronePin.setLngLat(this.isochronePinCoordinates).addTo(this.map);
    this.packageLockerUtil.addIsochronePolygonToMap(lat, lng);
    this.isochronePinPopup = await this.getIsochronePinPopup();
    if (this.isochronePinPopup) this.isochronePinPopup.setLngLat(this.isochronePinCoordinates);
  };

  removeIsochronePin = () => {
    this.isochronePin.remove();
    this.packageLockerUtil.removeIsochrone(this.isochronePinCoordinates.lat, this.isochronePinCoordinates.lng);
    this.removeIsochronePinPopup();
  };

  getIsochronePinPopup = async () => {
    // const entitiesCount = await this.packageLockerUtil.getIsochroneEntitiesCoveredCount(this.isochronePinCoordinates.lat, this.isochronePinCoordinates.lng);
    // if (entitiesCount === 0) return null;
    // const placeholder = document.createElement('div');
    // ReactDOM.render(<MapMarkerPopup data={{ isochroneCoveredEntities: { count: entitiesCount, hexType: this.props.hexType } }} />, placeholder);
    // return new mapboxgl.Popup({ maxWidth: '500px', offset: 30 }).setDOMContent(placeholder);
  };

  removeIsochronePinPopup = () => {
    // if (this.isochronePinPopup) {
    //   this.isochronePinPopup.remove();
    //   this.isochronePinPopup = null;
    // }
  };

  updateMapFeature = (type, visibility) => {
    this.packageLockerUtil.updateMapFeature(
      type,
      visibility,
      this.props.hexType,
      this.props.centerPositionData?.id,
      this.props.regionsDateFrom,
      this.props.regionsDateTo,
      this.entitiesPerHexMapCount ? this.entitiesPerHexMapCount[this.props.hexType] : null
    );
  };

  /**
   *Calculates limits for opacity calculation based on total number of items in all hexagons
   *
   * @returns {dict} limits
   * @function
   */
  getHexagonOpacityLimits() {
    const daysInBetweenDates = Math.max(moment(this.props.regionsDateTo).diff(moment(this.props.regionsDateFrom), 'days'), 1);

    const selectedTotals = {};
    selectedTotals[HexTypes.HEX_TYPE.STOPS] = 0;
    selectedTotals[HexTypes.HEX_TYPE.PACKAGES] = 0;
    selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS] = 0;
    selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] = 0;
    selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] = 0;

    if (this.deliveryAreaTotals) {
      selectedTotals[HexTypes.HEX_TYPE.STOPS] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.STOPS];
      selectedTotals[HexTypes.HEX_TYPE.PACKAGES] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.PACKAGES];
      selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS];
      selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE];
      selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS];
    }

    const result = {};

    const defaultAdjustmentFactorForStops = 0.01;
    const perDayAdjustmentFactorForStops = 0.003; // (0.1 - 0.01) / 30

    const defaultAdjustmentFactorForPackages = 0.025;
    const perDayAdjustmentFactorForPackages = 0.0075; // (0.25 - 0.025) / 30

    const defaultAdjustmentFactorForShipments = 0.025;
    const perDayAdjustmentFactorForShipments = 0.0075; // (0.25 - 0.025) / 30

    const defaultAdjustmentFactorForShipmentsWithOnePackage = 0.025;
    const perDayAdjustmentFactorForShipmentsWithOnePackage = 0.0075; // (0.25 - 0.025) / 30

    const defaultAdjustmentFactorForShipmentsForPackageLockers = 0.025;
    const perDayAdjustmentFactorForShipmentsForPackageLockers = 0.0075; // (0.25 - 0.025) / 30

    result[HexTypes.HEX_TYPE.STOPS] = Math.floor(
      (selectedTotals[HexTypes.HEX_TYPE.STOPS] / daysInBetweenDates) * (defaultAdjustmentFactorForStops + daysInBetweenDates * perDayAdjustmentFactorForStops)
    );
    result[HexTypes.HEX_TYPE.PACKAGES] = Math.floor(
      (selectedTotals[HexTypes.HEX_TYPE.PACKAGES] / daysInBetweenDates)
        * (defaultAdjustmentFactorForPackages + daysInBetweenDates * perDayAdjustmentFactorForPackages)
    );
    result[HexTypes.HEX_TYPE.SHIPMENTS] = Math.floor(
      (selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS] / daysInBetweenDates)
        * (defaultAdjustmentFactorForShipments + daysInBetweenDates * perDayAdjustmentFactorForShipments)
    );
    result[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] = Math.floor(
      (selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] / daysInBetweenDates)
        * (defaultAdjustmentFactorForShipmentsWithOnePackage + daysInBetweenDates * perDayAdjustmentFactorForShipmentsWithOnePackage)
    );
    result[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] = Math.floor(
      (selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] / daysInBetweenDates)
        * (defaultAdjustmentFactorForShipmentsForPackageLockers + daysInBetweenDates * perDayAdjustmentFactorForShipmentsForPackageLockers)
    );

    return result;
  }

  getChartTotals(regionsLength) {
    const selectedTotals = {};
    selectedTotals[HexTypes.HEX_TYPE.STOPS] = 0;
    selectedTotals[HexTypes.HEX_TYPE.PACKAGES] = 0;
    selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS] = 0;
    selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] = 0;
    selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] = 0;

    if (this.deliveryAreaTotals) {
      selectedTotals[HexTypes.HEX_TYPE.STOPS] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.STOPS];
      selectedTotals[HexTypes.HEX_TYPE.PACKAGES] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.PACKAGES];
      selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS];
      selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE];
      selectedTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] += this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS];
    }

    selectedTotals.length = regionsLength;
    this.props.setCenterTotalsInfo(selectedTotals);
  }

  /**
   * Debounce map resize - we will ignore function call if called in 200ms from first one
   *
   * @function
   */
  debounceMapResize = _.debounce(() => {
    this.map.resize();
  }, 200);

  /**
   * Place where we set all map events
   *
   * @function
   */
  setMapEvents = () => {
    this.map.on('load', () => {
      this.props.dispatchLoadingPage(false);
    });

    this.map.on('mousemove', (e) => {
      const features = this.map.queryRenderedFeatures(e.point);
      let hoveredRegion = null;

      features.forEach((feature) => {
        if (feature.properties.kind === 'POLYGON') {
          hoveredRegion = `${this.props.t('Region')}: ${feature.properties.title.replace(/['"]+/g, '')}`;
        } else if (feature.source.match(/^[A-Z]{2}-[A-Z]{2}-[\d]+(-hexagons)?$/)) {
          hoveredRegion = `${this.props.t('Region')}: ${feature.source.replace('-hexagons', '')}`;
        }
      });

      document.getElementById('region-displayer').textContent = hoveredRegion;
    });

    this.map.on('moveend', () => {
      const center = this.map.getCenter();
      if (this.props.setCurrentLeftMapPosition) {
        this.props.setCurrentLeftMapPosition({
          lat: center.lat,
          lng: center.lng,
          zoom: this.map.getZoom()
        });
      }
    });

    this.map.on('zoom', () => {
      const center = this.map.getCenter();
      if (this.props.setCurrentLeftMapPosition) {
        this.props.setCurrentLeftMapPosition({
          lat: center.lat,
          lng: center.lng,
          zoom: this.map.getZoom()
        });
      }
    });

    this.map.on('styledata', () => {
      const areaId = this.props.centerPositionData.id;
      const layers = [`${areaId}-hexagons-count`, `heatmap-view-${areaId}-hexagons-layer`, `${areaId}-hexagons-layer-border`];
      if (!this.layerReordered && MapUtil.doesMapHaveLayers(this.map, layers)) {
        const hasAllLayers = layers.reduce((result, layer) => result && !!this.map.getLayer(layer), true);
        if (hasAllLayers) {
          this.map.moveLayer(`heatmap-view-${areaId}-hexagons-layer`, `${areaId}-hexagons-count`);
          this.map.moveLayer(`${areaId}-hexagons-layer-border`, `${areaId}-hexagons-count`);
          this.layerReordered = true;
        }
      }
    });
  };

  /**
   * Place where we add all map controls
   *
   * @function
   */
  addMapControls = () => {
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  /**
   * Add new source to map
   *
   * @param {string} sourceId - sourceId
   * @param {string} type - source type
   * @param {object} data - source data
   * @function
   */
  mapAddSource = (sourceId, type, data) => {
    this.map.addSource(sourceId, {
      type,
      data
    });
    this.mapSources.push(sourceId);
  };

  showHexagonsInArea = async (areaId) => {
    this.props.dispatchLoadingPage(true);

    this.regionAnalysisMapViewUtil.removeMapLayers();

    this.mapSources.forEach((source) => {
      this.map.removeSource(source);
    });
    this.mapSources = [];

    try {
      this.deliveryAreaTotals = {};
      try {
        const hexsInArea = await RegionAnalysisApi.getHexagons(areaId, this.props.regionsDateFrom, this.props.regionsDateTo);
        if (!hexsInArea || !hexsInArea.hexagons || hexsInArea.hexagons.length === 0) {
          this.entitiesPerHexMapCount = {
            shipments: {},
            stops: {},
            packages: {},
            'shipments-with-one-package': {},
            'shipments-for-package-lockers': {}
          };
          this.updateAdditionalMapFeatures();
          toast.warn(this.props.t('noDataMessage'), {
            position: 'top-right',
            autoClose: 3000
          });
          this.props.dispatchLoadingPage(false);

          return undefined;
        }

        this.hexagons = hexsInArea.hexagons;
        this.plotHexagonsInArea(areaId, hexsInArea);
        this.hexagonOpacityLimits = this.getHexagonOpacityLimits();

        const additionalData = {
          color: this.getColor(areaId),
          count: this.deliveryAreaTotals[this.props.hexType],
          centerPositionData: this.props.centerPositionData
        };
        this.regionAnalysisMapViewUtil.addHeatMapLayers(areaId, additionalData, this.hexagonOpacityLimits, this.props.hexType);

        return false;
      } catch (error) {
        console.error(`Failed to fetch and plot Area '${areaId}'. ${error}`);
        raygunClient.send(error, 'noDataMessage');
        this.props.dispatchLoadingPage(false);
        toast.warn(this.props.t('noDataMessage'), {
          position: 'top-right',
          autoClose: 3000
        });
      }
    } catch (error) {
      raygunClient.send(error, `Failed to load hexagons data. ${error}`);
      this.props.dispatchLoadingPage(false);
      toast.warn(this.props.t('noDataMessage'), {
        position: 'top-right',
        autoClose: 3000
      });
    }

    return false;
  };

  /**
   * Asynchronously fetches and plots all regions.<br/>
   * While plots regions, it builds data for BarChart and then dispatches event with those data.
   *
   * @function
   */
  showRegions = async (areaId) => {
    this.regionStopsDistribution = [];
    const optimizedNum = 24;

    // load region polygons
    let regionFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${areaId}/${areaId}_regions.json`;
    if (this.props.mapSplitMode === 'optimized') {
      regionFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${areaId}/${areaId}_regions_optimized_${optimizedNum}.json`;
    }
    regionFileName = TenantUtil.addTenantToFileName(regionFileName);
    const regionsPromise = S3Util.getFileFromS3(regionFileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getRegionDataBucketName()
    }).then((content) => JSON.parse(content));

    // load hex-regions mapping and create stops count per region
    let hexMappingFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${areaId}/${areaId}_hex_region_mapping.json`;
    if (this.props.mapSplitMode === 'optimized') {
      hexMappingFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${areaId}/${areaId}_hex_region_mapping_optimized_${optimizedNum}.json`;
    }
    hexMappingFileName = TenantUtil.addTenantToFileName(hexMappingFileName);
    const hexRegionsMappingPromise = S3Util.getFileFromS3(hexMappingFileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getRegionDataBucketName()
    }).then((content) => {
      const hexRegionMap = JSON.parse(content);
      const regionsTotals = {};

      this.hexagons.forEach((hex) => {
        const rId = hexRegionMap[hex.id];
        if (!rId) {
          return;
        }
        if (!regionsTotals[rId]) {
          regionsTotals[rId] = {};
          regionsTotals[rId][HexTypes.HEX_TYPE.STOPS] = 0;
          regionsTotals[rId][HexTypes.HEX_TYPE.PACKAGES] = 0;
          regionsTotals[rId][HexTypes.HEX_TYPE.SHIPMENTS] = 0;
          regionsTotals[rId][HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] = 0;
          regionsTotals[rId][HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] = 0;
          regionsTotals[rId].maxStopsHex = { count: -1, hex: null };
        }

        regionsTotals[rId][HexTypes.HEX_TYPE.STOPS] += hex.stops;
        regionsTotals[rId][HexTypes.HEX_TYPE.PACKAGES] += hex.packages;
        regionsTotals[rId][HexTypes.HEX_TYPE.SHIPMENTS] += hex.shipments;
        regionsTotals[rId][HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] += hex.shipmentsWithOnePackage;
        regionsTotals[rId][HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] += hex.shipmentsForPackageLockers;
        if (regionsTotals[rId].maxStopsHex.count < hex.stops) regionsTotals[rId].maxStopsHex = { count: hex.stops, hex: hex.id };
      });

      return regionsTotals;
    });

    Promise.all([regionsPromise, hexRegionsMappingPromise])
      .then((res) => {
        const [regions, hexRegionCountMap] = res;
        this.regionsTotals = hexRegionCountMap;

        regions.forEach((geojson) => {
          const regionId = geojson.properties.title;

          // add source for region
          this.mapAddSource(regionId, 'geojson', geojson);

          // For region cluster
          const regionCenter = PolygonUtil.getPolygonCentroidHex(geojson.geometry.coordinates[0], this.regionsTotals[regionId]?.maxStopsHex?.hex);

          const hexagonsClusterSourceData = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: regionCenter
                }
              }
            ]
          };
          this.mapAddSource(`${regionId}-hexagons-cluster`, 'geojson', hexagonsClusterSourceData);

          const additionalData = {
            color: this.getColor(regionId),
            centerPositionData: this.props.centerPositionData,
            count: (hexRegionCountMap[regionId] && hexRegionCountMap[regionId][this.props.hexType]) || 0
          };
          this.regionAnalysisMapViewUtil.addRegionsLayer(areaId, regionId, additionalData);

          this.regionStopsDistribution.push({
            regionId,
            center: regionCenter,
            name: regionId.substring(regionId.indexOf('-') + 1),
            count: (hexRegionCountMap[regionId] && hexRegionCountMap[regionId][this.props.hexType]) || 0,
            color: this.getColor(regionId)
          });
        });

        this.getChartTotals(regions.length);

        this.props.dispatchUpdateRegionStopsDistribution(
          this.regionStopsDistribution.sort((a, b) => a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          }))
        );

        this.props.dispatchLoadingPage(false);
      })
      .catch((err) => {
        if (AuthUtil.getTenantId() === 'dexpress') {
          raygunClient.send(err, 'Error loading regions files');
          toast.error(this.props.t('Oops something went wrong'));
        }
        this.props.dispatchLoadingPage(false);
      });
  };

  plotHexagonsInArea = (areaId, hex) => {
    const { hexagons } = hex;
    let totalStops = 0;
    let totalShipments = 0;
    let totalPackages = 0;
    let totalShipmentsWithOnePackage = 0;
    let totalShipmentsForPackageLockers = 0;
    const h3StopsSet = {};
    const h3PackagesSet = {};
    const h3ShipmentsSet = {};
    const h3ShipmentsWithOnePackageSet = {};
    const h3ShipmentsForPackageLockersSet = {};

    // Add hexagons (if there are any in the given region)
    if (hexagons.length > 0) {
      for (let i = 0; i < hexagons.length; i++) {
        const { packages, shipments, shipmentsWithOnePackage, shipmentsForPackageLockers, stops } = hexagons[i];

        // Do not plot hexagons with no stops to improve rendering speed.
        if (stops !== 0) {
          h3StopsSet[hexagons[i].id] = stops;
          totalStops += stops;
        }

        if (packages !== 0) {
          h3PackagesSet[hexagons[i].id] = packages;
          totalPackages += packages;
        }

        if (shipments !== 0) {
          h3ShipmentsSet[hexagons[i].id] = shipments;
          totalShipments += shipments;
        }

        if (shipmentsWithOnePackage !== 0) {
          h3ShipmentsWithOnePackageSet[hexagons[i].id] = shipmentsWithOnePackage;
          totalShipmentsWithOnePackage += shipmentsWithOnePackage;
        }

        if (shipmentsForPackageLockers !== 0) {
          h3ShipmentsForPackageLockersSet[hexagons[i].id] = shipmentsForPackageLockers;
          totalShipmentsForPackageLockers += shipmentsForPackageLockers;
        }
      }

      this.entitiesPerHexMapCount = {
        shipments: h3ShipmentsSet,
        stops: h3StopsSet,
        packages: h3PackagesSet,
        'shipments-with-one-package': h3ShipmentsWithOnePackageSet,
        'shipments-for-package-lockers': h3ShipmentsForPackageLockersSet
      };
      this.updateAdditionalMapFeatures();

      // For region hexagons
      const hexagonFeatureCollection = geojson2h3.h3SetToFeatureCollection(Object.keys(h3StopsSet), (h3Index) => ({ stops: h3StopsSet[h3Index] }));
      this.mapAddSource(`${areaId}-hexagons-${HexTypes.HEX_TYPE.STOPS}`, 'geojson', hexagonFeatureCollection);
      const hexagonPackagesFeatureCollection = geojson2h3.h3SetToFeatureCollection(Object.keys(h3PackagesSet), (h3Index) => ({ stops: h3PackagesSet[h3Index] }));
      this.mapAddSource(`${areaId}-hexagons-${HexTypes.HEX_TYPE.PACKAGES}`, 'geojson', hexagonPackagesFeatureCollection);
      const hexagonShipmentsFeatureCollection = geojson2h3.h3SetToFeatureCollection(Object.keys(h3ShipmentsSet), (h3Index) => ({ stops: h3ShipmentsSet[h3Index] }));
      this.mapAddSource(`${areaId}-hexagons-${HexTypes.HEX_TYPE.SHIPMENTS}`, 'geojson', hexagonShipmentsFeatureCollection);
      const hexagonShipmentsWithOnePackageFeatureCollection = geojson2h3.h3SetToFeatureCollection(Object.keys(h3ShipmentsWithOnePackageSet), (h3Index) => {
        return { stops: h3ShipmentsWithOnePackageSet[h3Index] };
      });
      this.mapAddSource(`${areaId}-hexagons-${HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE}`, 'geojson', hexagonShipmentsWithOnePackageFeatureCollection);
      const hexagonShipmentsForPackageLockersFeatureCollection = geojson2h3.h3SetToFeatureCollection(Object.keys(h3ShipmentsForPackageLockersSet), (h3Index) => {
        return { stops: h3ShipmentsForPackageLockersSet[h3Index] };
      });
      this.mapAddSource(`${areaId}-hexagons-${HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS}`, 'geojson', hexagonShipmentsForPackageLockersFeatureCollection);

      this.deliveryAreaTotals = {};
      this.deliveryAreaTotals[HexTypes.HEX_TYPE.STOPS] = totalStops;
      this.deliveryAreaTotals[HexTypes.HEX_TYPE.PACKAGES] = totalPackages;
      this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS] = totalShipments;
      this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE] = totalShipmentsWithOnePackage;
      this.deliveryAreaTotals[HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS] = totalShipmentsForPackageLockers;
    }
  };

  /**
   * Get region color for provided regionId
   *
   * @param {string} regionId - Id of the region
   * @returns {string}  region color
   * @function
   */
  getColor = (regionId) => {
    const colorIndex = parseInt(regionId.replace(/[^0-9]/g, ''), 10) % POLYGON_COLORS.length;

    return POLYGON_COLORS[colorIndex];
  };

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  render() {
    return (
      <div className="region-analysis-map-wrapper ">
        <MapBoxWrapper setMapRef={this.setMapRef} />
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.regionAnalysisState, ...store.planningPageState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @param {object} ownProps - commponent props
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchUpdateRegionStopsDistribution: ownProps.updateRegionStopsDistributionAction,
      dispatchSetMap: MapActions.setMap
    },
    dispatch
  );
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RegionAnalysisMapClass));

RegionAnalysisMapClass.propTypes = {
  /**
   * Function that sets info about stops, shipments, packages for a Center.
   */
  setCenterTotalsInfo: PropTypes.func.isRequired,

  /**
   * A start date from which regions should be fetched
   */
  regionsDateFrom: PropTypes.instanceOf(Date).isRequired,

  /**
   * An end date to which regions should be fetched
   */
  regionsDateTo: PropTypes.instanceOf(Date).isRequired,

  /**
   * Function that dispatches event when page is loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,

  /**
   * Indicatior if map should resize
   */
  shouldResize: PropTypes.bool,

  /**
   * Initial center position data
   */
  initialCenterPositionData: PropTypes.shape({
    lng: PropTypes.number,
    lat: PropTypes.number,
    zoom: PropTypes.number
  }),

  /**
   * Center position data
   */
  centerPositionData: PropTypes.shape({
    lng: PropTypes.number,
    lat: PropTypes.number,
    zoom: PropTypes.number,
    id: PropTypes.string
  }),

  /**
   * Standard for default map, optimized for second map
   */
  mapSplitMode: PropTypes.string,

  /**
   * Type of hexagons that should be displayed in a map
   */
  hexType: PropTypes.string,

  /**
   * Indicator if optimized mode is turned on
   */
  optimizedMode: PropTypes.bool,

  /**
   * Bar chart hovered/selected region data
   */
  highlightedRegion: PropTypes.shape({
    flyTo: PropTypes.bool,
    regionId: PropTypes.string,
    opacity: PropTypes.number
  }),

  /**
   * Indicator if stops distribution chart is shown
   */
  showRegionStopsDistributionChart: PropTypes.bool,

  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,

  /**
   * Set second map initial position to be the same as first
   */
  setCurrentLeftMapPosition: PropTypes.func.isRequired,

  /**
   * Update stops distribution
   */
  dispatchUpdateRegionStopsDistribution: PropTypes.func.isRequired,

  /**
   * Indicator if package lockers are shown
   */
  stopsData: PropTypes.arrayOf(
    PropTypes.shape({
      labels: PropTypes.string,
      Longitude: PropTypes.string, // FIXME sync naming convention with back
      Latitude: PropTypes.string
    })
  ),
  additionalMapFeatures: PropTypes.object,
  dispatchSetMap: PropTypes.func.isRequired
};

RegionAnalysisMapClass.defaultProps = {
  shouldResize: false,
  initialCenterPositionData: null,
  centerPositionData: null,
  mapSplitMode: 'standard',
  optimizedMode: false,
  highlightedRegion: null,
  showRegionStopsDistributionChart: false,
  hexType: QueryStringUtil.getQueryStringValue('hexType'),
  stopsData: null,
  additionalMapFeatures: null
};
