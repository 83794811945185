/**
 * Util for getting entity data
 *
 * @namespace
 * @category Common
 */
const DeliveryAreasUtil = {
  getDefaultFilePath: getDefaultFilePath,
  getLatestFilePath: getLatestFilePath
};

function getDefaultFilePath(dataType) {
  if (dataType === 'zip-code') {
    return process.env.REACT_APP_DEFAULT_ZIP_CODE_FILE_PATH;
  }

  return process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH;
}

function getLatestFilePath(dataType) {
  if (dataType === 'zip-code') {
    return process.env.REACT_APP_LATEST_ZIP_CODE_FILE_PATH;
  }

  return process.env.REACT_APP_LATEST_DELIVERY_AREA_FILE_PATH;
}

export default DeliveryAreasUtil;
