import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import { raygunClient } from '../../../../setup/raygunClient';
import * as PageActions from '../../../../state/actions/pageActions';
import * as CoordinatesActions from '../../../../state/actions/coordinatesActions';
import StorageMap from '../components/StorageMap';
import './AddStoragePage.scss';
import StepInfoHeader from '../../../planning/planManagement/components/StepInfoHeader';
import StorageApi from '../api/storageApi';
import { INITIAL_VIEW_STATE } from '../../../../common/constants/mapConstants';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import StorageForm from '../components/StorageForm';

class AddStoragePageClass extends React.Component {
  constructor(props) {
    super(props);

    this.markerCoordinates = { lat: this.props.location.state.lat, lng: this.props.location.state.lng };

    if (!this.props.location.state.create) {
      this.zoom = 15.0;
    } else {
      this.zoom = INITIAL_VIEW_STATE.zoom;
      this.props.location.state.name = `${props.t('STORAGE')} - ${moment().format('YYYY-MM-DD')}`;
    }

    this.state = { formData: { name: props?.history?.location?.state?.name } };
  }

  componentDidMount() {
    this.props.dispatchLoadingPage(false);
    this.props.dispatchCoordinates(this.markerCoordinates);
  }

  saveStorage = (integrationId, storageName) => {
    const id = this.props.location.state.id;
    if (id) {
      const data = {
        lat: `${this.markerCoordinates.lat}`,
        lng: `${this.markerCoordinates.lng}`,
        name: storageName,
        integrationId: integrationId
      };
      StorageApi.upsertStorage(id, data)
        .then(() => {
          this.props.history.push('/storage-management');
        })
        .catch((error) => {
          raygunClient.send(error, 'Error saving storage', { id: id });
          toast.error(this.props.t('Oops something went wrong'));
        });
    }
  };

  getDefaultName = () => {
    return `${this.props.t('Storage')} ${moment().format('YYYY-MM-DD')}`;
  };

  openStorageForm = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: StorageForm,
      dialogComponentProps: {
        data: { name: this.state.formData.name || this.getDefaultName(), integrationId: this.props?.history?.location?.state?.integrationId },
        title: this.props.t('Add new delivery area'),
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);
          this.saveStorage(data.integrationId, data.name);
        }
      }
    });
  };

  savePinLocation = (marker) => {
    this.markerCoordinates = marker.getLngLat();
    this.props.dispatchCoordinates(this.markerCoordinates);
  };

  render() {
    return (
      <div className="storage-add">
        <StepInfoHeader
          message={this.props.t('storagePlacementMessage')}
          nextButtonText={this.props.t('Save')}
          options={{
            nextButtonDisabled: false,
            returnButtonHidden: true,
            cancelButtonText: this.props.t('Cancel'),
            cancelButtonHidden: false,
            cancelToPage: '/storage-management'
          }}
          onNextClick={this.openStorageForm}
          storageUsing
        />
        <StorageMap
          savePin={this.savePinLocation}
          storageData={{ lng: this.markerCoordinates.lng, lat: this.markerCoordinates.lat }}
          zoom={this.zoom}
          storageAdd
          showMap
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchCoordinates: CoordinatesActions.coordinatesChange
    },
    dispatch
  );
}

AddStoragePageClass.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  dispatchLoadingPage: PropTypes.func.isRequired,
  dispatchCoordinates: PropTypes.func.isRequired
};

AddStoragePageClass.defaultProps = { location: null };

export default withTranslation('translations')(connect(null, mapDispatchToProps)(AddStoragePageClass));
