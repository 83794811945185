import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './FormInput.scss';
import ReactTooltip from 'react-tooltip';
import Toggle from 'react-toggle';
import CustomSelect from '../../selections/CustomSelect';

export class FormInput extends React.Component {
  getErrorText = () => (this.props.error ? this.props.error : '');

  getErrorClass = () => (this.props.error ? 'wrapper-error' : '');

  renderInputComponent = () => {
    const props = _.omit(this.props, ['inputType', 'helperText', 'error', 'validation', 'onFormInputBeforeChange', 'onFormInputChange', 'hideErrorFiled']);
    // this.props.value comes from Form component!!!
    switch (this.props.inputType) {
      case 'input': {
        return (
          <div className="text-input" data-tip={`${this.props.helperText}`} data-for={props.name}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input className="default-input" {...props} />
            {props.label && <div className="form-label input-label">{props.label}</div>}
            {this.props.helperText && (this.props.error || this.props.disabled) && (
              <ReactTooltip id={props.name} className="tooltip" place="right" effect="solid" />
            )}
          </div>
        );
      }
      case 'input-number': {
        return (
          <div className="text-input" data-tip={`${this.props.helperText}`} data-for={props.name}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input className="default-input" {...props} />
            {props.label && <div className="form-label input-label">{props.label}</div>}
            {this.props.helperText && (this.props.error || this.props.disabled) && (
              <ReactTooltip id={props.name} className="tooltip" place="right" effect="solid" />
            )}
          </div>
        );
      }
      case 'checkbox': {
        return (
          <div className="checkbox-input" data-tip={`${this.props.helperText}`} data-for={props.name}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input className="checkbox" type="checkbox" {...props} />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {props.label && <div className="form-label">{props.label}</div>}
            {props.customLabel && props.customLabel}
            {this.props.helperText && (this.props.error || this.props.disabled) && (
              <ReactTooltip id={props.name} className="tooltip" place="right" effect="solid" />
            )}
          </div>
        );
      }
      case 'select': {
        return (
          <div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {props.label && <div className="form-label input-label">{props.label}</div>}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <CustomSelect {...props} />
          </div>
        );
      }
      case 'toggle': {
        return (
          <div className="toggle-input">
            {props.label && <div className="form-label input-label">{props.label}</div>}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Toggle {...props} />
          </div>
        );
      }
      default: {
        return '';
      }
    }
  };

  render() {
    return (
      <div className={`form-input-wrapper input-type-${this.props.inputType} ${this.getErrorClass()}`}>
        {this.renderInputComponent()}
        {!this.props.hideErrorFiled && <div className="error">{this.getErrorText()}</div>}
      </div>
    );
  }
}

FormInput.propTypes = {
  inputType: PropTypes.oneOf(['input', 'textarea', 'select', 'checkbox', 'input-number', 'toggle']).isRequired,
  name: PropTypes.string.isRequired,
  onFormInputBeforeChange: PropTypes.func, // function(newValue) must return value that will be saved as new value if validation pass
  onFormInputChange: PropTypes.func, // function(newValue, currentFormData, nextFormData) newValue is value returned from onFormInputBeforeChange (if this props is added to FormInput element)
  helperText: PropTypes.string,
  error: PropTypes.string,
  validation: PropTypes.shape({
    required: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([true])]),
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    isNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([true])]),
    regex: PropTypes.oneOfType([
      PropTypes.instanceOf(RegExp),
      PropTypes.shape({
        value: PropTypes.instanceOf(RegExp),
        message: PropTypes.string
      })
    ]),
    custom: PropTypes.func
  }),
  hideErrorFiled: PropTypes.bool,
  disabled: PropTypes.bool
};

FormInput.defaultProps = {
  helperText: undefined,
  error: undefined,
  validation: null,
  onFormInputBeforeChange: null,
  onFormInputChange: null,
  hideErrorFiled: false,
  disabled: false
};
