import React from 'react';
import PropTypes from 'prop-types';
import './TableActions.scss';

export default function TableActions(props) {
  const { row, onEdit, onDelete, onDownload, onNotify, type } = props;

  return (
    <div className={`table-actions ${type}`}>
      {onNotify && (
        <div onClick={onNotify.bind(this, row)}>
          <i className="icon icon-outgoing-mail" />
        </div>
      )}
      {onDownload && (
        <div onClick={onDownload.bind(this, row)}>
          <i className="icon icon-cloud-download" />
        </div>
      )}
      {onEdit && (
        <div onClick={onEdit.bind(this, row)}>
          <i className="icon icon-edit" />
        </div>
      )}
      {onDelete && (
        <div onClick={onDelete.bind(this, row)}>
          <i className="icon icon-delete" />
        </div>
      )}
    </div>
  );
}

TableActions.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onNotify: PropTypes.func,
  row: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

TableActions.defaultProps = {
  onEdit: null,
  onDelete: null,
  onDownload: null,
  onNotify: null
};
