import React from 'react';
import PropTypes from 'prop-types';
import Timeline from '@material-ui/lab/Timeline';
import { TimelineItem as MuiTimelineItem } from '@material-ui/lab';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import './StopsTimeline.scss';
import moment from 'moment';
import TimelineContent from '@material-ui/lab/TimelineContent';
import { PauseCalculationUtil } from '../utils/pauseCalculationUtil';
import StopsTimelineItem, { Paper } from './StopsTimelineItem';
import 'simplebar/dist/simplebar.min.css';
import * as MapActions from '../../../../state/actions/mapActions';
import MixPanel from '../../../../setup/mixPanel';
import StopUtil from '../../utils/stopsUtil';

const TimelineItem = withStyles({
  missingOppositeContent: { '&:before': { display: 'none' } },
  root: { 'min-height': '40px' }
})(MuiTimelineItem);

const customTimeline = {
  marginTop: '0',
  padding: '0 0 0 16px'
};

const lastTimelineDot = {
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  paddingLeft: '0',
  paddingRight: '0'
};

const lastTimelineItem = {
  paddingLeft: '12px',
  paddingRight: '0'
};

/**
 * Shows stops in a timeline
 *
 * @component
 * @alias StopsTimeline
 * @category RouteAnalysis
 */
class StopsTimelineClass extends React.Component {
  /**
   * Calculates how long it took from first to last stop
   *
   * @param {Array.<object>} stops - array of stops data
   * @returns {string} time in hh:mm format
   * @function
   */
  calculateTimeDiff = (stops) => {
    const startTime = moment(stops[0].timestamp);
    const endTime = moment(stops[stops.length - 1].timestamp);

    const diffS = endTime.diff(startTime, 'seconds');
    return moment.utc(moment.duration(diffS, 'seconds').asMilliseconds()).format('hh[h]:mm[min]');
  };

  getStopMarkerClass = (stopIndex) => {
    const currentStop = this.props.stops[stopIndex];

    if (stopIndex > 0) {
      const prevStop = this.props.stops[stopIndex - 1];
      const isPauseMarker = PauseCalculationUtil.checkIfPauseBetweenStops(
        PauseCalculationUtil.calculateTimeBetweenStops(prevStop.timestamp, currentStop.timestamp)
      );
      return isPauseMarker ? 'timeline-dot pause' : `timeline-dot ${StopUtil.getStopType(currentStop.event)}`;
    }

    return `timeline-dot ${StopUtil.getStopType(currentStop.event)}`;
  };

  render() {
    if (!this.props.stops || this.props.stops.length < 1) return <span />;

    return (
      <SimpleBar style={{ maxHeight: '100%' }} className="stops-timeline">
        <Timeline align="left" style={customTimeline}>
          {this.props.stops.map((stop, index) => {
            const featureClass = this.getStopMarkerClass(index);

            return (
              <TimelineItem key={stop.shipmentCodes[0]}>
                <TimelineSeparator>
                  <TimelineDot
                    className={featureClass}
                    onClick={() => {
                      this.props.dispatchCenterToStop(stop.lat, stop.lng, 16);
                      MixPanel.track(`Route Analysis - Stops Timeline - ${featureClass.replace('timeline-dot ', '')} pin clicked`);
                    }}
                  >
                    <div className="stop-number">{index + 1}</div>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <StopsTimelineItem stop={stop} />
              </TimelineItem>
            );
          })}
          <TimelineItem key={this.props.stops.length}>
            <TimelineSeparator>
              <TimelineDot style={lastTimelineDot}>
                <i className="icon icon-outlined-flag flag" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={lastTimelineItem}>
              <Paper elevation={3} className="paper">
                <div className="stop-timeline-row">
                  {this.props.t('Total time')}
                  {' '}
                  {this.calculateTimeDiff(this.props.stops)}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </SimpleBar>
    );
  }
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchCenterToStop: MapActions.centerToStop },
    dispatch
  );
}

export default withTranslation('translations')(connect(null, mapDispatchToProps)(StopsTimelineClass));

StopsTimelineClass.propTypes = {
  /**
   * Translation function
   */
  t: PropTypes.func.isRequired,
  /**
   * Stops data
   */
  stops: PropTypes.arrayOf(Object),
  /**
   * Dispatches action for a map to zoom on a specific stop
   */
  dispatchCenterToStop: PropTypes.func.isRequired
};

StopsTimelineClass.defaultProps = { stops: [] };
