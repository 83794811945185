import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './ShipmentTimelineItem.scss';
import { useTranslation } from 'react-i18next';
import CopyToClipboardWrapper from '../../../common/components/copy/CopyToClipboardWrapper';
import BackendResourceConfigUtil from '../../../common/utils/api/backendResourceConfigUtil';
import s3Util from '../../../common/utils/s3Util';
import TenantUtil from '../../../common/utils/tenantUtil';
import LabelingPopup from './LabelingPopup';
import AuthUtil from '../../../common/utils/authUtil';

export default function ShipmentTimelineItem(props) {
  const { t } = useTranslation();
  const { shipment } = props;
  const [hexIds, setHexIds] = useState(null);
  const labelingPopupRef = useRef();

  function isShipmentGeocoded() {
    return shipment && shipment.fuzzyAddress && shipment.fuzzyAddress.geoLat;
  }

  function isShipmentForLabeling() {
    return !isShipmentGeocoded() && shipment.fuzzyAddress && shipment.fuzzyAddress.hasFuzzyMatch && AuthUtil.isFeatureEnabled('labelingPopup');
  }

  function getRegionGeocodingHexagons() {
    let fileName = `latest/region-hex-mapping/${shipment.region}/${shipment.region}_level_7_hex_for_geocoding_mapping.json`;
    fileName = TenantUtil.addTenantToFileName(fileName);
    return s3Util
      .getFileFromS3(fileName, {
        download: true,
        bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
        contentType: 'application/json'
      })
      .then((res) => {
        const hexagons = JSON.parse(res);

        setHexIds(hexagons);
        if (labelingPopupRef?.current) {
          labelingPopupRef.current.open();
        }
      });
  }

  return (
    <div className="shipment-timeline-item" key={shipment.shipmentCode}>
      <div className="shipment-data-column">
        <div className="shipment-data">
          <i className="timeline-item-icon icon icon-assignment-turned-in-outlined" />
          {shipment.shipmentCode}
          <CopyToClipboardWrapper text={shipment.shipmentCode} />
        </div>
        {shipment.region && (
          <div className="shipment-data">
            <i className="timeline-item-icon icon icon-map" />
            {shipment.region}
          </div>
        )}
      </div>
      <div className="shipment-data">
        <i className="timeline-item-icon icon icon-markunread-mailbox-outlined" />
        {' '}
        {shipment.name}
      </div>
      <div className="shipment-data">
        <i className="timeline-item-icon icon icon-location-on-outlined" />
        <span className={isShipmentGeocoded() ? '' : 'shipment-address-wrong'}>{shipment.address}</span>
        {isShipmentForLabeling() && (
          <div className="enter-location" onClick={getRegionGeocodingHexagons}>
            {t('Enter location')}
          </div>
        )}
        {hexIds && <LabelingPopup shipment={shipment} hexIds={hexIds} labelingPopupRef={labelingPopupRef} />}
      </div>
    </div>
  );
}

ShipmentTimelineItem.propTypes = {
  shipment: PropTypes.shape({
    shipmentCode: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    region: PropTypes.string,
    fuzzyAddress: PropTypes.shape({
      geoLat: PropTypes.number,
      hasFuzzyMatch: PropTypes.bool
    })
  })
};

ShipmentTimelineItem.defaultProps = { shipment: null };
