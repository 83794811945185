import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination, useGlobalFilter, useRowSelect } from 'react-table';
import './CardMenuTable.scss';
import { useTranslation } from 'react-i18next';
import TableSearch from '../common/TableSearch';
import CardItem from './CardItem';

/**
 * Table for displaying data in card like style
 *
 * @param {object} props - table props
 * @returns {JSX.Element} - Table for displaying data in card like style
 * @component
 * @alias CardMenuTable
 * @category Common
 */
export default function CardMenuTable(props) {
  const { t } = useTranslation();
  const {
    columns,
    data,
    resetOn,
    pageSize,
    getAdditionalRowClass,
    showSearch,
    hiddenColumns,
    calculateTablePageSize,
    selectedIndex,
    lastActiveRowIndex,
    autoResetSelectedRows,
    autoResetPage,
    autoResetGlobalFilter
  } = props;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: getInitialPageIndex(),
        pageSize: pageSize,
        hiddenColumns: hiddenColumns
      },
      autoResetSelectedRows: autoResetSelectedRows,
      autoResetPage: autoResetPage,
      autoResetGlobalFilter: autoResetGlobalFilter
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const elementHeight = useRef(1);
  const prevResetOn = useRef();

  useEffect(() => {
    if (prevResetOn.current !== resetOn && !tableInstance.autoResetPage) {
      tableInstance.gotoPage(0);
      prevResetOn.current = resetOn;
    }
  }, [resetOn, tableInstance]);

  function getInitialPageIndex() {
    if (lastActiveRowIndex) {
      return Math.floor(parseInt(lastActiveRowIndex, 10) / pageSize);
    }

    return selectedIndex ? Math.floor(parseInt(getDataIndex(selectedIndex), 10) / pageSize) : 0;
  }

  const getPaginationNumbers = () => {
    let numbers = [];
    const { pageIndex } = tableInstance.state;

    if (tableInstance.pageCount <= 6) {
      numbers = [...Array(tableInstance.pageCount)
        .keys()];

      return numbers;
    }

    switch (pageIndex) {
      case 0:
        numbers = [];
        break;
      case 1:
        numbers = [0];
        break;
      case 2:
        numbers = [0, 1];
        break;
      case 3:
        numbers = [0, 1, 2];
        break;
      default:
        numbers = [0, -1];
        numbers.push(pageIndex - 2);
        numbers.push(pageIndex - 1);
    }

    numbers.push(pageIndex);

    if (pageIndex < tableInstance.pageCount - 4) {
      numbers.push(pageIndex + 1);
      numbers.push(pageIndex + 2);
    }

    switch (pageIndex) {
      case tableInstance.pageCount - 1:
        break;
      case tableInstance.pageCount - 2:
        numbers.push(tableInstance.pageCount - 1);
        break;
      case tableInstance.pageCount - 3:
        numbers.push(tableInstance.pageCount - 2);
        numbers.push(tableInstance.pageCount - 1);
        break;
      case tableInstance.pageCount - 4:
        numbers.push(tableInstance.pageCount - 3);
        numbers.push(tableInstance.pageCount - 2);
        numbers.push(tableInstance.pageCount - 1);
        break;
      default:
        numbers.push(-2);
        numbers.push(tableInstance.pageCount - 1);
    }

    return numbers;
  };

  const getPageEndRange = () => {
    if (tableInstance.pageCount === 0) return 0;
    if (tableInstance.state.pageIndex === tableInstance.pageCount - 1) {
      return tableInstance.state.pageIndex * tableInstance.state.pageSize + tableInstance.page.length;
    }
    return (tableInstance.state.pageIndex + 1) * tableInstance.state.pageSize;
  };

  const getItemRange = () => (tableInstance.pageCount > 0 ? tableInstance.state.pageIndex * tableInstance.state.pageSize + 1 : 0);

  function onRowClick(row) {
    tableInstance.toggleAllRowsSelected(false);
    row.toggleRowSelected();
    if (props.handleRowClick) {
      if (props.selectedDataType === 'row') {
        props.handleRowClick(row);
      } else {
        props.handleRowClick(row.original);
      }
    }
  }

  function onMouseEnter(row) {
    if (props.handleRowOnMouseEnter) {
      if (props.selectedDataType === 'row') {
        props.handleRowOnMouseEnter(row);
      } else {
        props.handleRowOnMouseEnter(row.original);
      }
    }
  }

  function onMouseLeave(row) {
    if (props.handleRowOnMouseLeave) {
      if (props.selectedDataType === 'row') {
        props.handleRowOnMouseLeave(row);
      } else {
        props.handleRowOnMouseLeave(row.original);
      }
    }
  }

  function getDataIndex() {
    return props.data.findIndex((element) => parseInt(element.region || element.regionId, 10) === parseInt(props.selectedIndex, 10));
  }

  const measuredRef = useCallback(
    (node) => {
      if (node !== null && calculateTablePageSize) {
        calculateTablePageSize(Math.trunc(node.clientHeight / elementHeight.current));
      }
    },
    [calculateTablePageSize]
  );

  const measureElementRef = useCallback(
    (node) => {
      if (node !== null) {
        if (elementHeight.current < node.clientHeight) {
          elementHeight.current = node.clientHeight;
        }
      }
    },
    [elementHeight]
  );

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <div className="card-menu-table-component" {...tableInstance.getTableProps()}>
      {showSearch && (
        <TableSearch
          preGlobalFilteredRows={tableInstance.preGlobalFilteredRows}
          globalFilter={tableInstance.state.globalFilter}
          setGlobalFilter={(value) => {
            if (value) tableInstance.gotoPage(0);
            tableInstance.setGlobalFilter(value);
          }}
          searchPlaceholder={props.searchPlaceholder}
        />
      )}

      <div className="table-body" {...tableInstance.getTableBodyProps()} ref={measuredRef}>
        {tableInstance.page.length > 0 ? (
          tableInstance.page.map((row) => {
            tableInstance.prepareRow(row);
            let rowClass = 'table-row';
            if (getAdditionalRowClass) {
              rowClass = `table-row ${getAdditionalRowClass(row)}`;
            }

            if (row.isSelected) {
              rowClass = `${rowClass} selected`;
            }

            return (
              <div
                className={rowClass}
                {...row.getRowProps()}
                onClick={() => {
                  onRowClick(row);
                }}
                ref={measureElementRef}
                onMouseEnter={() => {
                  onMouseEnter(row);
                }}
                onMouseLeave={() => {
                  onMouseLeave(row);
                }}
              >
                {props.cardItemComponent
                  ? React.createElement(props.cardItemComponent, {
                    row: row,
                    additionalProps: props.additionalCardItemProps
                  })
                  : React.createElement(CardItem, { row: row })}
              </div>
            );
          })
        ) : (
          <div className="no-data">{t('No data for selected filters')}</div>
        )}
      </div>

      <div className="pagination">
        <div className="page-items-number">
          {getItemRange()}
          {' '}
          -
          {getPageEndRange()}
        </div>
        <div className="change-page-wrapper">
          <div className={tableInstance.canPreviousPage ? 'button' : 'button disabled'} onClick={() => tableInstance.previousPage()}>
            <i className="icon icon-keyboard-arrow-left" />
          </div>
          <div className="pages">
            {getPaginationNumbers()
              .map((number) => {
                if (number < 0) {
                  return <div key={number}>...</div>;
                }

                return (
                  <div
                    className={tableInstance.state.pageIndex === number ? 'page-number selected' : 'page-number'}
                    key={number}
                    onClick={() => {
                      tableInstance.gotoPage(number);
                    }}
                  >
                    {number + 1}
                  </div>
                );
              })}
          </div>
          <div className={tableInstance.canNextPage ? 'button' : 'button disabled'} onClick={() => tableInstance.nextPage()}>
            <i className="icon icon-keyboard-arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
}

CardMenuTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetOn: PropTypes.string,
  pageSize: PropTypes.number,
  paginationLabel: PropTypes.string,
  getAdditionalRowClass: PropTypes.func,
  showSearch: PropTypes.bool,
  handleRowClick: PropTypes.func,
  handleRowOnMouseEnter: PropTypes.func,
  handleRowOnMouseLeave: PropTypes.func,
  cardItemComponent: PropTypes.func,
  additionalCardItemProps: PropTypes.shape({}),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  calculateTablePageSize: PropTypes.func,
  selectedIndex: PropTypes.string,
  selectedDataType: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  lastActiveRowIndex: PropTypes.string,
  autoResetSelectedRows: PropTypes.bool,
  autoResetPage: PropTypes.bool,
  autoResetGlobalFilter: PropTypes.bool
};

CardMenuTable.defaultProps = {
  resetOn: '',
  pageSize: 10,
  paginationLabel: '',
  getAdditionalRowClass: null,
  showSearch: false,
  handleRowClick: null,
  handleRowOnMouseEnter: null,
  handleRowOnMouseLeave: null,
  cardItemComponent: null,
  additionalCardItemProps: null,
  hiddenColumns: [],
  calculateTablePageSize: null,
  selectedIndex: null,
  selectedDataType: null,
  searchPlaceholder: null,
  lastActiveRowIndex: null,
  autoResetSelectedRows: false,
  autoResetPage: false,
  autoResetGlobalFilter: false
};
