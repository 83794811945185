import React from 'react';
import PropTypes from 'prop-types';

export default function CardItem(props) {
  const { row } = props;

  return (
    <div>
      {row.cells.map((cell) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className="table-data table-cell" {...cell.getCellProps()}>
          {cell?.column?.icon && (
            <div className="icon-wrapper">
              <i className={`icon ${cell.column.icon}`} />
            </div>
          )}
          <div className="value-wrapper">
            <div className="value">{cell.render('Cell')}</div>
            {cell?.column?.additionalText && <div className="additional-text">{cell.column.additionalText}</div>}
          </div>
        </div>
      ))}
    </div>
  );
}

CardItem.propTypes = { row: PropTypes.object.isRequired };
