import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace ChartActions
 * @category Actions
 */

/**
 * Creates an action with information on which data is selected in the chart.
 *
 * @param {string} chartType - type of a chart
 * @param {number} days - selected days
 * @param {number} hours - selected hours
 * @returns {{payload: {hours: *, chartType: *, days: *}, type: string}} Action with a payload
 * @memberOf ChartActions
 * @alias changeSelectedData
 * @function
 */
export const changeSelectedData = (chartType, days, hours) => ({
  type: ActionTypes.CHART_DATA_SELECTED,
  payload: {
    days,
    hours,
    chartType
  }
});
