import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace LanguageActions
 * @category Actions
 */

/**
 * Creates an action which changes language
 *
 * @param {string} language - newly selected language
 * @returns {{payload: *, type: string}} action payload
 * @memberOf LanguageActions
 * @alias changeLanguage
 * @function
 */
export const changeLanguage = (language) => ({
  type: ActionTypes.LANGUAGE_CHANGED,
  payload: language
});
