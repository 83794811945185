import React from 'react';
import mapboxgl from 'mapbox-gl';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import geojson2h3 from 'geojson2h3';
import MapModeButton from '../../../../common/components/buttons/mapModeButton/MapModeButton';
import { MAP_STYLE, MAP_SATELLITE_VIEW_STYLE } from '../../../../common/constants/mapConstants';
import MapUtil from '../../../../common/utils/mapUtil';
import * as PageActions from '../../../../state/actions/pageActions';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import './AddressLabelingMap.scss';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class AddressLabelingMapClass extends React.Component {
  constructor() {
    super();

    this.map = null;
    this.mapContainer = null;
    this.marker = null;
    this.isSatelliteViewOn = false;
  }

  componentDidMount() {
    this.props.dispatchLoadingPage(true);

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [this.props.addressData.lng, this.props.addressData.lat],
      zoom: this.props.zoom
    });

    this.addPinToMap();
    this.setMapEvents();
    this.addMapControls();
    this.updatePinDrag();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addressData !== this.props.addressData) {
      this.changeMapViewPosition();
    }
    if (this.props.hexIds && prevProps.hexIds !== this.props.hexIds) {
      this.plotHexagons();
    }
  }

  changeMapViewPosition = () => {
    if (this.props.addressData && this.props.addressData.lat && this.props.addressData.lng) {
      this.map.flyTo({
        center: [this.props.addressData.lng, this.props.addressData.lat],
        zoom: 15,
        speed: 1.0,
        curve: 1
      });
      this.addPinToMap();
    }
  };

  mapAddSource = (sourceId, type, data) => {
    this.map.addSource(sourceId, {
      type,
      data
    });
  };

  plotHexagons = () => {
    const hexIds = Array.from(this.props.hexIds);
    const hexagonFeatureCollection = geojson2h3.h3SetToFeatureCollection(hexIds);
    const sourceName = 'delivery-area-source';

    if (this.map.getSource(sourceName)) {
      this.map.getSource(sourceName).setData(hexagonFeatureCollection);
    } else {
      this.mapAddSource(sourceName, 'geojson', hexagonFeatureCollection);
      this.map.addLayer({
        id: 'hexagons-layer',
        type: 'fill',
        source: sourceName,
        minzoom: 3,
        layout: { visibility: 'visible' },
        paint: {
          'fill-color': colorsAndFonts.tile_border_color,
          'fill-opacity': 0.4
        }
      });
    }
  };

  /**
   * Add a pin to the map,
   *
   * @function
   */
  addPinToMap = () => {
    if (this.marker == null) {
      this.marker = new mapboxgl.Marker({ color: '#009bfa', draggable: true });
    }
    this.marker.setLngLat([this.props.addressData.lng, this.props.addressData.lat]).addTo(this.map);
    this.props.savePin(this.marker.getLngLat());
  };

  /**
   * Update the pin coordinates in StorageManagement
   *
   * @function
   */
  updatePinDrag = () => {
    this.marker.on('drag', () => {
      this.props.savePin(this.marker.getLngLat());
    });
  };

  /**
   * Place where we set all map events
   *
   * @function
   */
  setMapEvents = () => {
    const instance = this;
    this.map.on('load', () => {
      this.plotHexagons();
      this.props.dispatchLoadingPage(false);
    });

    this.map.on('style.load', () => {
      // Triggered when `setStyle` is called.
      if (!instance.isSatelliteViewOn) {
        instance.plotHexagons();
      }
    });
  };

  /**
   * Place where we add all map controls
   *
   * @function
   */
  addMapControls = () => {
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  changeMapViewType = () => {
    this.isSatelliteViewOn = !this.isSatelliteViewOn;
    const newStyle = this.isSatelliteViewOn ? MAP_SATELLITE_VIEW_STYLE : MAP_STYLE;
    this.map.setStyle(newStyle);
  };

  render() {
    return (
      <div className="address-label-map-section">
        <MapModeButton key="change-map-style" dataTip={this.props.t('Change map type')} onClick={this.changeMapViewType}>
          <i className="icon icon-map-outline" />
        </MapModeButton>
        <MapBoxWrapper setMapRef={this.setMapRef} />
      </div>
    );
  }
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

AddressLabelingMapClass.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  savePin: PropTypes.func.isRequired,
  addressData: PropTypes.object,
  zoom: PropTypes.number,
  hexIds: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired
};

AddressLabelingMapClass.defaultProps = {
  addressData: { lng: MapUtil.getInitialViewStateForCompany().lng, lat: MapUtil.getInitialViewStateForCompany().lat },
  zoom: MapUtil.getInitialViewStateForCompany().zoom,
  hexIds: null
};

export default withTranslation('translations')(connect(null, mapDispatchToProps)(AddressLabelingMapClass));
