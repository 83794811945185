import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace PlanningPageActions
 * @category Actions
 */

/**
 *  Open dialog
 *
 * @param {Array} shipmentsData - shipments data
 * @param {string} fileName - file name
 * @returns {{payload: object, type: string}} - Action with a payload
 * @memberOf PlanningPageActions
 * @alias saveShipmentsData
 * @function
 */
export const saveShipmentsData = (shipmentsData, fileName) => ({
  type: ActionTypes.PLANNING_PAGE_SAVE_SHIPMENTS_DATA,
  payload: {
    shipmentsData: shipmentsData,
    shipmentsFileName: fileName
  }
});
export const resetData = () => ({ type: ActionTypes.PLANNING_PAGE_RESET_DATA });
export const saveRegionsData = (regionsData) => ({
  type: ActionTypes.PLANNING_PAGE_SAVE_REGIONS_DATA,
  payload: regionsData
});
export const saveRoutesData = (routesData) => ({
  type: ActionTypes.PLANNING_PAGE_SAVE_ROUTES_DATA,
  payload: routesData
});

export const saveClusteringStops = (stopsData) => ({
  type: ActionTypes.CLUSTERING_SET_STOPS,
  payload: stopsData
});
