export const MAP_IS_LOADING = 'MAP_IS_LOADING';
export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';

export const CHART_DATA_SELECTED = 'CHART_DATA_SELECTED';

export const INFO_DIALOG_OPEN = 'INFO_DIALOG_OPEN';

export const INFO_DIALOG_CLOSE = 'INFO_DIALOG_CLOSE';
export const PAGE_IS_LOADING = 'PAGE_IS_LOADING';
export const PAGE_RELOAD_DATA = 'PAGE_RELOAD_DATA';

export const REGION_ANALYSIS_DATE_CHANGED = 'REGION_ANALYSIS_DATE_CHANGED';

export const REGION_ANALYSIS_MAP_TYPE_CHANGED = 'REGION_ANALYSIS_MAP_TYPE_CHANGED';
export const REGION_ANALYSIS_REGIONS_STOPS_DISTRIBUTION_CHANGED = 'REGION_ANALYSIS_REGIONS_STOPS_DISTRIBUTION_CHANGED';
export const REGION_ANALYSIS_OPTIMIZED_REGIONS_STOPS_DISTRIBUTION_CHANGED = 'REGION_ANALYSIS_OPTIMIZED_REGIONS_STOPS_DISTRIBUTION_CHANGED';
export const REGION_ANALYSIS_HIGHLIGHT_REGION = 'REGION_ANALYSIS_HIGHLIGHT_REGION';

export const AUTH_SET_USER_PERMISSIONS = 'AUTH_SET_USER_PERMISSIONS';
export const AUTH_REST_USER_PERMISSIONS = 'AUTH_REST_USER_PERMISSIONS';

export const CENTER_MAP_TO_STOP = 'CENTER_MAP_TO_STOP';

export const APP_DIALOG_OPEN = 'APP_DIALOG_OPEN';
export const APP_DIALOG_CLOSE = 'APP_DIALOG_CLOSE';
export const SAVE_CHANGES_DIALOG_SHOW_NEXT = 'SAVE_CHANGES_DIALOG_SHOW_NEXT';

export const PLANNING_PAGE_SAVE_SHIPMENTS_DATA = 'PLANNING_PAGE_SAVE_SHIPMENTS_DATA';
export const PLANNING_PAGE_RESET_DATA = 'PLANNING_PAGE_RESET_DATA';
export const PLANNING_PAGE_SAVE_REGIONS_DATA = 'PLANNING_PAGE_SAVE_REGIONS_DATA';
export const PLANNING_PAGE_SAVE_ROUTES_DATA = 'PLANNING_PAGE_SAVE_ROUTES_DATA';

export const ROUTE_DISTANCE_CHANGED = 'ROUTE_DISTANCE_CHANGED';

export const CLUSTERING_SET_STOPS = 'CLUSTERING_SET_STOPS';

export const COORDINATES_CHANGED = 'COORDINATES_CHANGED';
export const COORDINATES_RESET = 'COORDINATES_RESET';

export const SET_MAP = 'SET_MAP';
export const RESIZE_MAP = 'RESIZE_MAP';
