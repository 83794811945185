import csv from 'jquery-csv';
import BackendResourceConfigUtil from '../../../common/utils/api/backendResourceConfigUtil';
import S3Util from '../../../common/utils/s3Util';
import TenantUtil from '../../../common/utils/tenantUtil';

const PlanningDataUtil = {
  getPlanRouteData: getPlanRouteData,
  getPlanShipmentsData: getPlanShipmentsData,
  getPlanRegionData: getPlanRegionData,
  addCourierNameToPlans: addCourierNameToPlans
};

function getPlanRouteData(plan) {
  return S3Util.getFileFromS3(plan.optimizedRouteJson, { download: true, bucket: plan.fileFolder, cacheControl: 'no-cache' }).then((c) => JSON.parse(c));
}

function getPlanShipmentsData(plan) {
  return S3Util.getFileFromS3(plan.orderedShipmentsCsv, { download: true, bucket: plan.fileFolder, cacheControl: 'no-cache' }).then((content) => {
    return csv.toObjects(content, { separator: ';' });
  });
}

function getPlanRegionData(plan, deliveryAreaId, currentDate) {
  let fileName;
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    fileName = `delivery-area/${deliveryAreaId}/${currentDate.format('YYYY-MM-DD')}_${deliveryAreaId}_region_hexagons.json`;
  } else {
    fileName = `${process.env.REACT_APP_LATEST_DELIVERY_AREA_FILE_PATH}/${deliveryAreaId}/${deliveryAreaId}_region_hexagons.json`;
  }
  fileName = TenantUtil.addTenantToFileName(fileName);

  return S3Util.getFileFromS3(fileName, {
    download: true,
    bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
    cacheControl: 'no-cache'
  }).then((content) => {
    const data = JSON.parse(content);
    return data.filter((region) => region.regionId === parseInt(plan.region.match(/\d+/), 10).toString());
  });
}

function addCourierNameToPlans(plans, couriers) {
  return plans.map((plan) => {
    if (plan.courierId && plan.courierId !== '-') {
      const courier = couriers.find((c) => c.id === plan.courierId);
      // TODO check this
      if (courier) {
        return {
          ...plan,
          courierName: `${courier.firstName} ${courier.lastName}`
        };
      }
    }

    return {
      ...plan,
      courierName: '-'
    };
  });
}

export default PlanningDataUtil;
