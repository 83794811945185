import * as ActionTypes from '../constants/actionTypes';

const initialState = {
  isInfoDialogOpen: false,
  component: () => null,
  componentProps: {},
  infoDialogAnchorEl: null
};

/**
 * Reducer - InfoDialog
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INFO_DIALOG_OPEN:
      return {
        infoDialogAnchorEl: action.payload.anchorElement,
        component: action.payload.component,
        componentProps: action.payload.componentProps,
        isInfoDialogOpen: true
      };
    case ActionTypes.INFO_DIALOG_CLOSE:
      return {
        infoDialogAnchorEl: null,
        component: () => null,
        componentProps: {},
        isInfoDialogOpen: false
      };
    default:
      return state;
  }
};

export default reducer;
