import React from 'react';
import PropTypes from 'prop-types';
import '../courierAnalysis/CourierAnalysis.scss';
import './RouteAnalysis.scss';
import moment from 'moment';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { raygunClient } from '../../../setup/raygunClient';
import * as PageActions from '../../../state/actions/pageActions';

import colorsAndFonts from '../../../resources/colors-and-fonts.scss';
import DataCard from '../../../common/components/cards/DataCard';
import DatePickerWrapper from '../../../common/components/datePickers/DayPickerWrapper';
import MapWrapper from './components/MapWrapper';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import MixPanel from '../../../setup/mixPanel';
import StopsTimeline from './components/StopsTimeline';
import CourierTeamSelect from '../../../common/components/selections/teamSelect/CourierTeamSelect';
import CourierSelect from '../../../common/components/selections/courierSelect/CourierSelect';
import EntityUtil from '../utils/entityUtil';
import RouteAnalysisApi from './api/routeAnalysisApi';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';

/**
 * Wrapper for all functionalities of Route analysis page at /route-analysis
 *
 * @component
 * @alias RouteAnalysis
 * @category RouteAnalysis
 */
class RouteAnalysis extends React.Component {
  constructor() {
    super();
    this.state = {
      courierId: null,
      isLoadingStops: false,
      isLoadingCouriers: true,
      teamId: '',
      stopsData: null
    };

    this.couriersData = null;
    this.date = QueryStringUtil.getQueryStringValue('date')
      ? moment(QueryStringUtil.getQueryStringValue('date'), 'YYYY-MM-DD').toDate()
      : moment().subtract(1, 'day').toDate();
  }

  componentDidMount() {
    MixPanel.track('Page Load - Route Analysis');
    MixPanelUtil.setUnloadListener('Page Unload - Route Analysis');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isMapLoading !== this.props.isMapLoading
      || prevState.isLoadingStops !== this.state.isLoadingStops
      || prevState.isLoadingCouriers !== this.state.isLoadingCouriers
    ) {
      this.props.dispatchLoadingPage(this.props.isMapLoading || this.state.isLoadingStops || this.state.isLoadingCouriers);
    }
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  /**
   * Fetches couriers of a specific team
   *
   * @param {string} teamId -  team ID
   * @param {string} entityType - entity to fetch (courier / packageLocker)
   * @function
   */
  fetchCouriers = async (teamId, entityType) => {
    this.setState({ teamId: teamId, isLoadingCouriers: true });

    // fetch couriers
    EntityUtil.getEntityData(teamId, entityType, true)
      .then((couriersData) => {
        this.couriersData = couriersData;
        this.setState({ isLoadingCouriers: false, stopsData: null });
      })
      .catch((error) => {
        raygunClient.send(error, 'Failed to load couriers for Route Analysis');
        toast.error(this.props.t('Failed to load couriers'));
        this.setState({ isLoadingCouriers: false });
      });
  };

  /**
   * Handles event when courier is selected
   *
   * @param {object} opt - Option from react.Select - value is courier id
   * @function
   */
  onCourierChange = (opt) => {
    const courierId = opt && opt.value;
    this.setState({ courierId });
    MixPanel.track('Route Analysis - Courier changed');
    this.fetchCourierStopsDataAsync(courierId);
  };

  /**
   * Handles date change. Fetches stops for a new date.
   *
   * @param {Date} date - Selected date
   * @function
   */
  onDateChanged = (date) => {
    this.date = date;
    QueryStringUtil.setQueryStringValue('date', moment(date).format('YYYY-MM-DD'));
    QueryStringUtil.setQueryStringValue('fromDate', moment(date).clone().weekday(0).startOf('day')
      .format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('toDate', moment(date).clone().weekday(6).endOf('day')
      .format('YYYY-MM-DDTHH'));
    MixPanel.track('Route Analysis - Date changed');
    this.fetchCourierStopsDataAsync(this.state.courierId);
  };

  /**
   * Fetches couriers stops for selected date.
   *
   * @param {string} courierId - Courier id
   * @function
   */
  fetchCourierStopsDataAsync = async (courierId) => {
    if (!courierId) {
      return;
    }

    this.setState({ isLoadingStops: true });

    try {
      const stopsData = await RouteAnalysisApi.getStops(courierId, this.date);
      if (!stopsData || !stopsData.stops) {
        toast.warn(this.props.t('noDataMessage'), {
          position: 'top-right',
          autoClose: 3000
        });
      }
      this.setState({ isLoadingStops: false, stopsData: stopsData });
    } catch (error) {
      raygunClient.send(error, 'Error loading courier for Route Analysis');
      toast.error(this.props.t('Error loading courier'));
    }
  };

  render() {
    return (
      <div className="home">
        <div className="home-data-section">
          <div className="filters-section">
            <div className="team-selection-wrapper">
              <div className="label">{this.props.t('Team')}</div>
              <CourierTeamSelect onTeamChange={this.fetchCouriers} eventTrackerNamePrefix="Route Analysis" />
            </div>
            <div className="courier-selection-wrapper">
              <div className="label">{this.props.t('Courier')}</div>
              <CourierSelect couriersData={this.couriersData} onChange={this.onCourierChange} />
            </div>
            <div className="date-filters">
              <div className="label">{this.props.t('Date')}</div>
              <DatePickerWrapper onDateChange={this.onDateChanged} selectedDay={this.date} />
            </div>
          </div>
          {this.state.courierId && this.state.stopsData && this.state.stopsData.stops ? (
            <div>
              <div className="data-section">
                {!this.state.isLoadingStops ? (
                  <div className="data-cards">
                    <DataCard
                      title={this.props.t('Drop-offs')}
                      value={this.state.stopsData.statistics ? this.state.stopsData.statistics.totalNumberOfDeliveries : 0}
                      borderColor={colorsAndFonts.deliveries_color}
                    />
                    <DataCard
                      title={this.props.t('Pickups')}
                      value={this.state.stopsData.statistics ? this.state.stopsData.statistics.totalNumberOfPickups : 0}
                      borderColor={colorsAndFonts.pickups_color}
                    />
                    <DataCard
                      title={this.props.t('Total stops')}
                      value={this.state.stopsData.statistics ? this.state.stopsData.statistics.totalNumberOfStops : 0}
                      borderColor={colorsAndFonts.tile_background_color}
                    />
                  </div>
                ) : (
                  <div className="data-cards-loader">
                    <CircularProgress />
                  </div>
                )}
              </div>
              {!this.state.isLoadingStops ? (
                <div className="timeline-wrapper">
                  <StopsTimeline stops={this.state.stopsData.stops} />
                </div>
              ) : (
                <div className="timeline-wrapper-loader">
                  <CircularProgress />
                </div>
              )}
            </div>
          ) : (
            <div className="no-data">
              <div className="no-data-text">{this.props.t('No results found')}</div>
              <div className="no-data-call-to-action">{this.props.t('Please select a courier')}</div>
            </div>
          )}
        </div>
        <div className="map-section">
          {this.state.courierId ? (
            <MapWrapper hasStops={!this.state.isLoadingStops} stopsData={this.state.stopsData} teamId={this.state.teamId} />
          ) : (
            <i className="logo icon icon-mily-logo" />
          )}
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.chartState, ...store.mapState, ...store.authState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RouteAnalysis));

RouteAnalysis.propTypes = {
  /**
   * Translation function
   */
  t: PropTypes.func.isRequired,
  /**
   * Current user permissions
   */
  userPermissions: PropTypes.shape({}),
  /**
   * If map is currently loading data
   */
  isMapLoading: PropTypes.bool,
  /**
   * Dispatches the event if page should be loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired
};

RouteAnalysis.defaultProps = {
  userPermissions: null,
  isMapLoading: false
};
