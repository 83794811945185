import moment from 'moment';
import { gql } from '@apollo/client';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';

const StatisticsApi = {
  getStatisticsStops: getStatisticsStops,
  getPerformanceReportFile: getPerformanceReportFile
};

const GetStatistics = gql`
  query GetStatistics($teamId: String, $fromDate: String, $toDate: String) {
    getStatistics(teamId: $teamId, fromDate: $fromDate, toDate: $toDate) {
      days {
        average
        date
        stops
      }
      totals {
        avgPerCourier
        avgPerDay
        total
      }
      insights {
        bestDay {
          average
          date
          stops
        }
        percentUnderAverage
        performanceInfo {
          overperformingPercentage
          overperformingTotal
          overperformingLimit
          underperformingPercentage
          underperformingTotal
          underperformingLimit
        }
        worstDay {
          average
          date
          stops
        }
      }
      couriers {
        teamId
        courierId
        name
        stops
        workingDays
        numberOfBreaks
        timeOutOfRegion
        mileage
      }
    }
  }
`;

const GetPerformanceReportFile = gql`
  query getReport($teamId: String, $fromDate: String, $toDate: String, $fileName: String, $fileFormat: String, $weekend: Boolean) {
    getReport(teamId: $teamId, fromDate: $fromDate, toDate: $toDate, fileName: $fileName, fileFormat: $fileFormat, weekend: $weekend) {
      bucket
      fileName
    }
  }
`;

async function getStatisticsStops(teamId, dateFrom, dateTo) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const startDate = moment(dateFrom).startOf('day').format('YYYY-MM-DD');
    const endDate = moment(dateTo).endOf('day').format('YYYY-MM-DD');

    return ApiHelpers.createQuery(GetStatistics, { teamId: teamId, fromDate: startDate, toDate: endDate });
  }
  return fetch('testData/analysis/stopsStatistics.json').then((data) => data.json());
}

async function getPerformanceReportFile(teamId, dateFrom, dateTo, fileName, fileFormat, weekend) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const startDate = moment(dateFrom).startOf('day').format('YYYY-MM-DD');
    const endDate = moment(dateTo).endOf('day').format('YYYY-MM-DD');

    return ApiHelpers.createQuery(GetPerformanceReportFile, {
      teamId: teamId,
      fromDate: startDate,
      toDate: endDate,
      fileName: fileName,
      fileFormat: fileFormat,
      weekend: weekend
    });
  }

  return null;
}

export default StatisticsApi;
