import React from 'react';
import PropTypes from 'prop-types';

import './MapModeButton.scss';
import ReactTooltip from 'react-tooltip';

export default function MapModeButton(props) {
  const { isActive, dataTip, dataValue, dataFor, onClick } = props;

  return (
    <div
      className={isActive ? 'mode-button active' : 'mode-button'}
      data-tip={dataTip}
      data-for={dataFor || 'map-mode-button-tooltip'}
      data-value={dataValue}
      onClick={onClick}
    >
      {props.children}
      {!dataFor && <ReactTooltip id="map-mode-button-tooltip" className="tooltip" place="right" effect="solid" />}
    </div>
  );
}

MapModeButton.propTypes = {
  isActive: PropTypes.bool,
  dataTip: PropTypes.string,
  dataFor: PropTypes.string,
  dataValue: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired
};

MapModeButton.defaultProps = {
  isActive: false,
  dataTip: '',
  dataFor: null,
  dataValue: null,
  onClick: () => {}
};
