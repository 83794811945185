import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MixPanel from '../../../../setup/mixPanel';
import CustomSelect from '../CustomSelect';
import './CourierSelect.scss';
import QueryStringUtil from '../../../utils/queryStringUtil';

/**
 * Select for couriers
 *
 * @param {object} props - component props
 * @returns {JSX.Element} Select element
 * @component
 * @alias CourierSelect
 * @category Common
 */
export default function CourierSelect(props) {
  const { t } = useTranslation();
  const { couriersData, onChange, eventTrackerNamePrefix } = props;

  const [courierId, setCourierId] = useState(QueryStringUtil.getQueryStringValue('courierId'));
  const [courierOptions, setCourierOptions] = useState([]);

  /**
   * Create select option
   *
   * @param {string} id - courierId to create option for
   * @returns {object} - select option
   * @function
   */
  const createCourierOption = useCallback(
    (id) => {
      if (id) {
        const courier = couriersData.find((c) => c.id === id);
        if (courier) {
          if (!courier.firstName && !courier.lastName) {
            return {
              value: courier.id,
              label: courier.name
            };
          }

          return {
            value: courier.id,
            label: `${courier.firstName} ${courier.lastName}`
          };
        }
      }

      return null;
    },
    [couriersData]
  );

  /**
   * Handle courier change in select
   *
   * @param {object} opt - selected option
   * @function
   */
  const onCourierChange = useCallback(
    (opt) => {
      const updateQueryString = couriersData && couriersData.length > 0;
      if (eventTrackerNamePrefix && opt && opt.value) {
        MixPanel.track(`${eventTrackerNamePrefix} - Courier changed`, { courierId: opt.value });
      }
      setCourierId(opt && opt.value);
      if (updateQueryString) {
        QueryStringUtil.setQueryStringValue('courierId', opt && opt.value);
      }

      if (onChange) {
        onChange(opt);
      }
    },
    [couriersData, onChange, eventTrackerNamePrefix]
  );

  useEffect(() => {
    if (couriersData) {
      const options = couriersData.map((courier) => {
        if (courier.id) {
          return createCourierOption(courier.id);
        }

        return {};
      });

      setCourierOptions(options);
    }
  }, [couriersData, createCourierOption]);

  useEffect(() => {
    /**
     * Check if selected courier belongs to selected center
     *
     * @returns {boolean} - true if courier belongs to current center
     * @function
     */
    const isCourierInSelectedCenter = () => couriersData && couriersData.some((courier) => courier.id === QueryStringUtil.getQueryStringValue('courierId'));

    const id = QueryStringUtil.getQueryStringValue('courierId');

    let option;
    if (QueryStringUtil.getQueryStringValue('courierId') && isCourierInSelectedCenter()) {
      option = createCourierOption(id);
    }

    onCourierChange(option);
  }, [couriersData, onCourierChange, createCourierOption]);

  return (
    <div className="courier-select">
      {couriersData && (
        <CustomSelect
          className="select"
          options={courierOptions}
          value={courierOptions.filter((o) => o.value === courierId)}
          onChange={onCourierChange}
          placeholder={t('Enter courier name')}
        />
      )}
    </div>
  );
}

CourierSelect.propTypes = {
  couriersData: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  eventTrackerNamePrefix: PropTypes.string
};

CourierSelect.defaultProps = {
  couriersData: null,
  onChange: null,
  eventTrackerNamePrefix: null
};
