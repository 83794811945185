import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddDeliveryAreaPage from './pages/AddDeliveryAreaPage';
import DeliveryAreasManagementPage from './pages/DeliveryAreasManagementPage';
import EditViewStatePage from './pages/EditViewStatePage';

/**
 * Planning page wrapper for page navigation
 *
 * @returns {JSX.Element} - Planning page wrapper for page navigation
 * @component
 * @alias PlanningPageRoutes
 * @category planning
 */
export default function ZipCodeRoutes() {
  return (
    <Switch>
      <Route exact path="/zip-code" key="/zip-code" component={DeliveryAreasManagementPage} />
      <Route exact path="/zip-code/map-view/:deliveryAreaId" key="/zip-code/map-view" component={AddDeliveryAreaPage} />
      <Route exact path="/zip-code/map-view/:deliveryAreaId/view-state" key="/zip-code/map-view/view-state" component={EditViewStatePage} />
    </Switch>
  );
}
