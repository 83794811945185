import PropTypes from 'prop-types';
import './NumberCircle.scss';
import React from 'react';

export default function NumberCircle(props) {
  function getSize() {
    if (props.numberToShow && props.numberToShow.length > 2) {
      if (props.numberToShow.length > 3) return ' very-large';
      return ' large';
    }
    return '';
  }

  function getSizeField() {
    if (props.numberToShow && props.numberToShow.length === 1) {
      return ' single';
    }
    return '';
  }

  return (
    <div className={`circle-wrapper${getSize()}`}>
      <div className={`dot${getSizeField()}`}>{props.numberToShow}</div>
    </div>
  );
}

NumberCircle.propTypes = { numberToShow: PropTypes.string.isRequired };
