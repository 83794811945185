import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../MapModeButton.scss';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import MapModeButton from '../MapModeButton';
import { SELECTION_TOOL } from '../constants/drawHexagonsConst';

export default function HexSelectionToolsMenu(props) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectionTool, setSelectionTool] = useState(false);

  function changeSelectionTool(e) {
    if (props.changeSelectionTool) {
      props.changeSelectionTool(e);
    }

    setSelectionTool(e.currentTarget.dataset.value);
  }

  function getSelectionToolIcon() {
    switch (selectionTool) {
      case SELECTION_TOOL.CIRCLE:
        return <i className="icon icon-circle" />;
      case SELECTION_TOOL.POLYGON:
        return <i className="icon icon-draw-polygon" />;
      case SELECTION_TOOL.RECTANGLE:
        return <i className="icon icon-rectangle" />;
      case SELECTION_TOOL.PICK:
        return <i className="icon icon-select-object" />;
      default:
        return <i className="icon icon-select-object" />;
    }
  }

  return (
    <MapModeButton
      onClick={() => {
        setOpenMenu(!openMenu);
      }}
      dataFor="selection-tool-tooltip"
      dataTip={t('Choose selection tool')}
    >
      {getSelectionToolIcon()}
      <div className="menu menu-vertical" style={{ display: openMenu ? 'block' : 'none' }}>
        <div className="menu-item" onClick={changeSelectionTool} data-value={SELECTION_TOOL.PICK}>
          <i className="icon icon-select-object" />
          <span className="selection-label">Pick</span>
        </div>
        <div className="menu-item" onClick={changeSelectionTool} data-value={SELECTION_TOOL.RECTANGLE}>
          <i className="icon icon-rectangle" />
          <span className="selection-label">Rectangle</span>
        </div>
        <div className="menu-item" onClick={changeSelectionTool} data-value={SELECTION_TOOL.POLYGON}>
          <i className="icon icon-draw-polygon" />
          <span className="selection-label">Polygon</span>
        </div>
        <div className="menu-item" onClick={changeSelectionTool} data-value={SELECTION_TOOL.CIRCLE}>
          <i className="icon icon-circle" />
          <span className="selection-label">Circle</span>
        </div>
      </div>
      {!openMenu && <ReactTooltip id="selection-tool-tooltip" className="tooltip" place="right" effect="solid" />}
    </MapModeButton>
  );
}

HexSelectionToolsMenu.propTypes = { changeSelectionTool: PropTypes.func };

HexSelectionToolsMenu.defaultProps = { changeSelectionTool: null };
