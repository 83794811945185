import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardMenuTable from '../../../../common/components/tables/cardMenuTable/CardMenuTable';
import './DeliveryAreaMenuTable.scss';
import * as PageActions from '../../../../state/actions/pageActions';

class DeliveryAreaMenuTablePage extends React.Component {
  constructor() {
    super();
    this.state = { tablePageSize: 1 };
  }

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize !== pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  render() {
    return (
      <div className="da-table-wrapper">
        {this.props.deliveryAreas && this.props.deliveryAreas.length > 0 && (
          <CardMenuTable
            key={this.state.tablePageSize}
            columns={this.props.getTableColumns()}
            data={this.props.deliveryAreas}
            sortBy={[{ id: 'name', desc: false }]}
            showSearch
            handleRowClick={this.props.showDeliveryArea}
            pageSize={this.state.tablePageSize}
            calculateTablePageSize={this.calculateTablePageSize}
            hiddenColumns={this.props.hiddenColumns}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

DeliveryAreaMenuTablePage.propTypes = {
  showDeliveryArea: PropTypes.func,
  getTableColumns: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  deliveryAreas: PropTypes.arrayOf(PropTypes.shape({}))
};

DeliveryAreaMenuTablePage.defaultProps = {
  showDeliveryArea: () => {},
  hiddenColumns: [],
  deliveryAreas: null
};

export default connect(null, mapDispatchToProps)(DeliveryAreaMenuTablePage);
