import store from '../../../../state/store';
import * as DialogActions from '../../../../state/actions/dialogActions';
import SaveChangesDialog from '../saveChangesDialog/SaveChangesDialog';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';

/**
 * Util for interacting with AppDialog
 *
 * @namespace
 * @category Common
 */
const AppDialogActionsWrapper = {
  openAppDialog: openAppDialog,
  closeAppDialog: closeAppDialog,
  openConfirmationDialog: openConfirmationDialog,
  showSaveChangesAlert: showSaveChangesAlert
};

/**
 * @param props
 * @memberOf AppDialogActionsWrapper
 * @function
 */
function openAppDialog(props) {
  store.dispatch(DialogActions.openDialog(props));
}

function openConfirmationDialog(alertDialogProps, continueCallback, cancelCallback) {
  store.dispatch(
    DialogActions.openDialog({
      dialogComponent: ConfirmationDialog,
      dialogComponentProps: alertDialogProps,
      continueCallback: continueCallback,
      cancelCallback: cancelCallback
    })
  );
}

/**
 * @param {Function} continueCallback - additional callback called after continue is selected
 * @param {Function} cancelCallback - additional callback called after cancel is selected
 * @memberOf AppDialogActionsWrapper
 * @function
 */
function showSaveChangesAlert(continueCallback, cancelCallback) {
  if (store.getState().saveChangesDialogState.showNextAlertDialog) {
    store.dispatch(
      DialogActions.openDialog({
        dialogComponent: SaveChangesDialog,
        continueCallback: continueCallback,
        cancelCallback: cancelCallback
      })
    );
  } else if (continueCallback) {
    if (store.getState().saveChangesDialogState.showNextAlertDialog) {
      store.dispatch(DialogActions.openDialog(false));
    }
    continueCallback();
  }
}

/**
 * Closes app dialog and resolve promise with passed data.
 *
 * @memberOf AppDialogActionsWrapper
 * @function
 */
function closeAppDialog() {
  store.dispatch(DialogActions.closeDialog());
}

export default AppDialogActionsWrapper;
