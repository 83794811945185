import moment from 'moment';

function checkIfPauseBetweenStops(timeDifference) {
  const regHour = /^[0-9]\s+h/;
  const checkIfHour = regHour.exec(timeDifference);
  if (checkIfHour) return true;

  const regMin = /^[0-9]+/;
  const checkMin = regMin.exec(timeDifference);
  if (checkMin) {
    return parseInt(checkMin[0], 10) > 20;
  }
  return false;
}

/**
 * Calculates time between two stops
 *
 * @param {Date} prevTime - previous stop time
 * @param {Date} time - current stop time
 * @returns {string} time difference in H[ h ]: m[ min] format
 * @function
 */
function calculateTimeBetweenStops(prevTime, time) {
  const startTime = moment(prevTime);
  const endTime = moment(time);

  const diff = moment.duration(endTime.diff(startTime));
  const h = Math.floor(diff.asHours());
  const m = diff.minutes();

  return h > 0 ? `${h} h : ${m} min` : `${m} min`;
}

export const PauseCalculationUtil = {
  checkIfPauseBetweenStops: checkIfPauseBetweenStops,
  calculateTimeBetweenStops: calculateTimeBetweenStops
};
