import React, { useEffect, useState } from 'react';
import './App.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import CourierAnalysis from '../../features/analysis/courierAnalysis/CourierAnalysis';
import UsersPermissions from '../../features/management/users/UsersPermissions';
import ZipCodeRoutes from '../../features/management/deliveryAreas/ZipCodeRoutes';
import PlanTracking from '../../features/planning/planTracking/PlanTracking';
import RealTime from '../../features/realTime/RealTime';
import AddressLabelingPage from '../../features/management/addressLabeling/AddressLabelingPage';
import { amplifyI18n } from '../../setup/amplifyTranslate';
import { SetupApi } from '../api/setupApi';
import SideMenu from './SideMenu';
import SignInToolbar from './SignInToolbar';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import PageNotFound from './PageNotFound';
import RegionAnalysis from '../../features/analysis/regionAnalysis/RegionAnalysis';
import RouteAnalysis from '../../features/analysis/routeAnalysis/RouteAnalysis';
import MixPanel from '../../setup/mixPanel';
import AuthUtil from '../../common/utils/authUtil';
import StatisticPage from '../../features/analysis/statistics/StatisticPage';
import CouriersPage from '../../features/management/couriers/CouriersPage';
import AppDialog from '../../common/components/dialogs/AppDialog';
import './GlobalCss.scss';
import PlanningPageRoutes from '../../features/planning/planManagement/PlanningPageRoutes';
import DeliveryAreaRoutes from '../../features/management/deliveryAreas/DeliveryAreaRoutes';
import StorageRoutes from '../../features/management/storages/StorageRoutes';
import BackendResourceConfigUtil from '../../common/utils/api/backendResourceConfigUtil';
import ShipmentManagement from '../../features/management/shipments/ShipmentManagement';

Amplify.configure({
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: BackendResourceConfigUtil.getApiName(),
        endpoint: BackendResourceConfigUtil.getApiEndPoint()
      }
    ]
  },
  Storage: {
    bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    customPrefix: { public: '' }
  }
});

Auth.configure();

// Supported log levels: ERROR, WARN, INFO, DEBUG, VERBOSE
Amplify.Logger.LOG_LEVEL = 'ERROR';

const signInComponents = {
  Header() {
    return (
      <div className="sign-in-toolbar-wrapper">
        <SignInToolbar />
      </div>
    );
  },

  SignIn: {
    Header() {
      return (
        <div className="login-title">{amplifyI18n.get('Sign in to your account')}</div>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <div className="login-footer">
          <span>{amplifyI18n.get('Forgot your password?')}</span>
          <span className="reset-password" onClick={toResetPassword}>{amplifyI18n.get('Reset Password')}</span>
        </div>
      );
    }
  }
};

/**
 * Defines the whole app structure and layout
 *
 * @returns {JSX.Element} - whole app
 * @component
 * @alias App
 * @category App
 */
function App() {
  const isPageLoading = useSelector((state) => state.pageState.isPageLoading);
  const isMapLoading = useSelector((state) => state.mapState.isMapLoading);
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.authState.userPermissions);
  const { t } = useTranslation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const { user } = useAuthenticator((context) => [context.user]);
  const [pages, setPages] = useState(null);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      const getUserPermissions = async () => {
        await SetupApi.loadUserPermissions();
      };
      getUserPermissions();
    }
  }, [dispatch, authStatus]);

  useEffect(() => {
    if (authStatus === 'authenticated' && userPermissions) {
      const newPages = [];

      if (
        AuthUtil.hasAnyOfFeaturesEnabled([
          'courierManagement',
          'deliveryAreaManagement',
          'storageManagement',
          'shipmentManagement',
          'userManagement',
          'labeling'
        ])
      ) {
        newPages.push({
          key: 'management-separator',
          text: t('Management'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('courierManagement')) {
        newPages.push({
          key: 'courier-management',
          component: CouriersPage,
          iconName: 'account-group',
          text: t('Couriers and teams'),
          tooltip: t('Couriers and teams tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('deliveryAreaManagement')) {
        newPages.push({
          key: 'delivery-areas',
          component: DeliveryAreaRoutes,
          iconName: 'map',
          text: t('Delivery Areas'),
          tooltip: t('Delivery Areas tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('storageManagement')) {
        newPages.push({
          key: 'storage-management',
          component: StorageRoutes,
          iconName: 'location-on',
          text: t('Storages'),
          tooltip: t('Storage management')
        });
      }

      if (AuthUtil.isFeatureEnabled('shipmentManagement')) {
        newPages.push({
          key: 'shipment-management',
          component: ShipmentManagement,
          iconName: 'assignment-turned-in-outlined',
          text: t('Shipments'),
          tooltip: t('Shipment management tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('userManagement')) {
        newPages.push({
          key: 'user-management',
          component: UsersPermissions,
          iconName: 'account-cog',
          text: t('User management'),
          tooltip: t('User management tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('zipCodeManagement')) {
        newPages.push({
          key: 'zip-code',
          component: ZipCodeRoutes,
          iconName: 'markunread-mailbox',
          text: t('Zip Codes'),
          tooltip: t('Zip Codes tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('labeling')) {
        newPages.push({
          key: 'address-label',
          component: AddressLabelingPage,
          iconName: 'wrong-location-outlined',
          text: t('Address labeling'),
          tooltip: t('Address labeling')
        });
      }

      if (AuthUtil.isFeatureEnabled('planning')) {
        newPages.push({
          key: 'planning-separator',
          text: t('Planing'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('planning')) {
        newPages.push({
          key: 'delivery-plan',
          component: PlanningPageRoutes,
          iconName: 'list-alt',
          text: t('Delivery plans'),
          tooltip: t('Delivery plans tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('planning')) {
        newPages.push({
          key: 'delivery-plan-tracking',
          component: PlanTracking,
          iconName: 'content-search',
          text: t('Delivery plan tracking'),
          tooltip: t('Delivery plan tracking tooltip')
        });
      }

      if (AuthUtil.isFeatureEnabled('realTime')) {
        newPages.push({
          key: 'realTime-separator',
          text: t('Execution'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('realTime')) {
        newPages.push({
          key: 'real-time',
          component: RealTime,
          iconName: 'history',
          text: t('Real-time tracking'),
          tooltip: t('Real-time tracking')
        });
      }

      if (AuthUtil.hasAnyOfFeaturesEnabled(['courierAnalysis', 'routeAnalysis', 'statistics', 'deliveryAreaAnalysis'])) {
        newPages.push({
          key: 'analysis-separator',
          text: t('Analytics'),
          type: 'SEPARATOR'
        });
      }

      if (AuthUtil.isFeatureEnabled('courierAnalysis')) {
        newPages.push({
          key: 'courier-analysis',
          component: CourierAnalysis,
          iconName: 'local-shipping',
          text: t('Courier analysis'),
          tooltip: t('Courier analysis')
        });
      }

      if (AuthUtil.isFeatureEnabled('routeAnalysis')) {
        newPages.push({
          key: 'route-analysis',
          component: RouteAnalysis,
          iconName: 'timeline',
          text: t('Route analysis'),
          tooltip: t('Route analysis')
        });
      }

      if (AuthUtil.isFeatureEnabled('statistics')) {
        newPages.push({
          key: 'statistics',
          component: StatisticPage,
          iconName: 'bar-chart',
          text: t('Statistics'),
          tooltip: t('Statistics')
        });
      }

      if (AuthUtil.isFeatureEnabled('deliveryAreaAnalysis')) {
        newPages.push({
          key: 'region-analysis',
          component: RegionAnalysis,
          iconName: 'map-search',
          text: t('Delivery area analysis'),
          tooltip: t('Delivery area analysis')
        });
      }

      setPages(newPages);
    }
  }, [t, userPermissions, authStatus]);

  useEffect(() => {
    window.Intercom('shutdown');
  }, [userPermissions]);

  toast.configure({
    autoClose: 2000,
    position: toast.POSITION.BOTTOM_RIGHT
  });

  const isAuthenticated = authStatus === 'authenticated';

  if (isAuthenticated) {
    MixPanel.identify(user.username);
    if (userPermissions) {
      MixPanel.people()
        .set({
          $email: user.username,
          Email: user.username,
          'User Name': user.username,
          'Policy Name': userPermissions.policyName,
          'Zone Name': userPermissions.zoneName
        });
    }
  }

  function getDefaultPage() {
    switch (AuthUtil.getTenantId()) {
      case 'dexpress':
        return '/region-analysis';
      case 'xexpress':
        return '/courier-analysis';
      case 'jokic':
        return '/delivery-areas';
      case 'eexpress':
        return '/courier-analysis';
      default:
        return '/courier-analysis';
    }
  }

  const formFields = { resetPassword: { username: { label: 'Email*' } } };

  return (
    <Authenticator hideSignUp components={signInComponents} formFields={formFields}>
      {() => (
        <div className="app">
          {pages && pages.length > 0 && (
            <div className="app">
              <SideMenu pages={pages} />
              <div className="main">
                <div className="content">
                  <Switch>
                    {pages
                      .filter((p) => !p.type)
                      .map((page) => (
                        <Route path={`/${page.key}`} key={page.key}>
                          {React.createElement(page.component)}
                        </Route>
                      ))}
                    {isAuthenticated && <Redirect exact strict from="/" to={getDefaultPage()} />}
                    <Route path="*">
                      <PageNotFound />
                    </Route>
                  </Switch>
                </div>
              </div>

              <AppDialog />

              <Backdrop open={isPageLoading || isMapLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </div>
      )}
    </Authenticator>
  );
}

export default App;
