import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { formatDate } from 'react-day-picker/moment';
import moment from 'moment';
import PropTypes from 'prop-types';
import './DatePickerWrapper.scss';
import { withTranslation } from 'react-i18next';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from '../../../../common/constants/dayMonthsNames';

class DayPickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverRange: undefined,
      selectedDays: this.getWeekDays(this.getWeekRange(props.selectedDays[0]).from),
      showDayPicker: false,
      MONTHS: MONTHS.map((m) => this.props.t(m)),
      WEEKDAYS_LONG: WEEKDAYS_LONG.map((m) => this.props.t(m)),
      WEEKDAYS_SHORT: WEEKDAYS_SHORT.map((m) => this.props.t(m))
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this.handleLanguageChange();
    }
  }

  handleLanguageChange = () => {
    this.setState({
      MONTHS: MONTHS.map((m) => this.props.t(m)),
      WEEKDAYS_LONG: WEEKDAYS_LONG.map((m) => this.props.t(m)),
      WEEKDAYS_SHORT: WEEKDAYS_SHORT.map((m) => this.props.t(m))
    });
  };

  getWeekRange = (date) => ({
    from: moment(date).startOf('isoWeek').toDate(),
    to: moment(date).endOf('isoWeek').toDate()
  });

  getWeekDays = (weekStart) => {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
      days.push(moment(weekStart).add(i, 'days').toDate());
    }
    return days;
  };

  handleDayChange = (date) => {
    const selectedDays = this.getWeekDays(this.getWeekRange(date).from);
    this.setState({
      selectedDays: selectedDays,
      showDayPicker: false
    });

    if (this.state.showDayPicker) {
      this.props.onDateChange(selectedDays[0], selectedDays[6]);
    }
  };

  handleDayEnter = (date) => {
    this.setState({ hoverRange: this.getWeekRange(date) });
  };

  handleDayLeave = () => {
    this.setState({ hoverRange: undefined });
  };

  toggleDayPicker = () => {
    this.setState((prevState) => ({ showDayPicker: !prevState.showDayPicker }));
  };

  prevWeek = () => {
    const date = moment(this.state.selectedDays[0]).subtract(1, 'day');
    const selectedDays = this.getWeekDays(this.getWeekRange(date).from);
    this.setState({ selectedDays: selectedDays });

    this.props.onDateChange(selectedDays[0], selectedDays[6]);
  };

  nextWeek = () => {
    const date = moment(this.state.selectedDays[6]).add(1, 'day');
    const selectedDays = this.getWeekDays(this.getWeekRange(date).from);
    this.setState({ selectedDays: selectedDays });

    this.props.onDateChange(selectedDays[0], selectedDays[6]);
  };

  render() {
    const { hoverRange, selectedDays } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6]
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6]
    };

    return (
      <div className="date-picker-wrapper">
        <div className="date-picker-input">
          <i className="icon icon-keyboard-arrow-left calendar-hover" onClick={this.prevWeek} />

          <div className="dates-wrapper calendar-hover">
            <i className="icon icon-event" onClick={this.toggleDayPicker} />
            <div className="dates" onClick={this.toggleDayPicker}>
              {moment(selectedDays[0]).format('DD/MM/YYYY')}
              {' '}
              –
              {moment(selectedDays[6]).format('DD/MM/YYYY')}
            </div>
          </div>
          <i className="icon icon-keyboard-arrow-right calendar-hover" onClick={this.nextWeek} />
        </div>
        {this.state.showDayPicker && (
          <div className="picker-wrapper">
            <DayPicker
              firstDayOfWeek={1}
              selectedDays={selectedDays}
              showWeekNumbers
              showOutsideDays
              modifiers={modifiers}
              onDayClick={this.handleDayChange}
              onDayMouseEnter={this.handleDayEnter}
              onDayMouseLeave={this.handleDayLeave}
              format="DD/MM/YYYY"
              formatDate={formatDate}
              placeholder="DD/MM/YYYY"
              month={selectedDays[0]}
              months={this.state.MONTHS}
              weekdaysLong={this.state.WEEKDAYS_LONG}
              weekdaysShort={this.state.WEEKDAYS_SHORT}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translations')(DayPickerWrapper);

DayPickerWrapper.propTypes = {
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Selected dates
   */
  selectedDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onDateChange: PropTypes.func.isRequired
};

DayPickerWrapper.defaultProps = { selectedDays: [] };
