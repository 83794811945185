import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import mapPopupDefaultStyle from '../../../../common/components/popups/util/mapPopupDefaultStyle';
import * as DistanceActions from '../../../../state/actions/footerActions';
import store from '../../../../state/store';

/**
 * Util for rendering route map views
 *
 * @class RouteUtilClass
 * @param map - map ref
 * @category RouteAnalysis
 */

export default class RouteUtilClass {
  constructor(map, t) {
    this.map = map;
    this.mapRouteSources = [];
    this.mapRouteLayers = [];
    this.arrowImage = false;
    this.increment = 0;
    this.mapPopups = [];
    this.t = t;
  }

  plotRoute = (coordinates, properties, showLineRoute, mileage) => {
    if (showLineRoute) {
      this.plotLineRoute(coordinates);
    } else {
      this.plotPointRoute(coordinates, properties);
    }

    store.dispatch(DistanceActions.distanceChange(mileage));
  };

  plotLineRoute = (coordinates) => {
    this.addLineRoute({ coordinates: coordinates, type: 'LineString' }, `0-${this.increment++}`);
  };

  /**
   * Adds drivers route layer to the map
   *
   * @param {{coordinates, type: string}} coords - gsp coordinates
   * @param {string} index - index of the specific part of the route
   * @memberOf RouteUtilClass
   * @function
   */
  addLineRoute = (coords, index) => {
    this.map.addSource(`mapDataSourceId${index}`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: coords
      }
    });

    // Add a new layer to the map
    this.map.addLayer({
      id: `route${index}`,
      type: 'line',
      source: `mapDataSourceId${index}`,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': colorsAndFonts.selection_color,
        'line-width': 2,
        'line-opacity': 0.6
      }
    });
    this.map.loadImage('/mapData/directionArrow.png', (err, image) => {
      if (err) {
        console.error('err image', err);
        return;
      }
      if (!this.arrowImage) {
        this.map.addImage('arrow', image);
        this.arrowImage = true;
      }
      this.map.addLayer({
        id: `arrow-layer${index}`,
        type: 'symbol',
        source: `mapDataSourceId${index}`,
        layout: {
          'symbol-placement': 'line',
          'symbol-spacing': 1,
          'icon-allow-overlap': true,
          'icon-image': 'arrow',
          'icon-size': 0.045,
          visibility: 'visible'
        }
      });
    });

    this.mapRouteLayers.push(`route${index}`);
    this.mapRouteLayers.push(`arrow-layer${index}`);
    this.mapRouteSources.push(`mapDataSourceId${index}`);
  };

  registerRoutePointsEvents = () => {
    const instance = this;

    this.map.on('click', 'point-route-layer', (e) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      const coordinates = e.features[0].geometry.coordinates.slice();
      instance.mapPopups.push(
        new mapboxgl.Popup(mapPopupDefaultStyle)
          .setLngLat(coordinates)
          .setHTML(instance.createDescriptionForRoutePointFeature(e.features[0]))
          .addTo(instance.map)
      );
    });

    this.map.on('mouseenter', 'point-route-layer', () => {
      instance.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', 'point-route-layer', () => {
      instance.map.getCanvas().style.cursor = '';
    });
  };

  getPointRouteFeatureCollection = (coordinates, properties = null) => {
    const featureCollection = [];
    coordinates.forEach((coordinate, i) => {
      const feature = {
        type: 'Feature',
        geometry: {
          coordinates: coordinate,
          type: 'Point'
        }
      };
      if (properties) {
        feature.properties = { dateTime: `${moment.parseZone(properties[i].gpsTimestamp).format('HH:mm')}` };
      }
      featureCollection.push(feature);
    });
    return featureCollection;
  };

  plotPointRoute = (coordinates, properties = null) => {
    const featureCollectionObject = {
      features: this.getPointRouteFeatureCollection(coordinates, properties),
      type: 'FeatureCollection'
    };

    if (this.map.getSource('point-route')) {
      this.map.getSource('point-route').setData(featureCollectionObject);
    } else {
      this.map.addSource('point-route', {
        type: 'geojson',
        data: featureCollectionObject
      });
    }

    this.map.addLayer({
      id: 'point-route-layer',
      type: 'circle',
      source: 'point-route',
      paint: {
        'circle-radius': 6,
        'circle-color': colorsAndFonts.heatmap_color_1,
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1
      }
    });

    this.mapRouteLayers.push('point-route-layer');
    this.mapRouteSources.push('point-route');

    if (properties) this.registerRoutePointsEvents();
  };

  createDescriptionForRoutePointFeature = (feature) => `<div class="movement-tooltip">${feature.properties.dateTime}</div>`;

  /**
   * Removes route data from the map
   *
   * @memberOf RouteUtilClass
   * @function
   */
  removeRouteMapData = () => {
    this.mapRouteLayers.forEach((layer) => {
      this.map.removeLayer(layer);
    });
    this.mapRouteLayers = [];

    this.mapRouteSources.forEach((source) => {
      this.map.removeSource(source);
    });
    this.mapRouteSources = [];

    if (this.arrowImage) {
      this.map.removeImage('arrow');
      this.arrowImage = false;
    }

    this.map.off('click', 'point-route-layer');
    this.mapPopups.forEach((popup) => popup.remove());
    this.mapPopups = [];
  };
}
