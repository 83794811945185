import AuthUtil from './authUtil';

const TenantUtil = { addTenantToFileName: addTenantToFileName };

function addTenantToFileName(fileName) {
  const tenantId = AuthUtil.getTenantId();

  return `${tenantId}/${fileName}`;
}

export default TenantUtil;
