import React from 'react';
import PropTypes from 'prop-types';

import './PlanningCard.scss';

export default function PlanningCard(props) {
  const { row } = props;

  return (
    <div className="planning-card">
      <div className="title-wrapper">
        <div className="title-icon icon icon-delivery-van" />
        <div className="values">
          {row.cells.map((cell) => (
            <div key={cell.column.id} className="cell-wrapper">
              {cell.column.Header && <div className="cell-header">{cell.column.render('Header')}</div>}
              <div className="value">{cell.render('Cell')}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

PlanningCard.propTypes = { row: PropTypes.object.isRequired };
