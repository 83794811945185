import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace DialogActions
 * @category Actions
 */

/**
 *  Open dialog
 *
 * @param {object} props - dialog props
 * @returns {{payload: object, type: string}} - Action with a payload
 * @memberOf DialogActions
 * @alias openConfirmationDialog
 * @function
 */
export const openDialog = (props) => ({
  type: ActionTypes.APP_DIALOG_OPEN,
  payload: props
});

/**
 *  Close dialog
 *
 * @param {object} props - dialog props
 * @returns {{payload: object, type: string}} - Action with a payload
 * @memberOf DialogActions
 * @alias closeAlertDialog
 * @function
 */
export const closeDialog = (props) => ({
  type: ActionTypes.APP_DIALOG_CLOSE,
  payload: props
});

/**
 * Set if dialog should be opened on next page change (or other action)
 *
 * @param {object} show - show dialog on next page change (or other action)
 * @returns {{payload: object, type: string}} - Action with a payload
 * @memberOf DialogActions
 * @alias showNextAlertDialog
 * @function
 */
export const showNextAlertDialog = (show) => ({
  type: ActionTypes.SAVE_CHANGES_DIALOG_SHOW_NEXT,
  payload: show
});
