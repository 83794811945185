import React, { useEffect } from 'react';
import './ToggleSwitch.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ToggleSwitch(props) {
  const { t } = useTranslation();
  const filter = React.useRef(null);

  useEffect(() => {
    if (filter.current === null) filter.current = props.options[0];
  }, [props.options]);

  const handleOptionClick = (option) => {
    filter.current = option;
    props.handleOptionChange(option);
  };

  return (
    <div className="switch-wrapper">
      {props.options
        && props.options.map((option) => (
          <div
            className={`option${option === filter.current ? ' active' : ''}`}
            onClick={() => {
              handleOptionClick(option);
            }}
            key={option}
          >
            {t(option)}
          </div>
        ))}
    </div>
  );
}

ToggleSwitch.propTypes = {
  options: PropTypes.arrayOf(Object),
  handleOptionChange: PropTypes.func.isRequired
};

ToggleSwitch.defaultProps = { options: null };
