import * as ActionTypes from '../constants/actionTypes';
import { CHART_TYPES } from '../../features/analysis/courierAnalysis/constants/chartTypes';

const initialState = {
  selectedDay: null,
  selectedHours: null,
  resetBarChart: null,
  resetHeatMap: null
};

/**
 * Reducer - Charts
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.CHART_DATA_SELECTED) {
    switch (action.payload.chartType) {
      case CHART_TYPES.BAR_CHART:
        return {
          ...state,
          selectedDay: action.payload.days,
          selectedHours: action.payload.hours,
          resetHeatMap: new Date()
        };
      case CHART_TYPES.HEAT_MAP:
        return {
          ...state,
          selectedDay: action.payload.days,
          selectedHours: action.payload.hours,
          resetBarChart: new Date()
        };
      case CHART_TYPES.ALL:
        return {
          ...state,
          selectedDay: null,
          selectedHours: null,
          resetBarChart: new Date(),
          resetHeatMap: new Date()
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default reducer;
