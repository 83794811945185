import * as ActionTypes from '../constants/actionTypes';
/**
 * @namespace DistanceActions
 * @category Actions
 */

/**
 * Creates an action with information on whether or not a page is loading.
 *
 * @param {number} routeDistance - new distance traveled by courier
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf CoordinatesActions
 * @alias distanceChange
 * @function
 */
export const distanceChange = (routeDistance) => ({
  type: ActionTypes.ROUTE_DISTANCE_CHANGED,
  payload: routeDistance
});
