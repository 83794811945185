import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '../../../../../common/components/form/Form';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';
import { ENTITY_TYPE } from '../../../../../common/constants/entityTypes';

class TeamForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: props?.data?.name,
        description: props?.data?.description,
        teamType: props?.data?.teamType,
        integrationId: props?.data?.integrationId
        // teamType: { value: props?.data?.teamType, label: props?.data?.teamType ? props.t(`entity-${props.data.teamType}`) : props.t("Select team") }
      },
      serverErrors: null
    };
    this.formRef = null;
    this.teamTypeOptions = [
      { value: ENTITY_TYPE.COURIERS, label: props.t('entity-COURIER') },
      { value: ENTITY_TYPE.PACKAGE_LOCKERS, label: props.t('entity-PACKAGE_LOCKER') }
    ];
  }

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      this.props.submitCallback(this.state.formData);
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <div className="form-dialog-component">
        <div className="title">{this.props.title}</div>
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
          serverErrors={this.state.serverErrors}
        >
          <FormInput inputType="input" label={this.props.t('Name')} name="name" validation={{ required: true }} />
          <FormInput inputType="input" label={this.props.t('Integration Id')} name="integrationId" />
          <FormInput inputType="input" label={this.props.t('Description')} name="description" />
          <FormInput
            inputType="select"
            label={this.props.t('Team type')}
            options={this.teamTypeOptions}
            menuPortalTarget={document.querySelector('.MuiDialog-root')}
            menuPosition="fixed"
            name="teamType"
            placeholder={this.props.t('Select team type')}
            validation={{ required: true }}
          />
        </Form>

        <div className="buttons">
          <Button onClick={this.cancelCallback} type="cancel" text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} text={this.props.t('Save')} />
        </div>
      </div>
    );
  }
}

TeamForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    integrationId: PropTypes.string,
    teamType: PropTypes.string
  }),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

TeamForm.defaultProps = {
  data: null,
  submitCallback: null,
  cancelCallback: null
};

export default withTranslation('translations')(TeamForm);
