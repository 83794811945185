import React from 'react';
import PropTypes from 'prop-types';
import { FormValidation } from './utils/formValidation';
import { FormInput } from './components/FormInput';
import { ReactChildrenUtils } from './utils/reactChildrenUtils';
import './Form.scss';

/**
 * Save formRef using ref prop so later you can call this.formRef.isFormValid().
 * Client validation can be activated adding `validation` prop to `FormInput` component.
 * For server validation pass error from server to `serverErrors` prop, or pass custom object `{'nameOfField': 'Error message to display'}`
 *
 * Keep in state form data:
 *
 * <pre>
 this.state = {
   formData: {
     email: '',
     password: ''
   },
   serverErrors: {}
 };
 * </pre>
 *
 * Example of usage:
 * <pre>
 <Form
 ref={formRef => {
     this.formRef = formRef;
   }}
 formData={this.state.formData}
 serverErrors={this.state.serverErrors}
 onFormChange={newFormData => {
     this.setState({formData: newFormData});
   }}
 >
 <FormInput inputType="input" name="email" />
 </Form>
 * </pre>
 */
export class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      globalServerError: null
    };
    this.formInputNames = [];
    // this.showServerErrors = true;
  }

  // eslint-disable-next-line camelcase
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (this.showServerErrors) {
  //     this.mapServerErrorsToClient(nextProps.serverErrors);
  //   }
  // }

  // eslint-disable-next-line react/no-unused-class-component-methods
  isFormValid = () => {
    let isFormValid = true;
    const errors = {};
    // eslint-disable-next-line react/prop-types
    ReactChildrenUtils.recursiveChildrenForEach(this.props.children, (child) => {
      if (child.type !== FormInput) {
        return;
      }
      const formInputValidation = this.validateFormInput(child, this.props.formData[child.props.name]);
      if (!formInputValidation.isValid) {
        errors[child.props.name] = formInputValidation.message;
      }
      isFormValid = isFormValid && formInputValidation.isValid;
    });
    this.setState({ errors: errors, globalServerError: null });

    // this.showServerErrors = isFormValid;
    return isFormValid;
  };

  validateFormInput = (child, formInputValue) => {
    const propsValidation = child.props.validation;
    if (!propsValidation) {
      return { isValid: true };
    }

    let validationResult = FormValidation.isRequired(formInputValue, propsValidation.required);
    if (!validationResult.isValid) {
      return validationResult;
    }
    validationResult = FormValidation.checkMinLength(formInputValue, propsValidation.minLength);
    if (!validationResult.isValid) {
      return validationResult;
    }
    validationResult = FormValidation.checkMaxLength(formInputValue, propsValidation.maxLength);
    if (!validationResult.isValid) {
      return validationResult;
    }
    validationResult = FormValidation.isNumber(formInputValue, propsValidation.isNumber);
    if (!validationResult.isValid) {
      return validationResult;
    }
    validationResult = FormValidation.isPhoneNumber(formInputValue, propsValidation.isPhoneNumber);
    if (!validationResult.isValid) {
      return validationResult;
    }
    validationResult = FormValidation.checkRegex(formInputValue, propsValidation.regex);
    if (!validationResult.isValid) {
      return validationResult;
    }
    validationResult = FormValidation.checkCustomValidation(formInputValue, this.props.formData, propsValidation.custom);
    if (!validationResult.isValid) {
      return validationResult;
    }
    return validationResult;
  };

  childOnChange = (value, child) => {
    if (child.props.onFormInputBeforeChange) {
      value = child.props.onFormInputBeforeChange(value); // eslint-disable-line no-param-reassign
    }
    const newFormData = { ...this.props.formData, [child.props.name]: value };
    // call onFormInputChange event - we call this before "onFormChange" event so user can access old formData
    if (child.props.onFormInputChange) {
      child.props.onFormInputChange(value, this.props.formData, newFormData); // function(newValue, currentFormData, nextFormData)
    }
    // pass form changed data to parent component
    this.props.onFormChange(newFormData);

    // validate child
    // eslint-disable-next-line react/no-access-state-in-setstate
    const errors = { ...this.state.errors };
    if (this.props.showErrorAsUserTypes) {
      const formInputValidation = this.validateFormInput(child, value);
      errors[child.props.name] = formInputValidation.isValid ? null : formInputValidation.message;
    } else {
      errors[child.props.name] = null;
    }
    this.setState({ errors: errors });
  };

  // eslint-disable-next-line class-methods-use-this,react/no-unused-class-component-methods
  mapServerErrorsToClient = () => {
    // TODO implement this
    // const errors = {};
    // let globalServerError = null;
    // if (!err || !Object.keys(err).length) {
    //   return;
    // }
    // if (err.errorType && err.errorType === "Meteor.Error") {
    //   // handles when exception is thrown and passed to Form component via serverErrors
    //   if (err.reason) {
    //     if (this.formInputNames.includes(err.reason)) {
    //       errors[err.reason] = err.details;
    //     } else {
    //       globalServerError = err.reason;
    //     }
    //   } else {
    //     globalServerError = "Some error occurred";
    //   }
    // } else {
    //   // handles custom validation that is returned from server and passed to Form component via serverErrors
    //   Object.keys(err).forEach(key => {
    //     errors[key] = err[key];
    //   });
    // }
    // this.setState({ errors: errors, globalServerError: globalServerError });
  };

  hasError = () => (this.state.globalServerError ? 'error-container has-error' : 'error-container');

  render() {
    this.formInputNames = [];
    return (
      <div className={`form-container ${this.props.className}`}>
        {
          // eslint-disable-next-line react/prop-types
          ReactChildrenUtils.recursiveChildrenMap(this.props.children, (child) => {
            if (child.type !== FormInput) {
              return child;
            }
            this.formInputNames.push(child.props.name);
            const additionalProps = {
              key: child.props.name,
              error: this.state.errors[child.props.name] || null
            };

            switch (child.props.inputType) {
              case 'checkbox': {
                additionalProps.checked = this.props.formData[child.props.name];
                additionalProps.onChange = (event) => {
                  this.childOnChange(event.target.checked, child);
                };

                break;
              }
              case 'select': {
                if (this.props.formData[child.props.name]) {
                  const selectOption = child.props.options.find((o) => o.value === this.props.formData[child.props.name]);
                  additionalProps.value = selectOption;
                } else {
                  additionalProps.value = this.props.formData[child.props.name];
                }

                additionalProps.onChange = (event) => {
                  this.childOnChange(
                    {
                      value: event.value,
                      label: event.label
                    },
                    child
                  );
                };

                break;
              }
              case 'input-number': {
                additionalProps.value = this.props.formData[child.props.name];
                additionalProps.onChange = (event) => {
                  this.childOnChange(event.target.value.match(/^\d*?(\.\d*)$/) ? Number.parseFloat(event.target.value) : event.target.value, child);
                };

                break;
              }
              case 'toggle': {
                additionalProps.value = `${this.props.formData[child.props.name]}`;
                additionalProps.onChange = (event) => {
                  this.childOnChange(event.target.checked, child);
                };

                break;
              }
              default: {
                additionalProps.value = this.props.formData[child.props.name];
                additionalProps.onChange = (event) => {
                  this.childOnChange(event.target.value, child);
                };
              }
            }
            return React.cloneElement(child, additionalProps);
          })
        }
        <div className={this.hasError()}>
          <div className="error">{this.state.globalServerError}</div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  formData: PropTypes.shape().isRequired,
  onFormChange: PropTypes.func.isRequired,
  showErrorAsUserTypes: PropTypes.bool,
  // serverErrors: PropTypes.shape(),
  className: PropTypes.string
};

Form.defaultProps = {
  showErrorAsUserTypes: true,
  // serverErrors: {},
  className: ''
};
