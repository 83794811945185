import { DrawCircleFromCenterMode, DrawPointMode, DrawPolygonMode, DrawRectangleMode } from '@nebula.gl/edit-modes';
import { SELECTION_TOOL } from '../constants/drawHexagonsConst';

/**
 * Util helper for drawing hexagons with deckGl
 *
 * @namespace
 * @category Common
 */
const DrawHexagonsUtil = { getSelectionMode: getSelectionMode };

/**
 * Get deckGl draw mode
 *
 * @param {string} selectionTool - selection tool
 * @returns {string} deckGl draw mode
 * @memberOf DrawHexagonsUtil
 * @function
 */
function getSelectionMode(selectionTool) {
  switch (selectionTool) {
    case SELECTION_TOOL.PICK:
      return DrawPointMode;
    case SELECTION_TOOL.POLYGON:
      return DrawPolygonMode;
    case SELECTION_TOOL.CIRCLE:
      return DrawCircleFromCenterMode;
    case SELECTION_TOOL.RECTANGLE:
      return DrawRectangleMode;
    default:
      return DrawPointMode;
  }
}

export default DrawHexagonsUtil;
