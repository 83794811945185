import * as React from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

import './MapWrapper.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import * as MapActions from '../../../../state/actions/mapActions';
import RouteUtilClass from '../utils/routeUtilClass';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import Footer from '../../../../common/components/footers/Footer';
import PinPlottingUtilClass from '../../../../common/utils/pinPlotingUtil';
import MapUtil from '../../../../common/utils/mapUtil';
import PinLegend from './PinLegend';
import AuthUtil from '../../../../common/utils/authUtil';
import MapModeButton from '../../../../common/components/buttons/mapModeButton/MapModeButton';
import MixPanel from '../../../../setup/mixPanel';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

/**
 * Wrapper for all map functionalities
 *
 * @component
 * @alias MapWrapper
 * @category RouteAnalysis
 */
class MapWrapperClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = { lineRouteShown: AuthUtil.isLocationDataHighQuality() };

    this.defaultView = MapUtil.getInitialViewStateForCompany();
    this.map = null;
    this.loadingMapData = false;
    props.dispatchIsMapLoading(true);
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [this.defaultView.lng, this.defaultView.lat],
      zoom: this.defaultView.zoom
    });

    this.map = map;
    this.props.dispatchSetMap(map);
    this.RouteUtil = new RouteUtilClass(map, this.props.t);
    this.PinPlottingUtil = new PinPlottingUtilClass(map, this.props.t);

    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', () => {
      if (!this.loadingMapData) {
        this.loadMapData();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hasStops && !this.props.hasStops) {
      this.RouteUtil.removeRouteMapData();
      this.PinPlottingUtil.removeAllMarkers();
    }

    if (prevProps.stopsData !== this.props.stopsData && !this.loadingMapData && this.map.isStyleLoaded()) {
      this.loadMapData();
    }

    if (prevProps.teamId !== this.props.teamId) {
      this.RouteUtil.removeRouteMapData();
      this.PinPlottingUtil.removeAllMarkers();
    }

    if (prevProps.lng !== this.props.lng || prevProps.lat !== this.props.lat || prevProps.zoom !== this.props.zoom) {
      if (this.props.lng && this.props.lat) {
        this.map.flyTo({
          center: [this.props.lng, this.props.lat],
          zoom: this.props.zoom
        });
      }
    }
  }

  /**
   * Fetches all data for the map - center, stops, path and shows them
   *
   * @function
   */
  loadMapData = () => {
    this.loadingMapData = true;

    const { coordinates, properties } = this.getFilteredGpsLocationsCoordinates();
    this.PinPlottingUtil.removeAllMarkers();
    this.PinPlottingUtil.addStartFinishPins([coordinates[0], coordinates[coordinates.length - 1]]);

    this.RouteUtil.removeRouteMapData();

    this.RouteUtil.plotRoute(coordinates, properties, this.state.lineRouteShown, this.props.stopsData?.mileage);

    if (this.props.stopsData && !_.isEmpty(this.props.stopsData.stops)) this.PinPlottingUtil.plotPins(this.props.stopsData.stops, 'routeAnalysis');
    this.PinPlottingUtil.centerMapToBounds();
    this.props.dispatchIsMapLoading(false);
    this.loadingMapData = false;
  };

  /**
   * Filters coordinates that are close to each other to avoid cluttering of coordinates
   *
   * @returns {object} - coordinates in string and array format
   * @function
   */
  getFilteredGpsLocationsCoordinates = () => {
    const START_HOUR = 6;
    const END_HOUR = 22;
    const coordinates = [];
    const properties = [];

    if (this.props.stopsData && this.props.stopsData.courierGpsLocations) {
      this.props.stopsData.courierGpsLocations.forEach((gpsLocation) => {
        if (`${gpsLocation.lng}`.startsWith('0') || `${gpsLocation.lng}`.startsWith('0')) {
          return;
        }

        const pointHour = new Date(gpsLocation.gpsTimestamp).getHours();
        if (pointHour < START_HOUR && pointHour >= END_HOUR) {
          return;
        }

        coordinates.push([gpsLocation.lng, gpsLocation.lat]);
        properties.push(gpsLocation);
      });
    }
    return { coordinates, properties };
  };

  toggleRouteVisibility = () => {
    MixPanel.track(`Route Analysis - Show courier route turned ${this.state.lineRouteShown ? 'off' : 'on'}`);
    this.RouteUtil.removeRouteMapData();
    const { coordinates, properties } = this.getFilteredGpsLocationsCoordinates(this.props.stopsData.courierGpsLocations);
    this.RouteUtil.plotRoute(coordinates, properties, !this.state.lineRouteShown, this.props.stopsData?.mileage);
    this.setState((prevState) => ({ lineRouteShown: !prevState.lineRouteShown }));
  };

  getFooterData = () => (this.props.routeDistance ? [{ label: this.props.t('Distance traveled'), value: `${(parseFloat(this.props.routeDistance) / 1000).toFixed(2)} km` }] : null);

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  render() {
    const footerData = this.getFooterData();
    return (
      <div className="route-analysis-map-wrapper">
        <div className="route-toggle-wrapper">
          <MapModeButton
            dataTip={this.props.t('Route show toggle')}
            dataFor="map-mode-button-tooltip"
            onClick={this.toggleRouteVisibility}
            isActive={this.state.lineRouteShown}
          >
            <i className="icon-road" />
          </MapModeButton>
          <ReactTooltip id="map-mode-button-tooltip" className="tooltip" place="right" effect="solid" />
        </div>
        <MapBoxWrapper setMapRef={this.setMapRef} />
        <div className="pin-legend-wrapper">
          <PinLegend />
        </div>
        {footerData && <Footer data={footerData} />}
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.mapState, ...store.footerState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchIsMapLoading: MapActions.mapIsLoading,
      dispatchSetMap: MapActions.setMap
    },
    dispatch
  );
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(MapWrapperClass));

MapWrapperClass.propTypes = {
  /**
   * Translation function
   */
  t: PropTypes.func.isRequired,
  /**
   * Key of the delivery center
   */
  teamId: PropTypes.string.isRequired,
  /**
   * Represents if there are stops for a specific day
   */
  hasStops: PropTypes.bool,
  /**
   * Stops data
   */
  stopsData: PropTypes.object,
  /**
   * Longitude of the point where map should be centered
   */
  lng: PropTypes.string,
  /**
   * Longitude of the point where map should be centered
   */
  lat: PropTypes.string,
  /**
   * Zoom level to be used when centering the map
   */
  zoom: PropTypes.number,
  /**
   * Dispatches the event that map is loading data at the moment
   */
  dispatchIsMapLoading: PropTypes.func.isRequired,
  dispatchSetMap: PropTypes.func.isRequired,
  routeDistance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

MapWrapperClass.defaultProps = {
  hasStops: false,
  stopsData: null,
  lng: null,
  lat: null,
  zoom: null
};
