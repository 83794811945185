import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './AddressLabelingPage.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as h3 from 'h3-js';
import { toast } from 'react-toastify';
import { raygunClient } from '../../../setup/raygunClient';
import { AddressLabelingApi } from './api/addressLabelingApi';
import CardMenuTable from '../../../common/components/tables/cardMenuTable/CardMenuTable';
import * as PageActions from '../../../state/actions/pageActions';
import AppDialogActionsWrapper from '../../../common/components/dialogs/utils/appDialogActionsWrapper';
import * as CoordinatesActions from '../../../state/actions/coordinatesActions';
import AddressLabelingMap from './components/AddressLabelingMap';
import LogoDisplay from '../../../common/components/logoDisplay/LogoDisplay';
import TableRowsSave from '../../../common/components/tables/common/TableRowsSave';
import AddressToLabelCard from './components/AddressToLabelCard';
import ConfirmationDialog from '../../../common/components/dialogs/confirmationDialog/ConfirmationDialog';
import DeliveryAreaSelect from '../../../common/components/selections/deliveryAreaSelect/DeliveryAreaSelect';
import S3Util from '../../../common/utils/s3Util';
import BackendResourceConfigUtil from '../../../common/utils/api/backendResourceConfigUtil';
import AuthUtil from '../../../common/utils/authUtil';
import StepInfoHeader from '../../planning/planManagement/components/StepInfoHeader';
import { GEOCODING_HEX_LEVEL } from '../../../common/constants/geocodingConstants';
import MixPanel from '../../../setup/mixPanel';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import SortUtil from '../../../common/utils/sortUtil';

class AddressLabelingPageClass extends React.Component {
  constructor() {
    super();
    this.state = {
      addressesToLabel: null,
      showMap: false,
      tablePageSize: 1,
      selectedRowId: null,
      hexIds: null
    };

    this.markerCoordinates = null;
    this.previousSavedMarkerCoordinates = null;
  }

  componentDidMount() {
    MixPanel.track('Page Load - Address Labeling');
    MixPanelUtil.setUnloadListener('Page Unload - Address Labeling');
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  getTableColumns = () => {
    return [
      {
        Header: this.props.t('Name'),
        accessor: 'streetName',
        className: 'flex-3'
      },
      {
        Header: this.props.t('Number'),
        accessor: 'houseNumber',
        className: 'flex-3'
      },
      { accessor: 'receiver' },
      {
        Header: this.props.t('Actions'),
        id: 'actions',
        disableGlobalFilter: true,
        className: 'centered',
        Cell: ({ row }) => <TableRowsSave type="card" row={row} onSave={this.saveFixedAddress} onDelete={this.deleteUnresolvedAddress} />
      }
    ];
  };

  saveFixedAddress = (row) => {
    MixPanel.track('Address Labeling - Save address button clicked');
    const selectedHexId = h3.geoToH3(this.markerCoordinates.lat, this.markerCoordinates.lng, GEOCODING_HEX_LEVEL);
    if (this.state.hexIds.includes(selectedHexId)) {
      AppDialogActionsWrapper.openAppDialog({
        dialogComponent: ConfirmationDialog,
        dialogComponentProps: {
          title: this.props.t('Save location?'),
          body: this.props.t('Address corrected'),
          confirmButtonText: this.props.t('Save'),
          continueCallback: () => {
            this.props.dispatchLoadingPage(true);
            AddressLabelingApi.insertLabeledGeocodedAddress(
              row.original.streetName,
              row.original.houseNumber,
              row.original.receiver,
              selectedHexId,
              this.markerCoordinates,
              AuthUtil.getTenantId()
            )
              .then(() => {
                AddressLabelingApi.deleteUnsuccessfullyGeocodedAddress(
                  row.original.hexId,
                  row.original.streetName,
                  row.original.houseNumber,
                  row.original.receiver
                )
                  .then(() => {
                    this.setState((prevState) => ({
                      addressesToLabel: prevState.addressesToLabel.filter(
                        (address) => !(
                          address.hexId === row.original.hexId
                            && address.streetName === row.original.streetName
                            && address.houseNumber === row.original.houseNumber
                            && address.receiver === row.original.receiver
                        )
                      ),
                      showMap: false,
                      selectedRowId: null,
                      hexIds: null
                    }));
                    this.previousSavedMarkerCoordinates = this.markerCoordinates;
                    this.props.dispatchLoadingPage(false);
                    toast.success(this.props.t('Successfully labeled'));
                  })
                  .catch((error) => {
                    raygunClient.send(error, 'Error deleting unsuccessfully geocoded address');
                    this.props.dispatchLoadingPage(false);
                    toast.error(this.props.t('Error labeling'));
                  });
              })
              .catch((error) => {
                raygunClient.send(error, 'Error inserting labeled address');
                this.props.dispatchLoadingPage(false);
                toast.error(this.props.t('Error labeling'));
              });
          },
          cancelCallback: () => {}
        }
      });
    } else toast.error(this.props.t('Location not in hexagon'));
  };

  deleteUnresolvedAddress = (row) => {
    MixPanel.track('Address Labeling - Mark address as non-existant button clicked');
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: ConfirmationDialog,
      dialogComponentProps: {
        title: this.props.t('Non-existent address?'),
        body: this.props.t('Address non-existant'),
        confirmButtonText: this.props.t('Save'),
        continueCallback: () => {
          this.props.dispatchLoadingPage(true);

          AddressLabelingApi.deleteUnsuccessfullyGeocodedAddress(row.original.hexId, row.original.streetName, row.original.houseNumber, row.original.receiver)
            .then(() => {
              this.setState((prevState) => ({
                addressesToLabel: prevState.addressesToLabel.filter(
                  (address) => !(
                    address.hexId === row.original.hexId
                      && address.streetName === row.original.streetName
                      && address.houseNumber === row.original.houseNumber
                      && address.receiver === row.original.receiver
                  )
                ),
                showMap: false,
                selectedRowId: null,
                hexIds: null
              }));
              this.props.dispatchLoadingPage(false);
              toast.success(this.props.t('Successfully marked address'));
            })
            .catch((error) => {
              raygunClient.send(error, 'Error deleting labeled address');
              this.props.dispatchLoadingPage(false);
              toast.error(this.props.t('Error labeling'));
            });
        },
        cancelCallback: () => {}
      }
    });
  };

  calculateTablePageSize = (height) => {
    if (this.state.tablePageSize !== height) {
      this.setState({ tablePageSize: height });
    }
  };

  flyToCoordinates = (hexagonCoordinates) => {
    this.props.dispatchCoordinates({ lng: parseFloat(hexagonCoordinates.lng), lat: parseFloat(hexagonCoordinates.lat) });
  };

  rowClick = (selectedRow) => {
    if (this.state.selectedRowId !== selectedRow.id) {
      MixPanel.track('Address Labeling - Address selected');
      let markerCoordinates;
      if (this.previousSavedMarkerCoordinates) {
        markerCoordinates = this.previousSavedMarkerCoordinates;
      } else {
        const hexagonCoordinates = h3.h3ToGeo(selectedRow.original.hexId);
        markerCoordinates = { lat: parseFloat(hexagonCoordinates[0]), lng: parseFloat(hexagonCoordinates[1]) };
      }
      this.setState({
        selectedRowId: selectedRow.id,
        hexIds: selectedRow.original.hexIds,
        showMap: true,
        clickedAddressData: { ...selectedRow.original, ...markerCoordinates }
      });
      this.flyToCoordinates(markerCoordinates);
      this.markerCoordinates = markerCoordinates;
    }
  };

  getDeliveryAreaHexIds = async (deliveryAreaId) => {
    const fileName = `${AuthUtil.getTenantId()}/latest/delivery-area/${deliveryAreaId}/${deliveryAreaId}_level_7_hexagons.json`;
    return S3Util.getFileFromS3(fileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
      cacheControl: 'no-cache'
    })
      .then((content) => JSON.parse(content))
      .catch((e) => {
        raygunClient.send(e, 'Error loading delivery area ids');
        toast.error(this.props.t('Oops something went wrong'));
        return [];
      });
  };

  onDeliveryAreaChange = async (obj) => {
    this.props.dispatchLoadingPage(true);
    const hexIds = await this.getDeliveryAreaHexIds(obj.id);

    const addressesPromises = hexIds.map((hexId) => this.getUnsuccessfullyGeocodedAddressesForHex(hexId));

    const addressesToLabel = await Promise.all(addressesPromises).then((addressesPerHex) => SortUtil.sortAddresses(addressesPerHex.flat()));

    this.setState({
      addressesToLabel: addressesToLabel,
      showMap: false,
      selectedRowId: null,
      hexIds: hexIds,
      deliveryAreaId: obj.id
    });
    this.props.dispatchLoadingPage(false);
    MixPanel.track('Address Labeling - Delivery area changed');
  };

  getUnsuccessfullyGeocodedAddressesForHex = (hexId) => AddressLabelingApi.getAddressToLabel(hexId)
    .then((res) => res)
    .catch((error) => {
      raygunClient.send(error, 'Error loading unsuccessfully geocoded addresses', { hexId: hexId });
      toast.error(this.props.t('Oops something went wrong'));
    });

  setMarkerCoordinates = (location) => {
    this.markerCoordinates = location;
  };

  render() {
    return (
      <div className="address-labeling-wrapper">
        <StepInfoHeader
          message={this.props.t('Choose address location')}
          options={{
            returnButtonHidden: true,
            cancelButtonHidden: true
          }}
        />
        <div className="address-labeling">
          <div className="menu-wrapper">
            <div className="filter-wrapper">
              <DeliveryAreaSelect onDeliveryAreaChange={this.onDeliveryAreaChange} returnFullObject />
            </div>
            <div className="menu">
              <div className="table-wrapper">
                {this.state.addressesToLabel && this.state.addressesToLabel.length > 0 && (
                  <CardMenuTable
                    key={this.state.tablePageSize}
                    columns={this.getTableColumns()}
                    data={this.state.addressesToLabel}
                    resetOn={this.state.deliveryAreaId}
                    showSearch
                    handleRowClick={this.rowClick}
                    cardItemComponent={AddressToLabelCard}
                    pageSize={this.state.tablePageSize}
                    calculateTablePageSize={this.calculateTablePageSize}
                    selectedDataType="row"
                    searchPlaceholder={this.props.t('Search address')}
                    autoResetSelectedRows
                  />
                )}
              </div>
            </div>
          </div>
          <div className="address-labeling-map-section">
            {this.state.showMap && (
              <AddressLabelingMap addressData={this.state.clickedAddressData} savePin={this.setMarkerCoordinates} hexIds={this.state.hexIds} zoom={15.0} />
            )}
            {!this.state.showMap && <LogoDisplay />}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * @param {Function} dispatch - redux dispatch function
 * @returns {object} - map actions to component props
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchCoordinates: CoordinatesActions.coordinatesChange
    },
    dispatch
  );
}

export default withTranslation('translations')(withRouter(connect(null, mapDispatchToProps)(AddressLabelingPageClass)));

AddressLabelingPageClass.propTypes = {
  /**
   * Dispatches event if page is currently loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  dispatchCoordinates: PropTypes.func.isRequired
};
