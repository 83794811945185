import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PlanningPagePerCourier from './pages/PlanningPagePerCourier';
import ShipmentsPerCourierUploadPage from './pages/ShipmentsPerCourierUploadPage';
import RegionSelectionPage from './plansPerRegionFlow/RegionSelectionPage';
import RoutePreviewPage from './pages/RoutePreviewPage';

/**
 * Planning page wrapper for page navigation
 *
 * @returns {JSX.Element} - Planning page wrapper for page navigation
 * @component
 * @alias PlanningPageRoutes
 * @category planning
 */
export default function PlanningPageRoutes() {
  return (
    <Switch>
      <Route exact path="/delivery-plan" key="/delivery-plan" component={PlanningPagePerCourier} />
      <Route path="/delivery-plan/table-view" key="/delivery-plan/table-view" component={ShipmentsPerCourierUploadPage} />
      <Route path="/delivery-plan/map-view" key="/delivery-plan/map-view" component={RegionSelectionPage} />
      <Route path="/delivery-plan/route-view" key="/delivery-plan/route-view" component={RoutePreviewPage} />
    </Switch>
  );
}
