import React from 'react';
import PropTypes from 'prop-types';
import './TableRowsSave.scss';

export default function TableRowsSave(props) {
  const { row, onSave, onDelete, type } = props;

  return (
    <div className={`table-actions ${type}`}>
      <div onClick={onDelete.bind(this, row)}>
        <i className="icon icon-question" />
      </div>
      <div onClick={onSave.bind(this, row)}>
        <i className="icon icon-save" />
      </div>
    </div>
  );
}

TableRowsSave.propTypes = {
  row: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  type: PropTypes.string.isRequired
};

TableRowsSave.defaultProps = {
  onSave: () => {},
  onDelete: () => {}
};
