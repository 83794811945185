/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import AuthUtil from '../../../common/utils/authUtil';
import { raygunClient } from '../../../setup/raygunClient';
import CourierManagement from './components/couriers/CourierManagement';
import TeamManagement from './components/teams/TeamManagement';
import StepInfoHeader from '../../planning/planManagement/components/StepInfoHeader';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import './CouriersPage.scss';
import * as PageActions from '../../../state/actions/pageActions';
import SortUtil from '../../../common/utils/sortUtil';
import MixPanel from '../../../setup/mixPanel';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import EntityUtil from '../../analysis/utils/entityUtil';

export default function CouriersPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = React.useState(parseInt(QueryStringUtil.getQueryStringValue('tab-index') ?? 0, 10));
  const [couriers, setCouriers] = useState([]);
  const [teams, setTeams] = useState([]);

  const loadingCouriers = useRef(true);

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    QueryStringUtil.setQueryStringValue('tab-index', index);
  };

  const loadCouriers = useCallback(() => {
    if (teams.length > 0) {
      loadingCouriers.current = true;
      dispatch(PageActions.loadingPage(true));

      const couriersPromises = [];
      teams.forEach((team) => {
        if (team.teamType === 'COURIER') couriersPromises.push(EntityUtil.getEntityData(team.id, 'COURIER', false, true));
      });
      Promise.all(couriersPromises)
        .then((res) => {
          loadingCouriers.current = false;
          setCouriers(res.flat());
          dispatch(PageActions.loadingPage(loadingCouriers.current));
        })
        .catch((error) => {
          loadingCouriers.current = false;
          raygunClient.send(error, 'Error loading couriers');
          toast.error(t('Error loading courier'));
          dispatch(PageActions.loadingPage(false));
        });
    } else dispatch(PageActions.loadingPage(false));
  }, [dispatch, teams]);

  const loadTeams = useCallback(() => {
    const userTeams = AuthUtil.getTeams();
    const teamsArr = Object.keys(userTeams).map((teamId) => userTeams[teamId]);
    setTeams(SortUtil.sortArrayByUpperCaseField(teamsArr, 'name'));
  }, []);

  useEffect(() => {
    loadCouriers();
  }, [loadCouriers]);

  useEffect(() => {
    loadTeams();
  }, [loadTeams]);

  useEffect(() => {
    MixPanel.track('Page Load - Courier Management');
    MixPanelUtil.setUnloadListener('Page Unload - Courier Management');
    return () => {
      MixPanelUtil.removeUnloadListener();
    };
  }, []);

  return (
    <div className="couriers-page">
      <StepInfoHeader message={t('courierManagement')} options={{ cancelButtonHidden: true, returnButtonHidden: true, nextButtonHidden: true }} />
      <Tabs selectedIndex={selectedIndex} selectedTabClassName="active-tab" selectedTabPanelClassName="active-tab-panel" onSelect={handleTabChange}>
        <TabList className="tab-list">
          <Tab className="tab">
            <i className="icon icon-person" />
            {t('Couriers')}
          </Tab>
          <Tab className="tab">
            <i className="icon icon-account-group" />
            {t('Teams')}
          </Tab>
        </TabList>
        <TabPanel>
          <CourierManagement couriers={couriers || []} teams={teams} refreshData={loadCouriers} />
        </TabPanel>
        <TabPanel>
          <TeamManagement teams={teams} refreshData={loadTeams} />
        </TabPanel>
      </Tabs>
    </div>
  );
}
