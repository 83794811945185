import RegionAnalysisApi from '../regionAnalysis/api/regionAnalysisApi';
import { ENTITY_TYPE } from '../../../common/constants/entityTypes';
import CouriersApi from '../../management/couriers/api/couriersApi';
import SortUtil from '../../../common/utils/sortUtil';

/**
 * Util for getting entity data
 *
 * @namespace
 * @category Common
 */
const EntityUtil = { getEntityData: getEntityData };

/**
 * Fetches entity data based on type and team type
 *
 * @param {string} teamId - Team ID
 * @param {string} entityType
 * @param {boolean} loadTeamData - If true then courier's team data will be fetched alongside courier's data
 * @param {boolean} isActive - If true then only active entities will be fetched
 * @returns {Promise} Entity array
 * @memberOf EntityUtil
 * @function
 */
function getEntityData(teamId, entityType, isActive = false, loadTeamData = false) {
  if (entityType === ENTITY_TYPE.COURIERS) {
    return CouriersApi.getCouriers(teamId, isActive, loadTeamData).then((res) => {
      if (res.data.getCouriersForTeam && res.data.getCouriersForTeam.length > 0 && res.data.getCouriersForTeam[0].firstName) {
        return SortUtil.sortArrayByUpperCaseField(res.data.getCouriersForTeam, 'firstName');
      }

      return res.data.getCouriersForTeam;
    });
  }

  return RegionAnalysisApi.getPackageLockers(teamId).then((res) => res?.data?.getPackageLockers?.packageLockers);
}

export default EntityUtil;
