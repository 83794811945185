import { INITIAL_VIEW_STATE } from '../constants/mapConstants';
import AuthUtil from './authUtil';

function doesMapHaveLayers(map, layers) {
  let response = true;
  layers.forEach((layer) => {
    response = response && map.getLayer(layer);
  });

  return response;
}

function getInitialViewStateForCompany() {
  switch (AuthUtil.getTenantId()) {
    case 'dexpress':
      return {
        lat: 44.12058070680851,
        lng: 20.743900456556503,
        zoom: 6.5,
        pitch: 0,
        bearing: 0
      };
    case 'xexpress':
      return {
        lat: 44.0462,
        lng: 17.8751,
        zoom: 7,
        pitch: 0,
        bearing: 0
      };
    default:
      return INITIAL_VIEW_STATE;
  }
}

const MapUtil = {
  doesMapHaveLayers: doesMapHaveLayers,
  getInitialViewStateForCompany: getInitialViewStateForCompany
};

export default MapUtil;
