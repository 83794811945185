import geojson2h3 from 'geojson2h3';
import { MapMarkerUtil } from '../../../common/components/marker/util/mapMarkerUtil';
import IdUtils from '../../../common/utils/idUtil';
import PlanningPageUtil from './planningPageUtil';

const PlanningMapUtil = {
  plotRouteStartAndEndPoint: plotRouteStartAndEndPoint,
  plotRegion: plotRegion,
  plotRoute: plotRoute
};

function plotRouteStartAndEndPoint(map, startPoint, endPoint) {
  if (startPoint[0] === endPoint[0] && startPoint[1] === endPoint[1]) {
    const startEnd = MapMarkerUtil.addMarkerToMap(map, startPoint, { text: 'S/E', cssClass: 'small route-start-end', id: 'route-start-end' });

    return [startEnd];
  }

  const start = MapMarkerUtil.addMarkerToMap(map, startPoint, { text: 'S', cssClass: 'small route-start', id: 'route-start' });
  const end = MapMarkerUtil.addMarkerToMap(map, endPoint, { text: 'E', cssClass: 'small route-end', id: 'route-end' });

  return [start, end];
}

function plotRegion(map, region) {
  if (!region.hexIds || region.hexIds.length === 0) {
    return null;
  }

  const h3FeatureCollection = geojson2h3.h3SetToFeatureCollection(region.hexIds);

  map.addSource(`hex-source-${region.regionId}`, {
    type: 'geojson',
    data: h3FeatureCollection
  });

  map.addLayer({
    id: `hex-${region.regionId}`,
    type: 'fill',
    source: `hex-source-${region.regionId}`,
    layout: {},
    paint: {
      'fill-color': PlanningPageUtil.getColorFromRegionId(region.regionId),
      'fill-opacity': 0.6
    }
  });

  return {
    source: `hex-source-${region.regionId}`,
    layer: `hex-${region.regionId}`
  };
}

function plotRoute(map, route) {
  const r = IdUtils.generateId();

  map.addLayer({
    id: `route-${r}`,
    type: 'line',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route
        }
      }
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#f00',
      'line-width': 2,
      'line-opacity': 0.6
    }
  });

  return `route-${r}`;
}

export default PlanningMapUtil;
