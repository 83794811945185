import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '../../../../../common/components/form/Form';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';

class RoleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: props?.data?.name,
        policy: props?.data?.policy || this.getDefaultPolicyValue(),
        zone: props?.data?.zone || this.getDefaultZoneValue()
      },
      serverErrors: null
    };
    this.formRef = null;
  }

  getDefaultPolicyValue = () => {
    if (this.props.policies && this.props.policies.length > 0) return { value: this.props.policies[0].id, label: this.props.policies[0].name };
    return null;
  };

  getDefaultZoneValue = () => {
    if (this.props.zones && this.props.zones.length > 0) return { value: this.props.zones[0].id, label: this.props.zones[0].name };
    return null;
  };

  getPolicyOptions = () => this.props.policies.map((policy) => ({ value: policy.id, label: policy.name }));

  getZoneOptions = () => this.props.zones.map((zone) => ({ value: zone.id, label: zone.name }));

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      this.props.submitCallback({
        ...this.state.formData,
        policy: { id: this.state.formData.policy.value, name: this.state.formData.policy.label },
        zone: { id: this.state.formData.zone.value, name: this.state.formData.zone.label }
      });
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <div className="form-dialog-component">
        <div className="title">{this.props.title}</div>
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
          serverErrors={this.state.serverErrors}
        >
          <FormInput inputType="input" label={this.props.t('Role name')} name="name" validation={{ required: true }} />
          {this.props.policies && this.props.policies.length > 0 && (
            <FormInput
              inputType="select"
              label={this.props.t('Policy')}
              options={this.getPolicyOptions()}
              menuPortalTarget={document.querySelector('.MuiDialog-root')}
              menuPosition="fixed"
              placeholder={this.props.t('Select policy')}
              name="policy"
              defaultValue={this.props?.data?.policy || this.getDefaultPolicyValue()}
            />
          )}
          {this.props.zones && this.props.zones.length > 0 && (
            <FormInput
              inputType="select"
              label={this.props.t('Zone')}
              options={this.getZoneOptions()}
              menuPortalTarget={document.querySelector('.MuiDialog-root')}
              menuPosition="fixed"
              placeholder={this.props.t('Select zone')}
              name="zone"
              defaultValue={this.props?.data?.zone || this.getDefaultZoneValue()}
            />
          )}
        </Form>

        <div className="buttons">
          <Button onClick={this.cancelCallback} type="cancel" text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} text={this.props.t('Save')} />
        </div>
      </div>
    );
  }
}

RoleForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    zone: PropTypes.object,
    policy: PropTypes.object
  }),
  policies: PropTypes.arrayOf(PropTypes.object),
  zones: PropTypes.arrayOf(PropTypes.object),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

RoleForm.defaultProps = {
  data: null,
  submitCallback: null,
  cancelCallback: null,
  policies: null,
  zones: null
};

export default withTranslation('translations')(RoleForm);
