import React from 'react';
import { useTranslation } from 'react-i18next';
import MapMarker from '../../../../common/components/marker/MapMarker';
import './PinLegend.scss';

export default function PinLegend() {
  const { t } = useTranslation();

  return (
    <div className="pin-legend">
      <div className="legend-data">
        <div className="pin1">
          <MapMarker cssClass="small delivery" icon="icon-package" id="1" />
        </div>
        <div className="name">{t('Drop-off-single')}</div>

        <div className="pin3">
          <MapMarker cssClass="small pickup" icon="icon-package" id="3" />
        </div>
        <div className="name">{t('Pickup-single')}</div>

        <div className="pin5">
          <MapMarker cssClass="small stop-after-pause" icon="icon-package" id="5" />
        </div>
        <div className="unsuccessful name">{t('>20 min')}</div>

        <div className="pin6 additional-number">
          <div className="circle">
            <div className="dot">+3</div>
          </div>
        </div>
        <div className="number name">{t('Additional stops')}</div>
      </div>
    </div>
  );
}
