import moment from 'moment';
import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace PageActions
 * @category Actions
 */

/**
 * Creates an action with information on whether or not a page is loading.
 *
 * @param {boolean} isLoading - whether or not a page is loading
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf PageActions
 * @alias loadingPage
 * @function
 */
export const loadingPage = (isLoading) => ({
  type: ActionTypes.PAGE_IS_LOADING,
  payload: isLoading
});

export const reloadData = () => ({
  type: ActionTypes.PAGE_RELOAD_DATA,
  payload: moment().format('YYYY-MM-DD HH:mm:ss')
});
