import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import TableActions from '../../../../../common/components/tables/common/TableActions';
import TableComponent from '../../../../../common/components/tables/tableComponent/TableComponent';
import IdUtil from '../../../../../common/utils/idUtil';
import MixPanel from '../../../../../setup/mixPanel';
import { raygunClient } from '../../../../../setup/raygunClient';
import * as PageActions from '../../../../../state/actions/pageActions';
import PermissionsApi from '../../api/permissionsApi';
import ZoneForm from './ZoneForm';
import './ZoneManagement.scss';

class ZoneManagementClass extends React.Component {
  constructor() {
    super();
    this.state = { tablePageSize: 1 };
  }

  getTableColumns = () => [
    {
      Header: this.props.t('Zone name'),
      accessor: 'name'
    },
    {
      Header: this.props.t('Description'),
      accessor: 'description'
    },
    { accessor: 'id' },
    {
      Header: this.props.t('Actions'),
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editZone} />
    }
  ];

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize === 1 || this.state.tablePageSize < pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  upsertZone = (zoneId, newPayload, upsertType) => {
    this.props.dispatchLoadingPage(true);
    PermissionsApi.upsertZone(zoneId, newPayload)
      .then((res) => {
        if (res?.data?.upsertData > 0) {
          this.props.refreshData();
          MixPanel.track(`User Management - Zone ${upsertType}`);
        }
      })
      .catch((e) => {
        raygunClient.send(e, 'Error updating zone', { zoneId, newPayload });
        toast.error(this.props.t('Oops something went wrong'));
        this.props.dispatchLoadingPage(false);
      });
  };

  addZone = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: ZoneForm,
      dialogComponentProps: {
        title: this.props.t('Add new zone'),
        maxWidth: '100%',
        submitCallback: (newPayload) => {
          this.upsertZone(IdUtil.generateId(), newPayload, 'create');
        }
      }
    });
  };

  editZone = (zoneRow) => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: ZoneForm,
      dialogComponentProps: {
        title: this.props.t('Edit zone'),
        zone: zoneRow.original,
        maxWidth: '100%',
        submitCallback: (newPayload) => {
          this.upsertZone(zoneRow.values.id, newPayload, 'update');
        }
      }
    });
  };

  render() {
    return (
      <div className="zone-management">
        {this.props.zones && (
          <TableComponent
            key={`policies-${this.state.tablePageSize}`}
            columns={this.getTableColumns()}
            data={this.props.zones}
            paginationLabel={this.props.t('zone-pagination')}
            sortBy={[{ id: 'name' }]}
            pageSize={this.state.tablePageSize}
            onActionButtonClick={this.addZone}
            actionButtonText={this.props.t('Add new zone')}
            hiddenColumns={['id']}
            showSearch
            calculateTablePageSize={this.calculateTablePageSize}
            pageUsing="User Management - Zones"
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

ZoneManagementClass.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.shape({})),
  refreshData: PropTypes.func.isRequired,
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

ZoneManagementClass.defaultProps = { zones: null };

const ZoneManagement = withTranslation('translations')(connect(null, mapDispatchToProps)(ZoneManagementClass));

export default ZoneManagement;
