import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './StorageManagementPage.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import MapUtil from '../../../../common/utils/mapUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import * as MapActions from '../../../../state/actions/mapActions';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import CardMenuTable from '../../../../common/components/tables/cardMenuTable/CardMenuTable';
import Button from '../../../../common/components/buttons/button/Button';
import * as PageActions from '../../../../state/actions/pageActions';
import StorageMap from '../components/StorageMap';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import * as CoordinatesActions from '../../../../state/actions/coordinatesActions';
import StorageApi from '../api/storageApi';
import IdUtils from '../../../../common/utils/idUtil';
import TableActions from '../../../../common/components/tables/common/TableActions';
import LogoDisplay from '../../../../common/components/logoDisplay/LogoDisplay';
import AuthUtil from '../../../../common/utils/authUtil';

class StorageManagementPageClass extends React.Component {
  constructor() {
    super();
    this.state = {
      showStorage: false,
      tablePageSize: 1
    };
  }

  componentDidMount() {
    this.loadStorages();
  }

  loadStorages = () => {
    this.setState({ storage: AuthUtil.getStorages() });
    this.props.dispatchLoadingPage(false);
  };

  getTableColumns = () => {
    const tableColumns = [
      {
        Header: this.props.t('Name'),
        accessor: 'name', // accessor is the "key" in the data
        className: 'flex-3'
      },
      { accessor: 'integrationId' }
    ];
    if (AuthUtil.isFeatureEnabled('manageStorage')) {
      tableColumns.push({
        Header: this.props.t('Actions'),
        id: 'actions',
        disableGlobalFilter: true,
        className: 'centered',
        Cell: ({ row }) => <TableActions type="card" row={row} onEdit={this.editStorage} onDelete={this.deleteStorage} />
      });
    }

    return tableColumns;
  };

  createNewStorage = () => {
    const newId = IdUtils.generateId();
    this.props.history.push({
      pathname: `/storage-management/storage-add/${newId}`,
      state: {
        create: true,
        lat: MapUtil.getInitialViewStateForCompany().lat,
        lng: MapUtil.getInitialViewStateForCompany().lng,
        id: newId
      }
    });
  };

  editStorage = (row) => {
    this.props.history.push({
      pathname: `/storage-management/storage-add/${row.original.id}`,
      state: {
        name: row.original.name,
        lat: parseFloat(row.original.lat),
        lng: parseFloat(row.original.lng),
        id: row.original.id,
        integrationId: row.original.integrationId,
        create: false
      }
    });
  };

  deleteStorage = (row, e) => {
    e.stopPropagation();
    AppDialogActionsWrapper.openConfirmationDialog({
      title: `${this.props.t('Delete storage')}?`,
      body: `${this.props.t('Delete storage info')}.`,
      confirmButtonText: this.props.t('Delete'),
      continueCallback: () => {
        if (process.env.REACT_APP_DATA_SOURCE === 'api') {
          StorageApi.deleteStorage(row.original.id)
            .then(() => {
              if (this.message !== 'NoApi') {
                this.loadStorages();
                this.setState({ showStorage: false });
              }
            })
            .catch((error) => {
              raygunClient.send(error, 'Error deleting storage', { id: row.original.id });
              toast.error(this.props.t('Oops something went wrong'));
              this.props.dispatchLoadingPage(false);
            });
        }
      }
    });
  };

  flyToCoordinates = (showStorageData) => {
    this.setState({ showStorage: true, clickedStorageData: showStorageData });
    this.props.dispatchCoordinates({ lng: parseFloat(showStorageData.lng), lat: parseFloat(showStorageData.lat) });
  };

  calculateTablePageSize = (height) => {
    if (this.state.tablePageSize !== height) {
      this.setState({ tablePageSize: height });
    }
  };

  render() {
    return (
      <div className="storage-management">
        <div className="menu-wrapper">
          <div className="menu">
            {AuthUtil.isFeatureEnabled('manageStorage') && <Button text={this.props.t('Add new storage')} onClick={this.createNewStorage} />}
            <div className="table-wrapper">
              {this.state.storage && this.state.storage.length > 0 && (
                <CardMenuTable
                  key={this.state.tablePageSize}
                  columns={this.getTableColumns()}
                  data={this.state.storage}
                  sortBy={[{ id: 'name', desc: false }]}
                  showSearch
                  handleRowClick={this.flyToCoordinates}
                  pageSize={this.state.tablePageSize}
                  calculateTablePageSize={this.calculateTablePageSize}
                  hiddenColumns={['integrationId']}
                  autoResetSelectedRows
                />
              )}
            </div>
          </div>
        </div>
        <div className="storage-map-section">
          {this.state.showStorage && <StorageMap storageData={this.state.clickedStorageData} zoom={15.0} />}
          {!this.state.showStorage && <LogoDisplay />}
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.planningPageState };
}

/**
 * @param {Function} dispatch - redux dispatch function
 * @returns {object} - map actions to component props
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchIsMapLoading: MapActions.mapIsLoading,
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchResetPlanningData: PlanningPageActions.resetData,
      dispatchCoordinates: CoordinatesActions.coordinatesChange
    },
    dispatch
  );
}

export default withTranslation('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(StorageManagementPageClass)));

StorageManagementPageClass.propTypes = {
  /**
   * React router history object
   */
  history: PropTypes.object.isRequired,
  /**
   * Dispatches event if page is currently loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  dispatchCoordinates: PropTypes.func.isRequired
};
