import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../../../common/components/form/utils/defaultFormDialogCss.scss';
import { Form } from '../../../../common/components/form/Form';
import { FormInput } from '../../../../common/components/form/components/FormInput';
import Button from '../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import MixPanel from '../../../../setup/mixPanel';

class ShipmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        lat: this.props?.data?.lat,
        lng: this.props?.data?.lng,
        shipmentCode: this.props?.data?.shipmentCode,
        address: this.props?.data?.address,
        latLng: this.props?.data?.lat && this.props?.data?.lng ? `${this.props.data.lat},${this.props.data.lng}` : null,
        phone: this.props?.data?.phone,
        saveGeolocation: false
      },
      serverErrors: null
    };
    this.formRef = null;
  }

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      let mixPanelEventDescription;

      if (!this.hasFuzzyMatchUngeocodedAddress(this.props.data.fuzzyAddress)) mixPanelEventDescription = 'Address labeled with no option to save it';
      else if (this.state.formData.saveGeolocation) mixPanelEventDescription = 'Address labeled with option to save checked';
      else mixPanelEventDescription = 'Address labeled with option to save unchecked';

      MixPanel.track(`${this.props.data.featureRequest} - ${mixPanelEventDescription}`);
      this.props.submitCallback(this.state.formData);
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  hasFuzzyMatchUngeocodedAddress = (address) => !(address.geoLat && address.geoLng) && address.hasFuzzyMatch;

  getCheckboxLabel = () => (
    <div className="checkbox-label">
      <span>{this.props.t('Is?')}</span>
      <span className="highlighted">{` ${this.props.data.fuzzyAddress.streetName.toUpperCase()} ${this.props.data.fuzzyAddress.houseNumber} `}</span>
      <span>{this.props.t('Address suggestion')}</span>
      <div>{this.props.t('Save location for this address')}</div>
    </div>
  );

  render() {
    return (
      <div className="form-dialog-component">
        <div className="title">{this.props.title}</div>
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            const latLngArray = newFormData.latLng?.split(',');
            const lat = latLngArray && latLngArray.length > 0 ? parseFloat(latLngArray[0]) : null;
            const lng = latLngArray && latLngArray.length > 0 ? parseFloat(latLngArray[1]) : null;
            this.setState({ formData: { ...newFormData, lat: lat, lng: lng } });
          }}
          serverErrors={this.state.serverErrors}
        >
          <FormInput inputType="input" label={this.props.t('ShipmentCode')} name="shipmentCode" disabled />
          <FormInput inputType="input" label={this.props.t('Address')} name="address" disabled />
          <FormInput name="phone" inputType="input" label={this.props.t('Phone number')} />
          <FormInput name="latLng" inputType="input" label={this.props.t('Geo location')} validation={{ required: true }} />
          {this.hasFuzzyMatchUngeocodedAddress(this.props.data.fuzzyAddress) && (
            <FormInput name="saveGeolocation" inputType="checkbox" customLabel={this.getCheckboxLabel()} />
          )}
          {/* <FormInput inputType="input-number" label={this.props.t('Latitude')} name="lat" validation={{ required: true, isNumber: true }} /> */}
          {/* <FormInput inputType="input-number" label={this.props.t('Longitude')} name="lng" validation={{ required: true, isNumber: true }} /> */}
        </Form>

        <div className="buttons">
          <Button onClick={this.cancelCallback} type="cancel" text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} text={this.props.t('Save')} />
        </div>
      </div>
    );
  }
}

ShipmentForm.propTypes = {
  data: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    shipmentCode: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    fuzzyAddress: PropTypes.object,
    featureRequest: PropTypes.string
  }),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

ShipmentForm.defaultProps = {
  data: null,
  submitCallback: null,
  cancelCallback: null
};

export default withTranslation('translations')(ShipmentForm);
