import { gql } from '@apollo/client';
import { appSyncClient } from '../../../../setup/appSyncClient';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';

const UsersApi = {
  getWebUsers: getWebUsers,
  createWebUser: createWebUser,
  upsertWebUser: upsertWebUser,
  deleteWebUser: deleteWebUser
};

const GetWebUsers = gql`
  query getUsers {
    getUsers {
      email
      roleId
      firstName
      lastName
    }
  }
`;

const CreateWebUser = gql`
  mutation createUser($email: String, $roleId: String, $firstName: String, $lastName: String) {
    createUser(email: $email, roleId: $roleId, firstName: $firstName, lastName: $lastName)
  }
`;

const UpdateWebUser = gql`
  mutation updateUser($email: String, $roleId: String, $firstName: String, $lastName: String) {
    updateUser(email: $email, roleId: $roleId, firstName: $firstName, lastName: $lastName)
  }
`;

const DeleteWebUser = gql`
  mutation deleteUser($email: String) {
    deleteUser(email: $email)
  }
`;

async function getWebUsers() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetWebUsers);
  }

  return null;
}

async function createWebUser(email, roleId, firstName, lastName) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: CreateWebUser,
      variables: {
        email: email,
        roleId: roleId,
        firstName: firstName,
        lastName: lastName
      }
    });
  }

  return null;
}

async function upsertWebUser(email, roleId, firstName, lastName) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpdateWebUser,
      variables: {
        email: email,
        roleId: roleId,
        firstName: firstName,
        lastName: lastName
      }
    });
  }

  return null;
}

async function deleteWebUser(email) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: DeleteWebUser,
      variables: { email: email }
    });
  }

  return null;
}

export default UsersApi;
