/**
 * Enum with different values for hexagon data that can be displayed.
 *
 * @type {object}
 * @memberOf Util
 */
const HEX_TYPE = {
  STOPS: 'stops',
  PACKAGES: 'packages',
  SHIPMENTS: 'shipments',
  SHIPMENTS_WITH_ONE_PACKAGE: 'shipments-with-one-package',
  SHIPMENTS_FOR_PACKAGE_LOCKERS: 'shipments-for-package-lockers'
};

/**
 * Util which helps to wrap some functions that are easier to use wrapped.
 *
 * @namespace
 * @category RegionAnalysis
 */
const HexTypes = { HEX_TYPE: HEX_TYPE };

export default HexTypes;
