import React from 'react';
import PropTypes from 'prop-types';
import TimelineContent from '@material-ui/lab/TimelineContent';

import { Divider as MuiDivider, Paper as MuiPaper, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import './StopsTimeline.scss';
import CopyToClipboardWrapper from '../../../../common/components/copy/CopyToClipboardWrapper';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';

export const Paper = withStyles({
  root: {
    'background-color': 'transparent',
    border: `solid 1px ${colorsAndFonts.kashmir_blue}`,
    width: '400px',
    padding: '4px 16px'
  },
  rounded: { 'border-radius': '0' }
})(MuiPaper);

const Divider = withStyles({
  root: {
    'background-color': 'white',
    'margin-top': '4px',
    'margin-bottom': '4px'
  }
})(MuiDivider);

const customTimelineItem = { paddingRight: '0' };

/**
 * Represents an item in @link StopsTimeline and shows basic info about the stop
 *
 * @component
 * @alias StopsTimelineItem
 * @category RouteAnalysis
 */
class StopsTimelineItem extends React.Component {
  constructor() {
    super();

    this.state = { showShipments: false };
  }

  /**
   * Toggles visibility of shipments
   *
   * @function
   */
  toggleShowShipments = () => {
    this.setState((prevState) => ({ showShipments: !prevState.showShipments }));
  };

  render() {
    if (!this.props.stop) return <span />;
    const haveMultipleShipments = this.props.stop.shipmentCodes && this.props.stop.shipmentCodes.length > 1;
    let itemClass = 'shipments';

    if (haveMultipleShipments) {
      itemClass += ' cursor-pointer';
    }

    return (
      <TimelineContent style={customTimelineItem}>
        <Paper elevation={3} className="paper">
          <div className="stop-timeline-row">
            <span className="ui-icon-style">
              <i className="timeline-item-icon icon icon-alarm" />
            </span>
            <b>{moment.parseZone(this.props.stop.timestamp).format('HH:mm')}</b>
            <div className={itemClass} onClick={haveMultipleShipments ? this.toggleShowShipments : null}>
              <i className="timeline-item-icon icon icon-assignment-turned-in-outlined" />
              <b>
                {this.props.stop.shipmentCodes && this.props.stop.shipmentCodes.length > 1
                  ? this.props.stop.shipmentCodes.length
                  : this.props.stop.shipmentCodes[0]}
              </b>
              {this.props.stop.shipmentCodes && this.props.stop.shipmentCodes.length === 1 && (
                <CopyToClipboardWrapper text={this.props.stop.shipmentCodes[0]} />
              )}
              <i className="timeline-item-icon icon icon-package sh-pk-icon" />
              <b>
                {' '}
                {this.props.stop.numPackages}
              </b>
              {this.props.stop.shipmentCodes
                && this.props.stop.shipmentCodes.length > 1
                && (this.state.showShipments ? <i className="icon icon-keyboard-arrow-down arrow" /> : <i className="icon icon-keyboard-arrow-right arrow" />)}
            </div>
          </div>
          <div className="stop-timeline-row">
            <span className="ui-icon-style">
              <i className="timeline-item-icon icon icon-markunread-mailbox-outlined" />
            </span>
            {this.props.stop.name}
          </div>
          <div className="stop-timeline-row">
            <span className="ui-icon-style">
              <i className="timeline-item-icon icon icon-location-on-outlined" />
            </span>
            {this.props.stop.address}
          </div>
          {this.state.showShipments && <Divider />}
          {this.state.showShipments
            && this.props.stop.shipmentCodes
            && this.props.stop.shipmentCodes.length > 1
            && this.props.stop.shipmentCodes.map((shipmentCode) => (
              <div className="stop-timeline-row" key={shipmentCode}>
                <span className="ui-icon-style">
                  <i className="timeline-item-icon icon icon-assignment-turned-in-outlined" />
                </span>
                {shipmentCode}
                <CopyToClipboardWrapper text={shipmentCode} />
              </div>
            ))}
        </Paper>
      </TimelineContent>
    );
  }
}

export default withTranslation('translations')(StopsTimelineItem);

StopsTimelineItem.propTypes = {
  /**
   * Stop data
   */
  stop: PropTypes.shape({
    timestamp: PropTypes.string,
    shipmentCodes: PropTypes.arrayOf(PropTypes.string),
    numPackages: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string
  })
};

StopsTimelineItem.defaultProps = { stop: null };
