import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Form } from '../../../../../common/components/form/Form';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';
import ApiHelpers from '../../../../../common/utils/api/apiHelpers';
import PermissionsApi from '../../api/permissionsApi';
import './PolicyForm.scss';

class PolicyForm extends React.Component {
  constructor() {
    super();
    this.state = { formData: null };

    this.formRef = null;
  }

  componentDidMount() {
    const featuresPromise = PermissionsApi.getAllFeatures().then((res) => {
      let allFeaturesResponse = {};
      if (res?.data?.getAllFeatures) {
        allFeaturesResponse = ApiHelpers.removeTypenameFromObject(res.data.getAllFeatures);
      }
      return allFeaturesResponse;
    });

    Promise.all([featuresPromise]).then((res) => {
      const [allFeatures] = res;
      this.setState({
        formData: {
          name: this.props?.policy?.name,
          description: this.props?.policy?.description,
          ...this.convertFeaturesToFormData(this.props?.policy?.permissions || allFeatures, !!this.props.policy)
        },
        allFeatures: allFeatures
      });
    });
  }

  convertFeaturesToFormData = (rowFeatures, usePolicyValue) => {
    const features = ApiHelpers.removeTypenameFromObject(rowFeatures);
    const formData = {};
    Object.keys(features).forEach((pageGroup) => {
      if (pageGroup !== 'name' || pageGroup !== 'description') {
        formData[pageGroup] = true;

        if (features[pageGroup] && Object.keys(features[pageGroup]) && Object.keys(features[pageGroup]).length > 0) {
          Object.keys(features[pageGroup]).forEach((page) => {
            formData[`${pageGroup}.${page}`] = usePolicyValue ? !!features[pageGroup][page] : false;

            if (features[pageGroup][page] && features[pageGroup][page].length > 0) {
              features[pageGroup][page].forEach((feature) => {
                formData[`${pageGroup}.${page}.${feature}`] = usePolicyValue;
              });
            }
          });
        }
      }
    });

    return formData;
  };

  convertPolicyToFormData = (features) => this.convertFeaturesToFormData(features, true);

  convertFormDataToPolicy = (formData) => {
    const policyData = {
      name: formData.name,
      description: formData.description,
      permissions: {}
    };

    Object.keys(formData)
      .filter((k) => formData[k])
      .forEach((key) => {
        if (key === 'name' || key === 'description') {
          return;
        }

        const parts = key.split('.');
        if (!policyData.permissions[parts[0]]) {
          policyData.permissions[parts[0]] = {};
        }

        if (parts.length > 1 && !policyData.permissions[parts[0]][parts[1]] && !!formData[`${parts[0]}.${parts[1]}`]) {
          policyData.permissions[parts[0]][parts[1]] = [];
        }

        if (parts.length > 2 && !!formData[`${parts[0]}.${parts[1]}`] && policyData.permissions[parts[0]][parts[1]]) {
          policyData.permissions[parts[0]][parts[1]].push(parts[2]);
        }
      });

    return policyData;
  };

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    const policyData = this.convertFormDataToPolicy(this.state.formData);

    const newPayload = ApiHelpers.removeTypenameFromObject(policyData);

    if (this.props.submitCallback) {
      this.props.submitCallback(newPayload);
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  validateFormData = (formData) => {
    const policyData = this.convertFormDataToPolicy(formData);
    const newPolicyFormData = this.convertPolicyToFormData(policyData.permissions);
    return {
      name: formData.name,
      description: formData.description,
      ...newPolicyFormData
    };
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <SimpleBar style={{ maxHeight: 'calc(100vh - 64px)' }}>
        <div className="policy-form">
          {this.state.allFeatures && (
            <Form
              ref={(formRef) => {
                this.formRef = formRef;
              }}
              formData={this.state.formData}
              onFormChange={(newFormData) => {
                const validatedFormData = this.validateFormData(newFormData);
                this.setState({ formData: validatedFormData });
              }}
              serverErrors={this.state.serverErrors}
            >
              <FormInput name="name" inputType="input" label={this.props.t('Policy name')} />
              <FormInput name="description" inputType="input" label={this.props.t('Description')} />
              <div className="group-title">{this.props.t('policy-form-description')}</div>
              {Object.keys(this.state.allFeatures).map((pageGroup) => {
                if (!this.state.allFeatures[pageGroup]) {
                  return null;
                }
                const pages = Object.keys(this.state.allFeatures[pageGroup]).map((page) => {
                  if (!this.state.allFeatures[pageGroup][page]) {
                    return null;
                  }

                  const features = this.state.allFeatures[pageGroup][page]
                    && this.state.allFeatures[pageGroup][page].map((feature) => (
                      <FormInput
                        key={`${pageGroup}.${page}.${feature}`}
                        name={`${pageGroup}.${page}.${feature}`}
                        inputType="toggle"
                        label={this.props.t(`pf-${feature}`)}
                        checked={!!this.state.formData[`${pageGroup}.${page}.${feature}`]}
                      />
                    ));
                  return (
                    <div key={`${pageGroup}.${page}`} className={this.state.formData[pageGroup] ? 'visible' : 'hidden'}>
                      <FormInput
                        name={`${pageGroup}.${page}`}
                        inputType="toggle"
                        label={this.props.t(`pf-${page}`)}
                        checked={!!this.state.formData[`${pageGroup}.${page}`]}
                      />
                      <div className="features-wrapper">{features && features.length > 0 && features}</div>
                    </div>
                  );
                });
                return (
                  <div key={pageGroup} className="page-group">
                    <div className="group-name">{this.props.t(pageGroup)}</div>
                    {pages && pages.length > 0 && <div className="pages-wrapper">{pages}</div>}
                  </div>
                );
              })}
              <div className="buttons">
                <Button onClick={this.cancelCallback} type="cancel" text={this.props.t('Cancel')} />
                <Button onClick={this.handleSubmit} text={this.props.t('Save')} />
              </div>
            </Form>
          )}
        </div>
      </SimpleBar>
    );
  }
}

PolicyForm.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    permissions: PropTypes.object,
    description: PropTypes.string
  }),
  cancelCallback: PropTypes.func,
  submitCallback: PropTypes.func,
  t: PropTypes.func.isRequired
};

PolicyForm.defaultProps = {
  policy: null,
  cancelCallback: null,
  submitCallback: null
};

export default withTranslation('translations')(PolicyForm);
