import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SideMenu.scss';
import { NavLink, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LanguageSelection from '../../common/components/selections/languageSelection/LanguageSelection';
import * as AuthActions from '../../state/actions/authActions';
import MixPanel from '../../setup/mixPanel';
import AppDialogActionsWrapper from '../../common/components/dialogs/utils/appDialogActionsWrapper';
import * as MapActions from '../../state/actions/mapActions';

/**
 * Side Menu component which renders main navigation entries
 *
 * @param {object} props - component props
 * @returns {JSX.Element} main navigation
 * @component
 * @category Common
 */
export default function SideMenu(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handlePageChange = (event) => {
    event.preventDefault();
    const page = event.currentTarget.dataset.value;
    AppDialogActionsWrapper.showSaveChangesAlert(() => {
      history.push(page);
    });
  };

  /**
   * Signs current user out
   *
   * @async
   * @function
   */
  const signOut = () => {
    AppDialogActionsWrapper.showSaveChangesAlert(async () => {
      try {
        dispatch(AuthActions.resetUserPermissions());
        await Auth.signOut();
        MixPanel.reset();
        history.push('/');
      } catch (error) {
        console.log('error signing out: ', error);
      }
    });
  };

  /**
   * Refactors URL to include query params when switching pages.<br/>
   * If goes through page pairs where first element of the pair is source page, and second element is the destination.<br/>
   * If there is a pair that current page is source, and that page param is the destination, it will pass through query params.
   *
   * @param {string} page - next page path
   * @returns {string} - refactored url
   * @function
   */
  function createUrl(page) {
    const queryParamPassingPairs = [
      ['courier-analysis', 'route-analysis'],
      ['route-analysis', 'courier-analysis']
    ];
    let refactoredUrl = page;

    queryParamPassingPairs.forEach((pair) => {
      const source = pair[0];
      const destination = pair[1];
      if (window.location.pathname.indexOf(source) > -1 && destination === page) {
        refactoredUrl += window.location.search;
      }
    });
    return refactoredUrl;
  }

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
    setTimeout(() => {
      dispatch(MapActions.resizeMap());
    }, 250);
  }

  function extendablePageClass() {
    return isMenuOpen ? 'page-name page-name-extended' : 'page-name';
  }

  function goToMilyWebPage() {
    window.open('https://milytech.com/');
  }

  return (
    <div className={isMenuOpen ? 'side-menu menu-extended' : 'side-menu'}>
      <div className="logo" onClick={goToMilyWebPage}>
        <i className="icon icon-mily-logo" />
        <div className={`${extendablePageClass()} logo-name`}>Mily Tech</div>
      </div>
      <div className={isMenuOpen ? 'menu-arrow rotate-left' : 'menu-arrow rotate-right'} onClick={toggleMenu}>
        <i className="icon icon-keyboard-arrow-left" />
      </div>
      <div className="pages">
        {props.pages.map((page) => {
          if (page.type === 'SEPARATOR') {
            return (
              <div className="separator" key={page.key}>
                {isMenuOpen && <div className="separator-name">{page.text}</div>}
                {!isMenuOpen && <div className="separator-line" />}
              </div>
            );
          }

          return (
            <NavLink
              onClick={handlePageChange}
              data-value={`/${createUrl(page.key)}`}
              to={`/${createUrl(page.key)}`}
              activeClassName="active"
              className={isMenuOpen ? 'page page-extended' : 'page'}
              key={page.key}
              data-tip={page.tooltip}
              data-for={page.key}
            >
              <i className={`icon icon-${page.iconName}`} />
              <div className={extendablePageClass()}>{t(page.text)}</div>
              {!isMenuOpen && <ReactTooltip id={page.key} className="tooltip" place="right" effect="solid" />}
            </NavLink>
          );
        })}
      </div>
      <div className="language-section">
        <LanguageSelection showTextLabel={t('Language')} showingTextLabel={isMenuOpen} showTooltip={!isMenuOpen} />
      </div>
      <div className="logout" data-tip={`${t('Logout')}`} data-for="logout" onClick={signOut}>
        <i className="icon icon-exit-to-app" />
        <div className={extendablePageClass()}>{t('Logout')}</div>
        {!isMenuOpen && <ReactTooltip id="logout" className="tooltip" place="right" effect="solid" />}
      </div>
    </div>
  );
}

SideMenu.propTypes = {
  /**
   * Array of pages from which navi links are rendered
   */
  pages: PropTypes.arrayOf(Object)
};

SideMenu.defaultProps = { pages: [] };
