import store from '../../../../state/store';
import MapHelperUtil from '../../courierAnalysis/utils/mapHelpersUtil';

const MAP_VIEW_TYPE = {
  CLUSTER: 'cluster',
  HEATMAP: 'heatmap'
};
/**
 * Util for rendering different map views
 *
 * @class RegionAnalysisMapViewUtilClass
 * @param map - map ref
 * @category RegionAnalysis
 */
export default class RegionAnalysisMapViewUtilClass {
  constructor(map) {
    this.map = map;
    this.mapLayers = [];
    this.currentMapViewType = MAP_VIEW_TYPE.CLUSTER;
    store.subscribe(this.toggleLayerVisibility.bind(this));
  }

  /**
   * Change visible layer based on regionAnalysisState mapViewType
   *
   * @memberOf RegionAnalysisMapViewUtilClass
   * @function
   */
  toggleLayerVisibility() {
    const state = store.getState();
    const { mapViewType } = state.regionAnalysisState;
    if (this.currentMapViewType !== mapViewType) {
      this.currentMapViewType = mapViewType;
      this.mapLayers.forEach((layer) => {
        if (layer.key.includes('hexagons-count')) {
          return;
        }

        if (layer.key.includes('hexagons-layer-border')) {
          this.map.setPaintProperty(layer.key, 'line-color', this.getHexBorderLineColor());
          return;
        }

        if (mapViewType === MAP_VIEW_TYPE.HEATMAP) {
          const value = MapHelperUtil.getVisibilityString(layer.type === MAP_VIEW_TYPE.HEATMAP);
          this.map.setLayoutProperty(layer.key, 'visibility', value);
        } else {
          const value = MapHelperUtil.getVisibilityString(layer.type === MAP_VIEW_TYPE.CLUSTER);
          this.map.setLayoutProperty(layer.key, 'visibility', value);
        }
      });
    }
  }

  /**
   * Remove all map layers
   *
   * @function
   * @memberOf RegionAnalysisMapViewUtilClass
   */
  removeMapLayers() {
    this.mapLayers.forEach((layer) => {
      this.map.removeLayer(layer.key);
    });
    this.mapLayers = [];
  }

  /**
   * Add all layers needed for regions
   *
   * @param {string} areaId - Id of the view area
   * @param {string} regionId - Id of the region
   * @param {object} additionalData - Data needed for displaying regions (color, count, centerPositionData)
   * @memberOf RegionAnalysisMapViewUtilClass
   * @function
   */

  addRegionsLayer(areaId, regionId, additionalData) {
    const clusterVisibility = MapHelperUtil.getVisibilityString(this.currentMapViewType === MAP_VIEW_TYPE.CLUSTER);
    const heatmapVisibility = MapHelperUtil.getVisibilityString(this.currentMapViewType === MAP_VIEW_TYPE.HEATMAP);

    this.map.addLayer({
      id: `heatmap-view-${regionId}-layer`,
      type: 'line',
      source: regionId,
      minzoom: 3,
      layout: { visibility: heatmapVisibility },
      paint: { 'line-color': '#555555' }
    });
    this.mapLayers.push({ key: `heatmap-view-${regionId}-layer`, type: MAP_VIEW_TYPE.HEATMAP });

    this.map.addLayer({
      id: `heatmap-view-${regionId}-fill-layer`,
      type: 'fill',
      source: regionId,
      minzoom: 3,
      layout: { visibility: heatmapVisibility },
      paint: { 'fill-opacity': 0 }
    });
    this.mapLayers.push({ key: `heatmap-view-${regionId}-fill-layer`, type: MAP_VIEW_TYPE.HEATMAP });

    this.map.addLayer({
      id: `${regionId}-layer`,
      type: 'fill',
      source: regionId,
      minzoom: 3,
      layout: { visibility: clusterVisibility },
      paint: {
        'fill-color': additionalData.color,
        'fill-opacity': 0.4
      }
    });
    this.mapLayers.push({ key: `${regionId}-layer`, type: MAP_VIEW_TYPE.CLUSTER });

    this.map.addLayer({
      id: `${regionId}-hexagons-cluster-layer`,
      type: 'circle',
      source: `${regionId}-hexagons-cluster`,
      minzoom: 3,
      maxzoom: this.getMinZoomLevel(additionalData.centerPositionData),
      layout: { visibility: clusterVisibility },
      paint: {
        'circle-color': additionalData.color,
        'circle-radius': 18,
        'circle-opacity': 0.6
      }
    });
    this.mapLayers.push({ key: `${regionId}-hexagons-cluster-layer`, type: MAP_VIEW_TYPE.CLUSTER });

    this.map.addLayer({
      id: `${regionId}-hexagons-cluster-layer-count`,
      type: 'symbol',
      source: `${regionId}-hexagons-cluster`,
      minzoom: 3,
      maxzoom: this.getMinZoomLevel(additionalData.centerPositionData),
      layout: {
        'text-field': `${additionalData.count}\n${regionId.substring(regionId.indexOf('-') + 1)}`,
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 10,
        'text-allow-overlap': true,
        visibility: clusterVisibility
      },
      paint: { 'text-color': '#000000' }
    });
    this.mapLayers.push({ key: `${regionId}-hexagons-cluster-layer-count`, type: MAP_VIEW_TYPE.CLUSTER });
  }

  addHeatMapLayers(areaId, additionalData, hexagonOpacityLimits, hexType) {
    const heatmapVisibility = MapHelperUtil.getVisibilityString(this.currentMapViewType === MAP_VIEW_TYPE.HEATMAP);
    this.addCommonLayers(areaId, additionalData.centerPositionData, hexType);
    this.addHeatmapHexagonLayers(areaId, hexagonOpacityLimits, heatmapVisibility, hexType);
  }

  getHexBorderLineColor() {
    if (this.currentMapViewType === MAP_VIEW_TYPE.HEATMAP) {
      return '#FF0000';
    }

    return '#000';
  }

  /**
   * Add layers used both map views
   *
   * @param {string} areaId - Id of the view area
   * @param {object} centerPositionData - Region center data (lon, lat, zoom)
   * @param {string} hexType - Type of the hexagon data that should be shown in layers
   * @function
   */
  addCommonLayers(areaId, centerPositionData, hexType) {
    this.map.addLayer({
      id: `${areaId}-hexagons-count`,
      type: 'symbol',
      source: `${areaId}-hexagons-${hexType}`,
      maxzoom: 16,
      minzoom: this.getMinZoomLevel(centerPositionData),
      layout: {
        'text-field': [
          'case',
          ['>', ['get', 'stops'], 0],
          ['get', 'stops'],
          '' // Default.
        ],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 10
      }
    });
    this.mapLayers.push({ key: `${areaId}-hexagons-count`, type: MAP_VIEW_TYPE.CLUSTER });

    this.map.addLayer({
      id: `${areaId}-hexagons-layer-border`,
      type: 'line',
      source: `${areaId}-hexagons-${hexType}`,
      minzoom: this.getMinZoomLevel(centerPositionData),
      paint: {
        'line-color': this.getHexBorderLineColor(),
        'line-opacity': 0.1,
        'line-width': 2
      }
    });

    this.mapLayers.push({ key: `${areaId}-hexagons-layer-border`, type: MAP_VIEW_TYPE.CLUSTER });
  }

  /**
   * Add all layers needed for cluster view mode
   *
   * @param {string} areaId - Id of the view area
   * @param {dict} hexagonOpacityLimits - opacity limits for data displayed in hexagons
   * @param {string} visibility - Layer visibility (visible/none)
   * @param {string} hexType - Type of the hexagon data that should be shown in layers
   * @memberOf RegionAnalysisMapViewUtilClass
   * @function
   */
  addHeatmapHexagonLayers(areaId, hexagonOpacityLimits, visibility, hexType) {
    this.map.addLayer({
      id: `heatmap-view-${areaId}-hexagons-layer`,
      type: 'fill',
      source: `${areaId}-hexagons-${hexType}`,
      minzoom: 3,
      layout: { visibility: visibility },
      paint: {
        'fill-color': '#FF0000',
        'fill-opacity': ['interpolate', ['linear'], ['get', 'stops'], 0, 0, hexagonOpacityLimits[hexType] + 1, 0.8]
      }
    });
    this.mapLayers.push({ key: `heatmap-view-${areaId}-hexagons-layer`, type: MAP_VIEW_TYPE.HEATMAP });
  }

  /**
   * Calculates zoom level for region
   *
   * @param {object} centerPositionData - center position data which contains current zoom level
   * @returns {number} zoom level
   * @memberOf RegionAnalysisMapViewUtilClass
   * @function
   */
  // TODO fix this issue after #87 is merged
  // eslint-disable-next-line class-methods-use-this
  getMinZoomLevel(centerPositionData) {
    return Math.max(12, centerPositionData.zoom + 0.5);
  }

  /**
   * Changes source for hexagon relevant layers based on hexagon type
   *
   * @param {string} regionId - region Id
   * @param {string} type - hexagon data type (stops, packages, shipments, shipments-with-one-package)
   * @param {number} totalCount - total count of items in specified region
   * @param {dict} hexagonOpacityLimits - opacity limits for data displayed in hexagons
   * @function
   */
  changeHexagonLayersSource(regionId, type, totalCount, hexagonOpacityLimits) {
    this.setLayerSource(`${regionId}-hexagons-count`, `${regionId}-hexagons-${type}`);
    this.setLayerSource(`heatmap-view-${regionId}-hexagons-layer`, `${regionId}-hexagons-${type}`);
    this.setLayerSource(`${regionId}-hexagons-layer-border`, `${regionId}-hexagons-${type}`);

    this.map.setPaintProperty(`heatmap-view-${regionId}-hexagons-layer`, 'fill-opacity', [
      'interpolate',
      ['linear'],
      ['get', 'stops'],
      0,
      0,
      hexagonOpacityLimits[type] + 1,
      0.8
    ]);
  }

  changeClusterCountLayerSource(regionId, totalCount) {
    this.map.setLayoutProperty(`${regionId}-hexagons-cluster-layer-count`, 'text-field', `${totalCount}\n${regionId.substring(regionId.indexOf('-') + 1)}`);
  }

  /**
   * Replaces source in specified layer by re-declaring layer
   *
   * @param {string} layerId - layer Id
   * @param {object} source - new source
   * @function
   */
  setLayerSource(layerId, source) {
    const oldLayers = this.map.getStyle().layers;
    const layerIndex = oldLayers.findIndex((l) => l.id === layerId);
    if (layerIndex === -1) return;
    const layerDef = oldLayers[layerIndex];
    const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
    layerDef.source = source;

    this.map.removeLayer(layerId);
    this.map.addLayer(layerDef, before);
  }
}
