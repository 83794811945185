import React from 'react';
import { Dialog as MuiDialog, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as DialogActions from '../../../state/actions/dialogActions';
import colorsAndFonts from '../../../resources/colors-and-fonts.scss';

/**
 *
 */
export default function AppDialog() {
  const dispatch = useDispatch();
  const appDialogState = useSelector((state) => state.saveChangesDialogState);

  /**
   * Close dialog and call additional callback if needed
   *
   * @function
   */
  const handleClose = () => {
    if (appDialogState.handleClose) {
      appDialogState.handleClose();
    }

    dispatch(DialogActions.closeDialog());
  };

  const DialogItem = withStyles({
    paper: {
      backgroundColor: colorsAndFonts.tile_background_color,
      color: colorsAndFonts.lighter_text,
      border: `1px solid ${colorsAndFonts.kashmir_blue}`,
      borderRadius: 0,
      maxWidth: appDialogState?.dialogComponentProps?.maxWidth || '600px',
      width: '100%'
    }
  })(MuiDialog);

  return (
    <div>
      <DialogItem open={appDialogState.isDialogOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {appDialogState.dialogComponent && React.createElement(appDialogState.dialogComponent, appDialogState.dialogComponentProps)}
      </DialogItem>
    </div>
  );
}
