import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import AuthUtil from '../../../../common/utils/authUtil';
import SortUtil from '../../../../common/utils/sortUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import * as PageActions from '../../../../state/actions/pageActions';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import './DeliveryAreasManagementPage.scss';
import ZipCodeForm from '../components/ZipCodeForm';
import DeliveryAreaMapView from '../components/DeliveryAreaMapView';
import Button from '../../../../common/components/buttons/button/Button';
import DeliveryAreasApi from '../api/deliveryAreasApi';
import IdUtils from '../../../../common/utils/idUtil';
import TableActions from '../../../../common/components/tables/common/TableActions';
import MixPanel from '../../../../setup/mixPanel';
import MixPanelUtil from '../../../../common/utils/mixPanelUtil';
import CardMenuTable from '../../../../common/components/tables/cardMenuTable/CardMenuTable';

class DeliveryAreasManagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeliveryAreas: false,
      clickedDeliveryAreaData: null,
      deliveryAreas: null,
      tablePageSize: 1
    };

    this.dataType = props?.location?.pathname.split('/')[1] || 'delivery-areas';
  }

  componentDidMount() {
    MixPanel.track('Page Load - Delivery Areas Management');
    MixPanelUtil.setUnloadListener('Page Unload - Delivery Areas Management');
    this.loadDeliveryAreas();
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  loadDeliveryAreas = () => {
    this.props.dispatchLoadingPage(true);
    switch (this.dataType) {
      case 'zip-code':
        DeliveryAreasApi.getAllDeliveryAreas(this.dataType)
          .then((res) => {
            this.setState({ deliveryAreas: SortUtil.sortArrayByUpperCaseField(res.data.getDeliveryAreas, 'name') });
            this.props.dispatchLoadingPage(false);
          })
          .catch((error) => {
            console.log(error);
            this.props.dispatchLoadingPage(false);
          });
        break;
      default:
        DeliveryAreasApi.getDeliveryArea(this.dataType)
          .then((res) => {
            this.setState({ deliveryAreas: SortUtil.sortArrayByUpperCaseField(res, 'name') });
            this.props.dispatchLoadingPage(false);
          })
          .catch((error) => {
            console.log(error);
            this.props.dispatchLoadingPage(false);
          });
    }
  };

  addDeliveryAreas = () => {
    if (this.dataType === 'zip-code') {
      AppDialogActionsWrapper.openAppDialog({
        dialogComponent: ZipCodeForm,
        dialogComponentProps: {
          title: this.props.t('Zip Code'),
          submitCallback: (data) => {
            MixPanel.track('Zip Code Management - Add new delivery area button clicked');
            this.props.history.push({
              pathname: `/zip-code/map-view/${data.zipCode}`,
              state: { id: data.zipCode, hexagonsFile: `${data.zipCode}_hexagons.json`, deliveryAreas: this.state.deliveryAreas }
            });
          }
        }
      });
    } else {
      const newId = IdUtils.generateId();
      MixPanel.track('Delivery Areas Management - Add new delivery area button clicked');
      this.props.history.push({
        pathname: `/${this.dataType}/map-view/${newId}`,
        state: { id: newId, hexagonsFile: `${newId}_hexagons.json`, deliveryAreas: this.state.deliveryAreas }
      });
    }
  };

  editDeliveryAreas = (row) => {
    MixPanel.track('Delivery Area Management - Edit delivery area clicked');
    this.props.history.push({ pathname: `/${this.dataType}/map-view/${row.original.id}`, state: { ...row.original, deliveryAreas: this.state.deliveryAreas } });
  };

  deleteDeliveryArea = (row, e) => {
    e.stopPropagation();
    MixPanel.track('Delivery Area Management - Delete delivery area clicked');
    AppDialogActionsWrapper.openConfirmationDialog({
      title: `${this.props.t('Delete delivery area')}?`,
      body: `${this.props.t('All data relating to the delivery area will be removed')}.`,
      confirmButtonText: this.props.t('Delete'),
      continueCallback: () => {
        DeliveryAreasApi.deleteDeliveryArea(row.original.id, this.dataType)
          .then(() => {
            this.setState({ showDeliveryAreas: false });
            this.loadDeliveryAreas();
          })
          .catch((error) => {
            toast.error(this.props.t('Oops something went wrong'));
            raygunClient.send(error, 'Error deleting da', { areaId: row.original.id });
            this.props.dispatchLoadingPage(false);
          });
      }
    });
  };

  getEditPermissionFeatureNameForDataType = () => {
    if (this.dataType === 'zip-code') {
      return 'manageZipCode';
    }

    return 'manageDeliveryArea';
  };

  getTableColumns = () => {
    const columns = [
      {
        Header: this.props.t('Name'),
        accessor: 'name', // accessor is the "key" in the data
        className: 'flex-3'
      },
      { accessor: 'integrationId' }
    ];

    if (AuthUtil.isFeatureEnabled(this.getEditPermissionFeatureNameForDataType())) {
      columns.push({
        Header: this.props.t('Actions'),
        id: 'actions',
        disableGlobalFilter: true,
        className: 'centered',
        Cell: ({ row }) => <TableActions type="card" row={row} onEdit={this.editDeliveryAreas} onDelete={this.deleteDeliveryArea} />
      });
    }

    return columns;
  };

  showDeliveryAreas = (deliveryAreaData) => {
    MixPanel.track('Delivery Areas Management - Delivery area selected', { deliveryArea: deliveryAreaData.name });
    this.setState({ showDeliveryAreas: true, clickedDeliveryAreaData: deliveryAreaData });
  };

  getAddButtonText = () => {
    if (this.dataType === 'zip-code') {
      return this.props.t('Add new zip code');
    }

    return this.props.t('Add new delivery area');
  };

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize !== pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  render() {
    return (
      <div className="delivery-areas-management-component">
        <div className="menu-wrapper">
          <div className="menu">
            {AuthUtil.isFeatureEnabled('manageDeliveryArea') && <Button text={this.getAddButtonText()} onClick={this.addDeliveryAreas} />}
            <div className="da-table-wrapper">
              {this.state.deliveryAreas && this.state.deliveryAreas.length > 0 && (
                <CardMenuTable
                  key={this.state.tablePageSize}
                  columns={this.getTableColumns()}
                  data={this.state.deliveryAreas}
                  sortBy={[{ id: 'name', desc: false }]}
                  showSearch
                  handleRowClick={this.showDeliveryAreas}
                  pageSize={this.state.tablePageSize}
                  calculateTablePageSize={this.calculateTablePageSize}
                  autoResetSelectedRows
                />
              )}
            </div>
          </div>
        </div>
        <div className="map-wrapper">
          {!this.state.showDeliveryAreas ? (
            <i className="empty-page icon icon-mily-logo" />
          ) : (
            <DeliveryAreaMapView
              deliveryAreas={this.state.deliveryAreas}
              deliveryAreaData={this.state.clickedDeliveryAreaData}
              editDisabled
              dataType={this.dataType}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

DeliveryAreasManagementPage.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withTranslation('translations')(connect(null, mapDispatchToProps)(DeliveryAreasManagementPage)));
