import { gql } from '@apollo/client';
import moment from 'moment';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';

const CourierAnalysisApi = {
  getStops: getStops,
  getRegion: getRegion
};

const GetStopsForCourier = gql`
  query GetStopsForCourier($courierId: String, $fromDate: String, $toDate: String) {
    getStopsForCourier(courierId: $courierId, fromDate: $fromDate, toDate: $toDate) {
      count
      stops {
        address
        dayOfTheWeek
        event
        geoDistance
        geoLat
        geoLng
        hour
        lat
        lng
        name
        numPackages
        region
        shipmentCodes
        timestamp
      }
      courierId
      fromDate
      toDate
      statistics {
        totalNumberOfStops
        totalNumberOfDeliveries
        totalNumberOfPickups
        region
      }
      courierGpsLocations {
        dayOfTheWeek
        gpsTimestamp
        hour
        lat
        lng
      }
      mileage
    }
  }
`;

const GetRegion = gql`
  query GetRegion($regionId: String) {
    getRegionById(regionId: $regionId) {
      regionId
      regionPolygon
    }
  }
`;

/**
 * Fetches stops of a courier
 *
 * @param {string} courierId - courier id
 * @param {Date} dateFrom - filter start date
 * @param {Date} dateTo - filter end date
 * @returns {Promise<Array.<object>> | null} Array of stops data
 * @async
 * @function
 * @memberOf CourierAnalysisApi
 * @alias getStops
 */
async function getStops(courierId, dateFrom, dateTo) {
  const startDate = moment(dateFrom).startOf('day').format('YYYY-MM-DDTHH');
  const endDate = moment(dateTo).endOf('day').format('YYYY-MM-DDTHH');

  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetStopsForCourier, { courierId: courierId, fromDate: startDate, toDate: endDate }).then(
      (res) => res?.data?.getStopsForCourier
    );
  }

  const startDate2 = moment(dateFrom).startOf('day').format('YYYY-MM-DD');
  const endDate2 = moment(dateTo).endOf('day').format('YYYY-MM-DD');

  if (startDate2 === endDate2) {
    // route analysis
    return fetch(`testData/analysis/courier-${courierId}-one.json`).then((data) => data.json());
  }
  return fetch(`testData/analysis/courier-${courierId}.json`).then((data) => data.json());
}

/**
 * Fetches region information
 *
 * @param {string} regionId - regionId id
 * @returns {Promise<object | any>} Region data
 * @async
 * @function
 * @memberOf CourierAnalysisApi
 * @alias getRegion
 */
async function getRegion(regionId) {
  if (!regionId) return {};

  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetRegion, { regionId: regionId }).then((res) => res.data.getRegionById);
  }

  return fetch(`testData/analysis/${regionId}.json`).then((data) => data.json());
}

export default CourierAnalysisApi;
