import { gql } from '@apollo/client';
import moment from 'moment';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';
import { appSyncClient } from '../../../../setup/appSyncClient';

export const AddressLabelingApi = {
  getAddressToLabel: getAddressToLabel,
  deleteUnsuccessfullyGeocodedAddress: deleteUnsuccessfullyGeocodedAddress,
  insertLabeledGeocodedAddress: insertLabeledGeocodedAddress,
  addLabelingDataToShipment: addLabelingDataToShipment,
  saveLabeledShipment: saveLabeledShipment
};

const GetUnsuccessfullyGeocodedAddress = gql`
  query GetUnsuccessfullyGeocodedAddress($hexId: String, $withoutBB: Boolean) {
    getUnsuccessfullyGeocodedAddress(pk: $hexId, withoutBB: $withoutBB) {
      hexId
      houseNumber
      streetName
      hexIds
      receiver
    }
  }
`;

const DeleteUnsuccessfullyGeocodedAddress = gql`
  mutation DeleteUnsuccessfullyGeocodedAddress($pk: String, $sk: String) {
    deleteUnsuccessfullyGeocodedAddress(pk: $pk, sk: $sk)
  }
`;

const InsertLabeledGeocodedAddress = gql`
  mutation InsertLabeledGeocodedAddress(
    $pk: String
    $sk: String
    $lat: Float
    $lng: Float
    $createdAtUtc: String
    $confidence: String
    $receiver: String
    $tenantId: String
  ) {
    insertLabeledGeocodedAddress(
      pk: $pk
      sk: $sk
      lat: $lat
      lng: $lng
      createdAtUtc: $createdAtUtc
      confidence: $confidence
      receiver: $receiver
      tenantId: $tenantId
    )
  }
`;

const AddLabelingDataToShipmentPickupAddress = gql`
  mutation AddLabelingDataToShipmentPickupAddress($pk: String, $sk: String, $lat: Float, $lng: Float) {
    upsertData(pk: $pk, sk: $sk, input: { labelingShipmentInputData: { gsi: { fuzzyPickupAddress: { geoLat: $lat, geoLng: $lng } } } })
  }
`;

const AddLabelingDataToShipmentDeliveryAddress = gql`
  mutation AddLabelingDataToShipmentDeliveryAddress($pk: String, $sk: String, $lat: Float, $lng: Float) {
    upsertData(pk: $pk, sk: $sk, input: { labelingShipmentInputData: { gsi: { fuzzyDeliveryAddress: { geoLat: $lat, geoLng: $lng } } } })
  }
`;

const SaveLabeledShipment = gql`
  mutation SaveLabeledShipment(
    $pk: String
    $sk: String
    $lat: Float
    $lng: Float
    $shipmentCode: String
    $region: String
    $deliveryType: String
    $address: String
    $date: String
  ) {
    upsertData(
      pk: $pk
      sk: $sk
      input: {
        labeledShipmentInputData: {
          geoLat: $lat
          geoLng: $lng
          shipmentCode: $shipmentCode
          region: $region
          deliveryType: $deliveryType
          address: $address
          date: $date
        }
      }
    )
  }
`;

async function getAddressToLabel(hexId) {
  return ApiHelpers.createQuery(GetUnsuccessfullyGeocodedAddress, { hexId: hexId, withoutBB: true }).then((res) => res.data.getUnsuccessfullyGeocodedAddress);
}

async function deleteUnsuccessfullyGeocodedAddress(hexId, streetName, houseNumber, receiver) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: DeleteUnsuccessfullyGeocodedAddress,
      variables: {
        pk: hexId,
        sk: `${streetName}#${houseNumber}${receiver ? `#${receiver}` : ''}`
      }
    });
  }

  return null;
}

async function insertLabeledGeocodedAddress(streetName, houseNumber, receiver, hexId, coordinates, tenantId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: InsertLabeledGeocodedAddress,
      variables: {
        pk: `${streetName}#${houseNumber}`,
        sk: `${hexId}${receiver ? `#${receiver}` : ''}`,
        createdAtUtc: moment().utc().format('YYYY-MM-DD HH-mm-ss'),
        confidence: 'APP_LABELED',
        lat: parseFloat(coordinates.lat),
        lng: parseFloat(coordinates.lng),
        receiver: receiver,
        tenantId: tenantId
      }
    });
  }

  return null;
}

function getLabelingMutation(deliveryType) {
  return deliveryType === 'delivery' ? AddLabelingDataToShipmentDeliveryAddress : AddLabelingDataToShipmentPickupAddress;
}

async function addLabelingDataToShipment(shipmentCode, deliveryType, lat, lng) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: getLabelingMutation(deliveryType),
      variables: {
        pk: DynamoStringExtensionsUtil.addShipmentPrefix(shipmentCode),
        sk: DynamoStringExtensionsUtil.addShipmentPrefix(shipmentCode),
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }
    });
  }

  return null;
}

async function saveLabeledShipment(shipmentCode, deliveryType, lat, lng, momentDate, region, address) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: SaveLabeledShipment,
      variables: {
        pk: DynamoStringExtensionsUtil.addLabeledShipmentPrefix(momentDate.format('YYYY-MM-DD')),
        sk: `${DynamoStringExtensionsUtil.addLabeledShipmentPrefix(momentDate.format('YYYY-MM-DD HH:mm:ss'))}#${shipmentCode}`,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        deliveryType: deliveryType,
        shipmentCode: shipmentCode,
        region: region,
        date: momentDate.format('YYYY-MM-DD HH:mm:ss'),
        address: address
      }
    });
  }

  return null;
}
