import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Select from 'react-select';
import DefaultSelectStyleUtil from './util/defaultSelectStyleUtil';

export default class CustomSelect extends React.Component {
  static customMenu = (props, scrollBarRef) => (
    <SimpleBar autoHide={false} style={{ maxHeight: 300 }} ref={scrollBarRef}>
      {props.children}
    </SimpleBar>
  );

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.scrollBarRef = React.createRef();
  }

  handleKeyDown = () => {
    // Timeout is hack stolen from github https://github.com/JedWatson/react-select/issues/780
    setTimeout(() => {
      this.checkFocusedOptionPosition();
    }, 0);
  };

  handleOpenMenu = () => {
    // Timeout is hack stolen from github
    setTimeout(() => {
      this.checkFocusedOptionPosition();
    }, 0);
  };

  checkFocusedOptionPosition = () => {
    const focusedOptionRef = this.selectRef?.current?.focusedOptionRef;
    if (focusedOptionRef) {
      const focusedStart = focusedOptionRef.getBoundingClientRect().top;
      const focusedEnd = focusedStart + focusedOptionRef.getBoundingClientRect().height;

      const scrollEl = this.scrollBarRef.current.getScrollElement();
      const scrollElStart = scrollEl.getBoundingClientRect().top;
      const scrollElEnd = scrollElStart + scrollEl.getBoundingClientRect().height;

      if (focusedStart < scrollElStart) {
        scrollEl.scrollTo(0, scrollEl.scrollTop - (scrollElStart - focusedStart));
      } else if (focusedEnd > scrollElEnd) {
        scrollEl.scrollTop += focusedEnd - scrollElEnd;
      }
    }
  };

  render() {
    return (
      <Select
        ref={this.selectRef}
        styles={DefaultSelectStyleUtil.selectStyle}
        components={{ MenuList: (props) => CustomSelect.customMenu(props, this.scrollBarRef) }}
        onKeyDown={this.handleKeyDown}
        onMenuOpen={this.handleOpenMenu}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...this.props}
      />
    );
  }
}
