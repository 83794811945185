import React from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import moment from 'moment';
import './RangeDayPicker.scss';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as RegionAnalysisActions from '../../../../state/actions/regionAnalysisActions';

import 'moment/locale/sr';
import MixPanel from '../../../../setup/mixPanel';

/**
 * RangeDayPicker enables picking a range of dates
 *
 * @component
 * @alias RangeDayPicker
 * @category RegionAnalysis
 */
class RangeDayPickerClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDayPicker: false,
      fromDate: props.regionsDateFrom,
      toDate: props.regionsDateTo
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.regionsDateFrom !== this.props.regionsDateFrom || prevProps.regionsDateTo !== this.props.regionsDateTo) {
      // hack to trick linter
      this.updateDates();
    }
  }

  updateDates = () => {
    this.setState({
      fromDate: this.props.regionsDateFrom,
      toDate: this.props.regionsDateTo
    });
  };

  /**
   * Sets fromDate to a provided date.
   *
   * @param {Date} date -  fromDate
   */
  handleFromDateChange = (date) => {
    if (date > this.state.toDate) {
      return;
    }

    this.setState({ fromDate: date });
  };

  /**
   * Sets toDate to a provided date.
   *
   * @param {Date} date - toDate
   */
  handleToDateChange = (date) => {
    if (date < this.state.fromDate) {
      return;
    }

    this.setState({ toDate: date });
  };

  /**
   * Toggles visibility of RangeDayPicker
   */
  toggleDayPicker = () => {
    this.setState((prevState) => ({ showDayPicker: !prevState.showDayPicker }));
    if (this.props.togglePicker) {
      this.props.togglePicker(!this.state.showDayPicker);
    }
  };

  /**
   * Handles cancellation of RangdeDayPicker.<br/>
   * It resets date range to previous date range.
   */
  onCancel = () => {
    this.setState({
      showDayPicker: false,
      fromDate: this.props.regionsDateFrom,
      toDate: this.props.regionsDateTo
    });
    if (this.props.togglePicker) {
      this.props.togglePicker(!this.state.showDayPicker);
    }
  };

  /**
   * Dispatches event to fetch regions by new provided date range
   */
  changeDates = () => {
    this.setState({ showDayPicker: false });
    MixPanel.track(`${this.props.eventTrackerNamePrefix} - Date changed`);
    this.props.dispatchChangeDateRange(this.state.fromDate, this.state.toDate);
    if (this.props.togglePicker) {
      this.props.togglePicker(!this.state.showDayPicker);
    }
  };

  render() {
    return (
      <div className="range-picker-wrapper">
        <div className="date-picker-input">
          <div className="dates-wrapper calendar-hover">
            <i className="icon icon-event" onClick={this.toggleDayPicker} />
            <div className="dates" onClick={this.toggleDayPicker}>
              {moment(this.state.fromDate).format('DD/MM/YYYY')}
              {' '}
              –
              {moment(this.state.toDate).format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
        {this.state.showDayPicker && (
          <div className="picker-wrapper">
            <div className="calendar-wrapper">
              <div className="input-wrapper">
                <div className="calendar-label">
                  {this.props.t('Choose start date')}
                  :
                </div>
                <DayPicker
                  className="start-date"
                  firstDayOfWeek={1}
                  selectedDays={this.state.fromDate}
                  showWeekNumbers
                  showOutsideDays
                  onDayClick={this.handleFromDateChange}
                  format="DD/MM/YYYY"
                  formatDate={formatDate}
                  placeholder="DD/MM/YYYY"
                  month={this.state.fromDate}
                  disabledDays={[{ after: this.state.toDate }]}
                  locale={this.props.language}
                  localeUtils={MomentLocaleUtils}
                />
              </div>
              <div className="input-wrapper">
                <div className="calendar-label">
                  {this.props.t('Choose end date')}
                  :
                </div>
                <DayPicker
                  firstDayOfWeek={1}
                  selectedDays={this.state.toDate}
                  showWeekNumbers
                  showOutsideDays
                  onDayClick={this.handleToDateChange}
                  format="DD/MM/YYYY"
                  formatDate={formatDate}
                  placeholder="DD/MM/YYYY"
                  month={this.state.toDate}
                  disabledDays={[{ before: this.state.fromDate, after: new Date() }]}
                  locale={this.props.language}
                  localeUtils={MomentLocaleUtils}
                />
              </div>
            </div>
            <div className="calendar-footer">
              <div className="button cancel-button" onClick={this.onCancel}>
                {this.props.t('cancel')}
              </div>
              <div className="button" onClick={this.changeDates}>
                {this.props.t('apply')}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.regionAnalysisState, ...store.languageState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchChangeDateRange: RegionAnalysisActions.changeDateRange },
    dispatch
  );
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RangeDayPickerClass));

RangeDayPickerClass.propTypes = {
  /**
   * Translation function
   */
  t: PropTypes.func.isRequired,
  /**
   * A start date from which regions should be fetched
   */
  regionsDateFrom: PropTypes.instanceOf(Date).isRequired,
  /**
   * An end date to which regions should be fetched
   */
  regionsDateTo: PropTypes.instanceOf(Date).isRequired,
  /**
   * Function that dispatches event when date range changes
   */
  dispatchChangeDateRange: PropTypes.func.isRequired,
  /**
   * Function that toggles visibility of RangeDayPicker
   */
  togglePicker: PropTypes.func,
  /**
   * Current language used for translation
   */
  language: PropTypes.string,
  eventTrackerNamePrefix: PropTypes.string
};

RangeDayPickerClass.defaultProps = {
  language: 'sr',
  togglePicker: null,
  eventTrackerNamePrefix: null
};
