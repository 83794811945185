import { useAuthenticator } from '@aws-amplify/ui-react';
import React from 'react';
import LanguageSelection from '../../common/components/selections/languageSelection/LanguageSelection';

/**
 * Component which renders overridden SignIn Toolbar from aws-amplify-react
 *
 * @alias SignInToolbar
 * @augments SignIn
 * @component
 * @category App
 */
function SignInToolbar() {
  const { route } = useAuthenticator((context) => [context.route]);

  const hideToolbar = route !== 'signIn' && route !== 'forgotPassword' && route !== 'requireNewPassword' && route !== 'resetPassword';

  return (
    <div className="signin-toolbar" style={{ display: hideToolbar ? 'none' : 'flex' }}>
      <div className="name-section">
        <div className="logo">
          <i className="icon icon-mily-logo" />
        </div>
        <div className="name">
          <b>Mily</b>
          {' '}
          Technologies
        </div>
      </div>
      <LanguageSelection menuVertical />
    </div>
  );
}

export default SignInToolbar;
