import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { formatDate } from 'react-day-picker/moment';
import moment from 'moment';
import './DayPickerWrapper.scss';
import { withTranslation } from 'react-i18next';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from '../../constants/dayMonthsNames';

/**
 * Component for picking a day
 *
 * @component
 * @alias DayPickerWrapper
 * @category RouteAnalysis
 */
class DayPickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverRange: undefined,
      selectedDay: props.selectedDay,
      showDayPicker: false,
      MONTHS: MONTHS.map((m) => this.props.t(m)),
      WEEKDAYS_LONG: WEEKDAYS_LONG.map((m) => this.props.t(m)),
      WEEKDAYS_SHORT: WEEKDAYS_SHORT.map((m) => this.props.t(m))
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this.handleLanguageChange();
    }
  }

  handleLanguageChange = () => {
    this.setState({
      MONTHS: MONTHS.map((m) => this.props.t(m)),
      WEEKDAYS_LONG: WEEKDAYS_LONG.map((m) => this.props.t(m)),
      WEEKDAYS_SHORT: WEEKDAYS_SHORT.map((m) => this.props.t(m))
    });
  };

  /**
   * Handles day change and dispatches event with the new date
   *
   * @param {Date} date - new selected day
   * @param {boolean} disabled - if the date is disabled
   * @function
   */
  handleDayChange = (date, { disabled }) => {
    if (disabled) {
      return;
    }

    this.setState({
      selectedDay: date,
      showDayPicker: false
    });

    if (this.state.showDayPicker) {
      this.props.onDateChange(date);
    }
  };

  /**
   * Handles which dates should highlighted in the hover event
   *
   * @param {Date} date - Date which is hovered on
   * @function
   */
  handleDayEnter = (date) => {
    this.setState({ hoverRange: { from: date, to: date } });
  };

  /**
   * Removes hover effect from hovered dates
   *
   * @function
   */
  handleDayLeave = () => {
    this.setState({ hoverRange: undefined });
  };

  /**
   * Toggles visibility of the day picker
   *
   * @function
   */
  toggleDayPicker = () => {
    this.setState((prevState) => ({ showDayPicker: !prevState.showDayPicker }));
  };

  /**
   * Handles day change to a previous day
   *
   * @function
   */
  prevDay = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const date = moment(this.state.selectedDay).subtract(1, 'day').toDate();
    this.setState({ selectedDay: date });

    this.props.onDateChange(date);
  };

  /**
   * Handles day change to a next day
   *
   * @function
   */
  nextDay = () => {
    if (this.props.disableFutureDays && moment(this.state.selectedDay).isSameOrAfter(moment(), 'date')) return;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const date = moment(this.state.selectedDay).add(1, 'day').toDate();
    this.setState({ selectedDay: date });

    this.props.onDateChange(date);
  };

  render() {
    const { hoverRange, selectedDay } = this.state;

    const modifiers = {
      hoverRange,
      highlighted: moment().subtract(1, 'day').toDate(),
      selectedDay
    };

    return (
      <div className="date-picker-wrapper">
        <div className="date-picker-input">
          <i className="icon icon-keyboard-arrow-left calendar-hover" onClick={this.prevDay} />

          <div className="dates-wrapper calendar-hover">
            <i className="icon icon-event" onClick={this.toggleDayPicker} />
            <div className="dates" onClick={this.toggleDayPicker}>
              {moment(selectedDay).format('DD/MM/YYYY')}
            </div>
          </div>
          <i className="icon icon-keyboard-arrow-right calendar-hover" onClick={this.nextDay} />
        </div>
        {this.state.showDayPicker && (
          <div className="picker-wrapper">
            <DayPicker
              firstDayOfWeek={1}
              selectedDays={selectedDay}
              showWeekNumbers
              showOutsideDays
              modifiers={modifiers}
              onDayClick={this.handleDayChange}
              onDayMouseEnter={this.handleDayEnter}
              onDayMouseLeave={this.handleDayLeave}
              format="DD/MM/YYYY"
              formatDate={formatDate}
              placeholder="DD/MM/YYYY"
              month={selectedDay}
              months={this.state.MONTHS}
              weekdaysLong={this.state.WEEKDAYS_LONG}
              weekdaysShort={this.state.WEEKDAYS_SHORT}
              disabledDays={this.props.disableFutureDays ? [{ after: moment().toDate() }] : []}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translations')(DayPickerWrapper);

DayPickerWrapper.propTypes = {
  /**
   * Translation function
   */
  t: PropTypes.func.isRequired,
  /**
   * Function that dispatches event that date was changed
   */
  onDateChange: PropTypes.func.isRequired,
  /**
   * Day we are displaying
   */
  selectedDay: PropTypes.instanceOf(Date).isRequired,
  disableFutureDays: PropTypes.bool
};

DayPickerWrapper.defaultProps = { disableFutureDays: true };
