import rg4js from 'raygun4js';

class RaygunClient {
  constructor() {
    rg4js('apiKey', process.env.REACT_APP_RAYGUN_API_KEY);
    rg4js('enableCrashReporting', true);
    rg4js('logContentsOfXhrCalls', true);
    rg4js('options', { debugMode: process.env.REACT_APP_RAYGUN_DEBUG_MODE === 'true' });

    this.raygun = rg4js;
  }

  send(error, msg, additionalData) {
    let err = error;
    if (typeof error === 'string') {
      err = new Error(error);
    }

    if (msg) {
      this.raygun('withCustomData', { ...this.customUserData, errorMsg: msg, ...additionalData });
    }

    if (process.env.REACT_APP_RAYGUN_API_KEY) {
      this.raygun('send', err);
    } else {
      console.log(error);
      console.log(msg);
    }
  }

  setUser(userData, userCustomData) {
    this.raygun('setUser', userData);
    this.customUserData = userCustomData;
    this.raygun('withCustomData', { userCustomData: userCustomData });
  }
}

export const raygunClient = new RaygunClient();
