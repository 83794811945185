import { toast } from 'react-toastify';
import * as h3 from 'h3-js';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { MapMarkerUtil } from '../../../../common/components/marker/util/mapMarkerUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import RegionAnalysisApi from '../api/regionAnalysisApi';
import HexTypes from '../constants/hexTypes';
import { GAS_STATION_TYPES, MAP_ADDITIONAL_FEATURES_TYPES } from '../constants/mapAdditionalFeaturesData';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import MixPanel from '../../../../setup/mixPanel';
import MapMarkerPopup from '../components/MapMarkerPopup';

/**
 * Util for showing package lockers in a map based on the mapView type.
 * If heatmap view, show package lockers, if not, remove them.
 *
 * @category RegionAnalysis
 * @alias PackageLockerUtil
 */
export default class PackageLockerUtilClass {
  /**
   * @class
   * @param {mapboxgl.Map} map - an initialized map
   * @param t Translation method
   */
  constructor(map, t) {
    this.map = map;
    this.t = t;
    this.mapMarkers = {};
    this.perHexTotals = null;
    this.fetchedIsochronePolygons = {};
  }

  updateMapFeature = (key, visible, hexType, center, dateFrom, dateTo, perHexTotals) => {
    this.removeMapMarkers(key);
    if (perHexTotals) this.perHexTotals = perHexTotals;

    if (visible) {
      this.plotMapFeatures(key, hexType, center, dateFrom, dateTo);
    }
  };

  /**
   * Fetch package lockers and plot them on map
   *
   * @param {string} hexType - map view hexagon type
   * @param {string} center - current center
   * @param {Date} dateFrom - start date for fetching stops
   * @param {Date} dateTo - end date for fetching stops
   * @returns {Promise<object>} - plotted package lockers
   * @memberOf PackageLockerUtil
   */
  plotPackageLockers = async (hexType, center, dateFrom, dateTo) => {
    RegionAnalysisApi.getPackageLockers(center)
      .then((packageLockersRes) => {
        const packageLockers = packageLockersRes?.data?.getPackageLockers?.packageLockers;
        if (packageLockers && packageLockers.length > 0) {
          return Promise.all(
            packageLockers.map((packageLocker) => {
              if (hexType === HexTypes.HEX_TYPE.STOPS) {
                return RegionAnalysisApi.getStops(packageLocker.id, dateFrom, dateTo)
                  .then((res) => {
                    this.plotPackageLockerIcon(packageLocker, res?.statistics?.totalNumberOfStops || 0, hexType);

                    return res;
                  })
                  .catch((error) => {
                    raygunClient.send(error, 'Error loading package lockers stops', { hexType, center, dateFrom, dateTo });
                    toast.error(this.t('Oops something went wrong'));
                  });
              }

              this.plotPackageLockerIcon(packageLocker, null, hexType);

              return packageLocker;
            })
          );
        }

        return null;
      })
      .catch((error) => {
        raygunClient.send(error, 'Error loading package lockers', { hexType, center, dateFrom, dateTo });
        toast.error(this.t('Oops something went wrong'));
      });

    return null;
  };

  plotMapFeatures = async (type, hexType, center, dateFrom, dateTo) => {
    switch (type) {
      case MAP_ADDITIONAL_FEATURES_TYPES.PACKAGE_LOCKERS:
        if (center) {
          const plCenter = `${center.slice(0, 2)}-BX`;

          this.plotPackageLockers(hexType, plCenter, dateFrom, dateTo);
        }
        break;
      case MAP_ADDITIONAL_FEATURES_TYPES.GAS_STATION:
        GAS_STATION_TYPES.forEach((key) => {
          fetch(`mapData/${key}.geojson`)
            .then((data) => data.json())
            .then((mapGeojson) => {
              mapGeojson.features.forEach(async (feature, index) => {
                this.plotMapFeature(index, feature, type, key, hexType);
              });
            });
        });
        break;
      case MAP_ADDITIONAL_FEATURES_TYPES.ISOCHRONE_PIN:
        break;
      default:
        fetch(`mapData/${type}.geojson`)
          .then((data) => data.json())
          .then((mapGeojson) => {
            mapGeojson.features.forEach(async (feature, index) => {
              this.plotMapFeature(index, feature, type, type, hexType);
            });
          });
    }
  };

  plotPackageLockerIcon = async (packageLocker, additionalNumber, hexType) => {
    const feature = {
      geometry: {
        coordinates: [packageLocker.lng, packageLocker.lat],
        type: 'Point'
      },
      properties: { name: packageLocker.name }
    };

    const marker = MapMarkerUtil.addMarkerToMap(
      this.map,
      feature.geometry.coordinates,
      {
        id: `${packageLocker.id}-packageLockerIcon_logo`,
        backgroundImageUrl: '/mapData/packageLockerIcon.svg',
        additionalNumber: additionalNumber ? `${additionalNumber}` : null,
        onClick: () => {
          this.toggleIsochrone(packageLocker.lat, packageLocker.lng);
          MixPanel.track('Region analysis page - Isochrone toggled', { entityName: packageLocker.name, type: 'packageLockers' });
        }
      },
      feature.properties,
      async () => this.getOnHoverPopup(feature, 'package locker', hexType)
    );

    this.mapMarkers.packageLockers.push(marker);
  };

  plotMapFeature = (index, feature, type, key, hexType, disableIsochrone = false) => {
    const marker = MapMarkerUtil.addMarkerToMap(
      this.map,
      feature.geometry.coordinates,
      {
        id: `${index}-${key}`,
        backgroundImageUrl: `/mapData/${key}_logo.svg`,
        cssClass: key === 'shopsToRemove' ? 'less-opacity' : null,
        onClick: disableIsochrone
          ? null
          : () => {
            this.toggleIsochrone(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
            MixPanel.track('Region analysis page - Isochrone toggled', { entityName: feature.properties.name, type: type });
          }
      },
      feature.properties
      // async () => this.getOnHoverPopup(feature, type, hexType)
    );

    this.mapMarkers[type].push(marker);
  };

  getOnHoverPopup = async (feature, type, hexType) => {
    const updatedFeature = await this.addIsochroneEntitiesPerHexagonOnFeature(feature, type, hexType);
    const placeholder = document.createElement('div');
    ReactDOM.render(<MapMarkerPopup data={updatedFeature.properties} />, placeholder);
    return new mapboxgl.Popup({ maxWidth: '500px', offset: 8 }).setDOMContent(placeholder);
  };

  removeMapMarkers = (key) => {
    if (this.mapMarkers[key] && this.mapMarkers[key].length > 0) {
      this.mapMarkers[key].forEach((marker) => {
        marker.remove();
        this.removeIsochrone(marker._lngLat.lat, marker._lngLat.lng);
      });
    }
    this.mapMarkers[key] = [];
  };

  toggleIsochrone = async (lat, lng) => {
    const isochroneRemoved = this.removeIsochrone(lat, lng);
    if (!isochroneRemoved) this.addIsochronePolygonToMap(lat, lng);
  };

  addIsochronePolygonToMap = async (lat, lng) => {
    const isochronePolygon = await this.getIsochronePolygon(lat, lng);
    if (isochronePolygon) {
      const sourceId = `isochrone-${lat}-${lng}`;
      this.map.addSource(sourceId, { type: 'geojson', data: isochronePolygon });

      this.map.addLayer({
        id: `polygon-layer-${sourceId}`,
        type: 'fill',
        source: sourceId,
        paint: {
          'fill-color': colorsAndFonts.selection_color,
          'fill-opacity': 0.3
        }
      });

      this.map.addLayer({
        id: `outline-layer-${sourceId}`,
        type: 'line',
        source: sourceId,
        layout: {},
        paint: {
          'line-color': colorsAndFonts.selection_color,
          'line-width': 1
        }
      });
    }
  };

  removeIsochrone = (lat, lng) => {
    const sourceId = `isochrone-${lat}-${lng}`;
    if (this.map.getSource(sourceId)) {
      this.map.removeLayer(`polygon-layer-${sourceId}`);
      this.map.removeLayer(`outline-layer-${sourceId}`);
      this.map.removeSource(sourceId);
      return sourceId;
    }
    return null;
  };

  addIsochroneEntitiesPerHexagonOnFeature = async (feature, entity, hexType) => {
    const updatedFeature = feature;
    if (this.perHexTotals) {
      const entitiesCoveredSum = await this.getIsochroneEntitiesCoveredCount(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
      if (entitiesCoveredSum > 0) {
        updatedFeature.properties.isochroneCoveredEntities = {
          count: entitiesCoveredSum,
          entity: entity,
          hexType: hexType
        };
      }
    }
    return updatedFeature;
  };

  getIsochroneEntitiesCoveredCount = async (lat, lng) => {
    let entitiesPerHexSum = 0;
    if (this.perHexTotals) {
      const isochronePolygon = await this.getIsochronePolygon(lat, lng);
      if (isochronePolygon) {
        const hexagonsCovered = h3.polyfill(isochronePolygon.features[0].geometry.coordinates, 10, true);
        hexagonsCovered.forEach((hex) => {
          if (this.perHexTotals[hex]) {
            entitiesPerHexSum += this.perHexTotals[hex];
          }
        });
      }
    }
    return entitiesPerHexSum;
  };

  getIsochronePolygon = async (lat, lng) => {
    if (this.fetchedIsochronePolygons[this.createIdFromLatLng(lat, lng)]) {
      return this.fetchedIsochronePolygons[this.createIdFromLatLng(lat, lng)];
    }
    const isochronePolygon = await RegionAnalysisApi.callIsochronePolygonAPI(lat, lng);
    if (isochronePolygon) this.fetchedIsochronePolygons[this.createIdFromLatLng(lat, lng)] = isochronePolygon?.data;
    return isochronePolygon?.data;
  };

  createIdFromLatLng = (lat, lng) => `${Number.parseFloat(lat).toFixed(4)}#${Number.parseFloat(lng).toFixed(4)}`;
}
