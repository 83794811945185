import React from 'react';

export class ReactChildrenUtils {
  static recursiveChildrenMap(children, processChildCallback) {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }
      if (child.props.children) {
        // eslint-disable-next-line no-param-reassign
        child = React.cloneElement(child, { children: ReactChildrenUtils.recursiveChildrenMap(child.props.children, processChildCallback) });
      }
      return processChildCallback(child);
    });
  }

  static recursiveChildrenForEach(children, processChildCallback) {
    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }
      if (child.props.children) {
        ReactChildrenUtils.recursiveChildrenForEach(child.props.children, processChildCallback);
      }
      return processChildCallback(child);
    });
  }
}
