import { gql } from '@apollo/client';
import { API } from 'aws-amplify';
import ApiHelpers from '../../../common/utils/api/apiHelpers';
import BackendResourceConfigUtil from '../../../common/utils/api/backendResourceConfigUtil';
import DynamoStringExtensionsUtil from '../../../common/utils/api/dynamoStringExtensionsUtil';
import { appSyncClient } from '../../../setup/appSyncClient';
import MixPanel from '../../../setup/mixPanel';
import CouriersApi from '../../management/couriers/api/couriersApi';
import ShipmentsApi from '../../management/shipments/api/shipmentsApi';
import AuthUtil from '../../../common/utils/authUtil';

const PlanningApi = {
  invokeShipmentsEnrichSM: invokeShipmentsEnrichSM,
  deletePlan: deletePlan,
  assignPlanToCourier: assignPlanToCourier,
  getPlans: getPlans,
  sendPlanToCourier: sendPlanToCourier,
  generateHexMapping: generateHexMapping,
  getCouriers: getCouriers,
  invokeRouteOptimizationSM: invokeRouteOptimizationSM,
  triggerClustering: triggerClustering,
  getAssignedShipments: getAssignedShipments,
  getShipmentDbData: getShipmentDbData,
  sendClientMessagesForPlan: sendClientMessagesForPlan,
  getCouriersPlans: getCouriersPlans,
  labelShipmentAddress: labelShipmentAddress
};

const LabelShipmentAddress = gql`
  mutation LabelShipmentAddress(
    $regionId: String
    $lat: Float
    $lng: Float
    $streetName: String
    $houseNumber: String
    $receiverName: String
    $confidence: String
    $tenantId: String
  ) {
    labelShipmentAddress(
      regionId: $regionId
      lat: $lat
      lng: $lng
      streetName: $streetName
      houseNumber: $houseNumber
      receiverName: $receiverName
      confidence: $confidence
      tenantId: $tenantId
    )
  }
`;

function labelShipmentAddress(streetName, houseNumber, receiverName, regionId, lat, lng) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: LabelShipmentAddress,
      variables: {
        regionId: regionId,
        lat: lat,
        lng: lng,
        streetName: streetName,
        houseNumber: houseNumber,
        receiverName: houseNumber === 'bb' ? receiverName : null,
        confidence: 'PLANNING_LABELED',
        tenantId: AuthUtil.getTenantId()
      }
    });
  }

  return null;
}

const baseShipmentFields = ['name', 'address', 'shipmentCode', 'phone'];

const InvokeShipmentsEnrich = gql`
  query InvokeShipmentsEnrich($arn: String, $fileName: String, $bucketName: String) {
    invokeSM(arn: $arn, data: { shipmentEnrich: { bucketName: $bucketName, fileName: $fileName } }) {
      status
    }
  }
`;

function invokeShipmentsEnrichSM(arn, fileName, bucketName) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(InvokeShipmentsEnrich, { arn: arn, fileName: fileName, bucketName: bucketName });
  }

  return null;
}

const DeletePlan = gql`
  mutation DeletePlan($pk: String, $sk: String) {
    deleteData(pk: $pk, sk: $sk)
  }
`;

async function deletePlan(date, teamId, courierId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: DeletePlan,
      variables: { pk: DynamoStringExtensionsUtil.addCourierPlanPrefix(date), sk: DynamoStringExtensionsUtil.addCourierPlanPrefix(`${teamId}#${courierId}`) }
    });
  }

  return null;
}

/**
 * Assign courier to plan
 *
 * @param {string} date - format YYYY-MM-DD
 * @param {string} center
 * @param {string} region
 * @param {string} courierId
 * @returns {Promise} courier data
 * @async
 * @function
 * @memberOf PlanningApi
 * @alias assignPlanToCourier
 * @example
 * {
 *   Attributes: {
      FileFolder: "dexpress-develop-planning"
      OptimizedRouteJson: "Centar1#BG-1_optimized_route.json"
      OrderedShipmentsCsv: "Centar1#BG-1_ordered_shipments.csv"
      Pk: "o#2021-03-24"
      Sk: "Centar1#BG-1"
      Ssk: "vesna.katanic@gmail.com"
    },
    ResponseMetadata: {
      HTTPHeaders: {server: "Server", date: "Wed, 24 Mar 2021 11:56:29 GMT", content-type: "application/x-amz-json-1.0", content-length: "281", connection: "keep-alive", …}
      HTTPStatusCode: 200
      RequestId: "9A3JMGQV7R7EJNUCT8N3FH3M4BVV4KQNSO5AEMVJF66Q9ASUAAJG"
      RetryAttempts: 0
    }
 * }
 */
async function assignPlanToCourier(date, center, region, courierId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const apiName = BackendResourceConfigUtil.getApiName();
    const path = `${BackendResourceConfigUtil.getApiPath('plans')}/${date}/${center}/${region}`;
    const params = {
      body: { courierId: courierId },
      headers: await ApiHelpers.getHeader()
    };

    MixPanel.track('Assign plan to courier API called', { type: 'plan' });
    return API.put(apiName, path, params);
  }

  return {};
}

/**
 * Fetches plans
 *
 * @param {string} center - filter center
 * @param {string} date - filter start date format YYYY-MM-DD
 * @returns {Promise<Array.<object>> | null} Array of plans
 * @async
 * @function
 * @memberOf PlanningApi
 * @alias getPlans
 * @example
 * [{
 FileFolder: "dexpress-playground-planning"
 OptimizedRouteJson: "Centar1-BG-1_optimized_route.json"
 OrderedShipmentsCsv: "Centar1-BG-1_ordered_shipments.csv"
 Pk: "o#2021-03-16"
 Sk: "Centar1-BG-1"
 Ssk: "vesna.katanic+2@gmail.com"
 }]
 */
async function getPlans(center, date) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const apiName = BackendResourceConfigUtil.getApiName();
    const path = `${BackendResourceConfigUtil.getApiPath('plans')}/${date}/${center}`;
    const params = { headers: await ApiHelpers.getHeader() };

    MixPanel.track('Plans API called', { type: 'plan' });
    return API.get(apiName, path, params);
  }

  return fetch('/testData/planning/plans.json').then((data) => data.json());
}

/**
 * Sends email to a courier with a next delivery plan
 *
 * @param {string} courierId - courier id
 * @param {string} date - format YYYY-MM-DD
 * @param {string} center
 * @param {string} region
 * @returns {object} operation information
 * @async
 * @function
 * @memberOf PlanningApi
 * @alias sendPlanToCourier
 * @example
 * {
    "ResponseMetadata": {
        "RequestId": "fd882dcf-71ce-4fb8-93eb-4915e906603e",
        "HTTPStatusCode": 200,
        "HTTPHeaders": {
            "date": "Wed, 24 Feb 2021 21:45:13 GMT",
            "content-type": "application/x-amz-json-1.1",
            "content-length": "2",
            "connection": "keep-alive",
            "x-amzn-requestid": "fd882dcf-71ce-4fb8-93eb-4915e906603e"
        },
        "RetryAttempts": 0
    }
}
 */
async function sendPlanToCourier(courierId, date, center, region) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const apiName = BackendResourceConfigUtil.getApiName();
    const path = `${BackendResourceConfigUtil.getApiPath('couriers')}/${courierId}/plan/${date}/${center}/${region}`;
    const params = { headers: await ApiHelpers.getHeader() };

    return API.get(apiName, path, params);
  }
  return {};
}

const GenerateHexMappings = gql`
  query generateHexMappings($regionHexFile: String) {
    generateHexMappings(regionHexFile: $regionHexFile)
  }
`;

async function generateHexMapping(fileName) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    MixPanel.track('generateHexMapping API called', { regionHexFile: fileName });

    return ApiHelpers.createQuery(GenerateHexMappings, { regionHexFile: fileName });
  }

  return true;
}

async function getCouriers(teamId = null, isActive = false) {
  return CouriersApi.getCouriers(teamId, isActive);
}

const InvokeRouteOptimization = gql`
  query InvokeRouteOptimization(
    $arn: String
    $fileKey: String
    $bucketName: String
    $h3Resolution: String
    $teamId: String
    $planDate: String
    $startStorage: StorageInputData
    $endStorage: StorageInputData
    $departureTime: String
    $regionHexagonsFileKey: String
    $courierFullName: String
  ) {
    invokeSM(
      arn: $arn
      data: {
        routeOptimization: {
          bucketName: $bucketName
          fileKey: $fileKey
          h3Resolution: $h3Resolution
          teamId: $teamId
          planDate: $planDate
          startStorage: $startStorage
          endStorage: $endStorage
          departureTime: $departureTime
          regionHexagonsFileKey: $regionHexagonsFileKey
          courierFullName: $courierFullName
        }
      }
    ) {
      status
    }
  }
`;

function invokeRouteOptimizationSM(
  shipmentsFileName,
  h3Resolution,
  teamId,
  planDate,
  startStorage,
  endStorage,
  departureTime,
  regionHexagonsFileKey,
  courierFullName
) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(InvokeRouteOptimization, {
      arn: BackendResourceConfigUtil.getStateMachineArn('StateMachine-Route-Optimization-1'),
      bucketName: BackendResourceConfigUtil.getPlanningBucketName(),
      fileKey: shipmentsFileName,
      h3Resolution: h3Resolution,
      teamId: teamId,
      planDate: planDate,
      startStorage: startStorage,
      endStorage: endStorage,
      departureTime: departureTime,
      regionHexagonsFileKey: regionHexagonsFileKey,
      courierFullName: courierFullName
    });
  }

  return null;
}

const TriggerClustering = gql`
  query TriggerClustering($arn: String, $stopsFileKey: String, $outFileKey: String, $bucketName: String, $clustersNum: Int, $separator: String) {
    invokeSM(
      arn: $arn
      data: { clustering: { bucketName: $bucketName, stopsFileKey: $stopsFileKey, outFileKey: $outFileKey, clustersNum: $clustersNum, separator: $separator } }
    ) {
      status
    }
  }
`;

function triggerClustering(stopsFileKey, outFileKey, clustersNum, separator) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(TriggerClustering, {
      arn: BackendResourceConfigUtil.getStateMachineArn('Clustering'),
      bucketName: BackendResourceConfigUtil.getPlanningBucketName(),
      stopsFileKey: stopsFileKey,
      outFileKey: outFileKey,
      clustersNum: clustersNum,
      separator: separator
    });
  }

  return null;
}

function getAssignedShipments(courierId, date) {
  return ShipmentsApi.getAssignedShipments(courierId, date);
}

const GetShipmentData = gql`
  query GetShipmentData($pk: String, $sk: String) {
    queryShipments(pk: $pk, sk: $sk, featureRequest: "realTime") {
      items {
        ${ApiHelpers.createQueryFieldList(baseShipmentFields)}
        region
        deliveryCompletedAt
        pickupCompletedAt
        events {
         DELIVERED
         PICKED_UP
         OTHER
        }
        fuzzyAddress {
          streetName
          houseNumber
          geoLat
          geoLng
          hasFuzzyMatch
          receiver
        }
        senderName
        cashOnDelivery
      }
    }
  }
`;

async function getShipmentDbData(shipmentId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetShipmentData, {
      pk: DynamoStringExtensionsUtil.addShipmentPrefix(shipmentId),
      sk: DynamoStringExtensionsUtil.addShipmentPrefix(shipmentId)
    });
  }

  return null;
}

const SendClientMessagesForPlan = gql`
  mutation SendClientMsgForPlan($bucketName: String, $fileName: String) {
    sendClientMsgForPlan(bucketName: $bucketName, fileName: $fileName)
  }
`;

async function sendClientMessagesForPlan(bucketName, fileName) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: SendClientMessagesForPlan,
      variables: {
        bucketName: bucketName,
        fileName: fileName
      }
    });
  }

  return null;
}

const GetCouriersPlans = gql`
  query GetCouriersPlans($pk: String, $sk: String) {
    getCouriersPlans(pk: $pk, sk: $sk) {
      courierId
      date
      teamId
      clientMessagesCsv
      optimizedRouteJson
      orderedShipmentsCsv
      orderedShipmentsExcel
      fileFolder
      courierFullName
    }
  }
`;

function getCouriersPlans(date, teamId, courierId = null) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    let sk = teamId;
    if (courierId) {
      sk = `${sk}#${courierId}`;
    }

    return ApiHelpers.createQuery(GetCouriersPlans, {
      pk: DynamoStringExtensionsUtil.addCourierPlanPrefix(date),
      sk: DynamoStringExtensionsUtil.addCourierPlanPrefix(sk)
    });
  }

  return null;
}

export default PlanningApi;
