import _ from 'lodash';
import haversine from 'haversine-distance';
import { MapMarkerUtil } from '../components/marker/util/mapMarkerUtil';
import { PauseCalculationUtil } from '../../features/analysis/routeAnalysis/utils/pauseCalculationUtil';
import stopsUtil from '../../features/analysis/utils/stopsUtil';
import MapUtil from './mapUtil';

const MAX_LAT = 90;
const MAX_LNG = 200;
const DECIMAL_POINT_ACCURACY = 4;

export default class PinPlottingUtilClass {
  constructor(map, t) {
    this.map = map;
    this.t = t;
    this.bounds = { northEast: [-MAX_LAT, -MAX_LNG], southWest: [MAX_LAT, MAX_LNG] };
    this.markersData = [];
    this.startFinishMarkers = [];
    this.plottedPins = [];
    this.clusteredPins = null;
    this.defaultView = MapUtil.getInitialViewStateForCompany();
  }

  addStartFinishPins = (startFinish) => {
    if (
      !_.isEmpty(startFinish[0])
      && !_.isEmpty(startFinish[1])
      && haversine({ lng: startFinish[0][0], lat: startFinish[0][1] }, { lng: startFinish[1][0], lat: startFinish[1][1] }) < 50
    ) {
      this.startFinishMarkers.push({
        coordinates: startFinish[0],
        markerProps: {
          text: 'S/E',
          id: 'SE',
          cssClass: 'feature small route-start-end'
        }
      });
      this.addNewPointToBounds(startFinish[0]);
    } else {
      this.startFinishMarkers.push({
        coordinates: startFinish[0],
        markerProps: {
          icon: 'icon-play',
          id: 'S',
          cssClass: 'feature small route-start'
        }
      });
      this.startFinishMarkers.push({
        coordinates: startFinish[1],
        markerProps: {
          icon: 'icon-stop',
          id: 'E',
          cssClass: 'feature small route-end'
        }
      });
      this.addNewPointToBounds(startFinish[0]);
      this.addNewPointToBounds(startFinish[1]);
    }
  };

  plotPins = (stops, page) => {
    switch (page) {
      case 'routeAnalysis':
        this.routeAnalysisPrepareMarkerData(stops);
        break;
      case 'realTime':
        this.realTimePrepareMarkerData(stops);
        break;
      case 'plan':
        this.planningTrackingPrepareMarkerData(stops);
        break;
      default:
    }

    this.addPinsToMap();
  };

  routeAnalysisPrepareMarkerData = (stops) => {
    stops.forEach((stop, i) => {
      const prevStop = this.markersData[i - 1];

      const markerData = this.createRouteAnalysisMarkerData(stop, prevStop, i);

      this.markersData.push({
        coordinates: markerData.coordinates,
        markerProps: {
          text: i + 1,
          id: i,
          cssClass: markerData.stopClass,
          onMouseEnter: () => {
            this.mouseHoverActions(true, i);
          },
          onMouseLeave: () => {
            this.mouseHoverActions(false, i);
          },
          additionalNumber: null,
          onClickLogMessage: markerData.onClickLogMessage
        },
        markerData: {
          ...markerData.stopData,
          className: stopsUtil.getStopType(stop.event),
          deliveryType: stopsUtil.getStopType(stop.event),
          keyOrder: ['shipmentCode', 'name', 'address', 'time'],
          distanceFromPrevStop: i > 0 ? markerData.description : null,
          stopIndex: i + 1
        }
      });
    });
  };

  realTimePrepareMarkerData = (stops) => {
    this.removeAllMarkers();
    stops.forEach((shipment) => {
      if (shipment.lng && shipment.lat) {
        this.markersData.push({
          coordinates: [shipment.lng, shipment.lat],
          markerProps: {
            id: shipment.shipmentCode,
            cssClass: `small ${shipment.className}`,
            icon: 'icon-package',
            additionalNumber: null,
            onClickLogMessage: `Real-time - Map - ${shipment.className} pin clicked`
          },
          markerData: {
            shipmentCode: shipment.shipmentCode,
            address: shipment.address,
            name: shipment.name,
            className: shipment.className,
            deliveryType: shipment.deliveryType,
            additionalDeliveryTypeDescription: shipment.deliveryType === 'unsuccessful-attempt' ? shipment.statusDescription : null,
            phone: shipment.phone,
            time: this.getShipmentEventCompletedDate(shipment),
            keyOrder: ['shipmentCode', 'name', 'address', 'phone', 'time']
          }
        });
      }
    });
  };

  planningTrackingPrepareMarkerData = (stops) => {
    stops.forEach((shipment) => {
      const coordinates = [shipment.lng, shipment.lat];
      this.addNewPointToBounds(coordinates);
      this.markersData.push({
        coordinates: coordinates,
        markerProps: {
          id: shipment.optimizedOrder,
          cssClass: `small ${shipment.className}`,
          text: shipment.optimizedOrder,
          additionalNumber: null,
          onClickLogMessage: `Plan Tracking - Map - ${shipment.className} pin clicked`
        },
        markerData: {
          shipmentCode: shipment.shipmentCode,
          address: shipment.address,
          name: shipment.name,
          className: shipment.className,
          deliveryType: shipment.deliveryType,
          additionalDeliveryTypeDescription: shipment.deliveryType === 'unsuccessful-attempt' ? shipment.statusDescription : null,
          phone: shipment.phone,
          time: this.getShipmentEventCompletedDate(shipment),
          keyOrder: ['shipmentCode', 'name', 'address', 'phone', 'time', 'timeRange'],
          stopIndex: shipment.optimizedOrder,
          timeRange: `${shipment.timeSlotStart} - ${shipment.timeSlotEnd}`
        }
      });
    });
  };

  createRouteAnalysisMarkerData = (stop, prevStop, i) => {
    const stopData = {
      time: stop.timestamp,
      shipmentCode: stop.shipmentCodes[0],
      numberOfShipments: stop.shipmentCodes.length,
      numberOfPackages: stop.numPackages,
      name: stop.name,
      address: stop.address,
      deliveryType: stopsUtil.getStopType(stop.event)
    };

    const coordinates = [stop.lng, stop.lat];
    this.addNewPointToBounds(coordinates);

    let stopClass = `small ${stopData.deliveryType}`;
    let onClickLogMessage = `Route Analysis - Map - ${stopData.deliveryType} pin clicked`;

    if (i === 0) {
      return {
        stopData: stopData,
        coordinates: coordinates,
        stopClass: `${stopClass} ${stopData.deliveryType}`,
        description: { time: null, distance: null },
        onClickLogMessage: onClickLogMessage
      };
    }

    const description = this.getPrevStopCalculations(prevStop, { coordinates: coordinates, time: stop.timestamp });
    if (description.time && PauseCalculationUtil.checkIfPauseBetweenStops(description.time)) {
      onClickLogMessage = 'Route Analysis - Map - pause pin clicked';
      stopClass = 'small stop-after-pause';
    }

    return {
      stopData: stopData,
      coordinates: coordinates,
      stopClass: stopClass,
      description: description,
      onClickLogMessage: onClickLogMessage
    };
  };

  addPinsToMap = () => {
    if (!_.isEmpty(this.startFinishMarkers)) {
      this.startFinishMarkers.forEach((marker) => {
        this.plottedPins.push(MapMarkerUtil.addMarkerToMap(this.map, marker.coordinates, marker.markerProps));
      });
    }

    let newPinIndex;
    let markerInSameCluster;
    this.clusteredPins = {};
    this.markersData.forEach((marker) => {
      newPinIndex = this.createMappingIndex(marker.coordinates);
      if (_.isEmpty(this.clusteredPins[newPinIndex])) {
        this.clusteredPins[newPinIndex] = [marker];
      } else {
        markerInSameCluster = marker;
        markerInSameCluster.markerProps.additionalNumber = `+${this.clusteredPins[newPinIndex].length}`;
        this.clusteredPins[newPinIndex].push(markerInSameCluster);
      }
    });

    Object.keys(this.clusteredPins).forEach((index) => {
      const latestMarker = this.clusteredPins[index][this.clusteredPins[index].length - 1];
      const latestMarkerData = this.clusteredPins[index].map((data) => data.markerData);
      this.plottedPins.push(MapMarkerUtil.addMarkerToMap(this.map, latestMarker.coordinates, latestMarker.markerProps, latestMarkerData));
    });
  };

  removeAllMarkers = () => {
    this.plottedPins.forEach((marker) => {
      marker.remove();
    });
    this.plottedPins = [];
    this.bounds = { northEast: [-MAX_LAT, -MAX_LNG], southWest: [MAX_LAT, MAX_LNG] };
    this.markersData = [];
    this.startFinishMarkers = [];
    this.clusteredPins = null;
  };

  getPrevStopDistance = (prevStop, currentStop) => {
    const stopLatLng = {
      longitude: currentStop[0],
      latitude: currentStop[1]
    };
    const prevStopLatLng = {
      longitude: prevStop[0],
      latitude: prevStop[1]
    };
    const distance = haversine(stopLatLng, prevStopLatLng).toFixed(0);

    return distance === 'NaN' ? null : distance;
  };

  getPrevStopCalculations = (prevStop, stop) => {
    const timeBetweenStops = PauseCalculationUtil.calculateTimeBetweenStops(prevStop.markerData.time, stop.time);
    const distanceBetweenStops = this.getPrevStopDistance(prevStop.coordinates, stop.coordinates);
    return { distance: distanceBetweenStops, time: timeBetweenStops };
  };

  getShipmentEventCompletedDate = (shipment) => {
    if (shipment.unsuccessfulAttemptTimestamp) {
      return shipment.unsuccessfulAttemptTimestamp;
    }
    return shipment.deliveryType === 'delivery' ? shipment.deliveryCompletedAt : shipment.pickupCompletedAt;
  };

  createMappingIndex = (coordinates) => `${parseFloat(coordinates[0]).toFixed(DECIMAL_POINT_ACCURACY)}#${parseFloat(coordinates[1]).toFixed(DECIMAL_POINT_ACCURACY)}`;

  changeCSSClass = (elementClassList, className, extendClassList) => {
    if (extendClassList) elementClassList.add(className);
    else elementClassList.remove(className);
  };

  mouseHoverActions = (extendClassList, i) => {
    const next = document.getElementById((i + 1).toString());
    if (next) this.changeCSSClass(next.classList, 'highlight', extendClassList);
    this.changeCSSClass(document.getElementById(i.toString()).classList, 'feature-big', extendClassList);
  };

  addNewPointToBounds = (newPoint) => {
    if (!newPoint) return;
    const northEast = this.bounds.northEast;
    const southWest = this.bounds.southWest;
    if (newPoint[1] && newPoint[0] && parseInt(newPoint[1], 10) !== 0 && parseInt(newPoint[0], 10) !== 0) {
      northEast[1] = northEast[1] > newPoint[1] ? northEast[1] : newPoint[1];
      northEast[0] = northEast[0] > newPoint[0] ? northEast[0] : newPoint[0];
      southWest[1] = southWest[1] < newPoint[1] ? southWest[1] : newPoint[1];
      southWest[0] = southWest[0] < newPoint[0] ? southWest[0] : newPoint[0];
    }
    this.bounds = { northEast: northEast, southWest: southWest };
  };

  centerMapToBounds = () => {
    if (!_.isEmpty(this.bounds)) {
      if (this.bounds.northEast[0] + this.bounds.northEast[1] + this.bounds.southWest[0] + this.bounds.southWest[1]) {
        this.map.fitBounds([this.bounds.northEast, this.bounds.southWest], { padding: 100 });
      } else this.map.flyTo({ center: [this.defaultView.lng, this.defaultView.lat], zoom: this.defaultView.zoom });
    }
  };

  plotCourierLocation = (courierLocationMarker, lat, lng, locationLive, id) => {
    if (courierLocationMarker) {
      courierLocationMarker.remove();
    }

    return MapMarkerUtil.addMarkerToMap(this.map, [lng, lat], {
      id: id || 'courier-real-time-location',
      withPulsingAnimation: locationLive,
      backgroundImageUrl: '/mapData/realTimeVan.png'
    });
  };
}
