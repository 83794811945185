import React from 'react';
import PropTypes from 'prop-types';
import NumberCircle from './components/NumberCircle';
import './MapMarker.scss';
import MixPanel from '../../../setup/mixPanel';

export default function MapMarker(props) {
  const { icon, text, id, cssClass, onMouseEnter, onMouseLeave, backgroundImageUrl, onClick, withPulsingAnimation, onHoverPopupData } = props;
  let count = 0;
  let activePopup;

  function mouseEnter(e) {
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  }

  function mouseLeave(e) {
    if (onMouseLeave) {
      onMouseLeave(e);
    }
  }

  function handleClick(e) {
    if (onClick) {
      onClick(e);
    }

    if (props && props.onClickLogMessage) MixPanel.track(props.onClickLogMessage);
  }

  async function addPopup() {
    if (onHoverPopupData) {
      activePopup = await onHoverPopupData.getHoverPopup();
      if (count === 0) activePopup.setLngLat(onHoverPopupData.coordinates).addTo(onHoverPopupData.map);
      count++;
    }
  }

  function removePopup() {
    if (onHoverPopupData) {
      if (activePopup) activePopup.remove();
      count--;
    }
  }

  function getInnerElement() {
    if (icon) {
      return <i className={`icon ${icon}`} />;
    }

    if (text) {
      return <div className="text">{text}</div>;
    }
    return null;
  }

  function getImageElement() {
    if (withPulsingAnimation) {
      return (
        <div className="animation-wrapper">
          <img alt={backgroundImageUrl} className="background-image" src={backgroundImageUrl} />
          <div className="pulsating-circle" />
        </div>
      );
    }

    return (
      <img
        alt={backgroundImageUrl}
        className={`background-image ${cssClass}`}
        src={backgroundImageUrl}
        onPointerOverCapture={addPopup}
        onPointerOutCapture={removePopup}
      />
    );
  }

  return (
    <div className="map-marker" id={id} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={handleClick}>
      {props.additionalNumber && props.additionalNumber !== '0' && <NumberCircle numberToShow={props.additionalNumber} />}
      {backgroundImageUrl ? getImageElement() : <div className={`pin ${cssClass}`}>{getInnerElement()}</div>}
    </div>
  );
}

MapMarker.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  backgroundImageUrl: PropTypes.string,
  onClick: PropTypes.func,
  withPulsingAnimation: PropTypes.bool,
  additionalNumber: PropTypes.string,
  onClickLogMessage: PropTypes.string,
  onHoverPopupData: PropTypes.object
};

MapMarker.defaultProps = {
  icon: null,
  text: null,
  cssClass: '',
  onMouseEnter: null,
  onMouseLeave: null,
  backgroundImageUrl: '',
  onClick: null,
  withPulsingAnimation: false,
  additionalNumber: null,
  onClickLogMessage: null,
  onHoverPopupData: null
};
