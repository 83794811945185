import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { raygunClient } from '../../../../../setup/raygunClient';
import * as PageActions from '../../../../../state/actions/pageActions';
import TableComponent from '../../../../../common/components/tables/tableComponent/TableComponent';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import UsersApi from '../../api/usersApi';
import TableActions from '../../../../../common/components/tables/common/TableActions';
import MixPanel from '../../../../../setup/mixPanel';
import UserForm from './UserForm';

class UserManagement extends React.Component {
  constructor() {
    super();
    this.state = { tablePageSize: 1 };
  }

  addUser = () => {
    MixPanel.track('User Management - Actions click create user');
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: UserForm,
      dialogComponentProps: {
        title: this.props.t('Add user'),
        roles: this.props.roles,
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);
          UsersApi.createWebUser(data.email, data.role.id, data.firstName, data.lastName)
            .then((res) => {
              if (res?.data?.createUser > 0 || res?.data?.updateUser > 0) {
                this.props.refreshData();
                MixPanel.track('User Management - User create');
              }
            })
            .catch((e) => {
              if (e.graphQLErrors[0].errorType === 'UsernameExistsException') {
                toast.error(this.props.t('User already exists'));
              } else {
                raygunClient.send(e, 'Error creating user', data);
                toast.error(this.props.t('Oops something went wrong'));
              }
              this.props.dispatchLoadingPage(false);
            });
        }
      }
    });
  };

  editUser = (row) => {
    MixPanel.track('User Management - Actions click edit user');
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: UserForm,
      dialogComponentProps: {
        title: this.props.t('Edit user'),
        data: {
          firstName: row.values.firstName,
          lastName: row.values.lastName,
          email: row.values.email,
          role: row.values.roleName && row.values.roleId ? { label: row.values.roleName, value: row.values.roleId } : null
        },
        roles: this.props.roles,
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);

          UsersApi.upsertWebUser(data.email, data.role.id, data.firstName, data.lastName)
            .then((res) => {
              if (res?.data?.createUser > 0 || res?.data?.updateUser > 0) {
                this.props.refreshData();
                MixPanel.track('User Management - User update');
              }
            })
            .catch((e) => {
              raygunClient.send(e, 'Error upserting user', data);
              toast.error(this.props.t('Oops something went wrong'));
              this.props.dispatchLoadingPage(false);
            });
        }
      }
    });
  };

  // TODO uncomment when deletion is implemented
  // deleteUser = row => {
  //   AppDialogActionsWrapper.openConfirmationDialog({
  //     title: `${this.props.t('Delete user')}?`,
  //     body: `${this.props.t('Delete user info')}.`,
  //     confirmButtonText: this.props.t('Delete'),
  //     continueCallback: () => {
  //       if (process.env.REACT_APP_DATA_SOURCE === 'api')
  //         UsersApi.deleteWebUser(row.original.email)
  //           .then(() => {
  //             this.props.refreshData();
  //             MixPanel.track('User Management - User delete');
  //           })
  //           .catch(error => {
  //             raygunClient.send(error, 'Error deleting user', { id: row.original.id });
  //             toast.error(this.props.t('Oops something went wrong'));
  //             this.props.dispatchLoadingPage(false);
  //           });
  //     }
  //   });
  // };

  getTableColumns = () => [
    {
      Header: this.props.t('First name'),
      accessor: 'firstName'
    },
    {
      Header: this.props.t('Last name'),
      accessor: 'lastName'
    },
    {
      Header: this.props.t('Email'),
      accessor: 'email'
    },
    {
      Header: this.props.t('Role'),
      accessor: 'roleName'
    },
    { accessor: 'roleId' },
    {
      Header: this.props.t('Actions'),
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      // TODO add 'onDelete={this.deleteUser}' here when deletion is implemented
      Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editUser} />
    }
  ];

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize === 1 || this.state.tablePageSize < pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  render() {
    return (
      <div className="user-management">
        {this.props.users && (
          <div className="table-wrapper">
            <TableComponent
              key={`courier-${this.state.tablePageSize}`}
              columns={this.getTableColumns()}
              data={this.props.users}
              paginationLabel={this.props.t('users-pagination')}
              sortBy={[{ id: 'firstName' }]}
              pageSize={this.state.tablePageSize}
              onActionButtonClick={this.addUser}
              actionButtonText={this.props.t('Add new user')}
              hiddenColumns={['roleId']}
              showSearch
              calculateTablePageSize={this.calculateTablePageSize}
              pageUsing="User Management - Users"
            />
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

UserManagement.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      roleId: PropTypes.string,
      roleName: PropTypes.string
    })
  ),
  roles: PropTypes.arrayOf(PropTypes.object)
};

UserManagement.defaultProps = {
  users: null,
  roles: null
};

export default withTranslation('translations')(connect(null, mapDispatchToProps)(UserManagement));
