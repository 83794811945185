import React from 'react';
import './TableSearch.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function TableSearch({ globalFilter, setGlobalFilter, searchPlaceholder }) {
  const { t } = useTranslation();

  const onChange = (val) => {
    setGlobalFilter(val || undefined);
  };

  return (
    <div className="table-search">
      <input
        className="search"
        type="text"
        value={globalFilter || ''}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={searchPlaceholder || t('Search')}
      />
    </div>
  );
}

TableSearch.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string
};

TableSearch.defaultProps = {
  globalFilter: null,
  searchPlaceholder: null
};
