import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './RealTimeInfoLoader.scss';

export default function RealTimeInfoLoader(props) {
  const { t } = useTranslation();

  return (
    <div className="real-time-info-loader">
      {props.showRefresh ? (
        <div className="refresh" onClick={props.handleRefreshClick}>
          <i className="icon icon-rotate-left" />
          <div className="refresh-text">
            {' '}
            {t('Start real-time tracking')}
          </div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader">
            <i className="icon icon-delivery-van" />
            {' '}
          </div>
        </div>
      )}
    </div>
  );
}

RealTimeInfoLoader.propTypes = {
  showRefresh: PropTypes.bool.isRequired,
  handleRefreshClick: PropTypes.func.isRequired
};
