export const tabOrder = [
  {
    name: 'Users',
    icon: 'icon-person'
  },
  {
    name: 'Roles',
    icon: 'icon-clipboard-list'
  },
  {
    name: 'Policies',
    icon: 'icon-account-eye'
  },
  {
    name: 'Zones',
    icon: 'icon-map-outline'
  }
];
