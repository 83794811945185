import MixPanel from '../../setup/mixPanel';
import AuthUtil from './authUtil';

let mixPanelMessage = null;

function setUnloadListener(message) {
  mixPanelMessage = message;
  window.addEventListener('beforeunload', mixpanelTrigger);
}

function removeUnloadListener() {
  mixpanelTrigger();
  // TODO Delighted remove
  window.delighted.survey({ email: AuthUtil.getUsername() });
  window.removeEventListener('beforeunload', mixpanelTrigger);
}

function mixpanelTrigger() {
  if (mixPanelMessage) {
    MixPanel.track(mixPanelMessage);
    mixPanelMessage = null;
  }
}

const MixPanelUtil = {
  setUnloadListener: setUnloadListener,
  removeUnloadListener: removeUnloadListener
};

export default MixPanelUtil;
