import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './RadioButton.scss';
import { useTranslation } from 'react-i18next';

export default function RadioButton(props) {
  const { t } = useTranslation();
  const { selectedValueChanged, defaultValue } = props;
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);

  useEffect(() => {
    selectedValueChanged(defaultValue);
  }, [selectedValueChanged, defaultValue]);

  function onValueChange(val) {
    props.selectedValueChanged(val);
    setSelectedValue(val);
  }

  function getSingleInputComponent(value) {
    return (
      <div className="radio-option" key={value}>
        <input
          type="radio"
          value={value}
          name={props.type}
          onChange={() => {
            onValueChange(value);
          }}
          checked={selectedValue === value}
        />
        <div className="value-name">{t(value)}</div>
      </div>
    );
  }

  return (
    <div className="radio-button-group">
      <div className="radio-title">{`${t(props.title)}:`}</div>
      {props.values?.length > 0 && props.values.map((value) => getSingleInputComponent(value))}
    </div>
  );
}

RadioButton.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  selectedValueChanged: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string
};

RadioButton.defaultProps = {
  values: [],
  defaultValue: PropTypes.string,
  type: 'radio-group'
};
