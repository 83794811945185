import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';

/**
 * Contains default style adjustments on existing elements
 *
 * @namespace DefaultSelectStyleUtil
 * @category Common
 */
const selectStyle = {
  /**
   * Adjusts container style
   *
   * @param {object} provided - provided container style
   * @param {object} state - current state
   * @returns {{minHeight: string, opacity: string}} adjusted style
   */
  container: (provided, state) => ({
    ...provided,
    minHeight: '1px',
    opacity: state.isDisabled ? '0.5' : '1'
  }),
  /**
   * Adjusts input style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, color: *}} adjusted style
   */
  input: (provided) => ({
    ...provided,
    minHeight: '1px',
    color: colorsAndFonts.lighter_text
  }),
  /**
   * Adjusts dropdown indicator style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, padding: string}} adjusted style
   */
  dropdownIndicator: (provided) => ({
    ...provided,
    minHeight: '1px',
    padding: '5px'
  }),

  indicatorContainer: (provided) => ({
    ...provided,
    color: colorsAndFonts.kashmir_blue
  }),
  /**
   * Adjusts indicator separator style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, marginBottom: string, marginTop: string}} adjusted style
   */
  indicatorSeparator: (provided) => ({
    ...provided,
    minHeight: '1px',
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: colorsAndFonts.kashmir_blue
  }),
  /**
   * Resets indicator style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string}} adjusted style
   */
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: '1px'
  }),
  /**
   * Adjusts value container style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, paddingBottom: string}} adjusted style
   */
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: 300,
    minHeight: '1px',
    paddingBottom: '1px',
    height: '32px'
  }),
  /**
   * Adjusts indicators container style
   *
   * @param {object} provided - provided container style
   * @param {object} state - current state
   * @returns {{minHeight: string, display: (string), height: string}} adjusted style
   */
  indicatorsContainer: (provided, state) => ({
    ...provided,
    minHeight: '1px',
    height: '30px',
    display: state.isDisabled ? 'none' : 'flex',
    color: colorsAndFonts.kashmir_blue
  }),
  /**
   * Adjusts option element style
   *
   * @param {object} provided - provided container style
   * @param {object} state - current state
   * @returns {{backgroundColor: (*|null), borderRadius: string, color: *, ":active": {backgroundColor: *}, "&:hover": {backgroundColor: *}}} adjusted style
   */
  option: (provided, state) => ({
    ...provided,
    borderRadius: '0',
    color: colorsAndFonts.lighter_text,
    '&:hover': { backgroundColor: colorsAndFonts.dim_selection_color },
    ':active': { backgroundColor: colorsAndFonts.selection_color },
    backgroundColor: state.isSelected ? colorsAndFonts.selection_color : null
  }),
  /**
   * Adjusts control element style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, boxShadow: string, backgroundColor: *, borderRadius: string, color: *, "&:hover": {borderColor: *}, height: string}} adjusted style
   */
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    '&:hover': { borderColor: colorsAndFonts.selection_color },
    boxShadow: '0 !important',
    backgroundColor: colorsAndFonts.tile_background_color,
    color: colorsAndFonts.lighter_text,
    borderColor: colorsAndFonts.kashmir_blue,
    minHeight: '1px'
  }),
  /**
   * Adjusts single value element style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, color: *}} adjusted style
   */
  singleValue: (provided) => ({
    ...provided,
    minHeight: '1px',
    color: colorsAndFonts.lighter_text
  }),
  /**
   * Adjusts menu style
   *
   * @param {object} provided - provided container style
   * @returns {{border: string, backgroundColor: *, borderRadius: number, zIndex: string}} adjusted style
   */
  menu: (provided) => ({
    ...provided,
    backgroundColor: colorsAndFonts.tile_background_color,
    border: `1px solid ${colorsAndFonts.kashmir_blue}`,
    borderRadius: 0,
    zIndex: '10'
  }),
  /**
   * Adjusts placeholder style
   *
   * @param {object} provided - provided container style
   * @returns {{fontStyle: string}} adjusted style
   */
  placeholder: (provided) => ({
    ...provided,
    fontStyle: 'italic'
  })
};

const DefaultSelectStyleUtil = { selectStyle };

export default DefaultSelectStyleUtil;
