import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddDeliveryAreaPage from './pages/AddDeliveryAreaPage';
import DeliveryAreasManagementPage from './pages/DeliveryAreasManagementPage';
import EditViewStatePage from './pages/EditViewStatePage';

/**
 * Planning page wrapper for page navigation
 *
 * @returns {JSX.Element} - Planning page wrapper for page navigation
 * @component
 * @alias PlanningPageRoutes
 * @category planning
 */
export default function DeliveryAreaRoutes() {
  return (
    <Switch>
      <Route exact path="/delivery-areas" key="/delivery-areas" component={DeliveryAreasManagementPage} />
      <Route exact path="/delivery-areas/map-view/:deliveryAreaId" key="/delivery-areas/map-view" component={AddDeliveryAreaPage} />
      <Route exact path="/delivery-areas/map-view/:deliveryAreaId/view-state" key="/delivery-areas/map-view/view-state" component={EditViewStatePage} />
    </Switch>
  );
}
