import React from 'react';
import DeckGL from '@deck.gl/react';
import PropTypes from 'prop-types';
import { StaticMap } from 'react-map-gl';
import { EditableH3ClusterLayer } from '@nebula.gl/layers';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { H3ClusterLayer } from '@deck.gl/geo-layers';
import { GeoJsonLayer } from '@deck.gl/layers';
import { FlyToInterpolator } from '@deck.gl/core';
import { cellArea, h3GetResolution, UNITS } from 'h3-js';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import TenantUtil from '../../../../common/utils/tenantUtil';
import * as PageActions from '../../../../state/actions/pageActions';
import * as DialogActions from '../../../../state/actions/dialogActions';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import S3Util from '../../../../common/utils/s3Util';
import add from '../../../../resources/add.png';
import eraser from '../../../../resources/eraser.png';
import './DeliveryAreaMapView.scss';
import BackendResourceConfigUtil from '../../../../common/utils/api/backendResourceConfigUtil';
import MapUtil from '../../../../common/utils/mapUtil';
import { EDIT_MODE } from '../../../../common/components/buttons/mapModeButton/constants/drawHexagonsConst';
import { DEFAULT_HEX_SIZE } from '../constants/deliveryAreaConstants';
import Footer from '../../../../common/components/footers/Footer';
import DeliveryAreasUtil from '../util/DeliveryAreasUtil';

class DeliveryAreaMapViewClass extends React.Component {
  constructor(props) {
    super(props);
    const defaultView = MapUtil.getInitialViewStateForCompany();
    this.state = {
      mapViewState: {
        longitude: props?.deliveryAreaData?.lng || defaultView.lng,
        latitude: props?.deliveryAreaData?.lat || defaultView.lat,
        zoom: props?.deliveryAreaData?.zoom || defaultView.zoom,
        pitch: 0,
        bearing: 0
      },
      showRegions: false,
      showPolygons: false
    };

    props.handleViewStateChange({ viewState: this.state.mapViewState });
  }

  componentDidMount() {
    this.loadData(this.props.deliveryAreaData);
    this.changeMapViewPosition();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deliveryAreaData !== this.props.deliveryAreaData) {
      this.loadData(this.props.deliveryAreaData);
      this.changeMapViewPosition();
    }

    if (prevProps.activeDeliveryAreas !== this.props.activeDeliveryAreas) {
      const daPromises = Object.keys(this.props.activeDeliveryAreas).map((daId) => {
        const oldElement = this.daForPolygons && this.daForPolygons.find((e) => e.id === daId);
        if (oldElement) {
          return oldElement;
        }

        return this.loadDeliveryAreaPolygon(this.props.activeDeliveryAreas[daId]);
      });
      Promise.all(daPromises)
        .then((res) => {
          this.daForPolygons = res;
          this.setState({ showPolygons: true });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  changeMapViewPosition = () => {
    if (this.props.deliveryAreaData && this.props.deliveryAreaData.lat && this.props.deliveryAreaData.lng && this.props.deliveryAreaData.zoom) {
      const getNewMapViewState = (prevState) => ({
        ...prevState,
        zoom: parseFloat(this.props.deliveryAreaData.zoom),
        latitude: parseFloat(this.props.deliveryAreaData.lat),
        longitude: parseFloat(this.props.deliveryAreaData.lng),
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1000
      });

      this.setState((prevState) => ({ mapViewState: getNewMapViewState(prevState.mapViewState) }));
    }
  };

  loadData = (deliveryAreaData) => {
    this.setState({ showRegions: false });
    this.regionsData = {};
    this.props.dispatchSaveRegionsData([]);

    const regionsPromise = deliveryAreaData?.regionsFile ? this.loadRegionsData(deliveryAreaData) : null;
    const hexagonsPromise = deliveryAreaData?.hexagonsFile ? this.loadDeliveryAreaHexagons(deliveryAreaData) : null;
    Promise.all([regionsPromise, hexagonsPromise]).then((res) => {
      const [regions, hexLevel] = res;
      this.setState({ showRegions: regions });
      if (this.props.setHexLevel) {
        this.props.setHexLevel(hexLevel || DEFAULT_HEX_SIZE);
      }
    });
  };

  loadRegionsData = async (deliveryArea) => {
    let fileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${deliveryArea.id}/${deliveryArea.regionsFile}`;
    fileName = TenantUtil.addTenantToFileName(fileName);
    return S3Util.getFileFromS3(fileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
      cacheControl: 'no-cache'
    })
      .then((content) => {
        this.regionsData = JSON.parse(content);
        return true;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadDeliveryAreaHexagons = (deliveryArea) => {
    let fileName = `${DeliveryAreasUtil.getDefaultFilePath(this.props.dataType)}/${deliveryArea.id}/${deliveryArea.hexagonsFile}`;
    fileName = TenantUtil.addTenantToFileName(fileName);

    return S3Util.getFileFromS3(fileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
      cacheControl: 'no-cache'
    })
      .then((content) => {
        const deliveryAreaData = JSON.parse(content);
        const hexLevel = h3GetResolution(deliveryAreaData[0]);
        this.props.dispatchSaveRegionsData([{ colorId: 0, hexIds: deliveryAreaData }]);
        return hexLevel;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadDeliveryAreaPolygon = (deliveryArea) => {
    let fileName = `${DeliveryAreasUtil.getLatestFilePath(this.props.dataType)}/${deliveryArea.id}/${deliveryArea.polygonFile}`;
    fileName = TenantUtil.addTenantToFileName(fileName);

    return S3Util.getFileFromS3(fileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
      cacheControl: 'no-cache'
    })
      .then((content) => {
        const polygon = JSON.parse(content);

        return {
          polygon: polygon,
          id: deliveryArea.id
        };
      })
      .catch((err) => {
        console.log(err);
        return {
          polygon: null,
          id: deliveryArea.id
        };
      });
  };

  getCursor = () => {
    if (this.props.editMode === EDIT_MODE.ADD) {
      return `url(${add}) 5 15, pointer`;
    }

    if (this.props.editMode === EDIT_MODE.REMOVE) {
      return `url(${eraser}) 5 20, pointer`;
    }

    return 'grab';
  };

  generateHexDiffAndValidated = (updatedHex, oldHexs) => {
    const newHex = [];
    let adding = true;
    if (oldHexs.length > updatedHex.length) {
      // hex deleted
      adding = false;
      oldHexs.forEach((oldHexId) => {
        if (!updatedHex.includes(oldHexId)) {
          newHex.push(oldHexId);
        }
      });
    } else if (oldHexs.length < updatedHex.length) {
      // new hex added
      updatedHex.forEach((updatedHexId) => {
        if (!oldHexs.includes(updatedHexId)) {
          newHex.push(updatedHexId);
        }
      });
    }

    this.props.updateHexValidation(newHex, this.props.hexLevel, adding);
  };

  getH3Layer = () => {
    const selectedIndex = 0;
    const defaultLayerConfig = {
      id: 'h3-cluster-layer',
      data: this.props.regionsData,
      getHexagons: (d) => (d && d.hexIds ? d.hexIds : []),
      resolution: this.props.hexLevel,
      getFillColor: [0, 155, 250, 200]
    };

    const additionalEditableLayerConfig = {
      modeConfig: { booleanOperation: this.props.editMode },
      mode: this.props.selectionTool,
      selectedIndexes: [selectedIndex],
      getEditedCluster: (updatedHexagonIDs, existingCluster) => {
        this.generateHexDiffAndValidated(updatedHexagonIDs, existingCluster?.hexIds || []);
        if (existingCluster) {
          return {
            ...existingCluster,
            hexIds: updatedHexagonIDs,
            colorId: selectedIndex
          };
        }
        return {
          hexIds: updatedHexagonIDs,
          colorId: selectedIndex
        };
      },
      onEdit: ({ updatedData }) => {
        if (updatedData !== this.props.regionsData) {
          this.props.dispatchSaveRegionsData(updatedData);
        }
      },

      _subLayerProps: {
        'tentative-hexagons': {
          getFillColor: () => {
            if (this.props.editMode === EDIT_MODE.ADD) {
              return [0, 155, 250, 200];
            }
            return [0, 0, 0, 200];
          },
          lineWidthScale: 0
        },
        hexagons: {
          getFillColor: () => [0, 155, 250, 200],
          lineWidthScale: 0
        }
      }
    };

    if (this.props.editDisabled) {
      return new H3ClusterLayer(defaultLayerConfig);
    }

    return new EditableH3ClusterLayer({
      ...defaultLayerConfig,
      ...additionalEditableLayerConfig
    });
  };

  addRegionsLayer = () => new GeoJsonLayer({
    id: 'region-layer',
    data: this.regionsData,
    pickable: false,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 2,
    getFillColor: [160, 160, 180, 150],
    getPointRadius: 100,
    getLineWidth: 1,
    getElevation: 0
  });

  addPolygonLayer = (polygonData) => new GeoJsonLayer({
    id: `${polygonData.id}-polygon`,
    data: polygonData.polygon,
    pickable: false,
    stroked: false,
    filled: true,
    extruded: false,
    lineWidthScale: 20,
    lineWidthMinPixels: 2,
    getFillColor: [160, 160, 180, 150]
  });

  getPolygonLayers = () => {
    const pLayers = [];
    this.daForPolygons.forEach((data) => {
      if (data.polygon) {
        pLayers.push(this.addPolygonLayer(data));
      }
    });

    return pLayers;
  };

  getAreaData = () => {
    if (this.props.regionsData && this.props.regionsData[0] && this.props.regionsData[0].hexIds) {
      const area = cellArea(this.props.regionsData[0].hexIds[0], UNITS.km2);
      const hexLength = this.props.regionsData[0].hexIds.length;
      return [
        { label: this.props.t('Area size'), value: `${(area * hexLength).toFixed(2)} km\xB2` },
        { label: this.props.t('Number of hexagons'), value: hexLength }
      ];
    }
    return [
      { label: this.props.t('Area size'), value: 0 },
      { label: this.props.t('Number of hexagons'), value: 0 }
    ];
  };

  render() {
    let layers = [];
    if (!this.props.isPageLoading) {
      layers = [this.getH3Layer()];

      if (this.state.showRegions) {
        layers.push(this.addRegionsLayer());
      }

      if (this.state.showPolygons) {
        layers.push(this.getPolygonLayers());
      }
    }

    const cursorProps = this.props.editDisabled ? this.getCursor : null;

    return (
      <div className="delivery-area-map-view-component">
        <Footer data={this.getAreaData()} />
        <DeckGL
          initialViewState={this.state.mapViewState}
          controller
          layers={layers}
          height="100%"
          width="100%"
          getCursor={this.getCursor}
          onViewStateChange={this.props.handleViewStateChange}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...cursorProps}
        >
          <StaticMap mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} mapStyle={MAP_STYLE} />
        </DeckGL>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return { ...store.pageState, ...store.saveChangesDialogState, ...store.planningPageState };
}

/**
 * @param {object} dispatch - redux store
 * @returns {object} - actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchDataChanged: DialogActions.showNextAlertDialog,
      dispatchSaveRegionsData: PlanningPageActions.saveRegionsData
    },
    dispatch
  );
}

DeliveryAreaMapViewClass.propTypes = {
  handleViewStateChange: PropTypes.func,
  isPageLoading: PropTypes.bool,
  dispatchSaveRegionsData: PropTypes.func.isRequired,
  regionsData: PropTypes.arrayOf(PropTypes.object),
  selectionTool: PropTypes.func,
  editDisabled: PropTypes.bool,
  deliveryAreaData: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string,
    zoom: PropTypes.string,
    regionsFile: PropTypes.string,
    hexagonsFile: PropTypes.string
  }),
  editMode: PropTypes.string,
  setHexLevel: PropTypes.func,
  hexLevel: PropTypes.number,
  updateHexValidation: PropTypes.func,
  t: PropTypes.func.isRequired,
  activeDeliveryAreas: PropTypes.object,
  dataType: PropTypes.string
};

DeliveryAreaMapViewClass.defaultProps = {
  handleViewStateChange: () => {},
  setHexLevel: null,
  isPageLoading: true,
  regionsData: null,
  selectionTool: null,
  editDisabled: false,
  deliveryAreaData: null,
  editMode: null,
  hexLevel: DEFAULT_HEX_SIZE,
  updateHexValidation: () => {},
  activeDeliveryAreas: null,
  dataType: null
};

const DeliveryAreaMapView = withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(DeliveryAreaMapViewClass));

export default DeliveryAreaMapView;
