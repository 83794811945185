import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as MilyDialogActions from '../../../../state/actions/dialogActions';

/**
 * Component for displaying save changes dialog
 *
 * @returns {JSX.Element} Save changes dialog component
 * @component
 * @category Common
 */
export default function SaveChangesDialog() {
  const dispatch = useDispatch();
  const saveChangesDialogState = useSelector((state) => state.saveChangesDialogState);
  const { t } = useTranslation();

  /**
   * Cancel options selected on dialog - closing dialog and calling additional callback if needed
   *
   * @function
   */
  const cancelCallback = () => {
    if (saveChangesDialogState.cancelCallback) {
      saveChangesDialogState.cancelCallback();
    }
    dispatch(MilyDialogActions.closeDialog());
  };

  /**
   * Continue options selected on dialog - closing dialog and calling additional callback if needed
   *
   * @function
   */
  const continueCallback = () => {
    if (saveChangesDialogState.continueCallback) {
      saveChangesDialogState.continueCallback();
    }
    dispatch(MilyDialogActions.closeDialog({ discardChanges: true }));
  };

  return (
    <div>
      <DialogTitle id="alert-dialog-title">
        {t(saveChangesDialogState.title)}
        ?
      </DialogTitle>
      <DialogContent>
        <div>
          {t('unsavedMessageDescription')}
          .
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelCallback} color="primary">
          {t('Cancel')}
        </Button>
        <Button onClick={continueCallback} color="primary" autoFocus>
          {t('Discard')}
        </Button>
      </DialogActions>
    </div>
  );
}
