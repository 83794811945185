import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { raygunClient } from '../../../../setup/raygunClient';
import DeliveryAreaMapView from '../components/DeliveryAreaMapView';
import StepInfoHeader from '../../../planning/planManagement/components/StepInfoHeader';
import './EditViewStatePage.scss';
import * as PageActions from '../../../../state/actions/pageActions';
import DeliveryAreasApi from '../api/deliveryAreasApi';

export default function EditViewStatePage(props) {
  const deliveryAreaData = props.location.state.deliveryAreaData;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lastMapViewState = useRef(null);
  const dataType = props?.location?.pathname.split('/')[1] || 'delivery-areas';

  dispatch(PageActions.loadingPage(false));

  const handleViewStateChange = useCallback(({ viewState: nextViewState }) => {
    lastMapViewState.current = nextViewState;
  }, []);

  const saveViewState = useCallback(() => {
    const id = deliveryAreaData.id;
    if (id) {
      const data = {
        lat: `${lastMapViewState.current.latitude}`,
        lng: `${lastMapViewState.current.longitude}`,
        zoom: `${lastMapViewState.current.zoom}`
      };
      DeliveryAreasApi.updateDeliveryAreaMapView(id, data, dataType)
        .then(() => {
          props.history.push(`/${dataType}`);
        })
        .catch((error) => {
          raygunClient.send(error, 'Error updating delivery area map view', { id, data, dataType });
        });
    }
  }, [deliveryAreaData, lastMapViewState, props.history, dataType]);

  return (
    <div className="edit-view-state-component">
      <StepInfoHeader
        message={t('Select default map position')}
        nextButtonText={t('Save')}
        options={{ returnButtonHidden: true, cancelButtonText: t('Skip'), cancelToPage: `/${dataType}` }}
        onNextClick={saveViewState}
      />
      {deliveryAreaData && (
        <DeliveryAreaMapView deliveryAreaData={deliveryAreaData} editDisabled handleViewStateChange={handleViewStateChange} dataType={dataType} />
      )}
    </div>
  );
}

EditViewStatePage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
