import { Auth } from 'aws-amplify';
import Sockette from 'sockette';
import BackendResourceConfigUtil from '../../../common/utils/api/backendResourceConfigUtil';
import { POLYGON_COLORS } from '../../../common/constants/mapConstants';
import * as PageActions from '../../../state/actions/pageActions';
import store from '../../../state/store';

/**
 * Util for planning related functionalities
 *
 * @namespace
 * @category planning
 */
const PlanningPageUtil = {
  hexToRGB: hexToRGB,
  getRegionIdFromPlanKey: getRegionIdFromPlanKey,
  connectToWebSocket: connectToWebSocket,
  closeWebSocketConnection: closeWebSocketConnection,
  jsonToCsv: jsonToCsv,
  getColorFromRegionId: getColorFromRegionId
};

/**
 * Close websocket connection to server
 *
 * @param {object} ws - websocket instance
 * @param {Function} additionalCallback - additional function called before closing connection
 * @memberOf PlanningPageUtil
 * @function
 */
function closeWebSocketConnection(ws, additionalCallback) {
  if (additionalCallback) {
    additionalCallback();
  }

  if (ws) {
    ws.close();
  }
}

/**
 * Connect to websocket
 *
 * @param {string} fileName - uploaded file name
 * @param {Function} onMessage - function called when message is received from websocket
 * @returns {object} - websocket instance
 * @memberOf PlanningPageUtil
 * @function
 */
function connectToWebSocket(fileName, onMessage) {
  return Auth.currentSession().then((res) => {
    const token = res.getIdToken().getJwtToken();
    return new Sockette(`${BackendResourceConfigUtil.getWebSocketUrl()}?token=${token}&fileId=${fileName}`, {
      timeout: 5e3,
      maxAttempts: 1,
      onopen: (e) => console.log('Connected!', e),
      onmessage: onMessage,
      onreconnect: (e) => console.log('Reconnecting...', e),
      onmaximum: (e) => console.log('Stop Attempting!', e),
      onclose: (e) => console.log('Closed!', e),
      onerror: (e) => {
        store.dispatch(PageActions.loadingPage(false));
        console.log('Error:', e);
      }
    });
  });
}

/**
 * Create shipments tooltip as html string
 *
 * @param {string} planKey - plan key in format {CenterId}-{RegionId}
 * @returns {string} - regionId
 * @memberOf PlanningPageUtil
 * @function
 */
function getRegionIdFromPlanKey(planKey) {
  const separatorIndex = planKey.indexOf('-');

  return planKey.slice(separatorIndex + 1);
}

/**
 * Convert json object to csv
 *
 * @param {object} data - json data to convert to csv
 * @param {string} delimiter - delimiter for csv
 * @returns {string} - csv file
 * @memberOf PlanningPageUtil
 * @function
 */
function jsonToCsv(data, delimiter = ';') {
  const items = data;
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  return [
    header.join(delimiter), // header row first
    ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(delimiter))
  ].join('\r\n');
}

/**
 * Helper function to convert hex colors to rgb colors
 *
 * @param {string} h - hex color
 * @returns {Array.<number>} rgba color
 * @memberOf PlanningPageUtil
 * @function
 */
function hexToRGB(h, notTransparent) {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;

    // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }

  return [Number(r), Number(g), Number(b), notTransparent ? 200 : 256];
}

function getColorFromRegionId(regionId) {
  const numberReg = /\d+/;

  const colorIndex = regionId ? regionId.match(numberReg) || 0 : 0;

  if (POLYGON_COLORS[colorIndex]) {
    return POLYGON_COLORS[colorIndex];
  }

  return '#fff';
}

export default PlanningPageUtil;
