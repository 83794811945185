import * as ActionTypes from '../constants/actionTypes';

const initialState = { language: null };

/**
 * Reducer - Language
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.LANGUAGE_CHANGED) {
    localStorage.setItem('locale', action.payload);
    return { language: action.payload };
  }
  return state;
};

export default reducer;
