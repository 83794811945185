import { combineReducers } from 'redux';
import mapReducer from './mapReducer';
import languageReducer from './languageReducer';
import chartReducer from './chartReducer';
import infoDialogReducer from './infoDialogReducer';
import pageReducer from './pageReducer';
import regionAnalysisReducer from './regionAnalysisReducer';
import authReducer from './authReducer';
import saveChangesDialogReducer from './dialogReducer';
import planningPageReducer from './planningPageReducer';
import coordinatesReducer from './coordinatesReducer';
import footerReducer from './footerReducer';

/**
 * @category State
 * @namespace Reducers
 */

export default combineReducers({
  mapState: mapReducer,
  languageState: languageReducer,
  chartState: chartReducer,
  infoDialogState: infoDialogReducer,
  pageState: pageReducer,
  regionAnalysisState: regionAnalysisReducer,
  authState: authReducer,
  saveChangesDialogState: saveChangesDialogReducer,
  planningPageState: planningPageReducer,
  coordinatesState: coordinatesReducer,
  footerState: footerReducer
});
