import React from 'react';
import { useTranslation } from 'react-i18next';
import MapMarker from '../../../common/components/marker/MapMarker';
import './PinLegend.scss';

export default function PinLegend() {
  const { t } = useTranslation();

  return (
    <div className="pin-legend">
      <div className="legend">
        <div className="pin1">
          <MapMarker cssClass="small delivery-dim" icon="icon-package" id="1" />
        </div>
        <div className="name">{t('Dropped off')}</div>

        <div className="pin2">
          <MapMarker cssClass="small delivery" icon="icon-package" id="2" />
        </div>
        <div className="name">{t('To be dropped off')}</div>

        <div className="pin3">
          <MapMarker cssClass="small pickup-dim" icon="icon-package" id="3" />
        </div>
        <div className="name">{t('Picked up')}</div>

        <div className="pin4">
          <MapMarker cssClass="small pickup" icon="icon-package" id="4" />
        </div>
        <div className="name">{t('To be picked up')}</div>

        <div className="pin5">
          <MapMarker cssClass="small stop-after-pause" icon="icon-package" id="5" />
        </div>
        <div className="name">{t('Unsuccessful')}</div>

        <div className="pin6 additional-number">
          <div className="circle">
            <div className="dot">+3</div>
          </div>
        </div>
        <div className="number name">{t('Additional number')}</div>
      </div>
    </div>
  );
}
