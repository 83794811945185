import React from 'react';
import './infoDialog.scss';
import PropTypes from 'prop-types';

/**
 * Component which shows info in an info dialog
 *
 * @param {object} props - component props
 * @returns {JSX.Element} Info Dialog component
 * @component
 * @category Common
 */
export default function InfoDialog(props) {
  const { bodyText } = props;

  /**
   * Call onClose callback from parent component.
   *
   * @see InfoDialogActions.closeInfoDialog
   * @function
   */
  const closeDialog = () => {
    props.onClose();
  };

  return (
    <div className="info-dialog">
      <div className="close-dialog" onClick={closeDialog}>
        <i className="icon icon-close" />
      </div>
      {bodyText}
    </div>
  );
}

InfoDialog.propTypes = {
  /**
   * On close callback
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Text key for popup content
   */
  bodyText: PropTypes.string.isRequired
};
