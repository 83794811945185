import mixpanel from 'mixpanel-browser';

// eslint-disable-next-line camelcase
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { api_host: 'https://api-eu.mixpanel.com' }, '');

const envCheck = process.env.REACT_APP_MIXPANEL_ENV === 'production';

/**
 * Wrapper for MixPanel setup
 *
 * @namespace MixPanel
 * @category Common
 */

const MixPanel = {
  identify: identify,
  alias: alias,
  track: track,
  people: people,
  reset: reset
};
/**
 * Identifies user by provided id
 *
 * @param {string} id - id of the user to track
 * @function
 * @memberOf MixPanel
 */
function identify(id) {
  if (envCheck) mixpanel.identify(id);
}

/**
 * Adds an alias for provided id
 *
 * @param {string} id of the user
 * @function
 * @memberOf MixPanel
 */
function alias(id) {
  if (envCheck) mixpanel.alias(id);
}

/**
 * Tracks event by name and provided additional info
 *
 * @param {string} name - name of the event
 * @param {object} props - additional info
 * @memberOf MixPanel
 */
function track(name, props) {
  if (envCheck) mixpanel.track(name, props);
}

/**
 * Adds additional properties to current user
 *
 * @function
 * @returns {object} set - set function
 * @memberOf MixPanel
 */
function people() {
  return {
    set: (props) => {
      if (envCheck) {
        mixpanel.people.set_once(props);
      }
    }
  };
}

/**
 * Resets data of currently tracked user
 *
 * @function
 * @memberOf MixPanel
 */
function reset() {
  if (envCheck) mixpanel.reset();
}

export default MixPanel;
