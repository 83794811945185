import PropTypes from 'prop-types';
import React from 'react';

export default function DeliveryAreaTableToggleIcon(props) {
  const { checked } = props;

  const iconType = checked ? 'icon-visibility' : 'icon-visibility-off';

  return (
    <div className="visibility-icon">
      <i className={`icon ${iconType}`} />
    </div>
  );
}

DeliveryAreaTableToggleIcon.propTypes = { checked: PropTypes.bool.isRequired };
