import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';

import './DataCard.scss';
import 'react-toggle/style.css';

/**
 * Data card which shows and filters data by specific type
 *
 * @param {object} props - component props
 * @returns {JSX.Element} Data card in a specific color
 * @component
 * @alias DataCard
 * @category Common
 */
export default function DataCard(props) {
  const { initialChecked, filteredValue, title, borderColor, toggleData } = props;
  const [checked, setChecked] = useState('initialChecked' in props ? initialChecked : true);

  /**
   * Toggles data card  if checked or unchecked
   *
   * @param {object} event - Event which contains new state of the toggle
   * @function
   */
  const toggleDataCard = (event) => {
    props.toggleData(event.target.checked);
    setChecked(event.target.checked);
  };

  let valueToShow;
  if (filteredValue !== undefined) {
    valueToShow = (
      <div className="value">
        {props.filteredValue}
        <span className="total-value">
          /
          {props.value}
        </span>
      </div>
    );
  } else {
    valueToShow = <div className="value">{props.value}</div>;
  }

  const cardStyle = checked && !props.toggleDisabled ? { borderColor: borderColor } : {};

  return (
    <div className="data-card" style={cardStyle}>
      <div className="title">{title}</div>
      {valueToShow}
      {toggleData && (
        <Toggle onChange={toggleDataCard} checked={checked} icons={false} className={`toggle ${props.toggleClass}`} disabled={props.toggleDisabled} />
      )}
    </div>
  );
}

DataCard.propTypes = {
  /**
   * Shows if data card is initially checked or not
   */
  initialChecked: PropTypes.bool,
  /**
   * Function which handles toggle state change
   */
  toggleData: PropTypes.func,
  /**
   * Background color
   */
  borderColor: PropTypes.string,
  /**
   * Filtered value to display
   */
  filteredValue: PropTypes.number,
  /**
   * Total number to display
   */
  value: PropTypes.number.isRequired,

  /**
   * Class to change toggle background color
   */
  toggleClass: PropTypes.string,

  /**
   * Title
   */
  title: PropTypes.string,

  toggleDisabled: PropTypes.bool
};

DataCard.defaultProps = {
  initialChecked: true,
  filteredValue: undefined,
  title: '',
  toggleData: null,
  toggleClass: '',
  borderColor: null,
  toggleDisabled: false
};
