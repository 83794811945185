import { I18n } from '@aws-amplify/core';
import EN from '../resources/translations/amplify_en_US.json';
import SR from '../resources/translations/amplify_sr_SR.json';

const authScreenLabels = {
  sr: SR,
  en: EN
};

class AmplifyI18n {
  constructor() {
    if (!this.i18N) {
      I18n.putVocabularies(authScreenLabels);
      this.i18N = I18n;
    }
  }
}

export const amplifyI18n = new AmplifyI18n().i18N;
