import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { raygunClient } from '../../../../../setup/raygunClient';
import * as PageActions from '../../../../../state/actions/pageActions';
import TableComponent from '../../../../../common/components/tables/tableComponent/TableComponent';
import CourierForm from './CourierForm';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import CouriersApi from '../../api/couriersApi';
import TableActions from '../../../../../common/components/tables/common/TableActions';
import AuthUtil from '../../../../../common/utils/authUtil';
import MixPanel from '../../../../../setup/mixPanel';

class CourierManagement extends React.Component {
  constructor() {
    super();
    this.state = { tablePageSize: 1 };
  }

  // Uncomment when addCourier feature is back
  // addCourier = () => {
  //   AppDialogActionsWrapper.openAppDialog({
  //     dialogComponent: CourierForm,
  //     dialogComponentProps: {
  //       title: this.props.t('Add courier'),
  //       teams: this.props.teams,
  //       submitCallback: (data) => {
  //         this.props.dispatchLoadingPage(true);
  //         CouriersApi.createCourier(IdUtils.generateId(), data)
  //           .then(() => {
  //             this.props.refreshData();
  //           })
  //           .catch((error) => {
  //             raygunClient.send(error, 'Error creating courier');
  //             toast.error(this.props.t('Oops something went wrong'));
  //             this.props.dispatchLoadingPage(false);
  //           });
  //       }
  //     }
  //   });
  // };

  editCourier = (row) => {
    MixPanel.track('Courier Management - Actions click edit courier');
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: CourierForm,
      dialogComponentProps: {
        title: this.props.t('Edit courier'),
        teams: this.props.teams,
        data: row.values,
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);
          CouriersApi.updateCourier(row.original.id, data)
            .then(() => {
              this.props.refreshData();
              toast.success(this.props.t('Courier updated successfully'));
            })
            .catch((error) => {
              raygunClient.send(error, 'Error editing courier');
              toast.error(this.props.t('Oops something went wrong'));
              this.props.dispatchLoadingPage(false);
            });
        }
      }
    });
  };

  getTableColumns = () => {
    const tableColumns = [
      { accessor: 'id' },
      {
        Header: this.props.t('First name'),
        accessor: 'firstName'
      },
      {
        Header: this.props.t('Last name'),
        accessor: 'lastName'
      },
      // TODO uncomment, fix, or remove once a mobile application communication is mapped out
      // {
      //   Header: this.props.t("Email"),
      //   accessor: "email" // accessor is the "key" in the data
      // },
      // {
      //   Header: this.props.t("PhoneNumber"),
      //   accessor: "phoneNumber"
      // },
      {
        Header: this.props.t('Team'),
        accessor: 'team.name'
      },
      {
        accessor: 'team.id',
        hidden: true
      },
      {
        Header: this.props.t('Active'),
        accessor: 'isActive',
        sortType: 'basic',
        Cell: (row) => <span>{row.value ? this.props.t('Yes') : this.props.t('No')}</span>
      }
    ];

    if (AuthUtil.isFeatureEnabled('manageCouriers')) {
      tableColumns.push({
        Header: this.props.t('Actions'),
        id: 'actions',
        disableGlobalFilter: true,
        className: 'centered',
        Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editCourier} />
      });
    }
    return tableColumns;
  };

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize !== pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  render() {
    return (
      <div className="courier-management">
        {this.props.couriers && (
          <div className="table-wrapper">
            <TableComponent
              key={`courier-${this.state.tablePageSize}`}
              columns={this.getTableColumns()}
              data={this.props.couriers}
              paginationLabel={this.props.t('couriers-pagination')}
              sortBy={[{ id: 'lastName' }]}
              pageSize={this.state.tablePageSize}
              // onActionButtonClick={
              //   AuthUtil.isFeatureEnabled('manageCouriers') ? this.addCourier : null
              // }
              // actionButtonText={
              //   AuthUtil.isFeatureEnabled('manageCouriers') ? this.props.t('Add new courier') : null
              // }
              hiddenColumns={['id', 'team.id']}
              showSearch
              calculateTablePageSize={this.calculateTablePageSize}
              pageUsing="Courier management"
            />
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

CourierManagement.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  couriers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      team: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        teamType: PropTypes.string
      })
    })
  ),
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      teamType: PropTypes.string
    })
  )
};

CourierManagement.defaultProps = {
  couriers: null,
  teams: null
};

export default withTranslation('translations')(connect(null, mapDispatchToProps)(CourierManagement));
