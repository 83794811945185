import * as React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-toggle/style.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import * as MapActions from '../../../../state/actions/mapActions';
import './PlanTrackingPreview.scss';
import RouteUtilClass from '../../../analysis/routeAnalysis/utils/routeUtilClass';
import PinPlottingUtilClass from '../../../../common/utils/pinPlotingUtil';
import MapModeButton from '../../../../common/components/buttons/mapModeButton/MapModeButton';
import MixPanel from '../../../../setup/mixPanel';
import MapUtil from '../../../../common/utils/mapUtil';
import PlanningFooter from '../../planManagement/components/PlanningFooter';
import PlanningMapUtil from '../../utils/planningMapUtil';
import AuthUtil from '../../../../common/utils/authUtil';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class PlanTrackingPreviewClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showRealTimeRoute: true };

    this.map = null;
    this.mapMarkers = [];
    this.layers = [];
    this.sources = [];
    this.courierMapMarker = null;

    this.defaultView = MapUtil.getInitialViewStateForCompany();
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [this.defaultView.lng, this.defaultView.lat],
      zoom: this.defaultView.zoom
    });

    this.addMapControls();

    this.RouteUtil = new RouteUtilClass(this.map, this.props.t);
    this.PinPlottingUtil = new PinPlottingUtilClass(this.map, this.props.t);

    this.props.dispatchIsMapLoading(false);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.locationData || !this.props.shipmentsData) {
      this.clearMap();
    } else if (this.props.locationData && prevProps.locationData !== this.props.locationData) {
      if (this.props.locationData.lat && this.props.locationData.lng) {
        this.courierMapMarker = this.PinPlottingUtil.plotCourierLocation(
          this.courierMapMarker,
          this.props.locationData.lat,
          this.props.locationData.lng,
          this.props.locationLive
        );
      }
      if (this.state.showRealTimeRoute) {
        this.mapStyleLoadingProtection(this.plotRealTimeRoute);
      }
    }

    if (this.props.shipmentsData && prevProps.shipmentsData !== this.props.shipmentsData) {
      this.mapStyleLoadingProtection(this.plotShipments);
    }

    if (this.props.routesData && this.props.routesData.length > 0 && prevProps.routesData !== this.props.routesData) {
      this.mapStyleLoadingProtection(this.plotRouteData);
    }

    if (this.props.locationLive !== prevProps.locationLive && this.props.locationData) {
      this.courierMapMarker = this.PinPlottingUtil.plotCourierLocation(
        this.courierMapMarker,
        this.props.locationData.lat,
        this.props.locationData.lng,
        this.props.locationLive
      );
    }
  }

  mapStyleLoadingProtection = (callback) => {
    const mapLoadingInterval = setInterval(() => {
      if (this.map.isStyleLoaded()) {
        clearInterval(mapLoadingInterval);
        callback();
      }
    }, 50);
  };

  clearMap = () => {
    this.PinPlottingUtil.removeAllMarkers();
    if (this.courierMapMarker) {
      this.courierMapMarker.remove();
      this.courierMapMarker = null;
    }

    this.removeLayers();
    this.removeSources();
    this.removeMarkers();

    this.clearRealTimeRoute();
  };

  plotRouteData = () => {
    this.props.routesData.forEach((route) => {
      const routeLayer = PlanningMapUtil.plotRoute(this.map, route.route);
      this.layers.push(routeLayer);

      const startEndMarkers = PlanningMapUtil.plotRouteStartAndEndPoint(this.map, route.startPoint, route.endPoint);
      this.mapMarkers.push(...startEndMarkers);
    });
  };

  plotShipments = () => {
    this.PinPlottingUtil.removeAllMarkers();
    this.props.shipmentsData.forEach((planShipments) => {
      this.PinPlottingUtil.plotPins(planShipments, 'plan');
    });

    this.PinPlottingUtil.centerMapToBounds();
  };

  clearRealTimeRoute = () => {
    this.RouteUtil.removeRouteMapData();
  };

  plotRealTimeRoute = () => {
    const properties = [];
    const coordinates = this.props.locationData.route.map((location) => {
      properties.push({ gpsTimestamp: location.gpsTimestamp });
      return [location.lng, location.lat];
    });
    this.RouteUtil.removeRouteMapData();
    this.RouteUtil.plotRoute(coordinates, properties, AuthUtil.isLocationDataHighQuality(), this.props.locationData.mileage);
  };

  removeLayers = () => {
    if (this.layers && this.layers.length > 0) {
      this.layers.forEach((layer) => this.map.removeLayer(layer));
      this.layers = [];
    }
  };

  removeSources = () => {
    if (this.sources && this.sources.length > 0) {
      this.sources.forEach((source) => this.map.removeSource(source));
      this.sources = [];
    }
  };

  removeMarkers = () => {
    if (this.mapMarkers && this.mapMarkers.length > 0) {
      this.mapMarkers.forEach((marker) => marker.remove());
      this.mapMarkers = [];
    }
  };

  toggleRealTimeRoute = () => {
    MixPanel.track(`Plan tracking - Show courier route turned ${this.state.showRealTimeRoute ? 'off' : 'on'}`);
    if (this.state.showRealTimeRoute) {
      this.RouteUtil.removeRouteMapData();
      this.setState({ showRealTimeRoute: false });
    } else {
      if (this.props.locationData) this.plotRealTimeRoute();
      this.setState({ showRealTimeRoute: true });
    }
  };

  addMapControls = () => {
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  showFooter = () => {
    if (this.props.routesData) return this.props.routesData.length === 1;
    return false;
  };

  render() {
    return (
      <div className="plan-tracking-preview">
        <div className="plan-tracking-preview-map">
          <div className="route-toggle-wrapper">
            <MapModeButton
              dataTip={this.props.t('Route show toggle')}
              dataFor="map-mode-button-tooltip"
              onClick={this.toggleRealTimeRoute}
              isActive={this.state.showRealTimeRoute}
            >
              <i className="icon-road" />
            </MapModeButton>
            <ReactTooltip id="map-mode-button-tooltip" className="tooltip" place="right" effect="solid" />
          </div>
          {this.showFooter() && (
            <PlanningFooter
              data={{ distance: this.props.routesData[0].distance, duration: this.props.routesData[0].duration, currentDistance: this.props.actualDistance }}
            />
          )}
          <MapBoxWrapper setMapRef={this.setMapRef} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return { ...store.chartState, ...store.mapState, actualDistance: store.footerState.routeDistance };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchIsMapLoading: MapActions.mapIsLoading,
      dispatchCenterToStop: MapActions.centerToStop
    },
    dispatch
  );
}

PlanTrackingPreviewClass.propTypes = {
  locationData: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string,
    timestamp: PropTypes.string,
    route: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        dayOfTheWeek: PropTypes.string,
        hour: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    mileage: PropTypes.number
  }),
  routesData: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      startPoint: PropTypes.arrayOf(PropTypes.number),
      midPoint: PropTypes.arrayOf(PropTypes.number),
      endPoint: PropTypes.arrayOf(PropTypes.number),
      distance: PropTypes.number,
      duration: PropTypes.number
    })
  ),
  shipmentsData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        className: PropTypes.string,
        deliveryCompletedAt: PropTypes.string,
        deliveryType: PropTypes.string,
        events: PropTypes.object,
        fuzzyAddress: PropTypes.object,
        name: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        optimizedOrder: PropTypes.string,
        phone: PropTypes.string,
        pickupCompletedAt: PropTypes.string,
        region: PropTypes.string,
        shipmentCode: PropTypes.string,
        statusDescription: PropTypes.string,
        unsuccessfulAttemptTimestamp: PropTypes.string
      })
    )
  ),
  locationLive: PropTypes.bool,
  actualDistance: PropTypes.number,
  dispatchIsMapLoading: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

PlanTrackingPreviewClass.defaultProps = {
  locationData: null,
  routesData: null,
  shipmentsData: null,
  locationLive: false,
  actualDistance: null
};

const PlanTrackingPreview = connect(mapStateToProps, mapDispatchToProps)(PlanTrackingPreviewClass);

export default withTranslation('translations')(PlanTrackingPreview);
