import AuthUtil from '../authUtil';

const BackendResourceConfigUtil = {
  getApiName: getApiName,
  getApiEndPoint: getApiEndPoint,
  getApiPath: getApiPath,
  getWebSocketUrl: getWebSocketUrl,
  getStateMachineArn: getStateMachineArn,
  getRegionDataBucketName: getRegionDataBucketName,
  getPlanningBucketName: getPlanningBucketName,
  getGpsBucketName: getGpsBucketName
};

function getApiName() {
  return `${process.env.REACT_APP_COMPANY_NAME}-${process.env.REACT_APP_BRANCH_NAME}-api`;
}

function getApiEndPoint() {
  return `https://${process.env.REACT_APP_API_ID}.execute-api.eu-central-1.amazonaws.com`;
}

function getApiPath(resourceName) {
  return `/${process.env.REACT_APP_BRANCH_NAME}/v1/${resourceName}`;
}

function getWebSocketUrl() {
  return `wss://${process.env.REACT_APP_WEBSOCKET_ID}.execute-api.eu-central-1.amazonaws.com/Dev/`;
}

function getStateMachineArn(name) {
  return `arn:aws:states:eu-central-1:491277132866:stateMachine:all-${process.env.REACT_APP_BRANCH_NAME}-${name}`;
}

function getRegionDataBucketName() {
  return `all-${process.env.REACT_APP_BRANCH_NAME}-region-data`;
}

function getPlanningBucketName() {
  return `all-${process.env.REACT_APP_BRANCH_NAME}-planning-2`;
}

function getGpsBucketName() {
  return `${AuthUtil.getTenantId()}-${process.env.REACT_APP_BRANCH_NAME}-gps-data-3`;
}

export default BackendResourceConfigUtil;
