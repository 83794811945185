import React from 'react';
import PropTypes from 'prop-types';
import './RegionCard.scss';
import PlanningPageUtil from '../../utils/planningPageUtil';

export default function RegionCard(props) {
  const { row } = props;

  return (
    <div className="region-card">
      <div className="title-wrapper">
        <div className="color" style={{ backgroundColor: PlanningPageUtil.getColorFromRegionId(row?.original?.regionId) }} />
        <div className="values">
          {row.cells.map((cell) => (
            <div key={cell.column.id} className="cell-wrapper">
              {cell.column.Header && <div className="cell-header">{cell.column.render('Header')}</div>}
              <div className="value">{cell.render('Cell')}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

RegionCard.propTypes = { row: PropTypes.object.isRequired };
