import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import SplitPane from 'react-split-pane';
import RegionAnalysisMap from './components/RegionAnalysisMap';
import './RegionAnalysis.scss';
import RangeDayPicker from './components/RangeDayPicker';
import * as RegionAnalysisActions from '../../../state/actions/regionAnalysisActions';
import AuthUtil from '../../../common/utils/authUtil';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import RegionStopsDistributionChart from './components/RegionStopsDistributionChart';
import MixPanel from '../../../setup/mixPanel';
import MapModeButton from '../../../common/components/buttons/mapModeButton/MapModeButton';
import HexTypes from './constants/hexTypes';
import DeliveryAreaSelect from '../../../common/components/selections/deliveryAreaSelect/DeliveryAreaSelect';
import ButtonLegend from './components/ButtonLegend';
import { MAP_ADDITIONAL_FEATURES_DATA, MAP_ADDITIONAL_FEATURES_TYPES } from './constants/mapAdditionalFeaturesData';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import MapUtil from '../../../common/utils/mapUtil';

/**
 * Region Analysis provides all functionalities needed in Region Analysis page at /region-analysis
 *
 * @component
 * @alias RegionAnalysis
 * @category RegionAnalysis
 * @returns {React.Component} RegionalAnalysis page
 */
export default function RegionAnalysis() {
  const { t } = useTranslation();
  const [showExtendedWidth, setExtendedWidth] = useState(false);
  const dispatch = useDispatch();
  const [showRegionStopsDistributionChart, setRegionStopsDistributionChart] = useState(false);
  const [lastSelectedCenterPosition, setLastSelectedCenterPosition] = useState(null);
  const [optimizedMode, toggleOptimizedMode] = useState(false);
  const [shouldResize, setShouldResize] = useState(false);
  const [currentLeftMapPosition, setCurrentLeftMapPosition] = useState(null);
  const [openMenu, isMenuOpen] = useState(false);
  const [centerTotalsInfo, setCenterTotalsInfo] = useState({});
  const [optimizedCenterTotalsInfo, setOptimizedCenterTotalsInfo] = useState({});
  const additionalMapFeatures = AuthUtil.getAdditionalMapFeatures();
  const [showAdditionalMapFeatures, setShowAdditionalMapFeatures] = useState(getAdditionalMapFeaturesVisibility);
  const [buttonLegend, setButtonLegend] = useState(null);

  useEffect(() => {
    MixPanel.track('Page Load - Delivery Area Analysis');
    MixPanelUtil.setUnloadListener('Page Unload - Delivery Area Analysis');
    return () => {
      MixPanelUtil.removeUnloadListener();
    };
  }, []);

  function getAdditionalMapFeaturesVisibility() {
    const visibilityObject = {};
    additionalMapFeatures.forEach((feature) => {
      visibilityObject[feature] = QueryStringUtil.getQueryStringValue(feature) === 'true';
    });

    visibilityObject[MAP_ADDITIONAL_FEATURES_TYPES.ISOCHRONE_PIN] = false;
    return visibilityObject;
  }

  /**
   * Gets initial HexType value from query string if present. If not, returns HEX_TYPE.STOPS
   *
   * @returns {string} HexType
   * @function
   */
  const getInitialHexType = () => {
    if (QueryStringUtil.getQueryStringValue('hexType')) {
      return QueryStringUtil.getQueryStringValue('hexType');
    }
    QueryStringUtil.setQueryStringValue('hexType', HexTypes.HEX_TYPE.SHIPMENTS);
    return HexTypes.HEX_TYPE.SHIPMENTS;
  };

  const [hexType, setHexType] = useState(getInitialHexType());

  /**
   * Toggles visibility of RangeDayPicker
   *
   * @param {boolean} show - to show or hide the picker
   */
  const togglePicker = (show) => {
    setExtendedWidth(show);
  };

  /**
   * Toggles visibility of region distribution Bar chart
   */
  // eslint-disable-next-line no-unused-vars
  const toggleRegionStopsDistributionChartView = () => {
    MixPanel.track(`Delivery Area Analysis - Bar chart distribution turned ${!showRegionStopsDistributionChart ? 'ON' : 'OFF'}`, {});
    setRegionStopsDistributionChart(!showRegionStopsDistributionChart);
  };

  /**
   * Dispatches event that date range was changed based on the date provided in the event
   *
   * @param {object} e - event
   */
  const setDateFilters = (e) => {
    let startDate = null;
    let endDate = null;

    switch (e.target.dataset.target) {
      case 'last7Days':
        startDate = moment().subtract(8, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Delivery Area Analysis - Date changed - Last 7 days', {});
        break;
      case 'last30Days':
        startDate = moment().subtract(31, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Delivery Area Analysis - Date changed - Last 30 days', {});
        break;
      default:
        startDate = moment().subtract(1, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Delivery Area Analysis - Date changed - Yesterday', {});
    }

    dispatch(RegionAnalysisActions.changeDateRange(startDate, endDate));
    QueryStringUtil.setQueryStringValue('fromDate', moment(startDate).startOf('day').format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('toDate', moment(endDate).endOf('day').format('YYYY-MM-DDTHH'));
  };

  /**
   * Dispatches event to fetch regions from selected center which is provided in options
   *
   * @param {object} opt - Options from react.Select component
   */
  const changeSelectedRegions = useCallback((deliveryArea) => {
    const defaultView = MapUtil.getInitialViewStateForCompany();
    setLastSelectedCenterPosition({
      lat: parseFloat(deliveryArea.lat) || defaultView.lat,
      lng: parseFloat(deliveryArea.lng) || defaultView.lng,
      zoom: parseFloat(deliveryArea.zoom) || defaultView.zoom,
      id: deliveryArea.id
    });
  }, []);

  /**
   * Toggles visibility of optimization mode
   */
  const changeOptimizedMode = () => {
    toggleOptimizedMode(!optimizedMode);
  };

  /**
   * Change hex type
   *
   * @param {object} selectedHexType - selected hexagon data type
   * @function
   */
  const changeHexType = (selectedHexType) => {
    setHexType(selectedHexType);
    QueryStringUtil.setQueryStringValue('hexType', selectedHexType);
    MixPanel.track(`Delivery Area Analysis - hexagon type changed to ${selectedHexType}`, { hexType: selectedHexType });
  };

  /**
   * Return custom active class name if provided hexTypeName is same as current hexType
   *
   * @param {string} hexTypeName - name of the hexagon type
   * @returns {string} class name
   * @function
   */
  const getActiveClassIfCurrentHexType = (hexTypeName) => {
    return hexType === hexTypeName ? 'active' : '';
  };

  const toggleSomething = useCallback((event) => {
    const featureType = event.currentTarget.dataset.value;
    MixPanel.track(`Delivery Area Analysis - ${featureType} turned ${!showAdditionalMapFeatures[featureType] ? 'ON' : 'OFF'}`);
    if (featureType !== MAP_ADDITIONAL_FEATURES_TYPES.ISOCHRONE_PIN) QueryStringUtil.setQueryStringValue(featureType, !showAdditionalMapFeatures[featureType]);
    const newState = { ...showAdditionalMapFeatures };
    newState[featureType] = !showAdditionalMapFeatures[featureType];
    setShowAdditionalMapFeatures(newState);
  }, [showAdditionalMapFeatures]);

  return (
    <div className="region-analysis">
      <SplitPane
        split="vertical"
        minSize={0}
        maxSize="100%"
        size={optimizedMode ? '50%' : '100%'}
        onDragFinished={() => setShouldResize(true)}
        onDragStarted={() => setShouldResize(false)}
        style={{ position: 'relative' }}
      >
        <div className="split-screen">
          <div className="controls-wrapper">
            <div className={showExtendedWidth ? 'filter-wrapper extend-width' : 'filter-wrapper'}>
              <DeliveryAreaSelect onDeliveryAreaChange={changeSelectedRegions} returnFullObject />
              <div className="date-range">
                <div className="title">{t('Date range')}</div>
                <RangeDayPicker togglePicker={togglePicker} eventTrackerNamePrefix="Delivery Area Analysis" />
              </div>
              <div className="quick-links">
                <div onClick={setDateFilters} data-target="yesterday">
                  {t('yesterday')}
                </div>
                <div onClick={setDateFilters} data-target="last7Days">
                  {t('last7Days')}
                </div>
                <div onClick={setDateFilters} data-target="last30Days">
                  {t('last30Days')}
                </div>
              </div>
            </div>
            {AuthUtil.hasAnyOfFeaturesEnabled([
              'hex-type-stops',
              'hex-type-packages',
              'hex-type-shipments',
              'hex-type-shipments-with-one-package',
              'hex-type-shipments-for-package-lockers'
            ])}
            <div>
              <MapModeButton onClick={() => isMenuOpen(!openMenu)} dataTip={t('hex-type')} dataFor="hexagon-type-tooltip">
                <i className="icon icon-stack" />
                <div className="button-text">{t(`hex-${hexType}`)}</div>
                <div className="menu menu-vertical" style={{ display: openMenu ? 'block' : 'none' }}>
                  {AuthUtil.isFeatureEnabled('hex-type-stops') && (
                    <div
                      className={`menu-item ${getActiveClassIfCurrentHexType(HexTypes.HEX_TYPE.STOPS)}`}
                      onClick={(opt) => changeHexType(opt.currentTarget.dataset.value)}
                      data-value={HexTypes.HEX_TYPE.STOPS}
                    >
                      <span className="selection-label">{t(`hex-${HexTypes.HEX_TYPE.STOPS}`)}</span>
                    </div>
                  )}
                  {AuthUtil.isFeatureEnabled('hex-type-packages') && (
                    <div
                      className={`menu-item ${getActiveClassIfCurrentHexType(HexTypes.HEX_TYPE.PACKAGES)}`}
                      onClick={(opt) => changeHexType(opt.currentTarget.dataset.value)}
                      data-value={HexTypes.HEX_TYPE.PACKAGES}
                    >
                      <span className="selection-label">{t(`hex-${HexTypes.HEX_TYPE.PACKAGES}`)}</span>
                    </div>
                  )}
                  {AuthUtil.isFeatureEnabled('hex-type-shipments') && (
                    <div
                      className={`menu-item ${getActiveClassIfCurrentHexType(HexTypes.HEX_TYPE.SHIPMENTS)}`}
                      onClick={(opt) => changeHexType(opt.currentTarget.dataset.value)}
                      data-value={HexTypes.HEX_TYPE.SHIPMENTS}
                    >
                      <span className="selection-label">{t(`hex-${HexTypes.HEX_TYPE.SHIPMENTS}`)}</span>
                    </div>
                  )}
                  {AuthUtil.isFeatureEnabled('hex-type-shipments-with-one-package') && (
                    <div
                      className={`menu-item ${getActiveClassIfCurrentHexType(HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE)}`}
                      onClick={(opt) => changeHexType(opt.currentTarget.dataset.value)}
                      data-value={HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE}
                    >
                      <span className="selection-label">{t(`hex-${HexTypes.HEX_TYPE.SHIPMENTS_WITH_ONE_PACKAGE}`)}</span>
                    </div>
                  )}

                  {AuthUtil.isFeatureEnabled('hex-type-shipments-for-package-lockers') && (
                    <div
                      className={`menu-item ${getActiveClassIfCurrentHexType(HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS)}`}
                      onClick={(opt) => changeHexType(opt.currentTarget.dataset.value)}
                      data-value={HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS}
                    >
                      <span className="selection-label">{t(`hex-${HexTypes.HEX_TYPE.SHIPMENTS_FOR_PACKAGE_LOCKERS}`)}</span>
                    </div>
                  )}
                </div>
              </MapModeButton>
              {additionalMapFeatures
                && additionalMapFeatures.map((featureType) => (
                  <MapModeButton
                    key={featureType}
                    isActive={showAdditionalMapFeatures[featureType]}
                    dataTip={t(`${MAP_ADDITIONAL_FEATURES_DATA[featureType].label}`)}
                    onClick={toggleSomething}
                    dataValue={featureType}
                  >
                    <i className={`icon ${MAP_ADDITIONAL_FEATURES_DATA[featureType].icon}`} />
                  </MapModeButton>
                ))}
            </div>
            {!openMenu && <ReactTooltip id="hexagon-type-tooltip" className="tooltip" place="right" effect="solid" />}
          </div>

          <div className="map">
            <RegionAnalysisMap
              centerPositionData={lastSelectedCenterPosition}
              updateRegionStopsDistributionAction={RegionAnalysisActions.updateRegionStopsDistribution}
              mapSplitMode="standard"
              optimizedMode={optimizedMode}
              showRegionStopsDistributionChart={showRegionStopsDistributionChart}
              shouldResize={shouldResize}
              setCurrentLeftMapPosition={setCurrentLeftMapPosition}
              setCenterTotalsInfo={setCenterTotalsInfo}
              hexType={hexType}
              additionalMapFeatures={showAdditionalMapFeatures}
            />
            <div className="map-buttons">
              {AuthUtil.isFeatureEnabled('regionOptimization') && (
                <div
                  className={optimizedMode ? 'map-type-button button active' : 'map-type-button button'}
                  onClick={changeOptimizedMode}
                  onMouseOver={() => setButtonLegend(t('regionsOptimizeHint'))}
                  onFocus={() => {
                    return null;
                  }}
                  onMouseOut={() => setButtonLegend(null)}
                  onBlur={() => {
                    return null;
                  }}
                >
                  <i className="icon icon-build" />
                </div>
              )}
              {buttonLegend && <ButtonLegend text={buttonLegend} />}
            </div>
          </div>
          <div className={showRegionStopsDistributionChart ? 'chart' : 'chart hidden'}>
            <RegionStopsDistributionChart regionStopsDistributionStoreField="regionStopsDistribution" centerTotalsInfo={centerTotalsInfo} isChartVisible />
          </div>
        </div>

        {optimizedMode && AuthUtil.isFeatureEnabled('regionOptimization') ? (
          <div className="split-screen">
            <div className="map">
              <RegionAnalysisMap
                centerPositionData={lastSelectedCenterPosition}
                updateRegionStopsDistributionAction={RegionAnalysisActions.updateOptimizedRegionStopsDistribution}
                mapSplitMode="optimized"
                optimizedMode={optimizedMode}
                showRegionStopsDistributionChart={showRegionStopsDistributionChart}
                shouldResize={shouldResize}
                initialCenterPositionData={currentLeftMapPosition}
                setCenterTotalsInfo={setOptimizedCenterTotalsInfo}
                hexType={hexType}
                additionalMapFeatures={showAdditionalMapFeatures}
              />
            </div>
            <div className={showRegionStopsDistributionChart ? 'chart' : 'chart hidden'}>
              <RegionStopsDistributionChart
                regionStopsDistributionStoreField="optimizedRegionStopsDistribution"
                centerTotalsInfo={optimizedCenterTotalsInfo}
                isChartVisible={optimizedMode}
              />
            </div>
          </div>
        ) : (
          <div className="empty-pane-children" />
        )}
      </SplitPane>
    </div>
  );
}
