import * as ActionTypes from '../constants/actionTypes';

const initialState = {
  isDialogOpen: false,
  continueCallback: () => null,
  cancelCallback: () => null,
  showNextAlertDialog: false,
  dialogComponent: null,
  dialogComponentProps: null,
  title: 'Discard changes'
};

/**
 * Reducer - SaveChangesDialog
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.APP_DIALOG_OPEN:
      return {
        ...state,
        continueCallback: action.payload.continueCallback,
        cancelCallback: action.payload.cancelCallback,
        dialogComponent: action.payload.dialogComponent,
        dialogComponentProps: action.payload.dialogComponentProps,
        isDialogOpen: true
      };
    case ActionTypes.APP_DIALOG_CLOSE:
      return {
        ...state,
        continueCallback: () => null,
        cancelCallback: () => null,
        isDialogOpen: false,
        dialogComponent: null,
        dialogComponentProps: null,
        showNextAlertDialog: action.payload && action.payload.discardChanges ? false : state.showNextAlertDialog
      };
    case ActionTypes.SAVE_CHANGES_DIALOG_SHOW_NEXT:
      return {
        ...state,
        showNextAlertDialog: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
