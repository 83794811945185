import * as React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '../../analysis/courierAnalysis/components/MapWrapper.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import CourierTeamSelect from '../../../common/components/selections/teamSelect/CourierTeamSelect';
import BackendResourceConfigUtil from '../../../common/utils/api/backendResourceConfigUtil';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import S3Util from '../../../common/utils/s3Util';
import { raygunClient } from '../../../setup/raygunClient';
import * as MapActions from '../../../state/actions/mapActions';
import './PlanTracking.scss';
import * as PageActions from '../../../state/actions/pageActions';
import * as PlanningPageActions from '../../../state/actions/planningPageActions';
import EntityUtil from '../../analysis/utils/entityUtil';
import RealTimeApi from '../../realTime/api/realTimeApi';
import RealTimeInfoLoader from '../../realTime/components/RealTimeInfoLoader';
import PlanningApi from '../api/planningApi';
import DatePickerWrapper from '../../../common/components/datePickers/DayPickerWrapper';
import CardMenuTable from '../../../common/components/tables/cardMenuTable/CardMenuTable';
import PlanningCard from '../planManagement/components/PlanningCard';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import PlanningDataUtil from '../utils/planningDataUtil';
import ShipmentsUtil from '../../../common/utils/shipmentsUtil';
import PlanTrackingPreview from './components/PlanTrackingPreview';
import SortUtil from '../../../common/utils/sortUtil';

class PlanTrackingClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: null,
      tablePageSize: 1,
      showRefresh: true,
      shipmentsData: null,
      locationData: null,
      routesData: null,
      selectedPlan: null
    };

    this.currentDate = this.getDefaultDate();
    this.route = [];

    this.courierLocationSubscription = null;
    this.shipmentEventSubscription = null;
    this.stopDataPullIntervalId = null;
  }

  componentDidMount() {
    this.props.dispatchResetPlanningData();
    this.props.dispatchLoadingPage(false);
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
    this.stopDataPull();
  }

  startDataPullProtectionInterval = () => {
    this.stopDataPullIntervalId = setTimeout(() => {
      this.stopDataPull(true);
    }, 10 * 60 * 1000);
  };

  stopDataPull = (updateState) => {
    if (this.courierLocationSubscription) {
      this.courierLocationSubscription.unsubscribe();
      this.courierLocationSubscription = null;
    }

    if (this.shipmentEventSubscription) {
      this.shipmentEventSubscription.unsubscribe();
      this.shipmentEventSubscription = null;
    }

    if (this.stopDataPullIntervalId) {
      clearTimeout(this.stopDataPullIntervalId);
      this.stopDataPullIntervalId = null;
    }

    if (updateState) {
      this.setState({ showRefresh: true });
    }
  };

  loadPlans = () => {
    if (this.currentDate && this.state.teamId) {
      this.props.dispatchLoadingPage(true);
      PlanningApi.getCouriersPlans(this.currentDate.format('YYYY-MM-DD'), this.state.teamId)
        .then((plansData) => {
          this.setState({ plans: plansData?.data?.getCouriersPlans });
          this.props.dispatchLoadingPage(false);
        })
        .catch((error) => {
          raygunClient.send(error, 'Error loading plans data', { date: this.currentDate.format('YYYY-MM-DD') });
          toast.error(this.props.t('Failed to load plan data'));
          this.props.dispatchLoadingPage(false);
        });
    }
  };

  getShipmentFromDb = (shipment, courierId) => PlanningApi.getShipmentDbData(shipment.shipmentCode).then((res) => {
    if (res?.data?.queryShipments?.items && res.data.queryShipments.items.length > 0) {
      return {
        ...shipment,
        ...this.enrichShipmentData(res.data.queryShipments.items[0], courierId)
      };
    }

    return null;
  });

  getShipmentsFromDb = (shipments, courierId) => {
    const shipmentPromises = shipments.map((shipment) => this.getShipmentFromDb(shipment, courierId));

    return Promise.all(shipmentPromises)
      .then((res) => res.flat(1))
      .catch((e) => {
        raygunClient.send(e, 'Error loading shipments from db');
        return [];
      });
  };

  enrichShipmentData = (shipment, courierId) => {
    let newShipment = ShipmentsUtil.parseShipmentsEvents(shipment);
    newShipment = ShipmentsUtil.getShipmentLatLngFromFuzzyAddress(newShipment);

    return {
      ...newShipment,
      ...ShipmentsUtil.getShipmentColorData(newShipment, courierId)
    };
  };

  showPlan = (plan) => {
    this.resetRealTimeData();
    this.stopDataPull();
    this.loadPlanData(plan);
  };

  loadPlanData = (plan) => {
    this.props.dispatchLoadingPage(true);
    const courierId = plan.courierId;
    const routePromise = PlanningDataUtil.getPlanRouteData(plan);
    const shipmentsPromise = PlanningDataUtil.getPlanShipmentsData(plan).then((shipments) => this.getShipmentsFromDb(shipments, courierId));

    Promise.all([routePromise, shipmentsPromise])
      .then((res) => {
        const [routesData, shipmentsData] = res;
        this.getCourierLocation(courierId);
        this.startDataSubscriptions(courierId);

        this.setState({
          selectedPlan: plan,
          shipmentsData: shipmentsData,
          routesData: routesData
        });
        this.props.dispatchLoadingPage(false);
      })
      .catch((err) => {
        raygunClient.send(err, 'Error loading plan data', { plan: plan });
        toast.error(this.props.t('Oops something went wrong'));
        this.props.dispatchLoadingPage(false);
      });
  };

  onDateChange = (date) => {
    this.currentDate = moment(date);
    QueryStringUtil.setQueryStringValue('planDate', moment(date).format('YYYY-MM-DD'));
    this.resetPlanData();
    this.loadPlans();
  };

  getDefaultDate = () => {
    const planDate = QueryStringUtil.getQueryStringValue('planDate');
    return moment(this.props?.history?.location?.state?.planDate || planDate);
  };

  getTableColumns = () => [
    {
      Header: null,
      accessor: 'courierFullName'
    }
  ];

  calculateTablePageSize = (height) => {
    if (this.state.tablePageSize !== height) {
      this.setState({ tablePageSize: height });
    }
  };

  resetPlanData = () => {
    this.setState({
      shipmentsData: null,
      locationData: null,
      routesData: null,
      selectedPlan: null,
      plans: null
    });
    this.route = [];
  };

  onTeamChange = async (teamId, entityType) => {
    this.setState({ teamId: teamId });
    this.props.dispatchLoadingPage(true);
    this.stopDataPull(true);
    this.resetPlanData(true);
    QueryStringUtil.setQueryStringValue('courierId', null);

    // fetch couriers
    // TODO check if we need getEntityData
    EntityUtil.getEntityData(teamId, entityType, true)
      .then(() => {
        this.loadPlans();
      })
      .catch((error) => {
        raygunClient.send(error, 'Failed to load couriers for Planning');
        toast.error(this.props.t('Failed to load couriers'));
        this.props.dispatchLoadingPage(false);
      });
  };

  resetRealTimeData = () => {
    this.route = [];
    this.setState({ locationData: null });
  };

  handleRefreshClick = () => {
    if (this.state.selectedPlan && this.state.selectedPlan.courierId) {
      this.resetRealTimeData();
      this.loadPlanData(this.state.selectedPlan);
    }
  };

  isCurrentDateToday = () => this.currentDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

  startDataSubscriptions = (courierId) => {
    if (courierId && this.isCurrentDateToday()) {
      this.subscribeToEventChange(courierId);
      this.subscribeToCourierLocation(courierId);
      this.startDataPullProtectionInterval();
      this.setState({ showRefresh: false });
    }
  };

  getCourierLocation = (courierId) => {
    if (courierId) {
      if (this.isCurrentDateToday()) {
        RealTimeApi.getCourierLocation(courierId)
          .then((res) => {
            if (res?.data?.getCourierLocation) {
              this.route = res.data.getCourierLocation.route || [];
              this.setState({ locationData: res.data.getCourierLocation });
            }
          })
          .catch((e) => {
            raygunClient.send(e, 'Error loading courier location data for real time', { courierId: courierId });
            toast.error(this.props.t('Oops something went wrong'));
            this.stopDataPull(true);
          });
      } else {
        const fileName = `${courierId}/${this.currentDate.format('YYYY-MM-DD')}_${courierId}_locations.json`;
        S3Util.getFileFromS3(fileName, { download: true, bucket: BackendResourceConfigUtil.getGpsBucketName() })
          .then((content) => JSON.parse(content))
          .then((route) => {
            this.setState({ locationData: { route: route } });
            this.route = route || [];
          })
          .catch((e) => {
            raygunClient.send(e, 'Error loading courier location data', { courierId: courierId });
            toast.error(this.props.t('Oops something went wrong'));
            this.stopDataPull(true);
          });
      }
    }
  };

  subscribeToEventChange = (courierId) => {
    if (courierId) {
      const subscription = RealTimeApi.onShipmentEventChange(courierId);
      this.shipmentEventSubscription = subscription.subscribe({
        next: (data) => {
          const newShipmentData = [...this.state.shipmentsData];
          const updatedShipmentCode = data.data.updateShipmentEvent.shipmentId || null;
          if (updatedShipmentCode) {
            const oldShipment = newShipmentData.find((s) => s.shipmentCode === updatedShipmentCode);
            this.getShipmentFromDb(oldShipment, courierId).then((newShipment) => {
              const index = newShipmentData.indexOf(oldShipment);
              newShipmentData[index] = newShipment;
              this.setState({ shipmentsData: newShipmentData });
            });
          }
        },
        error: (e) => {
          raygunClient.send(e, 'Error loading subscription data for real time');
          toast.error(this.props.t('Oops something went wrong'));
          this.stopDataPull(true);
        }
      });
    }
  };

  subscribeToCourierLocation = (courierId) => {
    if (courierId) {
      const subscription = RealTimeApi.onLocationChange(courierId);

      this.courierLocationSubscription = subscription.subscribe({
        next: (data) => {
          const courierLocation = data.data.updateCourierLocation;
          this.route.push(...courierLocation.route);
          this.route.filter((point) => point.gpsTimestamp < this.currentDate.format('YYYY-MM-DD'));
          this.route = SortUtil.sortRouteLocations(this.route);
          this.setState({ locationData: { lat: courierLocation.lat, lng: courierLocation.lng, route: this.route } });

          return data;
        },
        error: (e) => {
          raygunClient.send(e, 'Error loading subscription data for real time');
          toast.error(this.props.t('Oops something went wrong'));
          this.stopDataPull(true);
        }
      });
    }
  };

  render() {
    return (
      <div className="plan-tracking-page">
        <div className="menu-wrapper">
          <div className="selection-wrapper">
            <div className="label">{this.props.t('Team')}</div>
            <CourierTeamSelect onTeamChange={this.onTeamChange} eventTrackerNamePrefix="Planning" />
          </div>
          <DatePickerWrapper onDateChange={this.onDateChange} selectedDay={this.currentDate.toDate()} disableFutureDays />
          <RealTimeInfoLoader showRefresh={this.state.showRefresh} handleRefreshClick={this.handleRefreshClick} />
          <div className="menu">
            {this.state.plans && this.state.plans.length > 0 && (
              <CardMenuTable
                key={this.state.tablePageSize}
                columns={this.getTableColumns()}
                data={this.state.plans}
                resetOn={this.state.teamId}
                sortBy={[{ id: 'name', desc: false }]}
                pageSize={this.state.tablePageSize}
                handleRowClick={this.showPlan}
                cardItemComponent={PlanningCard}
                hiddenColumns={['courierId']}
                defaultPageSize={this.state.tablePageSize}
                calculateTablePageSize={this.calculateTablePageSize}
                selectedIndex={this.state?.selectedPlan?.region}
                showSearch
              />
            )}
          </div>
        </div>
        {this.state.selectedPlan ? (
          <div className="map-wrapper">
            <PlanTrackingPreview
              routesData={this.state.routesData ? [this.state.routesData] : null}
              shipmentsData={this.state.shipmentsData ? [this.state.shipmentsData] : null}
              locationData={this.state.locationData}
              locationLive={!this.state.showRefresh}
            />
          </div>
        ) : (
          <div className="empty-page">
            <i className="icon icon-mily-logo" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return { ...store.planningPageState };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchIsMapLoading: MapActions.mapIsLoading,
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchResetPlanningData: PlanningPageActions.resetData
    },
    dispatch
  );
}

export default withTranslation('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanTrackingClass)));

PlanTrackingClass.propTypes = {
  history: PropTypes.object.isRequired,
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  dispatchResetPlanningData: PropTypes.func.isRequired
};

PlanTrackingClass.defaultProps = {};
