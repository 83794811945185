import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import { FEATURE_KEY_ICON_MAP } from '../../../../features/analysis/regionAnalysis/constants/mapAdditionalFeaturesData';
import './MultipleMarkersPopup.scss';
import { PauseCalculationUtil } from '../../../../features/analysis/routeAnalysis/utils/pauseCalculationUtil';
import CopyToClipboardWrapper from '../../copy/CopyToClipboardWrapper';
import MarkerPopupCircleIcon from './MarkerPopupCircleIcon';
import MarkerPopupRowWithIcon from './MarkerPopupRowWithIcon';

export default function MultipleMarkersPopup(props) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { t } = useTranslation();

  function renderAdditionalData(data) {
    const dataKeys = data.keyOrder || Object.keys(data);
    return dataKeys.map((key) => {
      if (key === 'shipmentCode' || key === 'name') {
        return null;
      }

      if (FEATURE_KEY_ICON_MAP[key] && data[key]) {
        return <MarkerPopupRowWithIcon key={key} dataKey={key} value={data[key]} />;
      }

      return null;
    });
  }

  function getStopsDescriptionClass(data) {
    return PauseCalculationUtil.checkIfPauseBetweenStops(data.distanceFromPrevStop.time) ? 'pause' : '';
  }

  function toggleAdditionalData(e) {
    const clickedIndex = parseInt(e.currentTarget.dataset.value, 10);
    setSelectedIndex(clickedIndex === selectedIndex ? -1 : clickedIndex);
  }

  return (
    <div className="multiple-markers-popup">
      <SimpleBar style={{ maxHeight: '320px' }}>
        <div className="card-wrapper">
          {props.data
            && props.data.map((data, i) => (
              <div key={data.shipmentCode}>
                <div className={selectedIndex === i ? 'card-header selected' : 'card-header'}>
                  <MarkerPopupCircleIcon className={data.className} value={data.stopIndex} />
                  <div className="header-data-wrapper">
                    <div className="delivery-type-wrapper">
                      {data.deliveryType && <span className={`delivery-type ${data.className}`}>{t(data.deliveryType)}</span>}
                      {data.additionalDeliveryTypeDescription && (
                        <div>
                          <span className={`delivery-type ${data.className}`}>-</span>
                          <span className={`delivery-type ${data.className}`}>{data.additionalDeliveryTypeDescription}</span>
                        </div>
                      )}
                      <div className="shipment-code">
                        {data.shipmentCode}
                        {' '}
                        <CopyToClipboardWrapper text={data.shipmentCode} />
                      </div>
                    </div>
                    <div className="name">{data.name}</div>
                  </div>
                  {props.data.length > 1 && (
                    <div className="arrow-wrapper" onClick={toggleAdditionalData} data-value={i}>
                      {i === selectedIndex ? <i className="icon icon-keyboard-arrow-down" /> : <i className="icon icon-keyboard-arrow-right" />}
                    </div>
                  )}
                </div>
                <div>
                  {((selectedIndex !== -1 && selectedIndex === i) || props.data.length === 1) && renderAdditionalData(data)}
                  {((selectedIndex !== -1 && selectedIndex === i) || props.data.length === 1) && data.distanceFromPrevStop && (
                    <div>
                      <div className="distance">
                        {t('From the previous stop')}
                        {' '}
                        <br />
                        <b>
                          {data.distanceFromPrevStop.distance ? t('Distance', { distance: data.distanceFromPrevStop.distance }) : t('Unknown')}
                          {' '}
                          <span className={getStopsDescriptionClass(data)}>
                            {' '}
                            {t('Time ago', { time: data.distanceFromPrevStop.time })}
                            {' '}
                          </span>
                        </b>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </SimpleBar>
    </div>
  );
}

MultipleMarkersPopup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      workingHours: PropTypes.string,
      address: PropTypes.string,
      className: PropTypes.string,
      deliveryType: PropTypes.string,
      keyOrder: PropTypes.arrayOf(PropTypes.string),
      distanceFromPrevStop: PropTypes.shape({
        distance: PropTypes.string,
        time: PropTypes.string
      })
    })
  )
};

MultipleMarkersPopup.defaultProps = { data: null };
