import { gql } from '@apollo/client';
import { appSyncClient } from '../../../../setup/appSyncClient';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import { DB_TYPES } from '../../../../common/constants/dbTypes';

const TeamsApi = {
  getTeams: getTeams,
  deleteTeam: deleteTeam,
  upsertTeam: upsertTeam
};

const GetTeams = gql`
  query getTeams($pk: String) {
    getTeams(pk: $pk) {
      id
      name
      description
      teamType
      integrationId
    }
  }
`;

const UpsertTeamWithMapping = gql`
  mutation UpsertTeamWithMapping(
    $pk: String
    $sk: String
    $id: String
    $name: String
    $description: String
    $teamType: String
    $type: String
    $integrationId: String
    $mappingPk: String
    $mappingSk: String
    $mappingType: String
  ) {
    upsertDataWithMapping(
      pk: $pk
      sk: $sk
      input: {
        teamInputData: { id: $id, name: $name, type: $type, description: $description, teamType: $teamType, integrationId: $integrationId }
        mappingInputData: { pk: $mappingPk, sk: $mappingSk, id: $integrationId, milyId: $id, type: $mappingType }
      }
    )
  }
`;

const DeleteEntityWithMapping = gql`
  mutation DeleteEntityWithMapping($pk: String, $sk: String, $mappingPk: String, $mappingSk: String) {
    deleteDataWithMapping(pk: $pk, sk: $sk, input: { mappingInputData: { pk: $mappingPk, sk: $mappingSk } })
  }
`;

async function getTeams() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetTeams, { pk: 'TEAM' });
  }

  return fetch('testData/management/teams.json').then((data) => data.json());
}

async function upsertTeam(id, data) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpsertTeamWithMapping,
      variables: {
        pk: DB_TYPES.TEAM,
        sk: `${DynamoStringExtensionsUtil.getTeamPrefix()}${id}`,
        id: id,
        name: data.name,
        description: data.description,
        teamType: data.teamType.value,
        type: DB_TYPES.TEAM,
        integrationId: data.integrationId,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getTeamPrefix()}${id}`,
        mappingType: DB_TYPES.INTEGRATION_MAPPING
      }
    });
  }

  return null;
}

async function deleteTeam(id) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: DeleteEntityWithMapping,
      variables: {
        pk: DB_TYPES.TEAM,
        sk: `${DynamoStringExtensionsUtil.getTeamPrefix()}${id}`,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getTeamPrefix()}${id}`
      }
    });
  }

  return null;
}

export default TeamsApi;
