import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import './StatisticPage.scss';
import CourierTeamSelect from '../../../common/components/selections/teamSelect/CourierTeamSelect';
import { ENTITY_TYPE } from '../../../common/constants/entityTypes';
import AuthUtil from '../../../common/utils/authUtil';
import { raygunClient } from '../../../setup/raygunClient';
import * as PageActions from '../../../state/actions/pageActions';
import CouriersChart from './components/CouriersChart';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import MixPanel from '../../../setup/mixPanel';
import RangeDayPicker from '../regionAnalysis/components/RangeDayPicker';
import InfoDialogWrapper from '../../../common/components/dialogs/infoDialog/InfoDialogWrapper';
import * as RegionAnalysisActions from '../../../state/actions/regionAnalysisActions';
import DataCard from '../../../common/components/cards/DataCard';
import DaysChart from './components/DaysChart';
import TableComponent from '../../../common/components/tables/tableComponent/TableComponent';
import colorsAndFonts from '../../../resources/colors-and-fonts.scss';
import StatisticsApi from './api/statisticsApi';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import S3Util from '../../../common/utils/s3Util';
import Button from '../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../common/components/dialogs/utils/appDialogActionsWrapper';
import ReportForm from './components/ReportForm';

/**
 * Page for displaying statistics
 *
 * @returns {JSX.Element} - Page for displaying statistics
 * @component
 * @alias StatisticPage
 * @category statistics
 */
export default function StatisticPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dateFrom = useSelector((state) => moment(state.regionAnalysisState.regionsDateFrom).format('YYYY-MM-DD'));
  const dateTo = useSelector((state) => moment(state.regionAnalysisState.regionsDateTo).format('YYYY-MM-DD'));
  const [teamId, setTeamId] = useState(QueryStringUtil.getQueryStringValue('teamId'));
  const [teamName, setTeamName] = useState(null);
  const [teamType, setTeamType] = useState(getTeamType(teamId));
  const [statisticsData, setStatisticsData] = useState(null);
  const [tablePageSize, setTablePageSize] = useState(1);

  useEffect(() => {
    MixPanel.track('Page Load - Statistics');
    MixPanelUtil.setUnloadListener('Page Unload - Statistics');
    return () => {
      MixPanelUtil.removeUnloadListener();
    };
  }, []);

  useEffect(() => {
    /**
     * Fetches stops statistics
     *
     * @returns {object} stops statistics per courier
     * @function
     */
    const getData = async () => {
      if (teamId) {
        try {
          dispatch(PageActions.loadingPage(true));
          const stopsStatistics = await StatisticsApi.getStatisticsStops(teamId, dateFrom, dateTo);
          setStatisticsData(stopsStatistics?.data?.getStatistics);
        } catch (error) {
          console.error(error);
          toast.error(t('Failed to load statistics'));
        } finally {
          dispatch(PageActions.loadingPage(false));
        }
      }
      return [];
    };
    getData();
  }, [dateTo, dateFrom, teamId, dispatch]);

  const generatePerformanceReportFile = useCallback((reportFormData) => {
    MixPanel.track('Statistics - Generate performance report');
    dispatch(PageActions.loadingPage(true));
    StatisticsApi.getPerformanceReportFile(
      teamId,
      dateFrom,
      dateTo,
      reportFormData.fileName,
      reportFormData.fileFormat,
      optionConvertToBool(reportFormData.weekend)
    )
      .then((file) => {
        if (file?.data?.getReport?.fileName && file?.data?.getReport?.bucket) downloadPerformanceReportFileS3(file.data.getReport);
        else {
          raygunClient.send('Client error', 'api call getPerformanceReportFile didnt return right data', file);
          toast.error(t('Oops something went wrong'));
        }
      })
      .then(() => {
        dispatch(PageActions.loadingPage(false));
      })
      .catch((err) => {
        if (err.graphQLErrors[0].message === 'No data') {
          toast.error(t('No data for selected filters'));
        } else {
          raygunClient.send(err, 'Error generating performance report', { teamId, dateFrom, dateTo, reportFormData });
          toast.error(t('Oops something went wrong'));
        }
        dispatch(PageActions.loadingPage(false));
      });
  }, [dispatch, dateTo, dateFrom, teamId, teamName]);

  const createReportButtonAction = useCallback(() => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: ReportForm,
      dialogComponentProps: {
        data: {
          dateFrom: dateFrom,
          dateTo: dateTo,
          teamId: teamId,
          teamName: teamName
        },
        submitCallback: generatePerformanceReportFile,
        cancelCallback: () => {
        },
        t: t,
        title: t('Create report')
      }
    });
  }, [dateTo, dateFrom, teamId, teamName, generatePerformanceReportFile, t]);

  function optionConvertToBool(option) {
    return option === 'Yes';
  }

  async function downloadPerformanceReportFileS3(reportData) {
    S3Util.downloadFileFromS3(reportData.fileName, {
      download: true,
      bucket: reportData.bucket,
      cacheControl: 'no-cache'
    });
  }

  function getTeamType(id) {
    return (AuthUtil.getTeams() && AuthUtil.getTeams()[id] && AuthUtil.getTeams()[id].teamType) || ENTITY_TYPE.COURIERS;
  }

  const handleTeamChanged = async (newTeam) => {
    setTeamId(newTeam.teamId);
    setTeamName(newTeam.teamName);
    setTeamType(newTeam.teamType);
  };

  /**
   * Column structure for courier stops table
   *
   * @type {object}
   */
  const columns = React.useMemo(() => {
    /**
     * Generates quick link to courier analysis page for specific courier and selected dates
     *
     * @param {object} row - row in a table
     * @returns {string} url
     * @function
     */
    const generateLink = (row) => {
      const courierId = row.cells.find((cell) => cell.column.id === 'courierId').value;
      const from = moment(dateFrom).startOf('isoWeek').format('YYYY-MM-DDTHH');
      const to = moment(dateFrom).endOf('isoWeek').format('YYYY-MM-DDTHH');

      return `/courier-analysis?team=${teamId}&courierId=${courierId}&fromDate=${from}&toDate=${to}`;
    };

    const tableColumns = [
      {
        Header: 'Id',
        accessor: 'courierId' // accessor is the "key" in the data
      },
      {
        Header: t('Name'),
        accessor: 'name',
        className: 'flex-3'
      },
      {
        Header: t('Stops'),
        accessor: 'stops'
      },
      {
        Header: t('Working days'),
        accessor: 'workingDays'
      }
    ];

    if (teamType === ENTITY_TYPE.COURIERS) {
      if (AuthUtil.getTenantId() === 'dexpress') {
        tableColumns.push({
          Header: t('Breaks'),
          accessor: 'numberOfBreaks'
        });

        tableColumns.push({
          Header: t('Time out of region'),
          accessor: 'timeOutOfRegion'
        });
      }

      if (AuthUtil.getTenantId() === 'xexpress') {
        tableColumns.push({
          Header: t('Mileage'),
          accessor: 'mileage'
        });
      }
    }

    tableColumns.push({
      Header: t('Link'),
      id: 'actions',
      Cell: (cell) => {
        const link = generateLink(cell.row);

        return (
          <a
            className="profile-link"
            href={link}
            onClick={logAction}
            target="_blank"
            rel="noopener noreferrer"
            data-tip={`${t('showCourier')}`}
            data-for="action-tooltip"
          >
            <i className="icon icon-open-in-new" />
            <ReactTooltip id="action-tooltip" className="tooltip" effect="solid" />
          </a>
        );
      },
      className: 'centered'
    });

    return tableColumns;
  }, [dateFrom, teamId, t, teamType]);

  function logAction() {
    MixPanel.track('Statistics - Courier link clicked');
  }

  /**
   * Dispatches event that date range was changed based on the date provided in the event
   *
   * @param {object} e - event
   * @function
   */
  const setDateFilters = (e) => {
    let startDate = null;
    let endDate = null;

    switch (e.target.dataset.target) {
      case 'last7Days':
        startDate = moment().subtract(8, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Statistics - Date changed - Last 7 days', {});
        break;
      case 'last30Days':
        startDate = moment().subtract(31, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Statistics - Date changed - Last 30 days', {});
        break;
      default:
        startDate = moment().subtract(1, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Statistics - Date changed - Yesterday', {});
    }

    dispatch(RegionAnalysisActions.changeDateRange(startDate, endDate));
    QueryStringUtil.setQueryStringValue('fromDate', moment(startDate).startOf('day').format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('toDate', moment(endDate).endOf('day').format('YYYY-MM-DDTHH'));
  };

  const calculateTablePageSize = useCallback((pageSize) => {
    if (tablePageSize === 1 || tablePageSize < pageSize) {
      setTablePageSize(pageSize);
    }
  }, [tablePageSize, setTablePageSize]);

  /**
   * Generates insights alerts
   *
   * @returns {JSX.Element} insights component for the dashboard
   * @function
   */
  const createAlertsFromInsights = () => {
    const insights = statisticsData?.insights;
    if (!insights || !insights.bestDay || !insights.worstDay || !insights.percentUnderAverage || !insights.performanceInfo) {
      return <div className="no-data">{t('No data for selected dates')}</div>;
    }

    const bestDayDate = moment(insights.bestDay.date).format('DD.MM.YYYY');
    const bestDayStops = insights.bestDay.stops;

    const worstDayDate = moment(insights.worstDay.date).format('DD.MM.YYYY');
    const worstDayStops = insights.worstDay.stops;

    const underperformingPercent = insights.performanceInfo.underperformingPercentage;
    const underperformingTotal = insights.performanceInfo.underperformingTotal;
    const underperformingLimit = insights.performanceInfo.underperformingLimit;

    const overperformingPercent = insights.performanceInfo.overperformingPercentage;
    const overperformingTotal = insights.performanceInfo.overperformingTotal;
    const overperformingLimit = insights.performanceInfo.overperformingLimit;

    return (
      <div className="insights-section">
        <InfoDialogWrapper title={t('Insights')} popupText={t('statisticInsightsInfoDialog')} eventName="Statistics - Info - Insights" />
        <div className="insight">
          <i className="icon icon-lightbulb" />
          {t('best-day-info', { bestDayDate, bestDayStops })}
        </div>
        <div className="insight">
          <i className="icon icon-lightbulb" />
          {t('worst-day-info', { worstDayDate, worstDayStops })}
        </div>
        <div className="insight">
          <i className="icon icon-lightbulb" />
          {t(`underperforming-info-${teamType}`, { underperformingPercent, underperformingTotal, underperformingLimit })}
        </div>
        <div className="insight">
          <i className="icon icon-lightbulb" />
          {t(`overperforming-info-${teamType}`, { overperformingPercent, overperformingTotal, overperformingLimit })}
        </div>
      </div>
    );
  };

  const totals = statisticsData?.totals;
  const statisticsDays = statisticsData?.days;
  const couriers = statisticsData?.couriers;

  return (
    <div className="statistic-page">
      <div className="selection-section">
        <div className="select-wrapper">
          <div className="label">{t('Team')}</div>
          <CourierTeamSelect onTeamChange={handleTeamChanged} returnFullObject eventTrackerNamePrefix="Statistics" />
        </div>
        <div className="date-range">
          <div className="date-picker-wrapper">
            <div className="label">{t('Date range')}</div>
            <RangeDayPicker eventTrackerNamePrefix="Statistics" />
          </div>
          <div className="quick-links">
            <div onClick={setDateFilters} data-target="yesterday">
              {t('yesterday')}
            </div>
            <div onClick={setDateFilters} data-target="last7Days">
              {t('last7Days')}
            </div>
            <div onClick={setDateFilters} data-target="last30Days">
              {t('last30Days')}
            </div>
          </div>
        </div>
        {AuthUtil.isFeatureEnabled('createReports') && (
          <div className="report-button">
            <Button onClick={createReportButtonAction} text={t('Create report')} type="cancel" />
          </div>
        )}
      </div>
      <div className="dashboard-grid">
        <div className="cards-wrapper">
          {totals ? (
            <div className="data-cards">
              <DataCard title={t('Total stops')} value={totals.total} />
              <DataCard title={t('Average stops per day')} value={totals.avgPerDay} borderColor={colorsAndFonts.dashboard_color_1} />
              <DataCard title={t(`Average stops per ${teamType}`)} value={totals.avgPerCourier} borderColor={colorsAndFonts.selection_color} />
            </div>
          ) : (
            <div className="no-data">{t('No data for selected dates')}</div>
          )}
        </div>
        <div className="table-wrapper">
          <div className="table">
            {couriers && couriers.length > 0 ? (
              <TableComponent
                key={tablePageSize}
                columns={columns}
                data={couriers}
                resetOn={teamId}
                paginationLabel={t(`${teamType}-pagination`)}
                pageSize={tablePageSize}
                calculateTablePageSize={calculateTablePageSize}
                pageUsing="Statistics"
                autoResetPage
              />
            ) : (
              <div className="no-data">{t('No data for selected dates')}</div>
            )}
          </div>
        </div>
        <div className="insight-wrapper">{createAlertsFromInsights()}</div>
        <div className="couriers-chart-wrapper">
          <div className="chart-wrapper">
            <InfoDialogWrapper
              title={t(`Stops per ${teamType}`)}
              popupText={t(`statisticCouriersStopsChartText-${teamType}`)}
              eventName={`Statistics - Info - Stops per ${teamType}`}
            />
            {couriers && couriers.length > 0 ? <CouriersChart data={couriers} /> : <div className="no-data">{t('No data for selected dates')}</div>}
          </div>
        </div>
        <div className="stops-chart-wrapper">
          <div className="chart-wrapper">
            <InfoDialogWrapper title={t('Stops per day')} popupText={t('statisticDaysStopsChartText')} eventName="Statistics - Info - Stops per day" />
            {statisticsDays && statisticsDays.length > 0 ? (
              <DaysChart data={statisticsDays} />
            ) : (
              <div className="no-data">{t('No data for selected dates')}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
