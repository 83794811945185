import * as h3 from 'h3-js';

/**
 * Util for map polygon related functionalities
 *
 * @namespace
 * @category Common
 */
const PolygonUtil = {
  getPolygonCentroid: getPolygonCentroid,
  getPolygonCentroidHex: getPolygonCentroidHex
};

/**
 * Calculates center of geojson
 *
 * @param {object} pts - points
 * @returns {Array.<number>} polygon center [lat, lng]
 * @memberOf PolygonUtil
 * @function
 */
function getPolygonCentroid(pts) {
  const first = pts[0].map((p) => parseFloat(p));
  const last = pts[pts.length - 1].map((p) => parseFloat(p));

  if (first[0] !== last[0] || first[1] !== last[1]) {
    pts.push(first);
  }

  let twicearea = 0;
  let x = 0;
  let y = 0;
  const nPts = pts.length;
  let p1;
  let p2;
  let f;

  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i].map((p) => parseFloat(p));
    p2 = pts[j].map((p) => parseFloat(p));
    f = (p1[1] - first[1]) * (p2[0] - first[0]) - (p2[1] - first[1]) * (p1[0] - first[0]);
    twicearea += f;
    x += (p1[0] + p2[0] - 2 * first[0]) * f;
    y += (p1[1] + p2[1] - 2 * first[1]) * f;
  }
  f = twicearea * 3;

  return [x / f + first[0], y / f + first[1]];
}

/**
 * Calculates center of geojson
 *
 * @param {object} pts - points
 * @returns {Array.<number>} polygon hexagon center [lat, lng]
 * @memberOf PolygonUtil
 * @function
 */
function getPolygonCentroidHex(pts, maxHexId) {
  const center = PolygonUtil.getPolygonCentroid(pts);
  if (!inside(center, pts) && maxHexId) {
    return h3.h3ToGeoBoundary(maxHexId, true)[0];
  }

  return center;
}

/**
 * @param {Array} point - point
 * @param {Array} vs - polygon vertices
 * @returns {boolean} true if point is inside, otherwise false
 */
function inside(point, vs) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  const x = point[0];
  const y = point[1];

  let isInside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) isInside = !isInside;
  }
  return isInside;
}

export default PolygonUtil;
