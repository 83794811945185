import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import './RegionStopsDistributionChart.scss';
import { bindActionCreators } from 'redux';
import AuthUtil from '../../../../common/utils/authUtil';
import * as RegionAnalysisActions from '../../../../state/actions/regionAnalysisActions';
import MixPanel from '../../../../setup/mixPanel';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';

/**
 * Region distribution Bar chart which shows distributions of stops per region
 * in a bar chart form implemented via d3.js.
 *
 * @component
 * @alias RegionStopsDistributionChart
 * @category RegionAnalysis
 */
class RegionStopsDistributionChartClass extends React.Component {
  /**
   * Calculates average number of stops for provided regions
   *
   * @returns {number} Average number of stops for provided regions
   * @function
   */
  getAvg = () => {
    const regionStopsDistribution = this.props[this.props.regionStopsDistributionStoreField];
    const sum = regionStopsDistribution.reduce((acc, value) => acc + value.count, 0);
    return sum / regionStopsDistribution.length || 0;
  };

  /**
   * Creates Tooltip to be shown on a Bar hover event
   *
   * @param {object} options - tooltip options
   * options.active - visibility of the element
   * options.payload - region name and stops count
   * @returns {JSX.Element|null} <div> element which shows region name and stops count on bar hover
   * @function
   */
  BarChartTooltip = (options) => {
    const { active } = options;
    const { payload } = options;
    if (!active) return null;

    return (
      <div className="bar-chart-tooltip">
        <p className="label">{payload[0].payload.name}</p>
        <p className="count">
          {this.props.t('Count')}
          :
          {payload[0].payload.count}
        </p>
      </div>
    );
  };

  createChartStatistic = () => {
    const { centerTotalsInfo } = this.props;
    const { length } = this.props.centerTotalsInfo;
    const data = [];
    if (AuthUtil.isFeatureEnabled('hex-type-stops')) {
      data.push({
        key: 'stops',
        titleTotal: this.props.t('Total stops'),
        valueTotal: centerTotalsInfo.stops,
        titleAvg: this.props.t('Average stops'),
        valueAvg: Math.round(centerTotalsInfo.stops / length)
      });
    }

    if (AuthUtil.isFeatureEnabled('hex-type-shipments')) {
      data.push({
        key: 'shipments',
        titleTotal: this.props.t('Total shipments'),
        valueTotal: centerTotalsInfo.shipments,
        titleAvg: this.props.t('Average shipments'),
        valueAvg: Math.round(centerTotalsInfo.shipments / length)
      });
    }

    if (AuthUtil.isFeatureEnabled('hex-type-packages')) {
      data.push({
        key: 'packages',
        titleTotal: this.props.t('Total packages'),
        valueTotal: centerTotalsInfo.packages,
        titleAvg: this.props.t('Average packages'),
        valueAvg: Math.round(centerTotalsInfo.packages / length)
      });
    }

    if (AuthUtil.isFeatureEnabled('hex-type-shipments-with-one-package')) {
      data.push({
        key: 'shipmentsWithOnePackage',
        titleTotal: this.props.t('Total shipments with one package'),
        valueTotal: centerTotalsInfo['shipments-with-one-package'],
        titleAvg: this.props.t('Average shipments with one package'),
        valueAvg: Math.round(centerTotalsInfo['shipments-with-one-package'] / length)
      });
    }

    if (AuthUtil.isFeatureEnabled('hex-type-shipments-for-package-lockers')) {
      data.push({
        key: 'shipmentsForPackageLockers',
        titleTotal: this.props.t('Total shipments for package lockers'),
        valueTotal: centerTotalsInfo['shipments-for-package-lockers'],
        titleAvg: this.props.t('Average shipments for package lockers'),
        valueAvg: Math.round(centerTotalsInfo['shipments-for-package-lockers'] / length)
      });
    }

    return (
      <div className="flex-container-totals-info">
        {data.map((item) => (
          <div className="flex-one-total-info" key={item.key}>
            <div className="stat-group">
              <div className="flex-item-title">
                {item.titleTotal}
                {' '}
              </div>
              <div className="flex-item-value">{item.valueTotal}</div>
            </div>
            <div className="stat-group">
              <div className="flex-item-title">
                {item.titleAvg}
                {' '}
              </div>
              <div className="flex-item-value">{item.valueAvg}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const regionStopsDistribution = this.props[this.props.regionStopsDistributionStoreField];

    if (!regionStopsDistribution || regionStopsDistribution.length < 1 || !this.props.isChartVisible) return <span />;

    return (
      <div className="bottom-content">
        {this.createChartStatistic()}
        <div className="div-responsive-container">
          <ResponsiveContainer width="99%" height="100%">
            <BarChart className="custom-bar-chart" data={regionStopsDistribution}>
              <XAxis dataKey="name" tick={{ fill: colorsAndFonts.light_text, fontSize: 10 }} interval={0} angle={-45} textAnchor="end" />
              <YAxis dataKey="count" tick={{ fill: '#fff' }} />
              <CartesianGrid strokeDasharray="3 3" stroke="#fff" line={{ fill: '#fff' }} style={{ opacity: 1 }} vertical={false} />
              <Tooltip cursor={{ fill: 'transparent' }} content={this.BarChartTooltip} />
              <Bar dataKey="count" fill="#8884d8">
                {regionStopsDistribution.map((entry) => (
                  <Cell
                    className="bar-cell"
                    key={entry.regionId}
                    fill={entry.color}
                    onMouseOver={() => {
                      this.props.dispatchHighlightRegion(entry.regionId, 0.8, false);
                      MixPanel.track(`Region Analysis - BarChart - Bar hovered ${entry.regionId}`);
                    }}
                    onMouseOut={() => this.props.dispatchHighlightRegion(entry.regionId, 0.4, false)}
                    onClick={() => {
                      this.props.dispatchHighlightRegion(entry.regionId, 0.8, true);
                      MixPanel.track(`Region Analysis - BarChart - Bar clicked ${entry.regionId}`);
                    }}
                  />
                ))}
              </Bar>
              <ReferenceLine y={this.getAvg()} stroke="red" strokeDasharray="3 3" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.regionAnalysisState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchHighlightRegion: RegionAnalysisActions.highlightRegion },
    dispatch
  );
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RegionStopsDistributionChartClass));

RegionStopsDistributionChartClass.propTypes = {
  /**
   * Info about total counts of package, stops, shipments for a one Center.
   */
  centerTotalsInfo: PropTypes.object.isRequired,
  /**
   * Translation function
   */
  t: PropTypes.func.isRequired,
  /**
   * Name of the prop from which region distribution should be extracted
   */
  regionStopsDistributionStoreField: PropTypes.string.isRequired,
  /**
   * Toggles visibility of the Bar chart
   */
  isChartVisible: PropTypes.bool,
  /**
   * Function that dispatches event with payload about region which should be highlighted.
   * It can change style of the region, or show it at the center of the screen.
   */
  dispatchHighlightRegion: PropTypes.func.isRequired
};

RegionStopsDistributionChartClass.defaultProps = { isChartVisible: false };
