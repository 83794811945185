import PropTypes from 'prop-types';
import React from 'react';
import './ButtonLegend.scss';

export default function ButtonLegend(props) {
  return (
    <div className="button-legend-wrapper">
      <div className="button-arrow" />
      <div className="button-legend">{props.text}</div>
    </div>
  );
}

ButtonLegend.propTypes = { text: PropTypes.string };

ButtonLegend.defaultProps = { text: null };
