import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import MapMarkerPopup from '../../regionAnalysis/components/MapMarkerPopup';
import MapHelperUtil from './mapHelpersUtil';
import mapPopupDefaultStyle from '../../../../common/components/popups/util/mapPopupDefaultStyle';
import { ENTITY_TYPE } from '../../../../common/constants/entityTypes';

/**
 * Util for rendering stops map views
 *
 * @class CourierAnalysisMapViewUtilClass
 * @param map - map ref
 * @param t - translate function
 * @category CourierAnalysis
 */
const delivery = ['==', ['get', 'deliveryType'], 'delivery'];

export default class CourierAnalysisMapViewUtilClass {
  constructor(map, t) {
    this.map = map;
    this.t = t;

    this.markers = {};
    this.markersOnScreen = {};
  }

  /**
   * Add all layers needed for stops
   *
   * @memberOf CourierAnalysisMapViewUtilClass
   * @param {boolean} isGeoFilterOn - whether or not geo filer is applied
   * @function
   */
  plotStops = (isGeoFilterOn) => {
    if (this.map.getLayer('stop-point')) {
      this.map.setLayoutProperty('stop-point', 'visibility', MapHelperUtil.getVisibilityString(!isGeoFilterOn));
      return;
    }

    const notClusterExpression = ['!', ['has', 'point_count']];
    const courierEntityTypeExpression = ['==', ['get', 'entityType'], ENTITY_TYPE.COURIERS];

    this.map.addLayer({
      id: 'stop-point',
      type: 'circle',
      source: 'stops',
      filter: ['all', notClusterExpression, courierEntityTypeExpression],
      paint: {
        'circle-color': ['case', delivery, colorsAndFonts.deliveries_color, colorsAndFonts.pickups_color],
        'circle-radius': ['step', ['get', 'numberOfShipments'], 5, 5, 6, 15, 8, 25, 9, 40, 10],
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      },
      visibility: 'visible'
    });

    this.registerStopsLayerEvents();
  };

  /**
   * Hide stop layers
   *
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  hideStopsLayer = () => {
    if (!this.map.getLayer('stop-point')) {
      return;
    }

    this.map.setLayoutProperty('stop-point', 'visibility', 'none');
  };

  /**
   * Toggles visibility for stop layers
   *
   * @param {boolean} isGeoFilterOn - whether or not geo filer is applied
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  toggleStopLayer = (isGeoFilterOn) => {
    this.map.setLayoutProperty('stop-point', 'visibility', MapHelperUtil.getVisibilityString(isGeoFilterOn));
  };

  /**
   * Place where we set all stops map events
   *
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  registerStopsLayerEvents = () => {
    this.map.on('click', 'stop-point', (e) => {
      e.originalEvent.cancelBubble = true;
      const coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const popupData = MapHelperUtil.createPopupArgument(e.features[0].properties, false);
      const placeholder = document.createElement('div');
      // eslint-disable-next-line react/jsx-props-no-spreading
      ReactDOM.render(<MapMarkerPopup data={popupData} />, placeholder);
      new mapboxgl.Popup(mapPopupDefaultStyle).setDOMContent(placeholder).setLngLat(coordinates).addTo(this.map);
    });

    this.map.on('mouseenter', 'stop-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', 'stop-point', () => {
      this.map.getCanvas().style.cursor = '';
    });
  };

  /**
   * Remove all stops markers
   *
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  removeAllMarkers = () => {
    if (this.markers === {}) {
      return;
    }

    // Delete existing markers before changing the source.
    Object.keys(this.markers).forEach((key) => {
      this.markers[key].remove();
    });

    this.markers = {};
    this.markersOnScreen = {};
  };

  /**
   * Update stops markers based on center entity type
   *
   * @param {string} centerEntityType - center entity type
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  updateMarkers = (centerEntityType) => {
    if (centerEntityType === ENTITY_TYPE.PACKAGE_LOCKERS) {
      this.updatePackageLockerMarker();
    } else {
      this.updateClusterMarkers();
    }
  };

  /**
   * Get unique feature from map source features, needed because mapbox can duplicate points in source, for mor info see https://github.com/mapbox/mapbox-gl-js/issues/3147
   *
   * @param {Array} features - map source features
   * @returns {Array} - unique map source features
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  getUniqueFeatures = (features) => {
    const result = [];
    const map = new Map();
    features.forEach((item) => {
      if (!map.has(item.properties.id)) {
        map.set(item.properties.id, true); // set any value to Map
        result.push(item);
      }
    });

    return result;
  };

  /**
   * Create package lockers marker html element
   *
   * @param {number} totalNumberOfStops - number of stops to show inside pin
   * @returns {object} - mapbox marker element
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  createPackageLockerMarkerElement = (totalNumberOfStops) => {
    const el = document.createElement('div');
    el.innerHTML = `<div class="map-marker"><div class="pin"><span class="text">${totalNumberOfStops}</span></div></div>`;

    return el;
  };

  /**
   * Add marker to map
   *
   * @param {any} id - marker id
   * @param {Array} coords - marker coordinates,
   * @param {Function} createMarkerFunc - function that creates marker html element
   * @param {any} functionProps - function props
   * @returns {object} mapbox marker
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  addMarker = (id, coords, createMarkerFunc, functionProps) => {
    let marker = this.markers[id];

    if (!marker) {
      const markerEl = createMarkerFunc(functionProps);
      marker = new mapboxgl.Marker({ element: markerEl }).setLngLat(coords);

      this.markers[id] = marker;
    }

    if (!this.markersOnScreen[id]) {
      marker.addTo(this.map);
    }

    return marker;
  };

  /**
   * Remove old markers from map
   *
   * @param {object} newMarkers - new markers added in current update
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  removeOldMarkers = (newMarkers) => {
    Object.keys(this.markersOnScreen).forEach((id) => {
      if (!newMarkers[id]) {
        this.markers[id].remove();
      }
    });
    this.markersOnScreen = newMarkers;
  };

  /**
   * Show only current package locker marker
   *
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  updatePackageLockerMarker = () => {
    const features = this.map.querySourceFeatures('stops');

    if (!features || features.length === 0) {
      this.removeOldMarkers({});
      return;
    }
    const newMarkers = {};
    let coords;
    let totalNumberOfStops;
    let id;

    if (features[0].properties.cluster) {
      // handle clustered features
      coords = features[0].geometry.coordinates;
      totalNumberOfStops = features[0].properties.point_count;
      id = features[0].id;
    } else {
      const uniqueFeatures = this.getUniqueFeatures(features);

      coords = uniqueFeatures[0].geometry.coordinates;
      totalNumberOfStops = uniqueFeatures.length;
      id = uniqueFeatures[0].properties.id;
    }

    newMarkers[id] = this.addMarker(id, coords, this.createPackageLockerMarkerElement, totalNumberOfStops);

    // For every marker we've added previously, remove those that are no longer visible
    this.removeOldMarkers(newMarkers);
  };

  /**
   * Show only visible clusters
   *
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */

  updateClusterMarkers = () => {
    const newMarkers = {};
    const features = this.map.querySourceFeatures('stops');

    // For every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (let i = 0; i < features.length; i++) {
      const coords = features[i].geometry.coordinates;
      const props = features[i].properties;
      if (props.cluster) {
        const id = props.cluster_id;
        newMarkers[id] = this.addMarker(id, coords, this.createDonutChart, props);
      }
    }

    // For every marker we've added previously, remove those that are no longer visible
    this.removeOldMarkers(newMarkers);
  };

  /* eslint-disable */
  // Code for creating an SVG donut chart from feature properties
  createDonutChart = (props) => {
    const offsets = [];
    const counts = [props.delivery, props.pickup];

    const chartColors = [colorsAndFonts.deliveries_color, colorsAndFonts.pickups_color];

    let total = 0;
    for (var i = 0; i < counts.length; i++) {
      offsets.push(total);
      total += counts[i];
    }

    const fontSize = total >= 1000 ? 20 : total >= 100 ? 18 : total >= 10 ? 16 : 14;
    const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style='font: ${colorsAndFonts.font_weight_normal} ${fontSize}px ${colorsAndFonts.font_family}'>`;

    for (i = 0; i < counts.length; i++) {
      html += this.donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, chartColors[i]);
    }

    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" /><text dominant-baseline="central" transform="translate(${r}, ${r})">${total.toLocaleString()}</text></svg></div>`;

    const el = document.createElement("div");
    el.innerHTML = html;
    return el.firstChild;
  };

  donutSegment = (start, end, r, r0, color) => {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    return [
      "<path d=\"M",
      r + r0 * x0,
      r + r0 * y0,
      "L",
      r + r * x0,
      r + r * y0,
      "A",
      r,
      r,
      0,
      largeArc,
      1,
      r + r * x1,
      r + r * y1,
      "L",
      r + r0 * x1,
      r + r0 * y1,
      "A",
      r0,
      r0,
      0,
      largeArc,
      0,
      r + r0 * x0,
      r + r0 * y0,
      `" fill="${color}" />`
    ].join(" ");
  };
  /* eslint-enable */
}
