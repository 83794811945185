import React from 'react';
import PropTypes from 'prop-types';
import './MapMarkerPopup.scss';
import { useTranslation } from 'react-i18next';
import CopyToClipboardWrapper from '../../../../common/components/copy/CopyToClipboardWrapper';
import MarkerPopupCircleIcon from '../../../../common/components/marker/components/MarkerPopupCircleIcon';
import MarkerPopupRowWithIcon from '../../../../common/components/marker/components/MarkerPopupRowWithIcon';
import { FEATURE_KEY_ICON_MAP } from '../constants/mapAdditionalFeaturesData';

export default function MapMarkerPopup(props) {
  const { t } = useTranslation();

  const dataKeys = props.data.keyOrder || Object.keys(props.data);
  let missedPointsPickup = null;
  let missedPointsDropoff = null;
  if (props.data.missedDistance && props.data.deliveryType === 'delivery') missedPointsPickup = true;
  if (props.data.missedDistance && props.data.deliveryType === 'pickup') missedPointsDropoff = true;

  return (
    <div className="map-marker-popup">
      <div>
        {dataKeys.map((key) => {
          if (key === 'shipmentCode' && props.data[key]) {
            return (
              <div className="row-wrapper" key={key}>
                <MarkerPopupCircleIcon className={props.data.className} />
                <div className="data-wrapper sh-codes">
                  <div className={`delivery-type ${props.data.className}`}>{t(props.data.deliveryType)}</div>
                  <div className="sh-code">
                    {props.data[key]}
                    <CopyToClipboardWrapper text={props.data[key]} />
                  </div>
                </div>
              </div>
            );
          }

          if (FEATURE_KEY_ICON_MAP[key] && props.data[key]) {
            return <MarkerPopupRowWithIcon key={key} dataKey={key} value={props.data[key]} />;
          }

          return null;
        })}
      </div>
      <div>
        {missedPointsDropoff && (
          <div className="description">
            {t('popUpMissedDistanceDeliveryText')}
            {' '}
            <b>
              {' '}
              {`${props.data.missedDistance} m`}
              {' '}
            </b>
          </div>
        )}
        {missedPointsPickup && (
          <div className="description">
            {t('popUpMissedDistancePickupText')}
            {' '}
            <b>
              {' '}
              {`${props.data.missedDistance} m`}
              {' '}
            </b>
          </div>
        )}
        {/* {props.data.isochroneCoveredEntities && ( */}
        {/*  <div className="description"> */}
        {/*    {t('Isochrone description', { */}
        {/*      count: props.data.isochroneCoveredEntities.count, */}
        {/*      hexType: t(`isochrone-${props.data.isochroneCoveredEntities.hexType}`) */}
        {/*    })} */}
        {/*  </div> */}
        {/* )} */}
      </div>
    </div>
  );
}

MapMarkerPopup.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    workingHours: PropTypes.string,
    address: PropTypes.string,
    className: PropTypes.string,
    deliveryType: PropTypes.string,
    keyOrder: PropTypes.arrayOf(PropTypes.string),
    missedDistance: PropTypes.string
    // isochroneCoveredEntities: PropTypes.object
  })
};

MapMarkerPopup.defaultProps = { data: null };
