import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ClusteringIcons.scss';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export default function ClusteringIcons(props) {
  const { t } = useTranslation();
  const [numberOfClusters, setNumberOfClusters] = useState(3);

  function changeClusterNum(e) {
    setNumberOfClusters(parseInt(e.target.value, 10));
  }

  function triggerClustering() {
    if (props.triggerClustering) {
      props.triggerClustering(numberOfClusters);
    }
  }

  return (
    <div className="clustering-icons-component">
      <div className="left-menu">
        <div className="icon-wrapper" onClick={props.clearPolygons} data-tip={t('Remove all regions')} data-for="cluster-mode-tooltip-right">
          <i className="icon icon-block" />
        </div>
        <div className="icon-wrapper" onClick={props.loadDefaults} data-tip={t('Reset to default')} data-for="cluster-mode-tooltip-right">
          <i className="icon icon-rotate-left" />
        </div>
      </div>
      <div className="right-menu">
        <div className="input-wrapper" data-tip={t('Number of regions')} data-for="cluster-mode-tooltip">
          <input className="input" type="number" min="1" value={numberOfClusters} onChange={changeClusterNum} />
        </div>
        <div className="icon-wrapper" onClick={triggerClustering} data-tip={t('Divide automatically')} data-for="cluster-mode-tooltip">
          <i className="icon icon-magic" />
        </div>
      </div>

      <ReactTooltip id="cluster-mode-tooltip" className="tooltip" place="bottom" effect="solid" />
      <ReactTooltip id="cluster-mode-tooltip-right" className="tooltip" place="right" effect="solid" />
    </div>
  );
}

ClusteringIcons.propTypes = {
  triggerClustering: PropTypes.func.isRequired,
  clearPolygons: PropTypes.func.isRequired,
  loadDefaults: PropTypes.func.isRequired
};
