import React from 'react';
import './Footer.scss';
import PropTypes from 'prop-types';

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="footer-wrapper">
        {props.data
          && props.data.map((element) => (
            <div className="footer-data" key={element.label}>
              {element.label}
              :
              {element.value}
            </div>
          ))}
      </div>
    </div>
  );
}

Footer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  )
};

Footer.defaultProps = { data: null };
