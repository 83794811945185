export const MAP_ADDITIONAL_FEATURES_DATA = {
  packageLockers: {
    icon: 'icon-package',
    label: 'Show package lockers'
  },
  gasStations: {
    icon: 'icon-gas-station',
    label: 'Show gas stations'
  },
  demoShops: {
    icon: 'icon-storefront',
    label: 'Show shopping centers'
  },
  sbbShops: {
    icon: 'icon-package',
    label: 'Show shops'
  },
  shopsToRemove: {
    icon: 'icon-delete',
    label: 'Mailboxes to remove'
  },
  jokicShops: {
    icon: 'icon-storefront',
    label: 'Show stores'
  },
  isochronePin: {
    icon: 'icon-draw-polygon',
    label: 'Show isochrone pin'
  }
};

export const GAS_STATION_TYPES = ['gazprom', 'nis'];

export const MAP_ADDITIONAL_FEATURES_TYPES = {
  PACKAGE_LOCKERS: 'packageLockers',
  GAS_STATION: 'gasStations',
  SBB_SHOPS: 'sbbShops',
  JOKIC_SHOPS: 'jokicShops',
  DEMO_SHOPS: 'demoShops',
  SHOPS_TO_REMOVE: 'shopsToRemove',
  ISOCHRONE_PIN: 'isochronePin'
};

export const FEATURE_KEY_ICON_MAP = {
  name: 'icon-markunread-mailbox-outlined',
  workingHours: 'icon-alarm',
  address: 'icon-location-on-outlined',
  phone: 'icon-phone',
  time: 'icon-alarm',
  numberOfShipments: 'icon-assignment-turned-in-outlined',
  numberOfPackages: 'icon-package',
  timeRange: 'icon-account-eye',
  isochroneCoveredEntitesCount: 'icon-assignment-turned-in-outlined'
};

export const WALKING_DISTANCES = {
  FIVE_MINUTES: 5,
  TEN_MINUTES: 10,
  FIFTEEN_MINUTES: 15
};
