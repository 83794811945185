import mapboxgl from 'mapbox-gl';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import MapHelperUtil from './mapHelpersUtil';
import mapPopupDefaultStyle from '../../../../common/components/popups/util/mapPopupDefaultStyle';
import './courierMovmentHeatmap.scss';

/**
 * Util for rendering heatmap map views
 *
 * @class CourierMovementHeatmapClass
 * @param map - map ref
 * @param t - translate function
 * @category CourierAnalysis
 */
export default class CourierMovementHeatmapClass {
  constructor(map, t) {
    this.map = map;
    this.t = t;
    this.mapLayers = [];
    this.mapViewType = 'cluster';
  }

  /**
   * Hide heatmap layer
   *
   * @memberOf CourierAnalysisMapViewUtilClass
   * @function
   */
  hideHeatmapLayer = () => {
    if (this.map.getLayer('courier-movement-heatmap-layer')) {
      this.map.setLayoutProperty('courier-movement-heatmap-layer', 'visibility', 'none');
    }

    if (this.map.getLayer('courier-movement-geo-locations-layer')) {
      this.map.setLayoutProperty('courier-movement-geo-locations-layer', 'visibility', 'none');
    }
  };

  /**
   * Toggles visibility for heatmap
   *
   * @memberOf CourierMovementHeatmapClass
   * @param {boolean} isHeatMapViewOn - new value to be set
   * @function
   */
  toggleVisibility = (isHeatMapViewOn) => {
    this.map.setLayoutProperty('courier-movement-heatmap-layer', 'visibility', MapHelperUtil.getVisibilityString(isHeatMapViewOn));
    this.map.setLayoutProperty('courier-movement-geo-locations-layer', 'visibility', MapHelperUtil.getVisibilityString(isHeatMapViewOn));
  };

  /**
   * Add all layers needed for heatmap
   *
   * @param {boolean} isHeatMapViewOn - true if heatMap view is on
   * @memberOf CourierMovementHeatmapClass
   * @function
   */
  plotCourierHeatmap = (isHeatMapViewOn) => {
    if (this.map.getLayer('courier-movement-heatmap-layer') && this.map.getLayer('courier-movement-geo-locations-layer')) {
      this.map.setLayoutProperty('courier-movement-heatmap-layer', 'visibility', MapHelperUtil.getVisibilityString(isHeatMapViewOn));
      this.map.setLayoutProperty('courier-movement-geo-locations-layer', 'visibility', MapHelperUtil.getVisibilityString(isHeatMapViewOn));

      return;
    }

    this.map.addLayer({
      id: 'courier-movement-heatmap-layer',
      type: 'heatmap',
      source: 'courier-movement-heatmap',
      layout: { visibility: MapHelperUtil.getVisibilityString(isHeatMapViewOn) },
      paint: {
        'heatmap-color': [
          'interpolate',
          ['linear'],
          ['heatmap-density'],
          0,
          'rgba(33,102,172,0)',
          0.1,
          colorsAndFonts.heatmap_color_1,
          0.25,
          colorsAndFonts.heatmap_color_2,
          0.5,
          colorsAndFonts.heatmap_color_3,
          0.75,
          colorsAndFonts.heatmap_color_4,
          1,
          colorsAndFonts.heatmap_color_5
        ],
        'heatmap-intensity': 0.2,
        // increase radius as zoom increases
        'heatmap-radius': {
          stops: [
            [11, 15],
            [15, 20]
          ]
        },
        // decrease opacity to transition into the circle layer
        'heatmap-opacity': {
          default: 1,
          stops: [
            [14, 1],
            [15, 0]
          ]
        }
      }
    });
    this.mapLayers.push('courier-movement-heatmap-layer');

    this.map.addLayer({
      id: 'courier-movement-geo-locations-layer',
      type: 'circle',
      source: 'courier-movement-heatmap',
      layout: { visibility: MapHelperUtil.getVisibilityString(isHeatMapViewOn) },
      minzoom: 14,
      paint: {
        // increase the radius of the circle as the zoom level and dbh value increases
        'circle-radius': 6,
        'circle-color': colorsAndFonts.heatmap_color_1,
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': {
          stops: [
            [14, 0],
            [15, 1]
          ]
        }
      }
    });
    this.mapLayers.push('courier-movement-geo-locations-layer');

    this.registerCourierHeatmapEvents(this.map);
  };

  /**
   * Place where we set all heatmap map events
   *
   * @memberOf CourierMovementHeatmapClass
   * @function
   */
  registerCourierHeatmapEvents = () => {
    const instance = this;

    this.map.on('click', 'courier-movement-geo-locations-layer', (e) => {
      if (e.originalEvent.cancelBubble) {
        return;
      }
      const coordinates = e.features[0].geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup(mapPopupDefaultStyle)
        .setLngLat(coordinates)
        .setHTML(instance.createDescriptionForHeatmapPointFeature(e.features[0]))
        .addTo(instance.map);
    });

    this.map.on('mouseenter', 'courier-movement-geo-locations-layer', () => {
      instance.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', 'courier-movement-geo-locations-layer', () => {
      instance.map.getCanvas().style.cursor = '';
    });
  };

  /**
   * Get popup content
   *
   * @memberOf CourierMovementHeatmapClass
   * @param {object} feature - feature collection
   * @returns {string} day of the week and timestamp
   * @function
   */
  createDescriptionForHeatmapPointFeature = (feature) => `<div class="movement-tooltip">${this.t(feature.properties.dayOfTheWeek)}, ${feature.properties.dateTime}</div>`;
}
