import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace InfoDialogActions
 * @category Actions
 */

/**
 * Creates an action with information on which dialog to display and where.
 *
 * @param {object} anchorElement - anchor element
 * @param {InfoDialog} component - component to display
 * @param {object} componentProps - component props
 * @returns {{payload: {component: *, componentProps: *, anchorElement: *}, type: string}} Action with a payload
 * @memberOf InfoDialogActions
 * @alias openInfoDialog
 * @function
 */
export const openInfoDialog = (anchorElement, component, componentProps) => ({
  type: ActionTypes.INFO_DIALOG_OPEN,
  payload: {
    anchorElement,
    component,
    componentProps
  }
});

/**
 * Creates an action to close info dialog.
 *
 * @returns {{type: string}} Action
 * @memberOf InfoDialogActions
 * @alias closeInfoDialog
 * @function
 */
export const closeInfoDialog = () => ({ type: ActionTypes.INFO_DIALOG_CLOSE });
