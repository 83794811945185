import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TimePickerWrapper.scss';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function TimePickerWrapper(props) {
  const { t } = useTranslation();
  const [time, setTime] = useState(props.defaultTime || moment());
  const [error, setError] = useState(false);

  const inputStringToTimeFormat = (inputTime) => {
    const [hour, minute] = inputTime.split(':');
    const date = moment();
    date.set('hours', hour);
    date.set('minutes', minute);
    return date;
  };

  const isInputValid = (inputTime) => inputStringToTimeFormat(inputTime).isSameOrAfter(moment());

  const handleChange = (event) => {
    const inputTime = event.target.value;
    const errorOccurred = !isInputValid(inputTime);
    setTime(inputTime);
    setError(errorOccurred);
    props.onChange(!errorOccurred ? inputTime : null);
  };

  const getErrorClass = () => (error ? 'wrapper-error' : '');

  return (
    <div className={`time-picker-wrapper ${getErrorClass()}`}>
      <div className="label-text">{props.label}</div>
      <div className="text-input">
        <input type="time" className="default-input" value={time} onChange={handleChange} />
        {error && <div className="error">{t('Time before present')}</div>}
      </div>
    </div>
  );
}

TimePickerWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  defaultTime: PropTypes.string
};

TimePickerWrapper.defaultProps = { defaultTime: null };
