import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QueryStringUtil from '../../../utils/queryStringUtil';
import CustomSelect from '../CustomSelect';
import MixPanel from '../../../../setup/mixPanel';
import AuthUtil from '../../../utils/authUtil';

/**
 * Select component for storages
 *
 * @param {object} props - props object for the component
 * @returns {JSX.Element} Storage select
 * @component
 * @alias StorageSelect
 * @category Common
 */
export default function StorageSelect(props) {
  const { t } = useTranslation();
  const { returnFullObject, onStorageChange } = props;

  const [storageOptions, setStorageOptions] = useState([]);
  const [storages, setStorages] = useState(null);

  const [selectedStorage, setSelectedStorage] = useState('');

  const getStorageSelectDefaultValue = useCallback(() => {
    let storageSelectDefaultValue;
    if (storages && storages.length > 0) {
      let key = QueryStringUtil.getQueryStringValue('storageId');
      if (!key) {
        key = storages && storages[0].id;
      }

      if (storages && key) {
        storageSelectDefaultValue = {
          label: storages.find((s) => s.id === key).name,
          value: key
        };
      }
    }

    return storageSelectDefaultValue;
  }, [storages]);

  const handlePropsOnChange = useCallback(
    (storageId) => {
      if (onStorageChange) {
        if (returnFullObject) {
          const storage = storages.find((s) => s.id === storageId);
          onStorageChange(storage);

          return;
        }

        onStorageChange(storageId);
      }
    },
    [onStorageChange, returnFullObject, storages]
  );

  useEffect(() => {
    const userStorages = AuthUtil.getStorages();
    if (userStorages) {
      setStorages(userStorages);
      setStorageOptions(
        userStorages.map((storage) => {
          return {
            value: storage.id,
            label: storage.name
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    const storageOpt = getStorageSelectDefaultValue();
    if (storageOpt) {
      handlePropsOnChange(storageOpt.value);
      setSelectedStorage(storageOpt.value);
    }
  }, [getStorageSelectDefaultValue, handlePropsOnChange]);

  const changeSelectedDeliveryArea = useCallback((opt) => {
    MixPanel.track(`Region Analysis - Center changed to ${opt.value}`, { deliveryArea: opt.value });
    QueryStringUtil.setQueryStringValue('storageId', opt.value);

    handlePropsOnChange(opt.value);

    setSelectedStorage(opt.value);
  }, [handlePropsOnChange, setSelectedStorage]);

  return (
    <div className="center-selection-wrapper">
      <div className="label">{props.label ? props.label : t('Storage')}</div>
      {storages && Object.keys(storages).length > 0 && (
        <CustomSelect
          className="select"
          options={storageOptions}
          placeholder={t('Select a storage')}
          value={storageOptions.filter((option) => option.value === selectedStorage)}
          defaultValue={getStorageSelectDefaultValue()}
          onChange={changeSelectedDeliveryArea}
          isDisabled={!storages || Object.keys(storages).length === 1}
          menuPortalTarget={document.querySelector('.MuiDialog-root')}
        />
      )}
    </div>
  );
}

StorageSelect.propTypes = {
  onStorageChange: PropTypes.func.isRequired,
  returnFullObject: PropTypes.bool,
  label: PropTypes.string
};

StorageSelect.defaultProps = {
  returnFullObject: false,
  label: null
};
