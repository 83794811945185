import * as ActionTypes from '../constants/actionTypes';
import { INITIAL_VIEW_STATE } from '../../common/constants/mapConstants';

const initialState = {
  coordinates: {
    lng: INITIAL_VIEW_STATE.lng,
    lat: INITIAL_VIEW_STATE.lat
  }
};

/**
 * Reducer - Authentication
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.COORDINATES_CHANGED:
      return {
        ...state,
        coordinates: action.payload
      };
    case ActionTypes.COORDINATES_RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
