export const POLYGON_COLORS = [
  '#b03060',
  '#F5C242',
  '#B86029',
  '#4FAD5B',
  '#556B2F',
  '#A0522D',
  '#7F0000',
  '#e3590a',
  '#b32ab7',
  '#191970',
  '#ff0000',
  '#007700',
  '#05a7bc',
  '#88b329',
  '#00008B',
  '#daa520',
  '#03ae5e',
  '#215fe2',
  '#880088',
  '#9d315a',
  '#ba6c09',
  '#26a726',
  '#ff0000',
  '#0178b7',
  '#ffaa00',
  '#0000CD',
  '#5648ab',
  '#bc0e30',
  '#00bfff',
  '#fd8213',
  '#9370db',
  '#ea4ddd',
  '#997344',
  '#1276d6',
  '#ff6f5d',
  '#ff5100',
  '#2348d9',
  '#e368e3',
  '#808000',
  '#45b592',
  '#A020F0',
  '#a76201',
  '#e95fa4'
];

export const INITIAL_VIEW_STATE = {
  lng: 19.837954119708865,
  lat: 44.29561798747357,
  zoom: 6.4953035023348535,
  pitch: 0,
  bearing: 0
};

export const COURIER_LOCATION_ZOOM = 15;

export const H3_HEX_RESOLUTION = 10;

export const MAP_STYLE = 'mapbox://styles/milytech/cl2gc6680000r14oize6b5z62';
export const MAP_SATELLITE_VIEW_STYLE = 'mapbox://styles/mapbox/satellite-streets-v11';
