import * as _ from 'lodash';
import haversine from 'haversine-distance';
import moment from 'moment';
import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import SimpleBar from 'simplebar-react';
import CourierSelect from '../../common/components/selections/courierSelect/CourierSelect';
import CourierTeamSelect from '../../common/components/selections/teamSelect/CourierTeamSelect';
import { SHIPMENT_RETURN_DOC_TYPE } from '../../common/constants/shipmentEventsConstants';
import QueryStringUtil from '../../common/utils/queryStringUtil';
import SortUtil from '../../common/utils/sortUtil';
import MixPanel from '../../setup/mixPanel';
import { raygunClient } from '../../setup/raygunClient';
import * as MapActions from '../../state/actions/mapActions';
import * as PageActions from '../../state/actions/pageActions';
import EntityUtil from '../analysis/utils/entityUtil';
import ShipmentsUtil from '../../common/utils/shipmentsUtil';
import RealTimeApi from './api/realTimeApi';
import RealTimeInfoLoader from './components/RealTimeInfoLoader';
import RealTimeMapWrapper from './components/RealTimeMapWrapper';
import './RealTime.scss';
import ShipmentTimeline from './components/ShipmentTimeline';
import DataCard from '../../common/components/cards/DataCard';
import colorsAndFonts from '../../resources/colors-and-fonts.scss';
import MixPanelUtil from '../../common/utils/mixPanelUtil';
import ToggleSwitch from './components/ToggleSwitch';
import AuthUtil from '../../common/utils/authUtil';

class RealTimeClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationData: null,
      couriersData: null,
      shipmentsData: null,
      shipmentsDataList: null,
      markersDataList: null,
      shipmentsStatistics: null,
      showRefresh: true,
      activeLogType: QueryStringUtil.getQueryStringValue('activeLogType') || 'shipments',
      additionalLocationMarker: null,
      searchTextInput: '',
      shipmentsFilter: 'All',
      toggleFilterStates: {
        delivery: true,
        pickup: true,
        unsuccessful: true
      }
    };

    this.stopDataPullTimeoutId = null;
    this.stopDataPullIntervalId = null;
    this.route = [];
  }

  componentDidMount() {
    this.startDataPullProtectionInterval();
    MixPanel.track('Page Load - Real-time');
    MixPanelUtil.setUnloadListener('Page Unload - Real-time');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.shipmentsData) {
      if (
        prevState.shipmentsFilter !== this.state.shipmentsFilter
        || prevState.searchTextInput !== this.state.searchTextInput
        || this.didFilterStatesChange(prevState.toggleFilterStates, this.state.toggleFilterStates)
      ) {
        this.filterShipmentItemList();
      }
    }

    if (this.state.shipmentsData && prevProps.pageLastReloadedAt !== this.props.pageLastReloadedAt) {
      this.debounceGetShipments();
    }

    if (prevState.activeLogType !== this.state.activeLogType) this.resetSearchText();
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
    this.stopDataPull();
  }

  debounceGetShipments = _.debounce(() => {
    this.getCourierShipments();
  }, 500);

  startDataPullProtectionInterval = () => {
    this.stopDataPullTimeoutId = setTimeout(() => {
      this.stopDataPull(true);
    }, 10 * 60 * 1000);
  };

  getCourierLocation = () => {
    if (this.state.courierId) {
      RealTimeApi.getCourierLocation(this.state.courierId)
        .then((res) => {
          if (res?.data?.getCourierLocation) {
            this.route = SortUtil.sortRouteLocations(res.data.getCourierLocation.route);
            this.setState({ locationData: { lat: res?.data?.getCourierLocation.lat, lng: res?.data?.getCourierLocation.lng, route: this.route } });
          }
        })
        .catch((e) => {
          raygunClient.send(e, 'Error loading courier location data for real time', { courierId: this.state.courierId });
          toast.error(this.props.t('Oops something went wrong'));
          this.stopDataPull(true);
        });
    }
  };

  didFilterStatesChange = (previousStates, currentStates) => {
    let different = false;

    Object.keys(previousStates).every((key) => {
      if (previousStates[key] !== currentStates[key]) different = true;
      return !different;
    });
    return different;
  };

  subscribeToCourierLocation = () => {
    if (this.state.courierId) {
      const subscription = RealTimeApi.onLocationChange(this.state.courierId);

      this.courierLocationSubscription = subscription.subscribe({
        next: (data) => {
          const courierLocation = data.data.updateCourierLocation;

          this.route.push(...courierLocation.route);
          this.route = SortUtil.sortRouteLocations(this.route);

          this.setState({ locationData: { lat: courierLocation.lat, lng: courierLocation.lng, route: this.route } });

          return data;
        },
        error: (e) => {
          raygunClient.send(e, 'Error loading subscription data for real time');
          toast.error(this.props.t('Oops something went wrong'));
          this.stopDataPull(true);
        }
      });
    }
  };

  subscribeToShipmentAssignment = () => {
    const subscription = RealTimeApi.onShipmentAssigmentChange(this.state.courierId);
    this.shipmentAssignmentSubscription = subscription.subscribe({
      next: () => {
        this.debounceGetShipments();
      },
      error: (e) => {
        raygunClient.send(e, 'Error loading subscription data for real time');
        toast.error(this.props.t('Oops something went wrong'));
        this.stopDataPull(true);
      }
    });
  };

  subscribeToEventChange = () => {
    const subscription = RealTimeApi.onShipmentEventChange(this.state.courierId);
    this.shipmentEventSubscription = subscription.subscribe({
      next: () => {
        this.debounceGetShipments();
      },
      error: (e) => {
        raygunClient.send(e, 'Error loading subscription data for real time');
        toast.error(this.props.t('Oops something went wrong'));
        this.stopDataPull(true);
      }
    });
  };

  stopDataPull = (updateState) => {
    if (this.courierLocationSubscription) {
      this.courierLocationSubscription.unsubscribe();
      this.courierLocationSubscription = null;
    }

    if (this.shipmentAssignmentSubscription) {
      this.shipmentAssignmentSubscription.unsubscribe();
      this.shipmentAssignmentSubscription = null;
    }

    if (this.shipmentEventSubscription) {
      this.shipmentEventSubscription.unsubscribe();
      this.shipmentEventSubscription = null;
    }

    if (this.stopDataPullTimeoutId) {
      clearTimeout(this.stopDataPullTimeoutId);
      this.stopDataPullTimeoutId = null;
    }

    if (this.stopDataPullIntervalId) {
      clearInterval(this.stopDataPullIntervalId);
      this.stopDataPullIntervalId = null;
    }

    if (updateState) {
      this.setState({ showRefresh: true });
    }
  };

  startDataPull = () => {
    if (this.state.courierId) {
      this.setState({ showRefresh: false });

      this.getCourierLocation();
      switch (AuthUtil.getTenantId()) {
        case 'dexpress':
          this.subscribeToCourierLocation();
          break;
        case 'xexpress':
          this.runCourierLiveLocationFetching();
          break;
        default:
      }
      this.subscribeToShipmentAssignment();
      this.subscribeToEventChange();

      this.debounceGetShipments();

      this.startDataPullProtectionInterval();
    }
  };

  runCourierLiveLocationFetching = () => {
    const instance = this;
    this.stopDataPullIntervalId = setInterval(async () => {
      const courierLocation = await RealTimeApi.getCourierLocation(instance.state.courierId).then((res) => res?.data?.getCourierLocation);
      if (courierLocation) {
        instance.route = SortUtil.sortRouteLocations(courierLocation.route);
        courierLocation.route = instance.route;
        instance.setState({ locationData: courierLocation });
      }
    }, 60 * 1000);
  };

  fetchCouriers = async (teamId, entityType) => {
    this.props.dispatchLoadingPage(true);

    EntityUtil.getEntityData(teamId, entityType, true)
      .then((couriersData) => {
        this.setState({ couriersData: couriersData, courierId: null, showRefresh: true, locationData: null, shipmentsData: null, shipmentsDataList: null });
        this.props.dispatchLoadingPage(false);
      })
      .catch((error) => {
        raygunClient.send(error, 'Failed to load couriers for Real time');
        toast.error(this.props.t('Failed to load couriers'));
        this.stopDataPull();
        this.props.dispatchLoadingPage(false);
      });
  };

  onCourierChange = (opt) => {
    this.stopDataPull();
    const courierId = opt && opt.value;
    if (courierId) {
      this.setState({ locationData: null, courierId: courierId }, () => {
        this.startDataPull();
      });
    }
    MixPanel.track('Real-time - Courier changed', { courierId: courierId });
  };

  // FIXME this is dexpress specific
  removeDocPickupsFromShipments = (shipments) => {
    const newShipments = shipments.map((shipment) => {
      if (!_.isEmpty(shipment.events.PICKED_UP)) {
        const pickupData = shipment.events.PICKED_UP;
        if (shipment.shipmentSourceTypeId === SHIPMENT_RETURN_DOC_TYPE && pickupData.courierId === this.state.courierId) {
          return null;
        }
      }

      return shipment;
    });

    return newShipments.filter(Boolean);
  };

  getCourierShipments = () => {
    RealTimeApi.getCourierShipments(this.state.courierId, moment().format('YYYY-MM-DD'))
      .then((res) => {
        if (res?.data?.queryShipments) {
          let newData = res.data.queryShipments.items;
          newData = SortUtil.sortArrayByUpperCaseField(newData, 'address');
          newData = this.enrichShipmentData(newData);
          newData = this.removeDocPickupsFromShipments(newData);
          this.setState({ shipmentsData: newData }, () => {
            this.filterShipmentItemList();
            this.getShipmentStatistics();
          });
        }
      })
      .catch((e) => {
        raygunClient.send(e, 'Error loading courier shipments data for real time', { courierId: this.state.courierId });
        toast.error(this.props.t('Oops something went wrong'));
        this.stopDataPull();
      });
  };

  getShipmentStatistics = () => {
    const shipmentsStatistics = {
      pickupsTotal: 0,
      deliveriesDone: 0,
      deliveriesTotal: 0,
      unsuccessful: 0
    };

    this.state.shipmentsData.forEach((shipment) => {
      if (shipment.deliveryType === 'delivery') {
        shipmentsStatistics.deliveriesTotal++;
        if (shipment.completed) shipmentsStatistics.deliveriesDone++;
      }
      if (shipment.deliveryType === 'pickup') shipmentsStatistics.pickupsTotal++;
      if (shipment.deliveryType === 'unsuccessful-attempt') shipmentsStatistics.unsuccessful++;
    });
    this.setState({ shipmentsStatistics: shipmentsStatistics });
  };

  createLocationLogs = () => this.state.locationData.route
    .map((point, i) => {
      const prevPoint = i >= 1
        ? {
          latitude: parseFloat(this.state.locationData.route[i - 1].lat),
          longitude: parseFloat(this.state.locationData.route[i - 1].lng)
        }
        : null;
      const currentPoint = { latitude: parseFloat(point.lat), longitude: parseFloat(point.lng) };

      return (
        <div
          className="log"
          key={`${point.gpsTimestamp}-${point.lat}-${point.lng}`}
          onMouseEnter={this.showCourierPrevLocation}
          onMouseLeave={this.hideCourierPrevLocation}
          onClick={this.flyToCourierPrevLocation}
          data-lat={point.lat}
          data-lng={point.lng}
        >
          <div className="timestamp">{moment(point.gpsTimestamp).format('HH:mm:ss')}</div>
          <div className="distance">
            {prevPoint ? `${haversine(currentPoint, prevPoint).toFixed(0)} m` : '-'}
            {' '}
          </div>
        </div>
      );
    })
    .reverse();

  getShipmentsLogElement = () => (
    <div className="shipments-wrapper">
      <div className="filter-wrapper">
        <input
          className="default-input"
          placeholder={this.props.t('Search shipment placeholder')}
          onChange={(e) => {
            this.onSearchTextChangeDebounce(e.target.value);
            this.onSearchTextChangeMixPanelDebounce(e.target.value);
          }}
        />
      </div>
      <div className="shipment-list-wrapper">
        <ShipmentTimeline shipments={this.state.shipmentsDataList} />
      </div>
    </div>
  );

  resetSearchText = () => {
    this.setState({ searchTextInput: '' });
  };

  onSearchTextChange = (e) => {
    this.setState({ searchTextInput: e });
  };

  onSearchTextChangeDebounce = _.debounce(this.onSearchTextChange, 500);

  onSearchTextChangeMixPanel = (e) => {
    MixPanel.track('Real-Time - Shipment search field data entered', { searchInput: e });
  };

  onSearchTextChangeMixPanelDebounce = _.debounce(this.onSearchTextChangeMixPanel, 5000);

  filterShipmentItemList = () => {
    const searchInput = this.state.searchTextInput.toUpperCase();
    const shipmentsToShow = [];
    const markersToShow = [];
    this.state.shipmentsData.forEach((shipment) => {
      if (this.state.shipmentsFilter === 'All') {
        Object.keys(this.state.toggleFilterStates).every((key) => {
          if (shipment.deliveryType.startsWith(key) && this.state.toggleFilterStates[key]) {
            markersToShow.push(shipment);
            if (this.shipmentPassesSearchTextFilter(shipment, searchInput)) shipmentsToShow.push(shipment);
            return false;
          }
          return true;
        });
      } else if (shipment.deliveryType === 'delivery' && !shipment.deliveryCompletedAt) {
        markersToShow.push(shipment);
        if (this.shipmentPassesSearchTextFilter(shipment, searchInput)) shipmentsToShow.push(shipment);
      }
    });
    this.setState({ shipmentsDataList: shipmentsToShow, markersDataList: markersToShow });
  };

  shipmentPassesSearchTextFilter = (shipment, searchInput) => shipment.name.toUpperCase().includes(searchInput)
    || shipment.shipmentCode.toUpperCase().includes(searchInput)
    || shipment.address.toUpperCase().includes(searchInput);

  enrichShipmentData = (shipments) => shipments.map((shipment) => {
    let newShipment = ShipmentsUtil.parseShipmentsEvents(shipment);
    newShipment = ShipmentsUtil.getShipmentLatLngFromEvent(newShipment);
    newShipment = ShipmentsUtil.getShipmentLatLngFromFuzzyAddress(newShipment);

    return {
      ...newShipment,
      ...ShipmentsUtil.getShipmentColorData(newShipment, this.state.courierId)
    };
  });

  setActiveLogType = (type) => {
    this.setState({ activeLogType: type });
    QueryStringUtil.setQueryStringValue('activeLogType', type);
  };

  flyToCourierPrevLocation = (e) => {
    this.props.dispatchCenterToStop(e.currentTarget.dataset.lat, e.currentTarget.dataset.lng, 16);
    MixPanel.track('Real-time - Timeline - Courier location clicked');
  };

  hideCourierPrevLocation = () => {
    this.setState({ additionalLocationMarker: null });
  };

  showCourierPrevLocation = (e) => {
    this.setState({ additionalLocationMarker: { lat: e.currentTarget.dataset.lat, lng: e.currentTarget.dataset.lng } });
  };

  handleRefreshClick = () => {
    MixPanel.track('Real-time - Refresh click');
    this.startDataPull();
  };

  handleFilterChange = (newFilter) => {
    this.setState({ shipmentsFilter: newFilter });
    MixPanel.track(`Real-time - Show ${newFilter}`);
  };

  capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  onToggleChange = (newValue, type) => {
    MixPanel.track(`Real-time - ${this.capitalizeFirstLetter(type)} turned ${newValue ? 'on' : 'off'}`);
    this.setState((prevState) => {
      const toggleFilters = { ...prevState.toggleFilterStates };
      toggleFilters[type] = newValue;
      return { toggleFilterStates: toggleFilters };
    });
  };

  render() {
    return (
      <div className="real-time">
        <div className="filter-section">
          <div className="selections-wrapper">
            <div className="team-selection-wrapper">
              <div className="label">{this.props.t('Team')}</div>
              <CourierTeamSelect onTeamChange={this.fetchCouriers} eventTrackerNamePrefix="Real-time" />
            </div>
            <div className="courier-selection-wrapper">
              <div className="label">{this.props.t('Courier')}</div>
              <CourierSelect couriersData={this.state.couriersData} onChange={this.onCourierChange} />
            </div>
            <div className="info-wrapper">
              <ToggleSwitch options={['All', 'Undelivered']} handleOptionChange={this.handleFilterChange} />
              <RealTimeInfoLoader showRefresh={this.state.showRefresh} handleRefreshClick={this.handleRefreshClick} />
            </div>
          </div>

          <div className="data-section">
            <div className="data-cards">
              <DataCard
                title={this.props.t('Drop-offs')}
                value={this.state.shipmentsStatistics ? this.state.shipmentsStatistics.deliveriesTotal : 0}
                filteredValue={this.state.shipmentsStatistics ? this.state.shipmentsStatistics.deliveriesDone : 0}
                borderColor={colorsAndFonts.deliveries_color}
                toggleData={(value) => {
                  this.onToggleChange(value, 'delivery');
                }}
                toggleClass="delivery"
                toggleDisabled={this.state.shipmentsFilter !== 'All'}
              />
              <DataCard
                title={this.props.t('Pickups')}
                value={this.state.shipmentsStatistics ? this.state.shipmentsStatistics.pickupsTotal : 0}
                borderColor={colorsAndFonts.pickups_color}
                toggleData={(value) => {
                  this.onToggleChange(value, 'pickup');
                }}
                toggleClass="pickups"
                toggleDisabled={this.state.shipmentsFilter !== 'All'}
              />
              <DataCard
                title={this.props.t('Unsuccessful attempts')}
                value={this.state.shipmentsStatistics ? this.state.shipmentsStatistics.unsuccessful : 0}
                borderColor={colorsAndFonts.warning_color}
                toggleData={(value) => {
                  this.onToggleChange(value, 'unsuccessful');
                }}
                toggleClass="unsuccessful"
                toggleDisabled={this.state.shipmentsFilter !== 'All'}
              />
            </div>
          </div>

          <div className="logs-types-wrapper">
            <div
              onClick={() => {
                this.setActiveLogType('shipments');
              }}
              className={`tab-button ${this.state.activeLogType === 'shipments' ? 'active' : ''}`}
            >
              <i className="icon icon-package" />
              {' '}
              {this.props.t('Assigned shipments')}
            </div>
            <div
              onClick={() => {
                this.setActiveLogType('location');
              }}
              className={`tab-button ${this.state.activeLogType === 'location' ? 'active' : ''}`}
            >
              <i className="icon icon-delivery-van" />
              <div className="location-text">
                {' '}
                {this.props.t('Location logs')}
                {' '}
              </div>
            </div>
          </div>
          {this.state.activeLogType === 'location' && (
            <div className="log-header">
              <div className="timestamp-header">{this.props.t('Time')}</div>
              <div className="distance-header">{this.props.t('Distance from previous point in meters')}</div>
            </div>
          )}

          {this.state.activeLogType === 'location' && this.state.locationData && this.state.locationData.route && this.state.locationData.route.length > 0 && (
            <div className="log-wrapper">
              <SimpleBar style={{ maxHeight: '100%' }} className="simple-bar">
                {this.createLocationLogs()}
              </SimpleBar>
            </div>
          )}

          {this.state.activeLogType === 'shipments' && this.getShipmentsLogElement()}
        </div>

        <div className="map-section">
          <RealTimeMapWrapper
            locationData={this.state.locationData}
            locationLive={!this.state.showRefresh}
            shipmentsData={this.state.markersDataList}
            courierId={this.state.courierId}
            additionalLocationMarker={this.state.additionalLocationMarker}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return { ...store.pageState };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchCenterToStop: MapActions.centerToStop
    },
    dispatch
  );
}

RealTimeClass.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  dispatchCenterToStop: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pageLastReloadedAt: PropTypes.string
};

RealTimeClass.defaultProps = { pageLastReloadedAt: null };

const RealTime = connect(mapStateToProps, mapDispatchToProps)(RealTimeClass);

export default withTranslation('translations')(RealTime);
