import React from 'react';
import PropTypes from 'prop-types';
import './MarkerPopupCircleIcon.scss';

export default function MarkerPopupCircleIcon(props) {
  const { className, value } = props;

  return (
    <div className={`marker-popup-circle-icon ${className}`}>
      {value}
      {' '}
    </div>
  );
}

MarkerPopupCircleIcon.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MarkerPopupCircleIcon.defaultProps = {
  className: null,
  value: null
};
