import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';

/**
 * Util for rendering region map views
 *
 * @class RegionClass
 * @param map - map ref
 * @category CourierAnalysis
 */

export default class RegionClass {
  constructor(map) {
    this.map = map;
  }

  /**
   * Add all layers needed for region
   *
   * @memberOf RegionClass
   * @param {object} regionPolygon - region data to be plotted
   * @function
   */
  plotRegion = (regionPolygon) => {
    try {
      if (this.map.getSource('region')) {
        this.map.getSource('region').setData(regionPolygon);
        return;
      }

      this.map.addSource('region', {
        type: 'geojson',
        data: regionPolygon
      });

      this.map.addLayer({
        id: 'region-layer',
        type: 'fill',
        source: 'region',
        minzoom: 8,
        paint: {
          'fill-color': colorsAndFonts.tile_background_color,
          'fill-opacity': 0.4
        }
      });
    } finally {
      const visibility = process.env.REACT_APP_DATA_SOURCE === 'api' ? 'visible' : 'none';
      this.map.setLayoutProperty('region-layer', 'visibility', visibility);
    }
  };

  /**
   * Hide region layers
   *
   * @memberOf RegionClass
   * @function
   */
  hideRegionLayer = () => {
    if (!this.map.getLayer('region-layer')) {
      return;
    }

    this.map.setLayoutProperty('region-layer', 'visibility', 'none');
  };
}
