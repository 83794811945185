export const SELECTION_TOOL = {
  PICK: 'pick',
  POLYGON: 'polygon',
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle'
};

export const EDIT_MODE = {
  ADD: 'union',
  REMOVE: 'difference'
};
