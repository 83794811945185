import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '../../../../../common/components/form/Form';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';

class CourierForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        firstName: props?.data?.firstName,
        lastName: props?.data?.lastName,
        // TODO uncomment, fix, or remove once a mobile application communication is mapped out
        // email: props?.data?.email,
        // phoneNumber: props?.data?.phoneNumber,
        isActive: props?.data?.isActive,
        team: props?.data && props?.data['team.id']
      },
      serverErrors: null
    };
    this.formRef = null;
  }

  getTeamsOptions = () => this.props.teams.map((team) => ({ value: team.id, label: team.name }));

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      this.props.submitCallback(this.state.formData);
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  // isEmailDisabled = () => !(!this.props.data || !this.props.data.id);

  render() {
    return (
      <div className="form-dialog-component">
        <div className="title">{this.props.title}</div>
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
          serverErrors={this.state.serverErrors}
        >
          <FormInput inputType="input" label={this.props.t('First name')} name="firstName" validation={{ required: true }} />
          <FormInput inputType="input" label={this.props.t('Last name')} name="lastName" validation={{ required: true }} />
          {/* TODO uncomment, fix, or remove once a mobile application communication is mapped out */}
          {/* <FormInput */}
          {/*  inputType="input" */}
          {/*  disabled={this.isEmailDisabled()} */}
          {/*  label={this.props.t("Email")} */}
          {/*  helperText={this.props.t("This field cannot be edited")} */}
          {/*  name="email" */}
          {/*  validation={{ required: true }} */}
          {/* /> */}
          {/* <FormInput inputType="input" label={this.props.t("PhoneNumber")} name="phoneNumber" validation={{ required: true, isPhoneNumber: true }} /> */}
          {this.props.teams && this.props.teams.length > 0 && (
            <FormInput
              inputType="select"
              label={this.props.t('Team')}
              options={this.getTeamsOptions()}
              menuPortalTarget={document.querySelector('.MuiDialog-root')}
              menuPosition="fixed"
              placeholder={this.props.t('Select team')}
              name="team"
              isDisabled
            />
          )}
          <FormInput name="isActive" inputType="checkbox" label={this.props.t('Active')} />
        </Form>

        <div className="buttons">
          <Button onClick={this.cancelCallback} type="cancel" text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} text={this.props.t('Save')} />
        </div>
      </div>
    );
  }
}

CourierForm.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
    'team.id': PropTypes.string,
    isActive: PropTypes.bool
  }),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      teamType: PropTypes.string
    })
  )
};

CourierForm.defaultProps = {
  data: null,
  submitCallback: null,
  cancelCallback: null,
  teams: null
};

export default withTranslation('translations')(CourierForm);
