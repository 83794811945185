import { gql } from '@apollo/client';
import { appSyncClient } from '../../../../setup/appSyncClient';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';
import { DB_TYPES } from '../../../../common/constants/dbTypes';

const StorageApi = {
  getStorages: getStorages,
  upsertStorage: upsertStorage,
  deleteStorage: deleteStorage
};

const GetStorages = gql`
  query GetStorages($pk: String) {
    getStorages(pk: $pk) {
      id
      name
      lat
      lng
      integrationId
    }
  }
`;

const UpsertStorage = gql`
  mutation UpsertStorage(
    $pk: String
    $sk: String
    $id: String
    $name: String
    $lat: String
    $lng: String
    $type: String
    $integrationId: String
    $mappingPk: String
    $mappingSk: String
    $mappingType: String
  ) {
    upsertDataWithMapping(
      pk: $pk
      sk: $sk
      input: {
        storageInputData: { name: $name, id: $id, lat: $lat, lng: $lng, type: $type, integrationId: $integrationId }
        mappingInputData: { pk: $mappingPk, sk: $mappingSk, id: $integrationId, milyId: $id, type: $mappingType }
      }
    )
  }
`;

const DeleteEntityWithMapping = gql`
  mutation DeleteEntityWithMapping($pk: String, $sk: String, $mappingPk: String, $mappingSk: String) {
    deleteDataWithMapping(pk: $pk, sk: $sk, input: { mappingInputData: { pk: $mappingPk, sk: $mappingSk } })
  }
`;

async function getStorages() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetStorages, { pk: 'STORAGE' });
  }

  return fetch('/testData/management/storages.json').then((data) => data.json());
}

async function upsertStorage(id, data) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpsertStorage,
      variables: {
        pk: DB_TYPES.STORAGE,
        sk: `${DynamoStringExtensionsUtil.getStoragePrefix()}${id}`,
        name: data.name,
        id: id,
        lat: data.lat,
        lng: data.lng,
        integrationId: data.integrationId,
        type: DB_TYPES.STORAGE,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getStoragePrefix()}${id}`,
        mappingType: DB_TYPES.INTEGRATION_MAPPING
      }
    });
  }

  return null;
}

function deleteStorage(id) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: DeleteEntityWithMapping,
      variables: {
        pk: DB_TYPES.STORAGE,
        sk: `${DynamoStringExtensionsUtil.getStoragePrefix()}${id}`,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getStoragePrefix()}${id}`
      }
    });
  }

  return null;
}

export default StorageApi;
