/**
 * Util for map related functionalities
 *
 * @namespace
 * @category CourierAnalysis
 */
const MapHelperUtil = {
  getStringFromFloat: getStringFromFloat,
  removeElementsByClass: removeElementsByClass,
  getVisibilityString: getVisibilityString,
  createPopupArgument: createPopupArgument
};

/**
 * Convert float to string
 *
 * @param {number} number - number to convert
 * @returns {string} given number in the string form
 * @memberOf MapHelperUtil
 * @function
 */
function getStringFromFloat(number) {
  return number ? number.toString() : '';
}

/**
 * Remove elements by class name
 *
 * @param {string} className - name of the element to be removed
 * @memberOf MapHelperUtil
 * @function
 */
function removeElementsByClass(className) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}

/**
 * Get visibility string
 *
 * @param {boolean} isVisible - boolean value to be transformed into visibility string
 * @returns {string} visible or none based on the input
 * @memberOf MapHelperUtil
 * @function
 */
function getVisibilityString(isVisible) {
  return isVisible ? 'visible' : 'none';
}

/**
 * Create an argument for a popup creation
 *
 * @param {object} properties - properties of a feature visible in popup
 * @param {boolean} missed - boolean value true if method is called from missedPointsClass.js
 * @returns {object} parameters necessary for a popup creation
 * @memberOf MapHelperUtil
 * @function
 */
function createPopupArgument(properties, missed) {
  let distance = null;

  if (missed) {
    distance = properties.missedDistance ? parseInt(properties.missedDistance, 10) : 0;
  }

  return {
    ...properties,
    keyOrder: ['shipmentCode', 'time', 'name', 'address', 'numberOfShipments', 'numberOfPackages'],
    className: properties.deliveryType,
    missedDistance: distance
  };
}

export default MapHelperUtil;
