import moment from 'moment';
import * as ActionTypes from '../constants/actionTypes';
import QueryStringUtil from '../../common/utils/queryStringUtil';

const initialState = {
  regionsDateFrom: QueryStringUtil.getQueryStringValue('fromDate')
    ? moment(QueryStringUtil.getQueryStringValue('fromDate'), 'YYYY-MM-DDTHH').toDate()
    : moment().subtract(1, 'day').toDate(),
  regionsDateTo: QueryStringUtil.getQueryStringValue('toDate')
    ? moment(QueryStringUtil.getQueryStringValue('toDate'), 'YYYY-MM-DDTHH').toDate()
    : moment().subtract(1, 'day').toDate(),
  mapViewType: 'heatmap',
  regions: [],
  regionStopsDistribution: [],
  optimizedRegionStopsDistribution: [],
  highlightedRegion: null
};

/**
 * Reducer - RegionAnalysis
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REGION_ANALYSIS_DATE_CHANGED:
      QueryStringUtil.setQueryStringValue('fromDate', moment(action.payload.dateFrom).startOf('day').format('YYYY-MM-DDTHH'));
      QueryStringUtil.setQueryStringValue('toDate', moment(action.payload.dateTo).endOf('day').format('YYYY-MM-DDTHH'));

      return {
        ...state,
        regionsDateFrom: action.payload.dateFrom,
        regionsDateTo: action.payload.dateTo
      };
    case ActionTypes.REGION_ANALYSIS_MAP_TYPE_CHANGED:
      return {
        ...state,
        mapViewType: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_REGIONS_STOPS_DISTRIBUTION_CHANGED:
      return {
        ...state,
        regionStopsDistribution: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_OPTIMIZED_REGIONS_STOPS_DISTRIBUTION_CHANGED:
      return {
        ...state,
        optimizedRegionStopsDistribution: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_HIGHLIGHT_REGION:
      return {
        ...state,
        highlightedRegion: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
