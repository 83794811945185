import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './UsersPermissions.scss';
import * as PageActions from '../../../state/actions/pageActions';
import PermissionsApi from './api/permissionsApi';
import UsersApi from './api/usersApi';
import PolicyManagement from './components/policies/PolicyManagement';
import UserManagement from './components/users/UserManagement';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import RoleManagement from './components/roles/RoleManagement';
import StepInfoHeader from '../../planning/planManagement/components/StepInfoHeader';
import ZoneManagement from './components/zones/ZoneManagement';
import MixPanel from '../../../setup/mixPanel';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import { tabOrder } from './constants/userManagementConstants';

class UsersPermissionsClass extends React.Component {
  constructor() {
    super();

    this.state = {
      users: null,
      roles: null,
      policies: null,
      zones: null,
      selectedIndex: 0
    };
  }

  componentDidMount() {
    MixPanel.track('Page Load - User Management');
    MixPanelUtil.setUnloadListener('Page Unload - User Management');
    this.setState({ selectedIndex: parseInt(QueryStringUtil.getQueryStringValue('tab-index') ?? 0, 10) });
    this.loadData();
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  loadData = () => {
    this.props.dispatchLoadingPage(true);
    const usersPromise = UsersApi.getWebUsers();
    const rolesPromise = PermissionsApi.getAllRoles();
    const policiesPromise = PermissionsApi.getPolicies();
    const zonesPromise = PermissionsApi.getZones();

    Promise.all([usersPromise, rolesPromise, policiesPromise, zonesPromise]).then((res) => {
      const [usersData, rolesData, policiesData, zonesData] = res;
      const users = usersData?.data?.getUsers || [];
      const roles = rolesData?.data?.getAllRoles || [];
      const policies = policiesData?.data?.getPolicies || [];
      const zones = this.preprocessZoneData(zonesData?.data?.getZones || []);
      const usersJoined = users.map((user) => ({
        ...user,
        roleName: roles.find((role) => role.id === user.roleId)?.name
      }));

      const rolesJoined = roles.map((role) => ({
        ...role,
        policyName: policies.find((policy) => policy.id === role.policyId)?.name,
        zoneName: zones.find((zone) => zone.id === role.zoneId)?.name
      }));

      this.setState({
        users: usersJoined,
        roles: rolesJoined,
        policies: policies,
        zones: zones
      });
      this.props.dispatchLoadingPage(false);
    });
  };

  handleTabChange = (index) => {
    this.setState({ selectedIndex: index });
    MixPanel.track(`User Management - Changed tab to ${tabOrder[index].name}`);
    QueryStringUtil.setQueryStringValue('tab-index', index);
  };

  preprocessZoneData = (zoneData) => zoneData.map((zone) => {
    const newZone = zone;
    newZone.teams = zone.teamIds;
    newZone.deliveryAreas = zone.deliveryAreaIds;
    newZone.storages = zone.storageIds;
    delete newZone.teamIds;
    delete newZone.deliveryAreaIds;
    delete newZone.storageIds;

    return newZone;
  });

  render() {
    return (
      <div className="user-permissions">
        <StepInfoHeader message={this.props.t('userManagement')} options={{ cancelButtonHidden: true, returnButtonHidden: true, nextButtonHidden: true }} />
        <Tabs
          selectedIndex={this.state.selectedIndex}
          selectedTabClassName="active-tab"
          selectedTabPanelClassName="active-tab-panel"
          onSelect={this.handleTabChange}
        >
          <TabList className="tab-list">
            {tabOrder.map((tab) => (
              <Tab className="tab" key={tab.name}>
                <i className={`icon ${tab.icon}`} />
                {this.props.t(tab.name)}
              </Tab>
            ))}
          </TabList>
          <TabPanel>
            <UserManagement users={this.state.users} roles={this.state.roles} refreshData={this.loadData} />
          </TabPanel>
          <TabPanel>
            <RoleManagement roles={this.state.roles} policies={this.state.policies} zones={this.state.zones} refreshData={this.loadData} />
          </TabPanel>
          <TabPanel>
            <PolicyManagement policies={this.state.policies} refreshData={this.loadData} />
          </TabPanel>
          <TabPanel>
            <ZoneManagement zones={this.state.zones} refreshData={this.loadData} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

UsersPermissionsClass.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation('translations')(connect(null, mapDispatchToProps)(UsersPermissionsClass));
