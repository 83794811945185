import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import { BUTTON_TYPES } from './constants/buttonTypes';

export default function Button(props) {
  const classes = ['mily-button'];
  if (props.type === BUTTON_TYPES.CANCEL) {
    classes.push('cancel');
  }
  return (
    <div className={classes.join(' ')} onClick={props.onClick}>
      {props.text}
    </div>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string
};

Button.defaultProps = {
  type: null,
  onClick: () => {},
  text: ''
};
