import qs from 'query-string';

/**
 * Util for query params related functionalities
 *
 * @namespace
 * @category Common
 */
const QueryStringUtil = {
  setQueryStringValue: setQueryStringValue,
  getQueryStringValue: getQueryStringValue
};

/**
 * Sets query string parameters without page reload
 *
 * @param {string} qsValue - query string
 * @function
 * @memberOf QueryStringUtil
 */
const setQueryStringWithoutPageReload = (qsValue) => {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qsValue}`;

  window.history.pushState({ path: newurl }, '', newurl);
};

/**
 *  Adds provided query parameter to a current query string
 *
 * @param {string} key - query parameter key
 * @param {string} value - query parameter value
 * @param {string} queryString - current query string
 * @function
 * @memberOf QueryStringUtil
 */
function setQueryStringValue(key, value, queryString = window.location.search) {
  const values = qs.parse(queryString);
  let newQsValue;
  if (value === null) {
    delete values[key];
    newQsValue = qs.stringify({ ...values });
  } else {
    newQsValue = qs.stringify({ ...values, [key]: value });
  }
  setQueryStringWithoutPageReload(newQsValue);
}

/**
 * Fetches query parameter value by provided key
 *
 * @param {string} key - query parameter key
 * @param {string} queryString - current query string
 * @returns {string[] | string | null} query parameter value
 * @function
 * @memberOf QueryStringUtil
 */
function getQueryStringValue(key, queryString = window.location.search) {
  const values = qs.parse(queryString);
  return values[key];
}

export default QueryStringUtil;
