import { gql } from '@apollo/client';
import moment from 'moment';
import Axios from 'axios';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import CourierAnalysisApi from '../../courierAnalysis/api/courierAnalysisApi';
import { raygunClient } from '../../../../setup/raygunClient';
import { WALKING_DISTANCES } from '../constants/mapAdditionalFeaturesData';

const RegionAnalysisApi = {
  getHexagons: getHexagons,
  getPackageLockers: getPackageLockers,
  getStops: getStops,
  callIsochronePolygonAPI: callIsochronePolygonAPI
};

const GetHexagons = gql`
  query GetHexagons($areaId: String, $fromDate: String, $toDate: String) {
    getHexagonsForDeliveryArea(areaId: $areaId, fromDate: $fromDate, toDate: $toDate) {
      hexagons {
        id
        stops
        shipments
        packages
        shipmentsWithOnePackage
        shipmentsForPackageLockers
      }
    }
  }
`;

async function getHexagons(areaId, dateFrom, dateTo) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const fromDate = dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : null;
    const toDate = dateTo ? moment(dateTo).format('YYYY-MM-DD') : null;

    return ApiHelpers.createQuery(GetHexagons, { areaId: areaId, fromDate: fromDate, toDate: toDate }).then((res) => res?.data?.getHexagonsForDeliveryArea);
  }

  if (!areaId) return {};

  return fetch(`testData/analysis/${areaId}_hex.json`).then((data) => data.json());
}

/**
 * Fetches package lockers for specific teamId
 *
 * @param {string} teamId - team ID
 * @returns {Promise<Array.<object>> | null} Array of couriers
 * @async
 * @function
 * @memberOf RegionAnalysisApi
 * @alias getCouriers
 * * @example
 * {
    "count": 34,
    "packageLockers": [
        {
            "id": "8150",
            "firstName": "-",
            "lastName": "-",
            "zone": "BG-BX",
            "name": "NIS petrol - Ada ciganlija",
            "lat": 44.786217,
            "lng": 20.415366,
            "workingHours": 0 - 24,
        }
     ]
   }
 */

const GetPackageLockers = gql`
  query GetPackageLockers($teamId: String) {
    getPackageLockers(teamId: $teamId) {
      count
      packageLockers {
        id
        lat
        lng
        workingHours
        name
      }
    }
  }
`;

async function getPackageLockers(teamId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetPackageLockers, { teamId: teamId });
  }

  return fetch(`testData/management/package_lockers${teamId}.json`).then((data) => data.json());
}

async function getStops(entityId, dateFrom, dateTo) {
  return CourierAnalysisApi.getStops(entityId, dateFrom, dateTo);
}

async function callIsochronePolygonAPI(lat, lng) {
  const contoursMinutesParameter = Object.values(WALKING_DISTANCES).join(',');
  const path = `https://api.mapbox.com/isochrone/v1/mapbox/walking/${lng},${lat}?contours_minutes=${contoursMinutesParameter}&polygons=true`;
  const params = {
    params: { access_token: process.env.REACT_APP_MAPBOX_TOKEN },
    headers: { 'Content-Type': 'application/json' }
  };
  return Axios.get(path, params)
    .then((response) => response)
    .catch((er) => {
      console.error('error fetching isochrone', er);
      raygunClient.send(er, 'Error fetching isochrone', { lat, lng });
    });
}

export default RegionAnalysisApi;
