import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { Form } from '../../../../common/components/form/Form';
import { FormInput } from '../../../../common/components/form/components/FormInput';
import Button from '../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../common/components/form/utils/defaultFormDialogCss.scss';

class DeliveryAreaForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: props?.data?.name || `${this.props.t('Delivery Area')} ${moment().format('YYYY-MM-DD')}`,
        integrationId: props?.data?.integrationId
      },
      serverErrors: null
    };
    this.formRef = null;
  }

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      this.props.submitCallback(this.state.formData);
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <div className="form-dialog-component">
        <div className="title">{this.props.title}</div>
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
          serverErrors={this.state.serverErrors}
        >
          <FormInput inputType="input" label={this.props.t('Entity name')} name="name" validation={{ required: true }} />
          <FormInput inputType="input" label={this.props.t('Integration Id')} name="integrationId" validation={{ required: true }} />
        </Form>
        <div className="buttons">
          <Button onClick={this.cancelCallback} type="cancel" text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} text={this.props.t('Save')} />
        </div>
      </div>
    );
  }
}

DeliveryAreaForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    integrationId: PropTypes.string
  }),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

DeliveryAreaForm.defaultProps = {
  data: null,
  submitCallback: null,
  cancelCallback: null
};

export default withTranslation('translations')(DeliveryAreaForm);
