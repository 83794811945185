import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { useTranslation } from 'react-i18next';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import './CouriersChart.scss';

/**
 * Chart for displaying couriers statistics
 *
 * @param {object} props - chart props
 * @returns {JSX.Element} - Chart element
 * @component
 * @alias CouriersChart
 * @category statistics
 */
export default function CouriersChart(props) {
  const { t } = useTranslation();

  /**
   * Creates Tooltip to be shown on a Bar hover event
   *
   * @param {object} options - tooltip options
   * options.active - visibility of the element
   * options.payload - region name and stops count
   * @returns {JSX.Element|null} <div> element which shows courier name and stops count on bar hover
   * @function
   */
  const BarChartTooltip = useCallback((options) => {
    const { active } = options;
    const { payload } = options;
    if (!active) return null;

    return (
      <div className="bar-chart-tooltip">
        <p className="label">{payload[0].payload.name}</p>
        <p className="count">
          {t('Stops')}
          :
          {payload[0].payload.stops}
        </p>
      </div>
    );
  }, [t]);

  /**
   * Calculates average number of stops for provided dates
   *
   * @returns {number} Average number of stops for provided dates
   * @function
   */
  const getAvg = () => {
    const stopsDistribution = props.data;
    const sum = stopsDistribution.reduce((acc, value) => acc + value.stops, 0);
    return sum / stopsDistribution.length || 0;
  };

  return (
    <ResponsiveContainer width="100%" height="100%" className="courier-chart">
      <BarChart
        data={props.data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}
      >
        <XAxis dataKey="name" tick={false} />
        <YAxis dataKey="stops" tick={{ fill: '#fff' }} />
        <CartesianGrid strokeDasharray="3 3" stroke="#fff" line={{ fill: '#fff' }} style={{ opacity: 1 }} vertical={false} />
        <Tooltip content={BarChartTooltip} cursor={{ fill: 'transparent' }} />
        <Bar dataKey="stops" fill={colorsAndFonts.selection_color} />
        <ReferenceLine y={getAvg()} stroke="red" strokeDasharray="3 1" />
      </BarChart>
    </ResponsiveContainer>
  );
}

CouriersChart.propTypes = { data: PropTypes.arrayOf(PropTypes.object).isRequired };
