import * as ActionTypes from '../constants/actionTypes';

const initialState = {
  shipmentsData: null,
  regionsData: [],
  routesData: null,
  shipmentsFileName: 'shipments_test_data.csv',
  stopsData: null
};

/**
 * Reducer - PlanningPage
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PLANNING_PAGE_SAVE_SHIPMENTS_DATA:
      return {
        ...state,
        shipmentsData: action.payload.shipmentsData,
        shipmentsFileName: action.payload.shipmentsFileName || initialState.shipmentsFileName
      };
    case ActionTypes.PLANNING_PAGE_RESET_DATA:
      return initialState;
    case ActionTypes.PLANNING_PAGE_SAVE_REGIONS_DATA:
      return {
        ...state,
        regionsData: action.payload
      };
    case ActionTypes.PLANNING_PAGE_SAVE_ROUTES_DATA:
      return {
        ...state,
        routesData: action.payload
      };
    case ActionTypes.CLUSTERING_SET_STOPS:
      return {
        ...state,
        stopsData: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
