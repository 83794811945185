import store from '../../state/store';
import SortUtil from './sortUtil';

/**
 * Util for authentication related functionalities
 *
 * @namespace
 * @category Common
 */
const AuthUtil = {
  getTeams: getTeams,
  getStorages: getStorages,
  getDeliveryArea: getDeliveryArea,
  isFeatureEnabled: isFeatureEnabled,
  hasAnyOfFeaturesEnabled: hasAnyOfFeaturesEnabled,
  getAdditionalMapFeatures: getAdditionalMapFeatures,
  getTenantId: getTenantId,
  getUsername: getUsername,
  isLocationDataHighQuality: isLocationDataHighQuality
};

/**
 * Fetches teams from the store for a current user
 *
 * @returns {Array.<object>} Array of teams
 * @memberOf AuthUtil
 * @function
 */
function getTeams() {
  const { authState } = store.getState();

  return authState?.userPermissions?.teams;
}

/**
 * Fetches storages from the store for a current user
 *
 * @returns {Array.<object>} Array of teams
 * @memberOf AuthUtil
 * @function
 */
function getStorages() {
  const { authState } = store.getState();

  return Object.keys(authState?.userPermissions?.storages).map((key) => authState?.userPermissions?.storages[key]);
}

/**
 * Fetches subcenters from store for a current user
 *
 * @returns {Array.<object>} Array of subcenters
 * @memberOf AuthUtil
 * @function
 */
function getDeliveryArea() {
  const { authState } = store.getState();

  return SortUtil.sortObjectByUpperCaseField(authState?.userPermissions?.deliveryAreas, 'name');
}

/**
 * Checks if feature is enabled for a current user
 *
 * @param {string} featureName - name of the feature
 * @returns {boolean} true if feature is enabled, otherwise false
 * @memberOf AuthUtil
 * @function
 */
function isFeatureEnabled(featureName) {
  const { authState } = store.getState();
  if (isPrivilegedUser(authState)) return true;

  return authState?.userPermissions?.features && authState.userPermissions.features.includes(featureName);
}

function hasAnyOfFeaturesEnabled(features) {
  const { authState } = store.getState();
  if (isPrivilegedUser(authState)) return true;

  return authState?.userPermissions?.features && authState.userPermissions.features.some((f) => features.includes(f));
}

function isPrivilegedUser(authState) {
  const privilegedUserName = `${process.env.REACT_APP_MILY_USER_NAME}.${authState?.userPermissions?.userTenant}${process.env.REACT_APP_MILY_USER_EMAIL}`;
  return privilegedUserName === authState?.userPermissions?.username && authState.userPermissions?.roleId === process.env.REACT_APP_MILY_USER_ROLE;
}

function getAdditionalMapFeatures() {
  const { authState } = store.getState();

  return authState?.userPermissions?.additionalMapFeatures;
}

function getTenantId() {
  const { authState } = store.getState();

  return authState?.userPermissions?.userTenant;
}

function getUsername() {
  const { authState } = store.getState();

  return authState?.userPermissions?.username;
}

// TODO create a structure on back-end that maps these kind of parametes for tenants
function isLocationDataHighQuality() {
  return AuthUtil.getTenantId() !== 'dexpress';
}

export default AuthUtil;
