import React from 'react';
import PropTypes from 'prop-types';
import Timeline from '@material-ui/lab/Timeline';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import MapMarker from '../../../common/components/marker/MapMarker';
import MixPanel from '../../../setup/mixPanel';
import * as MapActions from '../../../state/actions/mapActions';
import './ShipmentTimeline.scss';
import ShipmentTimelineItem from './ShipmentTimelineItem';

const customTimeline = {
  marginTop: '0',
  padding: '0 0 0 0'
};

function ShipmentTimelineClass(props) {
  return !props.shipments || props.shipments.length < 1 ? (
    <span />
  ) : (
    <SimpleBar style={{ maxHeight: '100%' }} className="shipment-timeline">
      <Timeline align="left" style={customTimeline}>
        {props.shipments.map((shipment) => {
          const featureClass = `small ${shipment.className}`;
          return (
            <div className="timeline-item-wrapper" key={shipment.shipmentCode}>
              <TimelineSeparator>
                <MapMarker
                  id={shipment.shipmentCode}
                  icon="icon-package"
                  cssClass={featureClass}
                  onClick={() => {
                    if (shipment?.fuzzyAddress?.geoLat && shipment?.fuzzyAddress?.geoLng) {
                      props.dispatchCenterToStop(shipment.fuzzyAddress.geoLat, shipment.fuzzyAddress.geoLng, 18);
                    }

                    MixPanel.track(`Real-time - Shipments Timeline - ${featureClass.replace('small ', '')} pin clicked`, { shipmentCode: shipment.shipmentCode });
                  }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <ShipmentTimelineItem shipment={shipment} />
            </div>
          );
        })}
      </Timeline>
    </SimpleBar>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchCenterToStop: MapActions.centerToStop },
    dispatch
  );
}

export default withTranslation('translations')(connect(null, mapDispatchToProps)(ShipmentTimelineClass));

ShipmentTimelineClass.propTypes = {
  shipments: PropTypes.arrayOf(Object),
  dispatchCenterToStop: PropTypes.func.isRequired
};

ShipmentTimelineClass.defaultProps = { shipments: [] };
